import { ReactNode, useState } from 'react';
// @mui
// components
import ConfirmDialog from '@/components/confirm-dialog';
import { LoadingButton } from '@mui/lab';
import { MyIcon } from '@/assets/icons/MyIcon';

export default function LeaveConfirm({
  visible,
  onClose,
  handleAction: handleOkConfirm,
  title,
  okBtnText,
  content,
}: {
  content: string | ReactNode;
  okBtnText: string;
  title: string;
  visible: boolean;
  onClose: () => void;
  handleAction: () => Promise<void>;
}) {
  const [loading, setLoading] = useState(false);

  const handleClose = () => {
    onClose();
  };

  const handleAction = async () => {
    setLoading(true);
    try {
      await handleOkConfirm();
      onClose();
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ConfirmDialog
        open={visible}
        onClose={handleClose}
        title={title}
        content={content}
        sx={{
          '.MuiDialogContent-root': { fontSize: '16px' },
          '.MuiDialogActions-root': {
            flexDirection: 'row-reverse',
            justifyContent: 'flex-start',
            '.MuiButton-containedError': {
              marginLeft: '12px',
            },
          },
        }}
        className="loading-btn-red"
        close
        action={
          <LoadingButton
            variant="contained"
            color={'error'}
            onClick={handleAction}
            loading={loading}
            sx={{ marginLeft: '12px' }}
            loadingIndicator={<MyIcon name="loading_red" className="loading-icon-rotate" />}
          >
            {okBtnText}
          </LoadingButton>
        }
      />
    </>
  );
}
