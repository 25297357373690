import { MyIcon } from '@/assets/icons/MyIcon';
import { Box } from '@mui/material';
import { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import { useLocales } from '@/locales';
import { Helmet } from 'react-helmet-async';
import { useAuthContext } from '@/auth/useAuthContext';
import ColorButton from '@/components/ColorButton';
import { CCP_URL } from '@/config';
import { MENUMANAGEMENT } from '@/auth/AuthRoute';

type Porps = {
  setOpenBenginer: () => void;
};

export default function Index({ setOpenBenginer }: Porps) {
  const [openId, setOpenId] = useState('');
  const navigate = useNavigate();
  const { translate } = useLocales();
  const { user, menuIds } = useAuthContext();
  console.log('user', user);
  const QuickStartOptions = [
    {
      logo: '/assets/img/simple_setting.svg',
      title: translate('common.quick_title_simple'),
      desc: translate('common.quick_desc_simple'),
      src: '/merchatsetting/menu/settings',
    },
    ...(menuIds.includes(MENUMANAGEMENT.developerApiDoc)
      ? [
          {
            logo: '/assets/img/integration_setting.svg',
            title: translate('common.quick_title_integration'),
            desc: translate('common.quick_desc_integration'),
            isSpecial: true,
            // src: '/developer/config',
          },
          {
            logo: '/assets/img/account_security.svg',
            title: translate('common.quick_title_account'),
            desc: translate('common.quick_desc_account'),
            src: '/account/settingPageSection/settings',
          },
        ]
      : []),
    {
      logo: '/assets/img/support.svg',
      title: translate('common.quick_title_support'),
      desc: translate('common.quick_desc_support'),
      src: 'https://t.me/CCPaymentSupportBot',
    },
  ];

  const startAccessingOption = [
    {
      logo: '/assets/img/inovice.svg',
      title: translate('common.integration_title_inovice'),
      desc: '',
      src: '/invoice/list/recurring',
    },
    ...(menuIds.includes(MENUMANAGEMENT.developerApiDoc)
      ? [
          {
            logo: '/assets/img/deposit_api.svg',
            title: translate('common.integration_title_deposit'),
            desc: '',
            src: 'https://ccpayment.com/api/doc/?en#deposit-apis',
          },
          {
            logo: '/assets/img/withdrawal_api.svg',
            title: translate('common.integration_title_withdrawal'),
            desc: '',
            src: 'https://ccpayment.com/api/doc/?en#withdrawal-api',
          },
        ]
      : []),

    {
      logo: '/assets/img/t_i_p.svg',
      title: translate('common.integration_title_trouble'),
      desc: '',
      // src: 'https://ccpayment.com/payment-doc-v1/faq/faq',

      src: `${CCP_URL}en/support-center/faq`,
    },
  ];

  const faqOptions = [
    {
      title: `${translate('common.faq_title1')}?`,
      desc: `${translate('common.faq_desc1')}.`,
    },
    ...(menuIds.includes(MENUMANAGEMENT.developerApiDoc)
      ? [
          {
            title: `${translate('common.faq_title2')}?`,
            desc: (
              <>
                <span>{translate('common.faq_desc2_use')}</span>
                <span
                  className="link"
                  onClick={() => {
                    window.open('https://ccpayment.com/api/doc/?en#get-order-information');
                  }}
                >
                  {translate('common.faq_desc2_link')}
                </span>
                <span>{`${translate('common.faq_desc2_order')}.`}</span>
              </>
            ),
          },
        ]
      : []),

    {
      title: `${translate('common.faq_title3')}?`,
      desc: (
        <>
          <div>{`${translate('common.faq_desc3_methods')}:`}</div>
          <ul>
            <li>{`${translate('common.faq_desc3_CCPayment')}.`}</li>
            <li>
              {translate('common.faq_desc3_in')}
              <span
                className="link"
                onClick={() => {
                  navigate('/merchatsetting/menu/settings');
                }}
              >
                {translate('common.faq_desc3_link')}
              </span>
              {`${translate('common.faq_desc3_account')}.`}
            </li>
          </ul>
        </>
      ),
    },
    {
      title: `${translate('common.faq_title4')}?`,
      desc: `${translate('common.faq_desc4')}.`,
    },
    {
      title: `${translate('common.faq_title5')}?`,
      desc: (
        <>
          <div>{`${translate('common.faq_desc5_as')}.`}</div>
          <div>{`${translate('common.faq_desc5_assistance')}!`}</div>
        </>
      ),
    },
  ];

  return (
    <MerchantGuideWrap>
      <Helmet>
        <title> {translate('seo_title.helmet_beginner')}</title>
      </Helmet>
      <Box className="top-bar" component={'div'}>
        <div className="left-box">
          <MyIcon name="beginner" size={24} />
          <span>{translate('common.faq_beginner')}</span>
        </div>
        <div
          className="right-box"
          onClick={() => {
            setOpenBenginer();
          }}
        >
          <MyIcon name="close_gray" size={24} />
        </div>
      </Box>

      <div className="quick-start-wrap">
        <div className="title">{translate('common.faq_quick')}</div>
        {QuickStartOptions.map((item, index) => (
          <div
            key={item.logo}
            className="quick-start-item"
            onClick={() => {
              if (item.src) {
                if (item.src.includes('http')) {
                  window.open(item.src);
                  return;
                }
                navigate(item.src);
                return;
              }
              if (item.isSpecial) {
                if (
                  (!(((user?.merchantStatus ?? 0) & 4) !== 4) && user?.webhookUrl) ||
                  (((user?.merchantStatus ?? 0) & 4) !== 4 && !user?.webhookUrl)
                ) {
                  navigate('/developer/config');
                  return;
                }
                navigate('/merchatsetting/menu/verification/index');
              }
            }}
          >
            <img src={item.logo} alt="" />
            <div>
              <h4>{item.title}</h4>
              <p>{item.desc}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="quick-start-wrap">
        <div className="title">{translate('common.faq_start2')}</div>
        {startAccessingOption.map((item) => (
          <div
            key={item.logo}
            className="quick-start-item"
            onClick={() => {
              if (item.src && !item.src.includes('http')) {
                navigate(item.src);
              } else if (item.src && item.src.includes('http')) {
                window.open(item.src);
              }
            }}
          >
            <img src={item.logo} alt="" style={{ width: '24px', height: '24px' }} />
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <h4 style={{ margin: '0' }}>{item.title}</h4>
            </div>
          </div>
        ))}
      </div>
      <div className="faq-warp">
        <div className="title">{translate('common.faq_faq')}</div>
        {faqOptions.map((item) => (
          <div key={item.title} className="faq-item-wrap">
            <div className="title">
              <span
                style={{ color: `${openId === item.title ? '#3C6FF5' : '#1E1E1E'}` }}
                onClick={() => {
                  if (openId === item.title) {
                    setOpenId('');
                  } else {
                    setOpenId(item.title);
                  }
                }}
              >
                {item.title}
              </span>
              <MyIcon
                name={`${openId === item.title ? 'ic_payment_close' : 'ic_payment_add'}`}
                size={24}
                onClick={() => {
                  if (openId === item.title) {
                    setOpenId('');
                  } else {
                    setOpenId(item.title);
                  }
                }}
              />
            </div>
            {openId === item.title ? (
              <div
                className="desc"
                style={{
                  overflow: openId === item.title ? 'visible' : 'hidden',
                  height: openId === item.title ? 'auto' : '0px',
                  transition: 'height 0.3s',
                }}
              >
                {item.desc}
              </div>
            ) : null}
          </div>
        ))}
      </div>
      <ColorButton
        type="primary"
        forth
        size="medium"
        className="more-help"
        onClick={() => {
          window.open(`${CCP_URL}en/support-center/faq`);
        }}
      >
        <span>{translate('common.faq_more')}</span>
        <MyIcon name="ic_payment_arrow" size={20} />
      </ColorButton>
      {/* <div
        className="more-help"
        onClick={() => {
          window.open('https://ccpayment.com/payment-doc-v1/faq/faq');
        }}
      >
        <span>{translate('common.faq_more')}</span>
        <MyIcon name="ic_payment_arrow" size={20} />
      </div> */}
    </MerchantGuideWrap>
  );
}

const MerchantGuideWrap = styled.div`
  height: 100vh;
  overflow: auto;

  background: #f9faf6;
  /* border-radius: 16px; */
  > .top-bar {
    height: 64px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 700;
    font-size: 18px;
    line-height: 28px;
    color: #27291e;
    padding: 0 16px;
    border-bottom: 1px solid #e8ecf2;
    .left-box {
      display: flex;
      align-items: center;
      img {
        margin-right: 8px;
      }
    }
    .right-box {
      cursor: pointer;
      padding: 8px;
    }
  }

  > .quick-start-wrap {
    padding: 0 12px;
    .title {
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.1px;
      color: #71757e;
      height: 56px;
      line-height: 56px;
    }
    .quick-start-item {
      display: flex;
      padding: 12px 16px;
      :hover {
        border-radius: 8px;
        background: rgba(216, 231, 254, 0.4);
        cursor: pointer;
        h4 {
          color: #3c6ff5;
        }
      }
      > img {
        width: 24px;
        height: 24px;
        margin-right: 16px;
      }
      > div {
        h4 {
          font-weight: 600;
          font-size: 14px;
          line-height: 18px;
          color: #1f2025;
          margin: 0;
          margin-bottom: 4px;
        }
        p {
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #71757e;
          margin: 0;
        }
      }
    }
  }

  .obtain-app-id {
    display: flex;
    justify-content: center;
    margin-top: 24px;
    margin-bottom: 24px;
    button {
      color: #3c6ff5;
      border: 1px solid #3c6ff5;
      border-radius: 8px;
      font-weight: 500;
    }
  }

  .faq-warp {
    padding: 0 16px;
    > .title {
      height: 68px;
      line-height: 68px;
      font-weight: 500;
      font-size: 14px;
      letter-spacing: 0.1px;
      color: #71757e;
    }
    .faq-item-wrap {
      margin-bottom: 24px;
      > .title {
        display: flex;
        font-weight: 500;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 8px;
        > span {
          &:hover {
            cursor: pointer;
            color: #3c6ff5 !important;
          }
        }
        img {
          height: 24px;
          cursor: pointer;
          margin-left: 8px;
        }
      }
      > .desc {
        font-weight: 400;
        font-size: 12px;
        line-height: 18px;
        color: #71757e;
        margin-top: 8px;
        span.link {
          color: #00b8d9;
          text-decoration: underline;
          margin: 0 4px;
          cursor: pointer;
        }
        ul {
          padding-left: 15px;
        }
      }
    }
  }

  .more-help {
    margin: 24px 0 24px 16px;
    img {
      margin-left: 8px;
    }
  }
`;
