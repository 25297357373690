import { useEffect, useState } from 'react';
// @mui
import {
  Box,
  Stack,
  List,
  Badge,
  Tooltip,
  Divider,
  IconButton,
  Typography,
  ListItemText,
  ListItemButton,
} from '@mui/material';
import useSWR from 'swr';
// utils
import { fToNow } from '../../../utils/formatTime';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
import { accountApi_ } from '@/api';
import { NotifyItemType } from '@/api/accountApi_';
import { useLocation, useNavigate } from 'react-router';
import { MyIcon } from '@/assets/icons/MyIcon';
import { observer } from 'mobx-react';
import { accountStore } from '@/stores';

export default observer(function NotificationsPopover() {
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const notifyResp = useSWR(
    'notify-list',
    async () => {
      const ret = await accountApi_.notifyList({ page: 1, page_size: 20 });
      const list = ret?.lists
        ? ret.lists.map((from) => ({
            id: from.message_id,
            title: from.title,
            description: from.body,
            avatar: null,
            type: from.type,
            createdAt: new Date(+from.created),
            isUnRead: !from.readed,
            source: from,
            tokens: from.content_params?.tokens ? JSON.parse(from.content_params?.tokens) : [],
          }))
        : null;
      return { total: ret.total_num, list, unReadCount: ret.unread_num };
    },
    { refreshInterval: 15 * 1000, revalidateOnFocus: false },
  );

  const notifications = notifyResp.data?.list ?? [];
  const totalUnRead = notifyResp.data?.unReadCount ?? 0;
  // console.log('totalUnRead--', totalUnRead, notifications)
  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleMarkAllAsRead = async () => {
    await Promise.all(
      notifications.map((notify) => accountApi_.markNotifyReaded({ message_id: notify.id })),
    );
    notifyResp.mutate();
  };

  useEffect(() => {
    // console.log('notifition:', accountStore.isSwitchMerchant);

    if (accountStore.isSwitchMerchant) {
      // console.log('notifition:', accountStore.isSwitchMerchant);
      notifyResp.mutate();
    }
    // eslint-disable-next-line
  }, [accountStore.isSwitchMerchant, notifyResp, notifyResp.mutate]);

  return (
    <>
      <IconButtonAnimate
        color={openPopover ? 'primary' : 'default'}
        onClick={handleOpenPopover}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <MyIcon name="notifition3" size={24} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        sx={{ width: 360, p: 0, top: '70px !important' }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>

          {totalUnRead > 0 && (
            <Tooltip title="Mark all as read">
              <IconButton color="primary" onClick={handleMarkAllAsRead}>
                <Iconify icon="eva:done-all-fill" />
              </IconButton>
            </Tooltip>
          )}
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />
        <Box className="notifition-scrollbar" sx={{ pb: 1 }}>
          <Scrollbar sx={{ height: { xs: 640 } }}>
            <List
              disablePadding
              sx={{
                padding: '0px 8px',
              }}
              // subheader={
              //   <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
              //     New
              //   </ListSubheader>
              // }
            >
              {notifications.map((notification) => (
                <NotificationItem key={notification.id} notification={notification} />
              ))}
            </List>

            {/* <List
            disablePadding
            subheader={
              <ListSubheader disableSticky sx={{ py: 1, px: 2.5, typography: 'overline' }}>
                Before that
              </ListSubheader>
            }
          >
            {notifications.slice(2, 5).map((notification) => (
              <NotificationItem key={notification.id} notification={notification} />
            ))}
          </List> */}
          </Scrollbar>
        </Box>

        {/* <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            View All
          </Button>
        </Box> */}
      </MenuPopover>
    </>
  );
});

export type NotificationItemProps = {
  id: string;
  title: string;
  description: string;
  avatar: string | null;
  type: NotifyItemType['type'];
  createdAt: Date;
  isUnRead: boolean;
  source: NotifyItemType;
  tokens?: notiTokenType[];
};

export type notiTokenType = {
  symbol: string;
  logo: string;
  pre_off_date: number;
};

function NotificationItem({ notification }: { notification: NotificationItemProps }) {
  const { title } = renderContent(notification);
  const location = useLocation();
  const nav = useNavigate();
  const onClick = async () => {
    if (notification.isUnRead) {
      accountApi_.markNotifyReaded({ message_id: notification.id });
    }
    // 如果是Token pre-delisting
    if (['token_pre_removal'].includes(notification.source.type)) {
      nav(`${location.pathname}/notification-token/${notification.id}`, {
        state: {
          backgroundLocation: location,
        },
      });
    } else {
      if (notification.source?.args?.bill_id) {
        nav(`${location.pathname}/order/${notification.source.args.bill_id}`, {
          state: {
            backgroundLocation: location,
          },
        });
      } else {
        nav(`${location.pathname}/notification/${notification.id}`, {
          state: {
            backgroundLocation: location,
          },
        });
      }
    }
  };

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: '4px',
        mb: '4px',
        // opacity: notification.isUnRead ? 1 : 0.3,
        borderRadius: '4px',
        backgroundColor: notification.isUnRead ? '#F8F9FB' : '',
        '&:hover': {
          '.MuiTypography-root': {
            color: notification.isUnRead ? '' : '#1E1E1E',
          },
          '.MuiStack-root': {
            span: {
              color: 'text.secondary',
            },
          },
          backgroundColor: '#E8ECF2',
        },
      }}
      onClick={onClick}
    >
      {/* <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar> */}

      <ListItemText
        disableTypography
        primary={title}
        secondary={
          <Box>
            <Stack direction="row" sx={{ mt: 0.5, typography: 'caption', color: 'text.disabled' }}>
              {/* <MyIcon name="hollowClock" size={16} style={{ marginRight: '4px', height: '16px' }} /> */}
              {/* <Iconify icon="eva:clock-fill" width={16} sx={{ mr: 0.5 }} /> */}

              <Typography variant="caption" color={'text.secondary'}>
                {fToNow(notification.createdAt)}
              </Typography>
            </Stack>
          </Box>
        }
      />
    </ListItemButton>
  );
}

function renderContent(notification: NotificationItemProps) {
  const regex = /<p>.*?<\/p>/;
  const match = notification.source.body?.match(regex);
  let desc = notification.source.body;
  if (match) {
    desc = match[0];
  }

  const title = (
    <>
      <Typography
        variant="subtitle2"
        color={notification.isUnRead ? 'text.primary' : 'text.secondary'}
        component={'div'}
        className="text-one-overflow-hidden"
        sx={{ width: '100%' }}
      >
        {notification.title}
      </Typography>
      <Typography
        component="div"
        variant="body2"
        color={notification.isUnRead ? 'text.primary' : 'text.secondary'}
        className="text-one-overflow-hidden"
        sx={{
          width: '100%',
          div: {
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            '& p': {
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            },
          },
        }}
      >
        {['BellMessage'].includes(notification.type) ? (
          <div dangerouslySetInnerHTML={{ __html: desc }} />
        ) : (
          notification.description
        )}
      </Typography>
    </>
  );

  // if (notification.type === 'order_placed') {
  //   return {
  //     avatar: <img alt={notification.title} src="/assets/icons/notification/ic_package.svg" />,
  //     title,
  //   };
  // }
  // if (notification.type === 'order_shipped') {
  //   return {
  //     avatar: <img alt={notification.title} src="/assets/icons/notification/ic_shipping.svg" />,
  //     title,
  //   };
  // }
  // if (notification.type === 'mail') {
  //   return {
  //     avatar: <img alt={notification.title} src="/assets/icons/notification/ic_mail.svg" />,
  //     title,
  //   };
  // }
  // if (notification.type === 'chat_message') {
  //   return {
  //     avatar: <img alt={notification.title} src="/assets/icons/notification/ic_chat.svg" />,
  //     title,
  //   };
  // }
  return {
    avatar: notification.avatar ? <img alt={notification.title} src={notification.avatar} /> : null,
    title,
  };
}
