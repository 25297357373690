import { useState } from 'react';
// @mui
// components

import ConfirmDialog from '@/components/confirm-dialog';
import { LoadingButton } from '@mui/lab';
import { MyIcon } from '@/assets/icons/MyIcon';
import { accountItem } from '@/api/accountApi_';
import { Box } from '@mui/material';
/**
 *
 * @author jasee
 * @description 邮件邀请是否接受弹窗
 */
function StepsDialog({
  visible,
  onClose,
  handleAction: handleOkConfirm,
  title,
  okBtn,
  celBtn,
  closeBtn,
  content,
  cancelBtn,
  sx,
  type,
  finshed,
  celloading,
}: {
  content: string[];
  okBtn: string;
  title: string;
  visible: boolean;
  onClose: (isSearch?: boolean) => void;
  handleAction: (type?: number) => void;
  finshed: boolean;
  celloading?: boolean;
  row?: accountItem;
  celBtn?: string;
  closeBtn?: boolean;
  cancelBtn?: boolean;
  sx?: {};
  type?: 'error' | 'success' | 'inherit' | 'info' | 'primary' | 'secondary' | 'warning';
}) {
  const [content_] = useState(content);
  const handleCloseConfirm = () => {
    onClose(true);
  };

  const handleAction = async (type?: number) => {
    try {
      await handleOkConfirm(type);
    } catch (error) {
      console.log('error', error);
    } finally {
      console.log('finally', finshed);
    }
  };

  return (
    <>
      <ConfirmDialog
        open={visible}
        closeBtn={closeBtn}
        onClose={handleCloseConfirm}
        title={title}
        content={
          <div>
            {content_?.map((item, index) => (
              <div style={{ ...sx }} key={index}>
                {item}
              </div>
            ))}
          </div>
        }
        sx={{
          '.MuiDialogContent-root': { fontSize: '16px' },
          '.MuiDialogActions-root': {
            flexDirection: 'row-reverse',
            justifyContent: 'flex-start',
            '.MuiButton-containedError': {
              marginLeft: '12px',
            },
          },
        }}
        close={cancelBtn}
        action={
          <Box sx={{ display: 'flex' }}>
            {celBtn && (
              <Box>
                <LoadingButton
                  variant="outlined"
                  color="inherit"
                  onClick={() => handleAction(2)}
                  loading={celloading}
                  sx={{
                    marginLeft: '12px',
                    ':hover': {
                      background: '#F8F9FB',
                    },
                  }}
                  loadingIndicator={<MyIcon name="loading_black" className="loading-icon-rotate" />}
                >
                  {celBtn}
                </LoadingButton>
              </Box>
            )}

            <Box
              className={
                !type || type === 'primary'
                  ? 'loading-btn'
                  : type === 'error'
                  ? 'loading-btn-red'
                  : ''
              }
            >
              <LoadingButton
                variant="contained"
                color={type ?? 'primary'}
                onClick={() => handleAction(1)}
                loading={finshed}
                sx={{ marginLeft: '12px' }}
                loadingIndicator={
                  <MyIcon
                    name={type === 'error' ? 'loading_red' : 'loading'}
                    className="loading-icon-rotate"
                  />
                }
              >
                {okBtn}
              </LoadingButton>
            </Box>
          </Box>
        }
      />
    </>
  );
}

export default StepsDialog;
