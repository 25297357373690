import { Helmet } from 'react-helmet-async';
// @mui
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  CardMedia,
  Container,
  InputAdornment,
  MenuItem,
  Typography,
} from '@mui/material';
// components
import { useSettingsContext } from '../components/settings';
import { AutocompleteElement, FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui';
import { useQRCode } from 'next-qrcode';

import { useEffect, useState } from 'react';
import { encrypt, priKey } from '@/utils/demo-sign';
import { useSnackbar } from 'notistack';
import { MaintainBox } from './balance/Utils';
import { MyIcon } from '@/assets/icons/MyIcon';
import { useLocales } from '@/locales';
import { ChainStatus } from '@/stores/WalletStroe';

export default function DemoPage() {
  const [order, setOrder] = useState<any>({});
  const [tokenList, setTokenList] = useState([]);
  const [chainList, setChainList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [paymentSettings, setPaymentSettings] = useState<{
    id: number;
    merchant_id: number;
    mid: string;
    title: string;
    app_id: string;
    api_key: string;
    logo: string;
    status: number;
    email_notify: number;
    client_id: string;
    client_secret: string;
    created: string;
    updated: string;
    sign_type: number;
  }>({
    id: 0,
    merchant_id: 0,
    mid: '',
    title: '',
    app_id: '',
    api_key: '',
    logo: 'https://test-resource.cctip.io/head/2022December/f14e43d17e2fcad04449b183d781e7f5.png',
    status: 1,
    email_notify: 7,
    client_id: '',
    client_secret: '',
    created: '',
    updated: '',
    sign_type: 1,
  });
  const { enqueueSnackbar } = useSnackbar();
  const { themeStretch } = useSettingsContext();
  const { Image } = useQRCode();
  const { translate } = useLocales();

  const formContext = useForm({
    defaultValues: {
      privateKey: priKey,
      token: null as any,
      chain: null as any,
      app_id: '',
      api_key: '',
      amount: '',
    },
  });

  const merchant_id = paymentSettings?.mid ?? '';
  let app_id = paymentSettings?.app_id ?? '';
  let api_key = paymentSettings?.api_key ?? '';
  const token = formContext.watch('token');
  const chain = formContext.watch('chain');
  const amount = formContext.watch('amount');

  async function getPaymentSettings() {
    app_id = formContext.getValues('app_id');
    api_key = formContext.getValues('api_key');
    try {
      setPaymentSettings({
        app_id,
        api_key,
      } as any);
      getTokenList();
    } catch (error) {
      enqueueSnackbar(error.message, { variant: 'error' });
    }
  }

  async function getTokenList() {
    setLoading(true);
    const time = Math.floor(Date.now()).toString();
    const resp = await fetch('/payment/v1/token/support/list', {
      method: 'POST',
      headers: {
        'content-type': 'application/json',
        Appid: app_id,
        Timestamp: time,
        'X-Timestamp': time,
        Sign: encrypt({ app_id, api_key, time } as any, 'token'),
        Authorization: JSON.parse(localStorage.getItem('Authorization') as string),
      },
    });
    const json = await resp.json();
    if (json.code !== 1000) {
      enqueueSnackbar(json.msg, { variant: 'error' });
      setLoading(false);
    } else {
      const list = json.data?.list ?? [];
      setTokenList(list);
      setLoading(false);
    }
  }

  async function getChainList() {
    const time = Math.floor(Date.now()).toString();
    const resp = await fetch('/ccpayment/v1/token/chain', {
      method: 'POST',
      body: JSON.stringify({ token_id: token?.token_id }),
      headers: {
        'content-type': 'application/json',
        Appid: app_id,
        Timestamp: time,
        'X-Timestamp': time,
        Authorization: JSON.parse(localStorage.getItem('Authorization') as string),
        Sign: encrypt(
          {
            app_id,
            api_key,
            token_id: JSON.stringify({ token_id: token?.token_id }),
            time,
          } as any,
          'chain',
        ),
      },
    });
    const json = await resp.json();
    if (json.code !== 1000) {
      enqueueSnackbar(json.msg, { variant: 'error' });
    } else {
      const list = json.data?.list ?? [];
      setChainList(list);
    }
  }

  async function postData(data: any) {
    setOrder({});
    const out_order_no = Math.floor(Date.now()).toString();
    const time = Math.floor(Date.now() / 1000).toString();
    const body = {
      notify_url: data.notify_url || '',
      remark: '',
      token_id: data.chain.token_id,
      amount: data.amount,
      merchant_order_id: out_order_no,
      fiat_currency: 'USD',
      custom_value: data.custom_value || '',
    };
    const resp = await fetch('/ccpayment/v1/bill/create', {
      method: 'POST',
      body: JSON.stringify(body),
      headers: {
        'content-type': 'application/json',
        Appid: app_id,
        Timestamp: time,
        Sign: encrypt(
          {
            app_id,
            api_key,
            merchant_order_id: out_order_no,
            amount: data.amount,
            chain: data.chain.chain,
            contract: data.chain.contract,
            time,
            bodystr: JSON.stringify(body),
          },
          'create',
        ),
      },
    });
    const json = await resp.json();
    setOrder(json.data ?? {});
    if (json.code !== 10000) {
      enqueueSnackbar(json.msg, { variant: 'error' });
    } else {
      enqueueSnackbar('Order created!');
    }
  }

  useEffect(() => {
    formContext.setValue('chain', undefined);
    // eslint-disable-next-line
  }, [formContext, token?.token_id]);

  useEffect(() => {
    if (tokenList.length > 0) {
      getChainList();
    }
    // eslint-disable-next-line
  }, [token?.token_id]);

  const handleGetAll = () => {
    if (!formContext.getValues('app_id') || !formContext.getValues('api_key')) return;
    getPaymentSettings();
  };

  return (
    <>
      <Helmet>
        <title>{translate('seo_title.helmet_demo')}</title>
      </Helmet>

      <Container maxWidth={themeStretch ? false : 'xl'}>
        <FormContainer formContext={formContext} handleSubmit={formContext.handleSubmit(postData)}>
          <TextFieldElement
            name="app_id"
            label="app_id"
            sx={{ mb: 3 }}
            fullWidth
            required
            autoComplete="off"
            onChange={(event) => {
              let val = event.target.value;
              formContext.setValue('app_id', val);
            }}
            InputProps={{
              onBlur: (even) => {
                if (!formContext.getValues('app_id') || !formContext.getValues('api_key')) return;
                handleGetAll();
              },
            }}
          />
          <TextFieldElement
            name="api_key"
            label="api_key"
            sx={{ mb: 3 }}
            fullWidth
            required
            autoComplete="off"
            onChange={(event) => {
              let val = event.target.value;
              formContext.setValue('api_key', val);
            }}
            InputProps={{
              onBlur: (even) => {
                if (!formContext.getValues('app_id') || !formContext.getValues('api_key')) return;
                handleGetAll();
              },
            }}
          />

          <AutocompleteElement
            name="token"
            label="Select Token"
            options={tokenList ?? []}
            loading={loading}
            autocompleteProps={{
              getOptionLabel(option) {
                return `${option.crypto} ${option.name}`;
              },
              isOptionEqualToValue(option, value) {
                return option.token_id === value.token_id;
              },
              sx: { mb: 3 },
              renderOption(props, option, state) {
                return (
                  <MenuItem
                    {...(props as any)}
                    {...state}
                    disabled={
                      [ChainStatus.Maintain].includes(option.status) ||
                      !formContext.getValues('app_id') ||
                      !formContext.getValues('api_key')
                    }
                  >
                    <Box sx={{ position: 'relative' }}>
                      <Avatar src={option.logo} sx={{ width: 24, height: 24, mr: 1 }} />
                      {[ChainStatus.Maintain].includes(option.status) && (
                        <MyIcon
                          name="ic_token_Pre_removal"
                          style={{ position: 'absolute', left: '-2px', bottom: '-2px' }}
                        />
                      )}
                    </Box>
                    {`${option.crypto} ${option.name}`}
                  </MenuItem>
                );
              },
            }}
            required
            textFieldProps={{
              InputProps: {
                startAdornment: (
                  <InputAdornment position="end">
                    {token?.logo ? (
                      <Avatar src={token.logo} sx={{ width: 24, height: 24 }} />
                    ) : null}
                  </InputAdornment>
                ),
              },
            }}
          />

          <AutocompleteElement
            name="chain"
            label="Select Chain"
            options={chainList ?? []}
            loading={chainList.length < 0}
            textFieldProps={{
              focused: true,
            }}
            autocompleteProps={{
              getOptionLabel(option) {
                return `${option.chain} - ${option.name}`;
              },
              isOptionEqualToValue(option, value) {
                return option.name === value.name;
              },
              sx: { mb: 3 },
              renderOption(props, option, state) {
                return (
                  <MenuItem
                    {...(props as any)}
                    {...state}
                    disabled={
                      [ChainStatus.Maintain].includes(option.status) ||
                      !formContext.getValues('app_id') ||
                      !formContext.getValues('api_key')
                    }
                  >
                    {`${option.chain} ${option.name}`}

                    {[ChainStatus.Maintain].includes(option.status) && (
                      <Box sx={{ ml: 1 }}>
                        <MaintainBox />
                      </Box>
                    )}
                  </MenuItem>
                );
              },
            }}
            required
          />

          <TextFieldElement
            name="amount"
            label="Amount"
            sx={{ mb: 3 }}
            fullWidth
            required
            disabled={!formContext.getValues('app_id') || !formContext.getValues('api_key')}
            autoComplete="off"
            onChange={(event) => {
              let val = event.target.value;
              formContext.setValue('amount', val);
            }}
          />
          <TextFieldElement
            name="notify_url"
            label="notify_url"
            sx={{ mb: 3 }}
            fullWidth
            autoComplete="off"
          />
          <TextFieldElement
            name="custom_value"
            label="custom_value"
            sx={{ mb: 3 }}
            fullWidth
            autoComplete="off"
          />

          <Button
            type="submit"
            variant="contained"
            disabled={
              !merchant_id ||
              !app_id ||
              !formContext.getValues('app_id') ||
              !amount ||
              !formContext.getValues('api_key') ||
              !token ||
              !chain
            }
          >
            {translate('demo.submit')}
          </Button>
        </FormContainer>
        <Card sx={{ maxWidth: 345, mt: 3, mx: 'auto' }}>
          <CardMedia
            sx={{ height: 345 }}
            component={Image}
            options={{ width: 345, margin: 1 }}
            text={order.pay_address ?? '1'}
          />
          <CardContent>
            <Typography variant="body2" color="text.secondary" className="w-100">
              {translate('demo.amount')}: {order.amount}
            </Typography>
            <Typography variant="body2" color="text.secondary" className="w-100">
              {translate('demo.symbol')}: {order.crypto}
            </Typography>
            <Typography variant="body2" color="text.secondary" className="w-100">
              {translate('demo.network')}: {order.network}
            </Typography>
            <Typography
              variant="body2"
              color="text.secondary"
              className="w-100"
              sx={{ wordBreak: 'break-all' }}
            >
              {translate('demo.address')}: {order.pay_address}
            </Typography>
          </CardContent>
        </Card>
      </Container>
    </>
  );
}
