import { Suspense, lazy, ElementType } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense>
      <Component {...props} />
    </Suspense>
  );

export const PageSwap = Loadable(lazy(() => import('@/pages/balance/Swap')));
export const PageSend = Loadable(lazy(() => import('@/pages/balance/SendSwitch')));
export const PageDeposit = Loadable(lazy(() => import('@/pages/balance/Deposit')));
export const OrderDetail = Loadable(lazy(() => import('@/components/OrderDetail/Index')));
export const WebHookDetail = Loadable(lazy(() => import('@/components/OrderDetail/WebHookDetail')));
export const PageAddRecipient = Loadable(
  lazy(() =>
    import('@/sections/MerchantSetting/dialog/add-recipient').then((m) => ({
      default: m.DialogAddRecipientForm,
    })),
  ),
);
export const NotifyDetail = Loadable(lazy(() => import('@/components/notification/index')));
export const TokenPreRemove = Loadable(
  lazy(() => import('@/components/notification/TokenPreRemove')),
);
export const SuggestAFeature = Loadable(lazy(() => import('@/pages/SuggestAFeature/index')));
export const PrepareOfflineForm = Loadable(
  lazy(() => import('@/pages/balance/dialog/PrepareOfflineForm')),
);
export const MaintenanceConfirm = Loadable(
  lazy(() => import('@/pages/balance/dialog/MaintenanceConfirm')),
);
export const MtModal = Loadable(lazy(() => import('@/components/merchant-settings/MtModal')));

export const BindPaymentPasswordForm = Loadable(
  lazy(() => import('@/pages/balance/dialog/bindPaymentPassword')),
);

export const ApiDetails = Loadable(lazy(() => import('@/pages/PaymentApiOrderDetailsPage')));

export const CreateRefundPage = Loadable(lazy(() => import('@/sections/CreateRefund/Index')));
// wallet api

//referral program
export const PageReferralNetworkSend = Loadable(
  lazy(() => import('@/pages/ReferralProgram/SendSwitch')),
);

export default function ModalRoute() {
  const location = useLocation();
  const path = location.state.backgroundLocation.pathname;
  console.log(
    'location.state.backgroundLocation.pathname',
    location.state.backgroundLocation.pathname,
  );

  return (
    <Routes>
      <Route path={`${path}/swap`} element={<PageSwap />} />
      <Route path={`${path}/swap/:token_id`} element={<PageSwap />} />
      <Route path={`${path}/send/:coin_id`} element={<PageSend />} />
      <Route path={`${path}/send/:token_id/add-recipient`} element={<PageAddRecipient />} />
      <Route path={`${path}/deposit`} element={<PageDeposit />} />
      <Route path={`${path}/deposit/:coin_id`} element={<PageDeposit />} />
      <Route path={`${path}/order/:record_id`} element={<OrderDetail />} />
      <Route path={`${path}/notifi/:id`} element={<WebHookDetail />} />
      <Route path={`${path}/add-recipient`} element={<PageAddRecipient />} />
      <Route path={`${path}/notification/:notify_id`} element={<NotifyDetail />} />
      <Route path={`${path}/notification-token/:notify_id`} element={<TokenPreRemove />} />
      <Route path={`${path}/suggestAFeature`} element={<SuggestAFeature />} />
      <Route path={`${path}/Prepare-Offline`} element={<PrepareOfflineForm />} />
      <Route path={`${path}/token-maintain`} element={<MaintenanceConfirm />} />
      <Route path={`${path}/mt-modal`} element={<MtModal />} />
      <Route path={`${path}/set-pw`} element={<BindPaymentPasswordForm />} />

      {/* api order */}
      <Route path={`${path}/api-details`} element={<ApiDetails />} />
      <Route path={`${path}/api-details/order/:record_id`} element={<OrderDetail />} />
      {/* refund */}
      <Route path={`${path}/api-details/refund`} element={<CreateRefundPage />} />
      <Route path={`${path}/refund`} element={<CreateRefundPage />} />

      {/* Referral NetworkSend */}
      <Route path={`${path}/referral-send/:token_id`} element={<PageReferralNetworkSend />} />
    </Routes>
  );
}
