import { useEffect, useState } from 'react';
// @mui
import { Box, Table, Divider, TableRow, TableBody, TableCell, TableContainer } from '@mui/material';

// components
import Scrollbar from '@/components/scrollbar';
import { TableHeadCustom } from '@/components/table';
import { fDate } from '@/utils/formatTime';
import EmptyContent from '@/components/empty-content';
import { useLocales } from '@/locales';
import { MyIcon } from '@/assets/icons/MyIcon';
import { accountApi_ } from '@/api';
import { LoginLogListItem } from '@/api/accountApi_';
import MerchantLoadingNoPosition from '@/layouts/dashboard/nav/components/merchant-loading-no-position';

export default function LoginListTable() {
  const { translate } = useLocales();

  const [loginLogs, setLoginLogs] = useState<LoginLogListItem[]>([]);
  const [loading, setLoading] = useState(false);

  let tableLabels = [
    { id: 'settings_history_name', label: translate('merchantSettings.settings_history_name') },
    {
      id: 'settings_history_location',
      label: translate('merchantSettings.settings_history_location'),
    },
    { id: 'settings_history_ip', label: translate('merchantSettings.settings_history_ip') },
    { id: 'settings_history_decice', label: translate('merchantSettings.settings_history_decice') },
    { id: 'settings_history_time', label: translate('merchantSettings.settings_history_time') },
    // { id: 'settings_history_status', label: translate('merchantSettings.settings_history_status') },
  ];

  const getLoginLogs = async () => {
    setLoading(true);
    try {
      const res = await accountApi_.getLoginLogs();
      if (res?.list) {
        setLoginLogs(res.list);
      }
    } catch (error) {
      console.log('error', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getLoginLogs();
  }, []);

  return (
    <Box sx={{ borderRadius: '0px 0px 16px 16px', height: 'calc(100% - 80px)' }}>
      <TableContainer sx={{ overflow: 'unset' }}>
        {loading ? (
          <Box width={'100%'} height={500}>
            <MerchantLoadingNoPosition width="100%" />
          </Box>
        ) : (
          <Scrollbar
            className="action"
            sx={{ maxHeight: { md: '520px', xl: '900px' }, overflow: 'auto' }}
          >
            <Table
              sx={{
                minWidth: 720,
                '& .MuiTableRow-root': {
                  borderBottom: '1px solid #DCE0E4',
                },
              }}
              stickyHeader
            >
              <TableHeadCustom
                headLabel={tableLabels?.map((tableLabel) => {
                  const item = { ...tableLabel };
                  return item;
                })}
                sx={{
                  '& .MuiTableCell-root': {
                    backgroundColor: '#fff',
                    color: '#919EAB',
                    fontWeight: 500,
                    fontSize: '14px',
                    letterSpacing: '0.1px',
                    // Change table head background color
                    backgroundImage: 'linear-gradient(to bottom, #F4F6F8 0%, #F4F6F8 100%)',
                  },
                }}
              />
              <TableBody
                sx={{
                  '& .MuiTableCell-root': {
                    color: '#1E1E1E',
                    fontWeight: 500,
                    fontSize: '14px',
                    letterSpacing: '0.1px',
                  },
                }}
              >
                {loginLogs?.length > 0 ? (
                  loginLogs.map((row, index) => <OrderTableRow key={index} row={row} />)
                ) : (
                  <TableRow>
                    <TableCell colSpan={12}>
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                        }}
                      >
                        <EmptyContent title={translate('common.No_Data')} />
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </Scrollbar>
        )}
      </TableContainer>

      <Divider />
      {/* paging component */}
      {/* <Box sx={{ p: 0 }}>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 80, 100]}
          component="div"
          count={total}
          rowsPerPage={searchParams.page_size}
          page={searchParams?.page - 1}
          onPageChange={(e: unknown, newPage: number) => {
            getSearchParams({ page: newPage + 1, page_size: searchParams?.page_size || 20 });
          }}
          onRowsPerPageChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const page_size = e.target.value;
            getSearchParams({ page: 1, page_size: Number(page_size) });
          }}
          sx={{
            border: 0,
          }}
        />
      </Box> */}
    </Box>
  );
}

type OrderTableRowProps = {
  row: LoginLogListItem;
};

function OrderTableRow({ row }: OrderTableRowProps) {
  return (
    <>
      <TableRow
        sx={{
          '&:last-child td, &:last-child th': { border: 0 },
          // '&:hover': {
          //   backgroundColor: 'rgba(145, 158, 171, 0.08)',
          // },
        }}
      >
        <TableCell>{row?.merchant_name || '--'}</TableCell>

        <TableCell
          sx={{
            whiteSpace: 'nowrap',
          }}
        >
          {`${row?.location || '--'}`}
        </TableCell>
        <TableCell
          sx={{
            whiteSpace: 'nowrap',
          }}
        >
          {`${row?.ip || '--'}`}
        </TableCell>
        <TableCell
          sx={{
            whiteSpace: 'nowrap',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {row?.device && (
            <MyIcon
              style={{ marginRight: '8px' }}
              name={
                row?.device.includes('Mac OS') || row?.device.includes('Windows')
                  ? 'pc_icon'
                  : 'mobile_icon'
              }
              size={20}
            />
          )}{' '}
          {`${row?.device || '--'}`}
        </TableCell>
        <TableCell
          sx={{
            minWidth: 60,
            whiteSpace: 'nowrap',
          }}
        >
          {row?.login_time ? fDate(row.login_time * 1000, 'dd MMM. yyyy HH:mm') : '--'}
        </TableCell>
        {/* <TableCell>
          <span
            style={{
              color: row?.status_str === 'online' ? '#36B37E' : 'rgba(145, 158, 171, 0.80)',
            }}
          >
            {row?.status_str === 'online' ? 'Online' : 'Offline'}
          </span>
        </TableCell> */}
      </TableRow>
    </>
  );
}
