import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import {
  Box,
  Stack,
  Drawer,
  Button,
  // alpha,
  // Dialog,
  Link,
  Badge,
  // useTheme,
  // Tooltip,
  //  Typography
} from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';
// config
import { CCP_URL, LAYOUTTOP, NAV } from '../../../config';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import { NavSectionVertical } from '../../../components/nav-section';
import SuggestAFeature from '@/pages/SuggestAFeature';
//
import navConfig from './config';
// import NavDocs from './NavDocs';
import NavAccount from './NavAccount';
import { approvalApi } from '@/api';
import { MyIcon } from '@/assets/icons/MyIcon';
import { useAuthContext } from '@/auth/useAuthContext';
import { observer } from 'mobx-react';
import useSWR from 'swr';
import { debounce } from 'lodash';

// import { useNavigate } from 'react-router';

type Props = {
  openNav: boolean;
  onCloseNav: VoidFunction;
  onOpenNav: VoidFunction;
  isFold?: boolean;
  setIsFold: () => void;
};

type RoleListItemType = { roleId: number; name: string; pageIds: string[] };

type NavItemType = {
  title?: string;
  path?: string;
  icon?: JSX.Element;
  subheader: string;
  meta: {
    mid: string[];
    role: number[];
  };
  children: NavItemType[];
};

export default observer(function NavVertical({
  openNav,
  onCloseNav,
  isFold,
  setIsFold,
  onOpenNav,
}: Props) {
  // const { pathname } = useLocation();
  const { user, roleList, showAnnouncement } = useAuthContext();
  let userAssets = user?.version !== 1;
  const isDesktop = useResponsive('up', 'lg');
  const location = useLocation();
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);
  // console.log('user.user', user);

  const { data, mutate } = useSWR('getApprovalPendingList', approvalApi.getApprovalPendingList, {
    refreshInterval: 300000,
    revalidateOnFocus: false,
  });

  const handleNav = (rlist: RoleListItemType[], navList: NavItemType[]) => {
    const newRoutes: NavItemType[] = [];
    for (let nav of navList) {
      if (nav.meta?.mid) {
        if (nav.meta?.role) {
          nav.meta.role = [];
          for (let rl of rlist) {
            if (rl.pageIds.filter((item) => nav.meta!.mid!.includes(item)).length > 0) {
              if (nav.meta?.role) {
                nav.meta.role.push(rl.roleId);
              }
            }
          }
          nav.meta.role = [...new Set(nav.meta.role)];
        }
      }

      const item = { ...nav };

      if (nav.children) {
        item.children = handleNav(rlist, nav.children);
      }

      newRoutes.push(item);
    }
    return newRoutes;
  };

  const navFilter = (navs: any[], role: number = 1): any[] => {
    const newNavs = [] as any[];
    for (let nav of navs) {
      // if (route.meta?.auth) {
      //   continue;
      // }

      if (nav.meta?.role) {
        // role 是数组，要求用户等级在数组中
        if (nav.meta.role instanceof Array) {
          if (!nav.meta.role.includes(role)) continue;
        } // role 不是数组，要求用户等级大于等于role
        else {
          if (role < nav.meta.role) continue;
        }
      }
      const item = { ...nav };

      // 如果路由有子路由，则递归过滤子路由
      if (nav.children) {
        item.children = navFilter(nav.children, role);
      }

      newNavs.push(item);
    }

    return newNavs;
  };

  const navConfigMemo = useMemo(() => {
    const handleNavlist = handleNav(
      roleList,
      !userAssets
        ? navConfig.filter((item) => item.subheader !== 'user_assets')
        : (navConfig as any),
    );

    const handleMutate = debounce(mutate, 1000, {
      leading: false,
      trailing: true,
    });

    const arr = [...navFilter(handleNavlist, user?.role_id || 1)].map((item: any) => {
      const obj = item;
      if (item.subheader === 'merchant_settings') {
        obj.children[0].children = obj.children[0]?.children?.map((item: any) => {
          const obj2 = item;
          if (item.title === 'member') {
            obj2.info = (
              <Box
                sx={{
                  padding: '2px 8px',
                  background: 'rgba(190, 226, 70, 0.16)',
                  color: '#7FA223',
                  lineHeight: '20px',
                  borderRadius: '6px',
                  fontSize: '12px',
                  fontWeight: 500,
                }}
              >
                {user?.member_num || 0}
              </Box>
            );
          }
          return {
            ...obj2,
          };
        });
      }

      if (item.subheader === 'withdrawal_approval') {
        if (data?.count) {
          obj.children[0].icon = (
            <Badge
              color="error"
              component={'div'}
              badgeContent=" "
              overlap="circular"
              variant="dot"
              sx={{
                width: '100%',
                '.MuiBadge-badge': {
                  backgroundColor: (theme) => theme.palette.error.dark,
                  width: '6px',
                  height: '6px',
                  minWidth: '6px',
                },
              }}
            >
              <Box
                sx={{ color: (theme) => theme.palette.text.secondary, width: 1 }}
                component={'span'}
              >
                {obj.children[0].icon}
              </Box>
            </Badge>
          );
        }

        obj.children[0].onClick = handleMutate;

        obj.children[0].children = obj.children[0]?.children?.map((item: any) => {
          const obj2 = item;
          if (item.title === 'Pending List') {
            if (data?.count) {
              obj2.info = (
                <Box
                  sx={{
                    padding: '2px 8px',
                    background: 'rgba(236, 104, 76, 0.16)',
                    color: (theme) => theme.palette.error.dark,
                    lineHeight: '20px',
                    borderRadius: '6px',
                    fontSize: '12px',
                    fontWeight: 500,
                  }}
                >
                  {data?.count || 0}
                </Box>
              );
            }
            obj2.onClick = handleMutate;
          }
          return {
            ...obj2,
          };
        });
      }
      return obj;
    });

    return user?.version !== 1 ? arr : arr.filter((item) => item.subheader !== 'user_list');
    // eslint-disable-next-line
  }, [user?.member_num, user?.role_id, user?.email, user?.version, roleList, data, mutate]);

  const handleOpen = () => {
    setOpen(false);
  };

  const FoldIconName = useMemo(() => {
    let name = 'ic_nav_flod_right';
    if (isFold) {
      name = user?.mode_type === 1 ? 'ic_nav_flod_right' : 'ic_nav_wallet_flod_right';
    } else {
      name = user?.mode_type === 1 ? 'ic_nav_flod_left' : 'ic_nav_wallet_flod_left';
    }
    return name;
  }, [user, isFold]);

  const renderContent = (
    <Scrollbar
      sx={{
        // position: 'relative',
        height: 1,
        background: '#fff',
        '& .simplebar-content': {
          height: 1,
          background: '#fff',
        },
        '.simplebar-vertical': {
          display: 'none',
        },
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        // zIndex: 1200,
        // ':hover': {
        //   '::-webkit-scrollbar': {
        //     display: 'block',
        //   },
        // },
        '::-webkit-scrollbar': {
          display: 'none',
          width: '6px',
          height: '6px',
        },

        // '::-webkit-scrollbar-thumb': {
        //   borderRadius: '12px',
        //   backgroundColor: '#b6bcc1',
        // },
      }}
    >
      <Box>
        <Stack
          sx={{
            display: 'flex',
            flexDirection: 'row',
            // alignItems: 'center',
            // height: '32px',
            // boxSizing: 'content-box',
            padding: '0px 28px 0px 28px',
            // padding: '0px 0px 0px 8px',
            justifyContent: !isFold ? 'inherit' : 'center',
            alignItems: !isFold ? 'inherit' : 'center',
            margin: !isFold ? '32px 0 40px 0' : '32px 0 40px',
          }}
          spacing={3}
        >
          <Logo sx={{ width: '32px', height: '32px', flexShrink: 0 }} />

          {/* 折叠时不展示ccpayment */}
          {!isFold && (
            <Link href={CCP_URL} sx={{ display: 'contents' }}>
              {/* <Tooltip title={translate('nav.Official_website')} placement="right-start"> */}
              <MyIcon name="ccpayment" size={140} style={{ marginLeft: '16px' }} />
              {/* </Tooltip> */}
            </Link>
          )}
        </Stack>
        {user?.mode_type === 1 && (
          <Stack
            mb={5}
            pl={isFold ? 1 : 2}
            pr={isFold ? 1 : 2}
            sx={{
              transition: 'padding 0.3s cubic-bezier(.21,.39,.63,.84)',
            }}
          >
            <NavAccount isFold={isFold} />
          </Stack>
        )}

        <NavSectionVertical data={navConfigMemo} isFold={isFold} />

        <Box sx={{ flexGrow: 1 }} />
      </Box>
      {/* SuggestAFeature nav */}
      {/* 折叠时 !isFold 反馈建议*/}
      <Box
        sx={{
          padding: isFold ? '0 8px 16px 8px' : '0 16px 16px 16px',
          mb: 2,
          transition: 'padding 0.3s cubic-bezier(.21,.39,.63,.84)',
        }}
      >
        {/* SuggestAFeature nav 20231019*/}
        {/* <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: isFold ? 'center' : '',
            cursor: 'pointer',
            mb: isFold ? 1 : 0,
            width: isFold ? 'calc(88px - 16px)!important' : 'calc(280px - 32px)!important',
            height: '48px',
            transition: 'width 0.3s cubic-bezier(.21,.39,.63,.84)',
            ':hover': {
              background: 'rgba(220, 224, 228, 0.32)',
              borderRadius: '8px',
            },
          }}
          onClick={() => {
            setOpen(!open);
          }}
        >
          <Button
            sx={{
              ':hover': {
                background: 'rgba(255, 255, 255, 0)',
              },
              display: isFold ? 'flex' : '',
              flexDirection: isFold ? 'column' : '',
              padding: isFold ? '0px' : '8px 16px',
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '20px',
            }}
          >
            <MyIcon
              name={open ? 'ic_suggest_blue' : 'ic_suggest'}
              size={24}
              style={{ marginRight: !isFold ? '8px' : '0px', cursor: 'pointer' }}
            />
            <span
              className="text-one-overflow-hidden"
              style={{
                width: '100%',
                display: 'inline-block',
                color: open ? '#3C6FF5' : '#637381',
                fontSize: '14px',
              }}
            >
              suggest_icon
            </span>
          </Button>
        </Box> */}
        {/* Help Center nav */}
        {/* <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: isFold ? 'center' : '',
            cursor: 'pointer',
            width: isFold ? 'calc(88px - 16px)!important' : 'calc(280px - 32px)!important',
            height: '48px',
            transition: 'width 0.3s cubic-bezier(.21,.39,.63,.84)',
            ':hover': {
              // background: 'rgba(220, 224, 228, 0.32)',
              background: theme.palette.primary.navHover,
              borderRadius: '8px',
            },
          }}
          onClick={() => {
            // console.log('跳转到帮助文档链接');
            window.open('https://ccpayment.com/payment-doc-v1/ccpayment-for-merchant/home');
          }}
        >
          <Button
            sx={{
              ':hover': {
                background: 'rgba(255, 255, 255, 0)',
              },
              display: isFold ? 'flex' : '',
              flexDirection: isFold ? 'column' : '',
              padding: isFold ? '0px' : '8px 16px',
              fontWeight: 500,
              fontSize: '14px',
              lineHeight: '20px',
            }}
          >
            <MyIcon
              name={`${user?.mode_type === 1 ? 'ic_help_center' : 'ic_help_center3'}`}
              size={24}
              style={{ marginRight: !isFold ? '8px' : '0px', cursor: 'pointer' }}
            />
            {!isFold && (
              <span
                className="text-one-overflow-hidden"
                style={{
                  width: '100%',
                  display: 'inline-block',
                  color: theme.palette.primary.navTextColor,
                  fontSize: '14px',
                }}
              >
                {translate('nav.Help_Center')}
              </span>
            )}
          </Button>
        </Box> */}
      </Box>
      {/* <NavDocs /> */}
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: isFold ? NAV.W_DASHBOARD_MINI : NAV.W_DASHBOARD,
        maxWidth: NAV.W_DASHBOARD,
        borderRight: '1px dashed rgba(145, 158, 171, 0.24)',
        transition: 'width 0.3s cubic-bezier(.21,.39,.63,.84)',
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: isFold ? NAV.W_DASHBOARD_MINI : NAV.W_DASHBOARD,
              bgcolor: 'transparent',
              borderRight: 0,
              // borderRightStyle: 'dashed',
              overflowY: 'visible',
              transition: 'width 0.3s cubic-bezier(.21,.39,.63,.84)',
              top: showAnnouncement ? LAYOUTTOP : 0,
            },
          }}
          sx={{
            '.MuiModal-backdrop': {
              backgroundColor: 'transparent',
            },
          }}
        >
          {/* 折叠btn */}
          <Button
            sx={{
              position: 'absolute',
              top: '16px',
              right: !isDesktop ? '8px' : '-15px',
              p: 0,
              width: '32px',
              height: '32px',
              minWidth: '20px',
              borderRadius: '50%',
              zIndex: 1200,
              backgroundColor: '#fff',
              border: ' 0.75px dashed rgba(145, 158, 171, 0.24)',
              ':hover': {
                backgroundColor: `${user?.mode_type === 1 ? '#EFF3FE' : '#F8FBF1'}`,
              },
            }}
            onClick={() => {
              // 小屏幕时变为导航栏关闭事件
              if (isDesktop) {
                setIsFold();
              } else {
                if (openNav) {
                  onCloseNav();
                } else {
                  onOpenNav();
                }
              }
            }}
          >
            <MyIcon
              name={FoldIconName as any}
              size={16}
              style={{
                cursor: isDesktop ? 'pointer' : 'default',
              }}
            />
          </Button>
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          sx={{
            '.MuiModal-backdrop': {
              backgroundColor: 'transparent',
            },
          }}
          PaperProps={{
            sx: {
              width: NAV.W_DASHBOARD,
            },
          }}
        >
          {/* 折叠btn */}
          <Button
            sx={{
              position: 'absolute',
              top: '8px',
              right: !isDesktop ? '8px' : '-15px',
              p: 0,
              width: '32px',
              height: '32px',
              minWidth: '20px',
              borderRadius: '50%',
              zIndex: 1200,
              backgroundColor: '#fff',
              border: ' 0.75px dashed rgba(145, 158, 171, 0.24)',
              ':hover': {
                backgroundColor: `${user?.mode_type === 1 ? '#EFF3FE' : '#F8FBF1'}`,
              },
            }}
            onClick={() => {
              // 小屏幕时变为导航栏关闭事件
              if (isDesktop) {
                setIsFold();
              } else {
                if (openNav) {
                  onCloseNav();
                } else {
                  onOpenNav();
                }
              }
            }}
          >
            <MyIcon
              name={FoldIconName as any}
              size={16}
              style={{
                cursor: 'pointer',
              }}
            />
          </Button>
          {renderContent}
        </Drawer>
      )}
      {open && <SuggestAFeature open={open} handleOpen={handleOpen} />}
    </Box>
  );
});
