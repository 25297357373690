import { useDropzone } from 'react-dropzone';
// @mui
import { Box, Stack, Button, IconButton, StackProps, Grid } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
// assets
import { MyIcon } from '@/assets/icons/MyIcon';
//
import Iconify from '../iconify';
//
import { UploadProps } from './types';
import RejectionFiles from './errors/RejectionFiles';
import MultiFilePreview from './preview/MultiFilePreview';
import SingleFilePreview from './preview/SingleFilePreview';
import { useLocales } from '@/locales';
import useResponsive from '@/hooks/useResponsive';

const StyledDropZone = styled('div')(({ theme }) => ({
  outline: 'none',
  cursor: 'pointer',
  overflow: 'hidden',
  position: 'relative',
  padding: theme.spacing(5, 1),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${alpha(theme.palette.grey[500], 0.32)}`,
  '&:hover': {
    opacity: 0.72,
  },
}));

export default function Upload({
  disabled,
  multiple = false,
  helperText,
  //
  file,
  onDelete,
  //
  files,
  thumbnail,
  onUpload,
  onRemove,
  onRemoveAll,
  error,
  errorMess,
  sx,
  ...other
}: UploadProps) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple,
    disabled,
    ...other,
  });
  const { translate } = useLocales();
  const isMobile = !useResponsive('up', 'sm');
  const hasFile = !!file && !multiple;

  const hasFiles = files && multiple && files.length > 0;

  const isError = isDragReject || !!error;
  return (
    <Box sx={{ width: '100%', position: 'relative', ...sx }}>
      <Grid container sx={{ display: 'flex', alignItems: 'center', marginTop: '24px' }}>
        {files?.length !== 3 && (
          <Grid item>
            <StyledDropZone
              {...getRootProps()}
              sx={{
                ...(isDragActive && {
                  opacity: 0.72,
                }),
                ...(isError && {
                  color: 'error.main',
                  bgcolor: 'error.lighter',
                  borderColor: 'error.light',
                }),
                ...(disabled && {
                  opacity: 0.48,
                  pointerEvents: 'none',
                }),
                ...(hasFile && {
                  padding: '12% 0',
                }),
                padding: '0px',
                background: 'rgba(190, 226, 70, 0.16)',
                border: 0,
              }}
            >
              <input
                type="file"
                accept=".numbers,image/jpeg,image/png,application/pdf,text/csv,text/plain,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
                {...getInputProps()}
              />
              <Placeholder
                sx={{
                  ...(hasFile && {
                    opacity: 0,
                  }),
                }}
              />
              <Box
                sx={{
                  fontSize: '14px',
                  display: 'flex',
                  alignItems: 'center',
                  color: '#7FA223',
                  border: '0',
                  borderRadius: '8px',
                  padding: '6px 16px',
                  boxSizing: 'border-box',
                  height: '36px',
                  fontWeight: 600,
                  '&:hover': {
                    backgroundColor: 'rgba(190, 226, 70, 0.32)',
                    color: '#7FA223',
                  },
                }}
              >
                <MyIcon name="ic_acc" size={20} style={{ marginRight: '8px' }} />
                {translate('invoices.creat_attachments')}
              </Box>
              {hasFile && <SingleFilePreview file={file} />}
            </StyledDropZone>
          </Grid>
        )}
        <Grid
          item
          xs={isMobile ? 12 : 9}
          mt={isMobile ? 3 : 0}
          sx={{
            fontSize: isMobile ? '12px' : '14px',
            paddingLeft: files?.length === 3 ? '0' : '24px',
            color: errorMess ? '#FF5630' : '#71757E',
            fontWeight: 500,
          }}
        >
          {translate('invoices.creat_up')}&nbsp;{'(' + (files?.length || 0) + '/3)'}
        </Grid>
      </Grid>
      <RejectionFiles fileRejections={fileRejections} />

      {hasFile && onDelete && (
        <IconButton
          size="small"
          onClick={onDelete}
          sx={{
            top: 16,
            right: 16,
            zIndex: 9,
            position: 'absolute',
            color: (theme) => alpha(theme.palette.common.white, 0.8),
            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
            '&:hover': {
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
            },
          }}
        >
          <Iconify icon="eva:close-fill" width={18} />
        </IconButton>
      )}

      {hasFiles && (
        <>
          <Box sx={{ my: 2, marginLeft: '-8px' }}>
            <MultiFilePreview files={files} thumbnail={thumbnail} onRemove={onRemove} />
          </Box>

          <Stack direction="row" justifyContent="flex-end" spacing={1.5}>
            {onRemoveAll && (
              <Button color="inherit" variant="outlined" size="small" onClick={onRemoveAll}>
                Remove all
              </Button>
            )}

            {onUpload && (
              <Button size="small" variant="contained" onClick={onUpload}>
                Upload files
              </Button>
            )}
          </Stack>
        </>
      )}

      {helperText && helperText}
    </Box>
  );
}

function Placeholder({ sx, ...other }: StackProps) {
  return <></>;
}
