// @mui
import { Tooltip } from '@mui/material';
// utils
// components
import { IconButtonAnimate } from '../../../components/animate';

import { observer } from 'mobx-react';
import { useLocales } from '@/locales';
import { currentEnv } from '@/config';

export default observer(function AnnouncementPopover() {
  const { translate: t } = useLocales();
  const baseUrl =
    currentEnv === 'prd'
      ? 'https://ccpayment.com/support-center/announcement/new-features'
      : 'https://efb014f4merchantdev.ccpayment.com/support-center/announcement/new-features';

  return (
    <>
      <IconButtonAnimate
        color={'default'}
        onClick={() => {
          window.open(baseUrl);
        }}
        sx={{
          width: 40,
          height: 40,
          svg: {
            width: 24,
            height: 24,
          },
        }}
      >
        <Tooltip title={t('common.announcement')}>
          <svg
            width="22"
            height="19"
            viewBox="0 0 22 19"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g id="Group 427321031">
              <g id="Vector">
                <path
                  d="M16.8858 8.1112H21.1331C21.3648 8.1112 21.5866 8.20927 21.7493 8.38219C21.912 8.55511 22.0032 8.7874 22.0032 9.03V9.31648C22.0032 9.82233 21.6138 10.2327 21.1331 10.2327H16.8883C16.4076 10.2327 16.0156 9.82233 16.0156 9.31648V9.03C16.0156 8.7874 16.1068 8.55511 16.2695 8.38219C16.4322 8.20927 16.6541 8.1112 16.8858 8.1112Z"
                  fill="#1F2025"
                />
                <path
                  d="M16.6403 3.23253L20.3185 1.10889C20.5192 0.99303 20.7604 0.967037 20.9877 1.03544C21.2151 1.10385 21.4102 1.25941 21.5315 1.46951L21.6748 1.71761C21.9277 2.15569 21.7956 2.70582 21.3793 2.94616L17.7032 5.06857C17.2869 5.30891 16.7423 5.1495 16.4893 4.71141L16.3461 4.46332C16.2248 4.25322 16.1876 4.00645 16.2421 3.77535C16.2965 3.54425 16.4396 3.34839 16.6403 3.23253Z"
                  fill="#1F2025"
                />
                <path
                  d="M17.6975 13.209L21.3757 15.3327C21.5764 15.4485 21.7195 15.6444 21.7739 15.8755C21.8284 16.1066 21.7912 16.3534 21.6699 16.5635L21.5267 16.8116C21.2737 17.2497 20.7313 17.4103 20.315 17.17L16.6388 15.0475C16.2226 14.8072 16.0883 14.2558 16.3412 13.8178L16.4845 13.5697C16.6058 13.3596 16.8009 13.204 17.0283 13.1356C17.2556 13.0672 17.4968 13.0932 17.6975 13.209Z"
                  fill="#1F2025"
                />
              </g>
              <path
                id="Vector (Stroke)"
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.6379 0.767613C13.9073 1.18713 14 1.70528 14 2.25019V16.7131C14 17.2592 13.9073 17.7721 13.6483 18.1905C13.3647 18.6485 12.9385 18.9003 12.4904 18.9753C12.0839 19.0432 11.7112 18.9596 11.4482 18.8665C11.1904 18.7753 10.9567 18.6461 10.7802 18.5146L5.17533 14.6077C5.1 14.5599 5.04135 14.5127 5.00285 14.4803C4.97951 14.4607 4.95846 14.4421 4.94422 14.4294L4.90917 14.3984C4.88246 14.3751 4.88013 14.3751 4.8799 14.3751C4.87506 14.3725 4.73497 14.2987 4.24629 14.2976H1.95112C1.48078 14.2976 0.947749 14.1754 0.537337 13.7658C0.126485 13.3558 0 12.819 0 12.3342V6.60321C0 6.09503 0.14719 5.55878 0.574156 5.16214C0.987795 4.77788 1.50872 4.67042 1.95112 4.67042H4.23835C4.73258 4.67042 4.87479 4.59636 4.88041 4.59344C4.88038 4.59344 4.88042 4.59343 4.88041 4.59344C4.88078 4.59339 4.88341 4.59303 4.90858 4.57107C4.91616 4.56445 4.92292 4.55844 4.93335 4.54914C4.93617 4.54663 4.93925 4.54389 4.94269 4.54083C4.95651 4.52853 4.97722 4.51015 4.99999 4.49085C5.03896 4.45784 5.09956 4.40861 5.17764 4.35904L10.7724 0.526898C10.9417 0.394898 11.1631 0.264317 11.406 0.16722C11.6626 0.0646776 12.0318 -0.03609 12.4398 0.0126492C12.8934 0.0668347 13.3395 0.303122 13.6379 0.767613ZM11.996 2.10915L11.9684 2.13186L6.28374 6.02559C6.27323 6.03479 6.25416 6.0513 6.2234 6.07813C6.13472 6.1555 5.99787 6.26702 5.8002 6.36939C5.40833 6.57231 4.91395 6.67042 4.23835 6.67042H2V12.2976H4.24942C4.92255 12.299 5.4147 12.3983 5.80478 12.6018C6.00114 12.7042 6.13686 12.8154 6.22374 12.8911C6.2502 12.9142 6.26774 12.9294 6.27842 12.9386L11.9575 16.8973L11.9754 16.911C11.9746 16.9104 11.9786 16.9135 11.9875 16.9192C11.9886 16.9199 11.9898 16.9206 11.9909 16.9214C11.9964 16.8641 12 16.7952 12 16.7131V2.25019C12 2.1977 11.9985 2.15083 11.996 2.10915Z"
                fill="#1F2025"
              />
            </g>
          </svg>
        </Tooltip>
      </IconButtonAnimate>
    </>
  );
});
