import { accountApi_ } from '@/api';
import { MyIcon } from '@/assets/icons/MyIcon';
import { useAuthContext } from '@/auth/useAuthContext';
import MyModal from '@/components/modal';
import useResponsive from '@/hooks/useResponsive';
import { useLocales } from '@/locales';
import { LoadingButton } from '@mui/lab';
import { Box, Drawer } from '@mui/material';
import { enqueueSnackbar } from 'notistack';

import { useEffect, useState } from 'react';
import styled from 'styled-components';

interface CaptchaBaseInfo {
  captcha_key: string;
  image_base_64: string;
  thumb_base_64: string;
}
const blurImg =
  'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAADUlEQVR42mO8evNiPQAICgMAGt1KtwAAAABJRU5ErkJggg==';
function getDefaultCaptchaBaseInfo(): CaptchaBaseInfo {
  return {
    captcha_key: '',
    image_base_64: blurImg,
    thumb_base_64: blurImg,
  };
}

type Props = {
  email?: string;
  open: boolean;
  onClose: (data: { CaptchaKey?: string; CaptchaCode?: string }) => void;
};

// const str =`request expired:%string=1714206438473),currey time 17142028467083`

const NewCaptchaDialog = ({ email, open, onClose }: Props) => {
  const { translate: t } = useLocales();
  const { user } = useAuthContext();

  const [state, setState] = useState(getDefaultCaptchaBaseInfo());
  const [errText, setErrText] = useState('');
  const [maxPoint] = useState(5);
  const [points, setPoints] = useState<Array<{ x: number; y: number }>>([]);
  const [refreshHover, setRefreshHover] = useState(false);
  const [loading, setLoading] = useState(false);
  const [refresh, setRefresh] = useState(false);

  const isMobile = !useResponsive('up', 'sm');

  useEffect(() => {
    handleRefresh();
    // eslint-disable-next-line
  }, []);

  function handleClose() {
    onClose({});
  }

  async function handleRefresh() {
    if (refresh) return;
    setErrText('');
    setPoints([]);
    setRefresh(true);
    try {
      setState(getDefaultCaptchaBaseInfo());
      const res = await accountApi_.getCaptchaInfo((user?.email as string) || (email as string));
      setState(res);
      setErrText('');
    } catch (e) {
      if (e?.code) {
        enqueueSnackbar(t(`new_interface_err.${e?.code}`), { variant: 'error' });
      } else {
        enqueueSnackbar(e.message, { variant: 'error' });
      }
    } finally {
      setRefresh(false);
    }
  }

  async function handleConfirm() {
    setLoading(true);
    try {
      const code = points.map((item) => `${item.x},${item.y}`).join(',');

      await accountApi_.checkCaptcha({ captcha_key: state.captcha_key, captcha_code: code });

      onClose({ CaptchaKey: state.captcha_key, CaptchaCode: code });
    } catch (e) {
      if (e?.code) {
        setErrText(t(`new_interface_err.${e?.code}`));
      } else {
        setErrText(e.message);
      }
      setPoints([]);
    } finally {
      setLoading(false);
    }
  }

  function addPoint(x: number, y: number) {
    if (Array.isArray(points) && points.length < maxPoint) {
      setPoints((val) => [...val, { x, y }]);
    }
  }

  const CapchaContent = (
    <NewCapchaWrap className="captcha" translate="no">
      <div className="captcha__header">
        <div className="title">
          <span>{t('common.captcha_header_title')}</span>{' '}
        </div>
        <img className="thumb" src={state.thumb_base_64} width={150} height={40} alt="" />
        <div
          onClick={handleRefresh}
          className={`${refresh ? 'loading-refresh-rotate' : ''} refresh`}
          onMouseOver={() => {
            setRefreshHover(true);
          }}
          onMouseLeave={() => {
            setRefreshHover(false);
          }}
          style={{
            cursor: isMobile ? 'default' : 'pointer',
          }}
        >
          <MyIcon
            name={`${refreshHover && !refresh ? 'refresh_green' : 'refresh_gray'}`}
            size={20}
          />
        </div>
      </div>
      <h5>{errText}</h5>
      <div className="captcha__body">
        <img
          className="source"
          width={350}
          height={250}
          src={state.image_base_64}
          alt=""
          onClick={(e) => {
            if (refresh) return;
            e.stopPropagation();
            e.preventDefault();
            // eslint-disable-next-line
            let nativeEvent: any = e.nativeEvent;
            let x = nativeEvent.offsetX + 10;
            let y = nativeEvent.offsetY;
            addPoint(x, y);
          }}
        />
        {points.map((item, index) => (
          <div
            className="point"
            key={index}
            style={{ left: item.x + 'px', top: item.y + 'px', zIndex: index }}
          >
            <span>{index + 1}</span>
          </div>
        ))}
      </div>
      <div
        className="captcha__footer loading-btn-green"
        style={{
          justifyContent: isMobile ? 'flex-end' : 'space-between',
          marginTop: isMobile ? '32px' : '16px',
        }}
      >
        <button
          style={{
            marginRight: isMobile ? '24px' : 0,
          }}
          className="close"
          onClick={handleClose}
        >
          <span>{t('common.Close')}</span>
        </button>
        <LoadingButton
          disabled={refresh}
          loading={loading}
          onClick={handleConfirm}
          className="confirm"
          loadingIndicator={
            <MyIcon name="loading_green" className="loading-icon-rotate" size={18} />
          }
        >
          <span>{loading ? '' : t('common.Confirm')}</span>
        </LoadingButton>
      </div>
    </NewCapchaWrap>
  );

  useEffect(() => {
    setPoints([]);
    setErrText('');
  }, [open]);

  return (
    <>
      {isMobile ? (
        <Drawer
          open={open}
          onClose={() => {
            onClose({});
          }}
          anchor="bottom"
          container={() => document.body.querySelector('body')}
          sx={{
            zIndex: 1400,
          }}
        >
          {CapchaContent}
        </Drawer>
      ) : (
        <MyModal
          open={open}
          onClose={() => {
            onClose({});
          }}
          showTitle={false}
          ModalWarpWidth={378}
          // PageStyleSx={{
          //   width: '378px',
          // }}
        >
          <Box sx={{ padding: '0px' }}>{CapchaContent}</Box>
        </MyModal>
      )}
    </>
  );
};

export default NewCaptchaDialog;

const NewCapchaWrap = styled.div`
  background-color: #fff;
  padding: 24px 14px;
  border-radius: 10px;
  padding-right: 10px;

  .captcha__header {
    display: flex;
    align-items: center;
    .title {
      span {
        color: #000;
        font-size: 16px;
        font-weight: 500;
        margin-right: 10px;
      }
    }
    .thumb {
      width: 150px;
      height: 40px;
      flex-shrink: 0;
      margin-right: 10px;
    }
  }
  .refresh {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  > h5 {
    color: #f25145;
    margin-top: 2px;
    height: 10px;
  }
  .captcha__body {
    margin-top: 10px;
    border-radius: 5px;
    overflow: hidden;
    position: relative;
    width: 100%;

    display: flex;
    justify-content: center;
    .source {
      width: 350px;
      height: 250px;
      vertical-align: middle;
      border-radius: 8px;
    }
    .point {
      position: absolute;
      background: #3e7cff;
      border: 2px solid #fff;
      border-radius: 50%;
      width: 26px;
      height: 26px;
      display: flex;
      align-items: center;
      justify-content: center;
      transform: translate(-50%, -50%);
      user-select: none;
      span {
        font-size: 14px;
        font-weight: 500;
        color: #fff;
      }
    }
  }
  .captcha__footer {
    margin-top: 16px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 500;

    > button {
      width: 86px;
      height: 36px;

      border-radius: 4px;
      border: 1px solid #000;
      color: #000;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      :hover {
        cursor: pointer;
      }
    }

    .close {
      background: #fff;
      :hover {
        border: 1px solid rgba(0, 0, 0, 0.2);
      }
    }
    .confirm {
      border: none;
      background-color: #b7e34f;
      margin-right: 5px;
      :hover {
        background: #b1d346;
      }
    }
  }
`;
