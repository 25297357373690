import { ReactNode, forwardRef } from 'react';
import { enqueueSnackbar } from 'notistack';
// eslint-disable-next-line
import { ReactComponent as CopyIcon } from '!!@svgr/webpack!file-loader!@/assets/icons/img/ic_copy.svg';
import { useLocales } from '@/locales';
import { IconButton, SvgIcon, SxProps } from '@mui/material';

type Props = {
  source: string;
  height?: string;
  sx?: SxProps;
  icon?: ReactNode;
};

const CopyButton = forwardRef(function CopyButton(props: Props, ref: any) {
  const { translate: t } = useLocales();

  const onClick = async (e: { stopPropagation: () => void }) => {
    e.stopPropagation();
    try {
      await doCopy(props.source);
      enqueueSnackbar(t('common.Copied'), {
        variant: 'success',
        key: new Date().getTime().toString(),
      });
    } catch (e) {
      enqueueSnackbar(e.message ?? t('common.failed_copy'), { variant: 'error' });
    }
  };
  return (
    <IconButton
      onClick={onClick}
      ref={ref}
      {...props}
      sx={{
        p: 0,
        marginLeft: '8px',
        height: props.height || '32px',
        width: props.height || '32px',
        borderRadius: '50%',
        ...props.sx,
      }}
    >
      {props.icon ? props.icon : <SvgIcon component={CopyIcon} inheritViewBox />}
    </IconButton>
  );
});

export default CopyButton;

async function doCopy(text: string) {
  if (window.location.protocol === 'http:' && window.location.hostname !== 'localhost')
    throw new Error('can not copy without ssl context.');
  if (!navigator.clipboard) throw new Error('can not access clipboard!');
  await navigator.clipboard.writeText(text);
}

export const handleCopy = async (text: string, t: (v: any) => string) => {
  try {
    await doCopy(text);
    enqueueSnackbar(t('common.Copied'), {
      variant: 'success',
      key: new Date().getTime().toString(),
    });
  } catch (e) {
    enqueueSnackbar(e.message ?? t('common.failed_copy'), { variant: 'error' });
  }
};
