import { systemStore } from '@/stores';

export { default as styled, ThemeProvider, createGlobalStyle } from 'styled-components';

/** rem计算函数 */
export function rem(px: number) {
  return remScale(px) + 'rem';
}

function remScale(px: number) {
  return px / 16;
}

export function relatePx(px: number) {
  return remScale(px) * systemStore.rootEm;
}
export function pxToRem(px: number) {
  return (px / systemStore.rootEm) * 16;
}
/** 移动端兼容函数 */
export function mobile(mobile: string, pc: string) {
  return (p: any) => (p.theme.mobile ? mobile : pc);
}

/** 预设 */
export const preset = {
  /** 字体 */
  fonts: {
    ultra: `
    //font-family: AmsiPro-Ultra;
    font-weight: 900;
    `,
    black: `
    //font-family: AmsiPro-Black;
    font-weight:800;
    `,
    bold: `
    //font-family: AmsiPro-Bold;//700
    font-weight:bold;
    `,
    semibold: `
    //font-family: AmsiPro-Semibold;
    font-weight: 500; 
    `,
    regular: `
    //font-family: AmsiPro-Regular;
    font-weight:400;
    `,
    light: `
    font-weight:300;
    `,
    /**700 */
    ttfirsNeueBold: 'font-family: TTFirsNeue-DemiBold;',
    /**600 */
    ttfirsNeueDemiBold: 'font-family: TTFirsNeue-Bold;',
    /**500 */
    ttfirsNeueMedium: 'font-family: TTFirsNeue-Medium;',
    /**400 */
    ttfirsNeueRegular: 'font-family: TTFirsNeue-Regular;',
  },
  /** 居中对齐 */
  center: 'display: flex; justify-content: center; align-items: center;',
  /** 主色 */
  primary: (p: any) => p.theme.primary,
  primaryText: (p: any) => p.theme.primaryText,
  /** 错误颜色 */
  errorColor: '#f25145',
  /** 成功 */
  success: '#42c955',
  /** 信息 */
  gray: '#bcc2bd',
  /** 警告 */
  warning: '#ffe05d',
  /** 危险 */
  dangger: '#f25145',
  mobileMedia: `@media(max-width: ${systemStore.maxDesignWidth}px)`,
  notMobileMedia: `@media(min-width: ${systemStore.maxDesignWidth}px)`,
  smallScreen: `@media(min-width: ${systemStore.maxDesignWidth}px) and (max-width: ${systemStore.smallChangeWidth}px)`,
  normalMedia: `@media(min-width: ${systemStore.smallChangeWidth}px) and (max-width: ${systemStore.bigChangeWidth}px)`,
  textEllipsis: 'white-space: nowrap; overflow: hidden; text-overflow: ellipsis;',
  /** 多行文本省略显示 */
  textEllipsisLn: (line: number) =>
    `overflow: hidden;text-overflow: ellipsis;display:-webkit-box;-webkit-box-orient:vertical;-webkit-line-clamp:${line};`,
  smallHeight: `@media(max-height: ${systemStore.smallHeight}px)`,
};
