import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';
import { accountStore } from '@/stores';

const Loadable = (Component: ElementType, isFallback?: boolean) => (props: any) => {
  const { user } = accountStore;
  return (
    <Suspense fallback={isFallback || user ? null : <LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
};

export const LoginPage = Loadable(lazy(() => import('@/pages/LoginPage')));

export const VerifitionPage = Loadable(lazy(() => import('@/pages/VerifitionPage')));

export const ForgotPasswordPage = Loadable(
  lazy(() => import('@/pages/ForgotPassword/ForgotPasswordPage')),
);
export const RegisterPage = Loadable(lazy(() => import('@/pages/RegisterPage')));

export const RegistrationReviewPage = Loadable(
  lazy(() => import('@/pages/RegistrationReview/RegistrationReview')),
);

export const RegisterApplyAgainPage = Loadable(
  lazy(() => import('@/pages/RegistrationReview/RegisterApplyAgain')),
);

// Referral Program src/pages/ReferralProgram
export const ReferralProgramPage = Loadable(lazy(() => import('@/pages/ReferralProgram')));

// WithdrawRecord
export const WithdrawRecordPage = Loadable(
  lazy(() => import('@/pages/ReferralProgram/WithdrawRecord')),
);

// dashboard
export const DashboardPage = Loadable(lazy(() => import('../pages/DashboardPage')));

// trading order
export const TradingOrderPage = Loadable(lazy(() => import('@/pages/TradingOrderPage')));

//user  trading order
export const UserTradingOrderPage = Loadable(lazy(() => import('@/pages/UserTransactionPage')));

// trading order AddressList
export const AddressListPage = Loadable(lazy(() => import('@/sections/tradingOrder/AddressList')));

// trading order AddressList
export const CollectionListPage = Loadable(
  lazy(() => import('@/sections/tradingOrder/CollectionList/CollectionList')),
);
export const ActionLogListPage = Loadable(
  lazy(() => import('@/sections/MerchantSetting/member/actionLog/ActionLogList')),
);

//CreateRefundPage

export const CreateRefundPage = Loadable(lazy(() => import('@/pages/CreateRefundPage')));

//Balances
export const BalancesPage = Loadable(lazy(() => import('@/pages/balance')));

//Invoice
export const InvoicePage = Loadable(lazy(() => import('@/pages/Invoice')));

//InvoiceOneDetails
export const InvoiceOneDetailsPage = Loadable(
  lazy(() => import('@/pages/Invoice/details/OneTime')),
);

// RecurringDetail
export const RecurringDetailsPage = Loadable(
  lazy(() => import('@/pages/Invoice/details/RecurringDetail')),
);

//ManageDetail
export const ManageDetailsPage = Loadable(
  lazy(() => import('@/pages/Invoice/details/ManageDetail')),
);

//Invoice Create
export const CreateInvoicePage = Loadable(lazy(() => import('@/pages/Invoice/CreateInvoice')));

//Invoice PDFpage
export const InvoicePDFpage = Loadable(
  lazy(() => import('@/pages/Invoice/components/PDFpage')),
  true,
);

//EmailBilling
export const EmailBillingPage = Loadable(lazy(() => import('@/pages/EmailBillingPage')));

//CheckPage
export const CheckPage = Loadable(lazy(() => import('@/pages/CheckPage')));

//PersonalStore
export const PersonalStorePage = Loadable(lazy(() => import('@/pages/PersonalStorePage')));

//PaymentApi
export const PaymentApiPage = Loadable(lazy(() => import('@/pages/PaymentApiPage')));

//PaymentApi
export const WebsitVerificationPageSection = Loadable(
  lazy(() => import('@/sections/MerchantSetting/websit-verification/index')),
);
//SettingPage
export const SettingAccountPage = Loadable(lazy(() => import('@/pages/SettingAccountPage')));

//PaymentApiConfig
export const PaymentApiConfigPage = Loadable(lazy(() => import('@/pages/PaymentApiConfigPage')));

//Account Settings
export const AccountSettingsPage = Loadable(lazy(() => import('@/pages/AccountSettingsPage')));

//PaymentApiOrderDetails
export const PaymentApiOrderDetailsPage = Loadable(
  lazy(() => import('@/pages/PaymentApiOrderDetailsPage')),
);

//MerchantSetting

export const BrandingPage = Loadable(lazy(() => import('@/sections/MerchantSetting/branding')));

export const SettingPage = Loadable(lazy(() => import('@/sections/MerchantSetting/setting')));

export const SecurityPage = Loadable(
  lazy(() => import('@/sections/MerchantSetting/securitySetting/index')),
);

export const VerificationPage = Loadable(
  lazy(() => import('@/sections/MerchantSetting/verification')),
);

export const MemberPage = Loadable(lazy(() => import('@/sections/MerchantSetting/member/index')));
//WebHookPage
export const WebHookPage = Loadable(lazy(() => import('@/pages/WebHook')));

//UserListPage
export const UserListPage = Loadable(lazy(() => import('@/pages/UserList')));

//UserListPageDetails
export const UserListDetailPage = Loadable(lazy(() => import('@/pages/UserList/Details')));

//Support
export const SupportPage = Loadable(lazy(() => import('@/pages/SupportPage')));

//UserTransactionPage
export const UserTransactionPage = Loadable(lazy(() => import('@/pages/UserTransaction')));

export const UserDepositWithTablePage = Loadable(
  lazy(() => import('@/pages/UserTransaction/DepositWithTable')),
);

export const UserSwapTablePage = Loadable(lazy(() => import('@/pages/UserTransaction/SwapTable')));

//error Page
export const Page404 = Loadable(lazy(() => import('../pages/Errors/Page404')));
export const Page403 = Loadable(lazy(() => import('../pages/Errors/Page403')));
export const Page500 = Loadable(lazy(() => import('../pages/Errors/Page500')));

//wallet
export const SelectPlatformPage = Loadable(lazy(() => import('@/pages/SelectPlatform/Index')));

// Withdrawal Approval
export const WithdrawalApproval = Loadable(lazy(() => import('@/pages/WithdrawalApproval/index')));
export const WithdrawalApprovalRecords = Loadable(
  lazy(() => import('@/sections/Withdrawal/Record/index')),
);
export const WhiteAndBlacklist = Loadable(
  lazy(() => import('@/sections/Withdrawal/WhiteAndBlackList/index')),
);
export const WithdrawalApprovalLayout = Loadable(
  lazy(() => import('@/pages/WithdrawalApproval/WithdrawalApprovalLayout')),
);
export const UserAssetsLayout = Loadable(lazy(() => import('@/pages/UserTransactionPage')));
