export const style = {
  text_length: {
    color: '#DCE0E4',
    position: 'absolute',
    bottom: '8px',
    right: '16px',
    fontSize: '12px',
  },
  loading_btn: {
    flexShrink: 0, width: '384px', height: '48px', marginBottom: '24px'
  },
  suggest: {
    mt: 4,
    mb: 4,
    ml: 1,
    '.MuiFormControlLabel-label': { fontWeight: 500 },
  },
  container: {
    maxWidth: 'inherit !important',
    '.MuiDialog-paper': {
      minWidth: '480px',
    },
  },
  dialog: {
    '.MuiDialog-paper': {
      minWidth: '480px',
      padding: '0 20px 24px 20px',
    },
  },
  text: {
    marginTop: '24px',
    '.MuiFormHelperText-root': { color: '#FF5630' },
    '.MuiInputBase-root,.MuiInputBase-input': {
      minHeight: '120px',
    },
  }
};