import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
//
import { defaultLang } from './config';
//

import arLocales from './langs/ar/common.json';

import bnLocales from './langs/bn/common.json';

import myLocales from './langs/my/common.json';

import zh_CNLocales from './langs/zh-CN/common.json';

import zh_TWLocales from './langs/zh-TW/common.json';

import nlLocales from './langs/nl/common.json';

import enLocales from './langs/en/common.json';

import frLocales from './langs/fr/common.json';

import deLocales from './langs/de/common.json';

import hiLocales from './langs/hi/common.json';

import idLocales from './langs/id/common.json';

import itLocales from './langs/it/common.json';

import jaLocales from './langs/ja/common.json';

import koLocales from './langs/ko/common.json';

import msLocales from './langs/ms/common.json';

import ne_NPLocales from './langs/ne-NP/common.json';

import plLocales from './langs/pl/common.json';

import pt_PTLocales from './langs/pt-PT/common.json';

import ruLocales from './langs/ru/common.json';

import es_ESLocales from './langs/es-ES/common.json';

import tlLocales from './langs/tl/common.json';

import trLocales from './langs/tr/common.json';

import ukLocales from './langs/uk/common.json';

import ur_PKLocales from './langs/ur-PK/common.json';

import vnLocales from './langs/vi/common.json';

import en_UDLocales from './langs/en-UD/common.json';



let lng = defaultLang.value;

if (typeof window !== 'undefined') {
  lng = localStorage.getItem('i18nextLng') || defaultLang.value;
}

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: { translations: enLocales },
      fr: { translations: frLocales },
      vn: { translations: vnLocales },
      'zh-cn': { translations: zh_CNLocales },
      'zh-TW': { translations: zh_TWLocales },
      ar: { translations: arLocales },
      bn: { translations: bnLocales },
      my: { translations: myLocales },
      nl: { translations: nlLocales },
      de: { translations: deLocales },
      hi: { translations: hiLocales },
      id: { translations: idLocales },
      it: { translations: itLocales },
      ja: { translations: jaLocales },
      ko: { translations: koLocales },
      ms: { translations: msLocales },
      'ne-NP': { translations: ne_NPLocales },
      pl: { translations: plLocales },
      'pt-PT': { translations: pt_PTLocales },
      ru: { translations: ruLocales },
      'es-ES': { translations: es_ESLocales },
      tl: { translations: tlLocales },
      tr: { translations: trLocales },
      uk: { translations: ukLocales },
      'ur-PK': { translations: ur_PKLocales },
      'en-UD': { translations: en_UDLocales },
    },
    lng,
    fallbackLng: defaultLang.value,
    debug: false,
    ns: ['translations'],
    defaultNS: 'translations',
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
