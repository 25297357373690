// routes
import { PATH_DASHBOARD, PATH_PAYMENT_API, PATH_TRADING_ORDER, PATH_WALLET } from './routes/paths';
import { test_token_ids } from './utils/until';
// API
export enum PaySecurityVerificationFormEnum {
  Swap = 'swap',
  Deposit = 'deposit',
  Withdraw = 'Withdraw',
}

export enum MerchantStatusEnum {
  DisabledStatus = 0,
  GeneralStatus = 1,
  InvStatus = 2,
  APIStatus = 4,
  SwapStatus = 8,
  Withdraw = 16,
}

export const MerchantStatusREG = 1 | 2 | 4 | 8 | 16;

export const EmptyMerchantId = 'd100000';

/** 人机验证达到最大次数 */
export const CheckCaptchaMax = 16038;

export const CCpIntercomColor = { background_color: '#c6d7ff', action_color: '#3c6ff5' };
export const WalletIntercomColor = { background_color: '#e5f9a9', action_color: '#82993c' };
export const TESTEMAILACCOUNTS =
  process.env.REACT_APP_ENVIRONMENT === 'true'
    ? [
        'mr3oaks@protonmail.com',
        'shirley130@proton.me',
        'jenkinsins@protonmail.com',
        'herculew@protonmail.com',
        'denglw381@gmail.com',
        'benson-ly@protonmail.com',
        'lygit@protonmail.com',
        '6hang@proton.me',
        'momek50304@proton.me',
        'Jasees@proton.me',
        'ccjasee.me@gmail.com',
        'an20cloud@gmail.com',
        'gmou028@gmail.com',
        'cceric@proton.me',
        'rdlucasgm@gmail.com',
        'jared0718@proton.me',
      ]
    : [];

export const TESTEMAILS =
  process.env.REACT_APP_ENVIRONMENT === 'true' ? ['herculew@protonmail.com'] : [];

export const ENVIRONMENT = process.env.REACT_APP_ENVIRONMENT === 'true' ? true : false;
// export const ENVIRONMENT = true;

export const HOST_API_KEY = process.env.REACT_APP_HOST_API_KEY || '';

export const CCP_URL =
  process.env.REACT_APP_ENVIRONMENT === 'true'
    ? 'https://ccpayment.com/'
    : 'https://efb014f4merchantdev.ccpayment.com/';

export const currentEnv = process.env.REACT_APP_ENVIRONMENT === 'true' ? 'prd' : 'test';
// export const currentEnv = 'prd';

export const AUTH_CALLBACK_URLV2 =
  process.env.REACT_APP_ENVIRONMENT === 'true'
    ? 'https://console.ccpayment.com/login_callback.html'
    : 'https://admin-test-v2.ccpayment.com/login_callback.html';

// export const AUTH_CALLBACK_URL =
//   process.env.REACT_APP_ENVIRONMENT === 'true'
//     ? 'https://admin.ccpayment.com/login_callback.html'
//     : 'https://ebc65a6dtestpaymentadmin.ccpayment.com/login_callback.html';

// export const AUTH_REFERRAL_URL =
//   process.env.REACT_APP_ENVIRONMENT === 'true'
//     ? 'https://admin.ccpayment.com'
//     : 'https://ebc65a6dtestpaymentadmin.ccpayment.com';

export const AUTH_REFERRAL_URL =
  process.env.REACT_APP_ENVIRONMENT === 'true'
    ? 'https://console.ccpayment.com'
    : 'https:///admin-test-v2.ccpayment.com';

export const HOMEDOMAINV2 =
  process.env.REACT_APP_ENVIRONMENT === 'true'
    ? 'https://admin.ccpayment.com'
    : 'https://ebc65a6dtestpaymentadmin.ccpayment.com';

export const CONTACT_US_URL = 'https://t.me/max_CCPayment';
/**
 * integerToken array of integer tokens
 */
export const integerTokenArr = ['SATS'];

export const TETH = test_token_ids;

export const FIREBASE_API = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APPID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

export const COGNITO_API = {
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
};

export const AUTH0_API = {
  clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
  domain: process.env.REACT_APP_AUTH0_DOMAIN,
};

export const MAPBOX_API = process.env.REACT_APP_MAPBOX_API;

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = PATH_DASHBOARD.one;

export const PATH_AFTER =
  process.env.REACT_APP_ENVIRONMENT === 'true'
    ? {
        1: PATH_DASHBOARD.one,
        2: PATH_DASHBOARD.one,
        3: PATH_TRADING_ORDER.one,
        4: PATH_TRADING_ORDER.one,
        6: PATH_DASHBOARD.one,
        8: PATH_DASHBOARD.one,
        100000: '/account/settingPageSection/merchantlist',
      }
    : {
        1: PATH_DASHBOARD.one,
        2: PATH_DASHBOARD.one,
        3: PATH_DASHBOARD.one,
        4: PATH_TRADING_ORDER.one,
        8: PATH_PAYMENT_API.one,
        9: PATH_DASHBOARD.one,
        15: PATH_DASHBOARD.one,
        100000: '/account/settingPageSection/merchantlist',
      };

export function getPathAfter(v: string | undefined) {
  // eslint-disable-next-line
  return !!v ? v : '/account/settingPageSection/merchantlist';
}

export const WALLET_PATH_AFTER_LOGIN = PATH_WALLET.overview;

// LAYOUT

export const LAYOUTTOP = 52;

export const HEADER = {
  H_MOBILE: 64,
  H_MAIN_DESKTOP: 88,
  H_DASHBOARD_DESKTOP: 92,
  H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
};

export const NAV = {
  W_BASE: 260,
  W_DASHBOARD: 280,
  W_DASHBOARD_MINI: 88,
  W_DASHBOARD_NAV_MINI: 66,
  //
  W_DRAWER_FIRST: 400,
  W_DRAWER_Mobile: '100vw',
  //
  H_DASHBOARD_ITEM: 48,
  H_DASHBOARD_ITEM_SUB: 36,
  //
  H_DASHBOARD_ITEM_HORIZONTAL: 32,
};

export const ICON = {
  NAV_ITEM: 24,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};

export const ICONFOLD = {
  NAV_ITEM: 56,
  NAV_ITEM_HORIZONTAL: 22,
  NAV_ITEM_MINI: 22,
};

export const DIALOG = {
  W_BASE: 480,
  W_MOBILE: '100vw',
};

/** Contains upper and lower case, special symbols, numbers, 8-16 bits */
export const PWDREG = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^\da-zA-Z\s]).{8,16}$/;
/** At least one uppercase letter, one lowercase letter, and one number */
export const PWDREG2 = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d).+$/;
/** Password cannot have spaces  */
export const PWDREG3 = /^(?!\s)(?!.*\s)[^\s]+$/;

/** Whether it is a number, and the length is not greater than 10 */
export const NUMREG = /^[0-9]\d{0,9}$/;
/** Whether it is a number, and the length is not greater than 28 */
export const NUMREG2 = /^[0-9]\d{0,26}$/;

/** */
export const MTMODALREGTEST =
  // eslint-disable-next-line
  /^(?:http(s)?:\/\/)?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

export const WEBHOOKURLRULE =
  /^(http|https):\/\/(?:\S+(?::\S*)?@)?(?:(?:[0-9]{1,3}\.){3}[0-9]{1,3}|(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z]{2,})))(?::\d{2,5})?(?:\/[^\s]*)?$/i;

/** email reg rule */
export const EMAILRULE =
  /^[0-9a-zA-Z][+_.0-9a-zA-Z-]{0,31}@([0-9a-z][0-9a-z-]{0,30}\.){1,4}[a-z]{2,10}$$/;
/** phone reg rule */
export const PHONERULE = /^\d+-\d+$/;

/** telegram reg rule */
export const TELEGRAMRULE = /(https?:\/\/)?(www[.])?(telegram|t)\.me\/(.+)\/?$/;

/** twitter reg rule */
export const TWITTERRULE = /(?:https?:\/\/)?(?:twitter\.com|x\.com)\/\w+|@[a-zA-Z0-9_]+/;

/** website reg rule  */
export const WEBSITERULE =
  // eslint-disable-next-line
  /^(https?|ftp):\/\/[\w.-]+(?:\.[\w\.-]+)+[\w-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/;

export const ADDEESSRULE = /^(?!\d+$)(?!.*[\s,.;@#$%])[-a-zA-Z\d_]+$/;
