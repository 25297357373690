import { configure } from 'mobx';
import { createContext, useContext } from 'react';
import { AccountStore } from './account';
import WalletStroe from './WalletStroe';
import System from './System';
import CCPWebhookStroe from './webhook';
import MerchantStroe from './merchant';

configure({ enforceActions: 'never' });

export const walletStroe = new WalletStroe();

export const accountStore = new AccountStore();

export const systemStore = new System();

export const ccpWebhookStroe = new CCPWebhookStroe();

export const merchantStroe = new MerchantStroe();

function createStores() {
  return {
    walletStroe,
    accountStore,
    systemStore,
    ccpWebhookStroe,
    merchantStroe,
  };
}
const stores = createStores();

export const StoresContext = createContext(stores);

const useStores = () => useContext(StoresContext);

export { stores, useStores };
