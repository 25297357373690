import { Http } from '@/api/http';
import { formatDate } from '@/utils/formatTime';
const http = new Http('/payment/v1');
export default class InvoiceApi {
  /**
   * Calculate the number of invoice periods
   */
  termInvoice(params: termInvoiceParamsType) {
    return http.postJson('/invoice/calculate/term', { ...params });
  }

  /**
   * Get the list of tokens paid for the order
   */
  getBillSymbolsList(bill_id: string): Promise<billSymbolsListRes[]> {
    return http.postJson('/invoice/bill/symbols', { bill_id });
  }

  /**
   * Get the order balance of a certain token in the order
   */
  getBillSymbolRemain({
    bill_id = '',
    symbol = '',
  }: {
    bill_id: string;
    symbol: string;
  }): Promise<billSymbolsListRes[]> {
    return http.postJson('/invoice/bill/symbols/remain', { bill_id, symbol });
  }

  /**
   * create refund
   */
  createBillRefund(params: createBillRefundParams): Promise<createBillRefundRes> {
    return http.postJson('/invoice/bill/refund', { ...params });
  }

  // ------------------------------------------------------------------new api
  /**
   * Get the list of fiat currencies for the order
   */
  getrateList(): Promise<{ list: TokenResponse[] }> {
    return http.postJson('/currency/rate', {});
  }

  /**
   * Create invoice, upload attachment
   */
  upLoad(data: { file: (File | string)[]; maxLength: number }) {
    let formData = new FormData();
    for (var i = 0; i < data.maxLength; i++) {
      formData.append('file', data.file[i]);
    }
    return http.postForm('/file/upload', formData);
  }

  /**
   *Create invoice, upload attachment
   */
  upLoadURL(paramsUrl: signUrlType) {
    return http.postJson('/file/sign/url', { ...paramsUrl });
  }

  /**
   * order details_
   */
  orderDetails_({ bill_id = '' }: { bill_id: string }): Promise<orderDetailRes_> {
    return http.postJson('/invoice/bill/detail', { bill_id });
  }

  /**
   * done order_
   */
  doneOrder_({ bill_id = '', remark = '' }: { bill_id: string; remark: string }) {
    return http.postJson('/invoice/bill/done', { bill_id, remark });
  }

  /**
   * order list_
   */
  orderList_(params: orderListParams_): Promise<orderListRes_> {
    const begin_at = new Date(`${formatDate(params.begin_at, 'yyyy-MM-dd')} 00:00:00`).getTime();
    const end_at = new Date(`${formatDate(params.begin_at, 'yyyy-MM-dd')} 23:59:59`).getTime();
    return http.postJson('/invoice/bill/list', { ...params, begin_at, end_at });
  }

  /**
   * order details page_
   */
  orderDetailsPage_(origin_id: string, cur_term_num: number): Promise<orderDetailRes_> {
    return http.postJson('/invoice/bill/page/detail', { origin_id, cur_term_num });
  }

  /**
   * add order remark_
   */
  addOrderRemark_({ bill_id = '', remark = '' }: { bill_id: string; remark: string }) {
    return http.postJson('/invoice/bill/remark/add', { bill_id, remark });
  }

  /**
   * revoke order
   */
  revokeOrder_(bill_id: string) {
    return http.postJson('/invoice/bill/revoke', { bill_id });
  }

  /**
   * sent total
   */
  sent_total_({ bill_id = '' }: { bill_id: string }): Promise<{
    total: number;
  }> {
    return http.postJson('/invoice/bill/sent/total', { bill_id });
  }

  /**
   * inovice bill order list
   */
  getInvoiceBillList_(params: getBillParams_): Promise<{
    list: billListRes_[];
  }> {
    return http.postJson('/invoice/bill/single/list', { ...params });
  }

  /**
   * Order status statistics
   */
  getBillStatistics_(): Promise<statisticRes_> {
    return http.postJson('/invoice/bill/statistics', {});
  }

  /**
   * Calculate the number of invoice periods
   */
  termInvoice_(params: termInvoiceParamsType_): Promise<{ term: number }> {
    return http.postJson('/invoice/calculate/term', { ...params });
  }

  /**
   * create invoice
   */
  createInvoice_(params: createInvoiceParamsType_) {
    return http.postJson('/invoice/create', { invoice: params });
  }

  /**
   * edit invoice
   */
  editInvoice_(params: createInvoiceParamsType_) {
    return http.postJson('/invoice/edit', { invoice: params });
  }

  /**
   * invoice details
   */
  getInvoiceDetails_(invoice_id: string): Promise<invoiceDetailsType_> {
    return http.postJson('/invoice/detail', { invoice_id });
  }

  /**
   * save mailboxes and name lists
   */
  saveEmail_(params: saveEamilParams_) {
    return http.postJson('/invoice/email/create', { ...params });
  }

  /**
   * Delete mailboxes and name lists
   */
  getEmailDelete_(params: { email_id: string }) {
    return http.postJson('/invoice/email/delete', { ...params });
  }

  /**
   * List of email addresses and names
   */
  getEmailList_(): Promise<{ list: emailType_[] }> {
    return http.postJson('/invoice/email/list', {});
  }

  /**
   * invoice list
   */
  getInvoiceList_(params: ListParams_): Promise<InvoiceList> {
    return http.postJson('/invoice/list', { ...params });
  }

  /**
   * Obtain merchant website and other information
   */
  getBussinsInfo_(): Promise<{ detail: bussinsInfoRes_ }> {
    return http.postJson('/invoice/merchant/simple/info', {});
  }

  /**
   * stop invoice
   */
  stopInvoice_(invoice_id: string) {
    return http.postJson('/invoice/stop', { invoice_id });
  }

  searchRate(): Promise<{ list: RateResListType[] }> {
    return http.postJson('/invoice/config-list', {});
  }

  saveRate(params: RateSaveParamsType) {
    return http.postJson('/invoice/config-save', params);
  }
}

// ----------------------------
export type RateSaveParamsType = {
  configs: Array<RateResListType>;
};
export type RateResListType = {
  chain_id: string;
  minutes: number;
  name?: string;
};
export interface bussinsInfoRes_ {
  name: string;
  email: string;
  company_address: string;
  company_website: string;
  twitter_url: string;
  telegram_url: string;
}

export interface ListParams_ {
  keyword: string;
  status: number;
  dt_start: number;
  dt_end: number;
  pagination: {
    page_num: number;
    page_size: number;
  };
}
export interface emailType_ {
  email_id: string;
  email: string;
  name: string;
}
export interface eamilParams_ {
  key: string;
}
export interface saveEamilParams_ {
  email: string;
  name: string;
}
export interface Token_list {
  symbol: string;
  token_id: string;
  name: string;
  logo: string;
  status: number;
  coin_id?: string;
}

export interface Item_list {
  title: string;
  price: string;
  quantity: number;
  total: string;
  discount_mode?: number;
  discount_number?: string;
}

export interface Invoice {
  invoice_id: string;
  origin_id: string;
  title: string;
  payer_name: string;
  payer_email: string;
  business_name: string;
  business_email: string;
  desc: string;
  currency: string;
  currency_type: number;
  token_list: Token_list[];
  item_list: Item_list[];
  invoice_type: number;
  period: number;
  email_notify?: number;
  webhook_notify: number;
  send_time: number;
  due_time?: number;
  next_time?: number;
  timestamp?: number;
  created: number;
  price: string;
  term_num?: number;
  had_term_num?: number;
  status: number;
  attachment?: {
    name: string;
    path: string;
    size_str?: string;
    seize: number;
  }[];
  is_standing?: boolean;
  more?: string;
  extra_fee: string;
}

export interface invoiceDetailsType_ {
  invoice: Invoice;
}
export interface TokenList {
  symbol: string;
  token_id: string;
  name: string;
  logo: string;
  status: number;
  coin_id: string;
}

export interface ItemList {
  title: string;
  price: string;
  quantity: number;
  total: string;
  discount_mode: number;
  discount_number: string;
}

export interface Attachment {
  name: string;
  path: string;
  size_str: string;
  seize: number;
}

export interface detailInvoice {}

export interface createInvoiceParamsType_ {
  invoice_id: string;
  origin_id: string;
  title: string;
  payer_name: string;
  payer_email: string;
  business_name: string;
  business_email: string;
  desc: string;
  currency: string;
  currency_type: number;
  currency_id: number;
  token_list: Token_list[];
  item_list: Item_list[];
  invoice_type: number;
  period: number;
  email_notify: number;
  webhook_notify: number;
  send_time: number;
  due_time: number;
  next_time?: number;
  timestamp: number;
  created?: number;
  price?: string;
  term_num: number;
  had_term_num?: number;
  status?: number;
  attachment: (string | File)[];
  is_standing: boolean;
  more: string;
  extra_fee: string;
}

export interface termInvoiceParamsType_ {
  period: number;
  send_time: number;
  due_time: number;
}

export interface statisticRes_ {
  statistics: {
    inv_await_num: number;
    inv_paid_num: number;
    inv_late_paid_num: number;
    inv_due_num: number;
    total: number;
    single_total: number;
    cycle_total: number;
    inv_under_paid_num: number;
  };
}

export interface billListRes_ {
  bill_id: string;
  bill_created_at: number;
  last_payment_time?: number;
  received?: string;
  refund?: string;
  status: number;
}

export interface getBillParams_ {
  origin_id: string;
}

export interface Amount {
  chain_id?: string;
  contract: string;
  symbol: string;
  amount: string;
  service_fee: string;
  pay_time: number;
  logo: string;
}

export interface List {
  bill_id: string;
  title: string;
  payer_name: string;
  price: string;
  currency: string;
  amount: Amount[];
  send_time: number;
  due_date: number;
  status: number;
  cur_term_num: number;
  term_num: number;
  invoice_id: string;
  currency_type: number;
}

export interface Pagination {
  page_num: number;
  page_size: number;
  total: number;
}

export interface orderListRes_ {
  list: List[];
  pagination: Pagination;
}

export interface orderListParams_ {
  /**
   * Type: 1 single; 2 loop
   */
  type: number;
  keyword: string;
  /**
   * Order status: 1 email sending, 2 unpaid, 3 paid, 4 expired, 5 overdue payment, 6 done, 7 revoked
   */
  status: number;
  begin_at: number;
  end_at?: number;
  page_num: number;
  page_size: number;
  origin_id?: string;
}

export interface billDetail_ {
  bill_id: string;
}

export interface ItemList {
  title: string;
  price: string;
  quantity: number;
  total: string;
  discount_mode: number;
  discount_number: string;
}

export interface Detail {
  bill_id: string;
  invoice_id: string;
  created: number;
  status: number;
  cur_term_num: number;
  term_num?: number;
  title: string;
  desc?: string;
  type: number;
  payer_name: string;
  payer_email: string;
  business_name: string;
  business_email: string;
  token_list: {
    symbol: string;
    token_id: string;
    name: string;
    logo: string;
    status: number;
    coin_id?: string;
  }[];
  item_list: {
    title: string;
    price: string;
    quantity: number;
    total: string;
    discount_mode?: number;
    discount_number?: string;
    currency?: string;
  }[];
  currency_type: number;
  currency: string;
  price: string;
  period: number;
  email_notify: number;
  webhook_notify: number;
  invoice_status: number;
  send_time: number;
  due_time: number;
  remark?: string;
  overdue_expired?: number;
  merchant_id?: string;
  had_term_num: number;
  origin_id: string;
  bill_type: string;
  updated: number;
  chain_id?: string;
  contract?: string;
  payment_url: string;
  attachment?: {
    name: string;
    path: string;
    size_str?: string;
    seize?: number;
  }[];
  more?: string;
  invoice?: string;
  total_refund_amount: string;
  extra_fee: string;
}

export interface Pay {
  record_id: string;
  chain_id: string;
  contract: string;
  symbol: string;
  amount: string;
  em: number;
  pay_time: number;
  service_fee: string;
  pay_status: string;
  logo: string;
}

export interface Refund {
  record_id: string;
  chain_id: string;
  contract: string;
  symbol: string;
  refund_amount: string;
  pay_time: number;
  network_fee: string;
  fee_style: number;
  refund_status: number;
  logo: string;
}

export interface Remark {
  remark: string;
  created: number;
}

export interface orderDetailRes_ {
  detail: Detail;
  pays: Pay[];
  refunds: Refund[];
  remarks: Remark[];
}
// -----------------------------

export interface billListRes {
  bill_created_at: number;
  last_payment_time: number;
  received: string;
  refund: string;
  status: number; //Order status: 0 not sent, 1 mail sending, 2 unpaid, 3 paid, 4 expired, 5 overdue payment, 6 done 7 revoke
}
export interface getBillParams {
  origin_id: string;
}

export interface bussinsInfoRes {
  name: string;
  origin_id: string;
  email: string;
  company_address: string;
  company_website: string;
  twitter_url: string;
  telegram_url: string;
}

export interface saveEamilParams {
  email: string;
  name: string;
}

export type emailType = {
  business_name?: string;
  business_email?: string;
  email_id: string;
  email: string;
  name: string;
  more?: string;
};

export interface eamilParams {
  key: string;
}

export interface signUrlType {
  /**
   * files  list
   */
  files: {
    name: string;
    content_type: string;
    size: number;
  }[];
}

export interface billStatisticsRes {
  inv_await_num: number;
  inv_paid_num: number;
  inv_late_paid_num: number;
  inv_due_num: number;
  total: number;
  single_total: number;
  cycle_total: number;
  inv_under_paid_num: number;
}

export interface createBillRefundParams {
  refund_amount: string;
  merchant_bill_id: string;
  chain_id: string;
  contract: string;
  symbol: string;
  remark: string;
  refund_fee_amount: string;
  memo: string;
  to_address: string;
  fee_style: string;
  network: string;
  code: string;
  pass: string;
}

export interface createBillRefundRes {
  refund_apply_id: string;
  /**
   * Transaction Record id
   */
  transaction_record_id: string;
}

export interface billSymbolsListRes {
  symbol: string;
  token_id: string;
}

export interface orderListParams {
  /**
   * Type: 1 single; 2 loop
   */
  type: number;
  keyword: string;
  /**
   * Order status: 1 email sending, 2 unpaid, 3 paid, 4 expired, 5 overdue payment, 6 done, 7 revoked
   */
  status: number;
  begin_at: number;
  end_at?: number;
  page_num: number;
  page_size: number;
}

export interface orderListRes {
  list: {
    bill_id: string;
    title: string;
    payer_name: string;
    price: string;
    currency: string;
    amount: {
      chan_id?: string;
      contract: string;
      symbol: string;
      amount: string;
      service_fee: string;
      pay_time: number;
      logo: string;
    }[];
    send_time: number;
    due_date: number;
    status?: number;
    cur_term_num: number;
    term_num: number;
    invoice_id: string;
    currency_type: number;
  }[];
  pagination: {
    page_num: number;
    page_size: number;
    total: number;
  };
}

export interface orderDetailRes {
  detail: {
    extra_fee: any;
    service_fee?: string;
    paid_amount?: string;
    amount?: string;
    symbol?: string;
    logo: string | undefined;
    total_refund_amount: number;
    /**
     * order id
     */
    bill_id: string;
    bill_type: string;
    invoice_id: string;
    created: number;
    /**
     * Order status: 1 email sending, 2 unpaid, 3 paid, 4 expired, 5 overdue payment, 6 done
     */
    status: number;
    cur_term_num: number;
    term_num: number;
    had_term_num: number;
    /**
     * invoice title
     */
    title: string;
    /**
     * invoice desc
     */
    desc: string;
    /**
     * Type: 1 single; 2 loop
     */
    type: number;
    /**
     * mail receiver
     */
    payer_name: string;
    /**
     * recipient mail
     */
    payer_email: string;
    /**
     * sender
     */
    business_name: string;
    /**
     * sender email
     */
    business_email: string;
    attachment?: {
      name: string;
      path: string;
      size: number;
    }[];
    token_list: {
      symbol: string;
      logo: string;
      name: string;
      token_id: string;
    }[];
    item_list: {
      currency: any;
      title: string;
      price: string;
      quantity: number;
      total: string;
    }[];
    /**
     * Coin Type 1 Token 2 Fiat
     */
    currency_type: number;
    /**
     * Pricing currency
     */
    currency: string;
    /**
     * total
     */
    price: string;
    /**
     * loop invoice cycle
     */
    period: number;
    /**
     * Whether to open email reminder 1 open
     */
    email_notify: number;
    /**
     * Whether to open webhook reminder 1 open
     */
    webhook_notify: number;
    /**
     * invoice status
     */
    invoice_status: number;
    send_time: number;
    due_time: number;
    updated: number;
    /**
     * order status
     */
    remark: string;
    /**
     * origin invoice_id
     */
    origin_id: string;
  };

  /**
   * order pay list
   */
  pays: {
    record_id: string;
    chain_id: string;
    contract: string;
    symbol: string;
    amount: string;
    /**
     * Bill Type: 1 Incoming, 2 Out
     */
    em: number;
    pay_time: number;
    service_fee: string;
    pay_status: string;
    logo: string;
  }[];
  /**
   * Order Refund List
   */
  refunds: {
    record_id: string;
    chain_id: string;
    contract: string;
    symbol: string;
    refund_amount: string;
    pay_time: number;
    network_fee: string;
    /**
     * Refund handling fee method, 1 deducted from the refund, 2 deducted from the account
     */
    fee_style: number;
    refund_status: number;
    logo: string;
  }[];
  remarks: {
    remark: string;
    created: number;
  }[];
}

export type createInvoiceParamsType = {
  invoice_id?: string;
  payer_name: string;
  payer_email: string;
  business_name: string;
  business_email: string;
  title: string;
  /**
   * about
   */
  desc: string;
  /**
   * currency
   */
  currency?: string;
  /**
   * Coin Type 1 Token 2 Fiat
   */
  currency_type: number;
  /**
   * coin list
   */
  token_list: {
    symbol: string;
    logo: string;
    name: string;
    token_id: string;
  }[];
  more?: string;
  is_standing: boolean;
  /**
   * Uploading attachments involves echo business, the type is variable
   */
  attachment?: (string | File)[];
  /**
   * item list
   */
  item_list: {
    title: string;
    price: string;
    quantity: number;
  }[];
  /**
   * Type 1 single 2 cycle
   */
  invoice_type: number;
  /**
   * cycle 1 week 2 month 3 per quarter 4 year
   */
  period: number;
  /**
   * email alert
   */
  email_notify: number;
  /**
   * webhook提醒
   */
  webhook_notify: number;
  /**
   *send timeDate
   */
  send_time: number;
  /**
   * End Time
   */
  due_time: number;
  /**
   * timestamp
   */
  timestamp: number;
};

export type termInvoiceParamsType = {
  /**
   * cycle  1 week 2 month 3 per quarter 4 year
   */
  period: number;
  /**
   *send timeDate
   */
  send_time: number;
  /**
   * End Time
   */
  due_time: number;
};

export type invoiceDetailsType = {
  id: number;
  invoice_id: string;
  /**
   * origin invoice_id
   */
  origin_id: string;
  payer_name: string;
  payer_email: string;
  business_name: string;
  business_email: string;
  title: string;
  desc: string;
  attachment?: {
    name: string;
    path: string;
    size: number;
  }[];
  /**
   * currency
   */
  currency: string;
  /**
   * currency type  1 Token 2 Fiat
   */
  currency_type: number;
  /**
   * Support Tokens list
   */
  token_list: Token_list[];
  item_list: Item_list[];
  /**
   * status 1 in progress 2 finished 3 stop 4 revoke
   */
  status: number;
  price: string;
  /**
   * Type 1Single 2Cycle
   */
  invoice_type: number;
  /**
   * cycle 1 week 2 month 3 per quarter 4 year
   */
  period: number;
  /**
   * total number of periods
   */
  term_num: number;
  had_term_num: number;
  /**
   * email alert
   */
  email_notify: number;
  email: string;
  webhook_notify: number;
  /**
   * send date
   */
  send_time: number;
  /**
   * end date
   */
  due_time: number;
  next_time: number;
  created: number;
  extra_fee?: string;
};

export interface Token_list {
  symbol: string;
  logo: string;
  name: string;
  token_id: string;
}

export interface Item_list {
  currency?: string;
  title: string;
  price: string;
  quantity: number;
  total: string;
}

export interface Pagination {
  page_num: number;
  page_size: number;
}

export interface ListParams {
  /**
   * search keyword
   */
  keyword: string;
  /**
   * start time
   */
  dt_start: number;
  /**
   * End Time
   */
  dt_end: number;
  /**
   * Status 1 in progress 2 finished  3 stop
   */
  status: number;
  pagination: Pagination;
}

export interface InvoiceList {
  pagination: {
    total: number;
    page_num?: number;
    page_size?: number;
  };
  list: {
    id?: number;
    /**
     * Only one-time, unsent messages
     */
    invoice_id: string;
    origin_id: string;
    payer_name: string;
    payer_email: string;
    business_name: string;
    business_email: string;
    title: string;
    currency: string;
    currency_type: number;
    /**
     * 1 stop 2 start
     */
    status: number;
    price: string;
    invoice_type: number;
    term_num: number;
    had_term_num: number;
    created: number;
    next_time: number;
  }[];
}
export interface TokenResponse {
  rate: string;
  mark: any;
  logo_url: any;
  /**Full name of the token */
  name: string;
  /**Token Abbreviation */
  symbol: string;
  /**to be determined*/
  min: string;
  /**icon*/
  logo: string;
  /**currency id*/
  token_id: string;
  /**USDT price*/
  price: string;
  /**type*/
  type: number;
  id: string;
  chain_id: string;
  contract: string;
  change24: string;
  market_cap: string;
  balance: string;
  value: string;
  out_balance: string;
  out_value: string;
  label: string;
}
