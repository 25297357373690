import { Http } from '@/api/http';
const http = new Http('/payment/v1');

export default class WebhookApi {
  getWebhookLogList(params: WebhookLogListSearchParam): Promise<WebhookLogListRes> {
    return http.postJson('/webhook/log/list', params);
  }

  getWebhookLogTrend(zone: string): Promise<WebhookLogTrendRes> {
    return http.postJson('/webhook/log/trend', { zone });
  }

  getWebhookLogDetail(id: number): Promise<WebhookLogDetailRes_> {
    return http.postJson('/webhook/log/detail', { id });
  }

  resendOneLog(id: number) {
    return http.postJson('/webhook/log/resend/one', { id });
  }

  resendBatchLog(data: resendBatchLogParamType) {
    return http.postJson('/webhook/log/resend/batch', data);
  }
}
export type resendBatchLogParamType = {
  all: 1 | 0 | number;
  result: 'success' | 'failed' | string;
  begin_at: number;
  end_at: number;
};
export interface WebhookLogListSearchParam {
  /** API Deposit、API Withdrawal、Invoice、Direct Deposit */
  type: 'API Deposit' | 'API Withdrawal' | 'Invoice' | 'Direct Deposit' | string;
  /** pending、processing、success */
  status: 'pending' | 'processing' | 'success' | string;
  /** record_id、notify_url */
  key: 'record_id' | 'notify_url';
  value: string;
  /** success、failed */
  result: 'success' | 'failed' | string;
  page_num: number;
  page_size: number;
  begin_at: number;
  end_at: number;
  is_last: boolean;
}

export interface WebhookLogTrendRes {
  list: WebhookLogTrendItem[];
}

export interface WebhookLogTrendItem {
  id: string;
  merchant_id: string;
  success_count: number;
  failed_count: number;
  begin_at: number;
  end_at: number;
}

export interface WebhookLogListRes {
  list: WebhookLogList[];
  pagination: {
    page_num: number;
    page_size: number;
    total: number;
  };
}

export interface WebhookLogList {
  record_id: string;
  type: string;
  times: number;
  result: string;
  id: number;
  notify_url: string;
  timestamp: number;
  pay_status: string;
  result_remark: number;
}

export interface WebhookLogDetailRes {
  record_id: string;
  times: number;
  result: string;
  send_data: string;
  type: string;
  timestamp: number;
  /** 
   *  Success     = 1 // 成功
 Failed      = 2 // 失败
 CallbackErr = 3 // 请求回调错误
 ConfirmErr  = 4 // 确定信息错误
 InnerSysErr = 5 // 内部系统错误 */
  result_remark: number;
  notify_url: string;
  pay_status: string;
}
export interface WebhookLogDetailRes_ {
  detail: WebhookLogDetailRes;
}
