import styled from 'styled-components';

export const StyleWrap = styled.div`
  .flex {
    display: flex;
  }
  .between {
    justify-content: space-between;
  }
  .alignItem {
    align-items: center;
  }
  .left-border {
    border-left: 1px solid #e8ecf2;
  }
  .left-avatar {
    position: relative;
    width: 40px;
    .left-avatar-icon {
      position: absolute;
      right: 0px;
      bottom: 0px;
    }
  }
  .left-name {
    color: #1f2025;
    font-family: 'Poppins';
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 155.556% */
  }
  .left-role {
    color: #71757e;
    font-family: 'Poppins';
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px; /* 157.143% */
  }
  .right-now {
    color: #71757e;
    font-family: 'Poppins';
    font-size: 12px;
    font-style: normal;
    font-weight: 600;
    line-height: 22px; /* 183.333% */
    display: flex;
    align-items: center;
  }
  .btn {
    padding: 4px 12px;
    border: 0;
    font-weight: 600;
  }
  .leave-btn {
    background: rgba(255, 86, 48, 0.16);
    color: #b71d18;
    :hover {
      border: 0;
      background: rgba(255, 86, 48, 0.26);
    }
  }
  .acc-btn {
    background: #f8f9fb;
    color: #1f2025;
    :hover {
      border: 0;
      background-color: rgba(145, 158, 171, 0.08);
    }
  }
  .creat-btn {
    background: rgba(60, 111, 245, 0.16);
    color: #1e3eb0;
    :hover {
      border: 0;
      background-color: rgba(60, 111, 245, 0.26);
    }
  }
  .ab-btn {
    position: absolute;
    top: -60px;
    right: 0;
  }
`;
