import { useState, useEffect, ReactNode } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { Box, Collapse } from '@mui/material';
// hooks
import useActiveLink from '../../../hooks/useActiveLink';
//
import { NavListProps } from '../types';
import NavItem from './NavItem';
import MenuPopover from '@/components/menu-popover';

type NavListRootProps = {
  data: NavListProps;
  depth: number;
  hasChild: boolean;
  isFold?: boolean;
  openPopoverB?: HTMLElement | null;
  handlePopver?: (e: any) => void;
  onClick?: () => void;
};

export default function NavList({
  data,
  depth,
  hasChild,
  isFold,
  openPopoverB,
  handlePopver,
}: NavListRootProps) {
  const { pathname } = useLocation();

  const { active, isExternalLink } = useActiveLink(data.path);

  const [open, setOpen] = useState(active);

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  useEffect(() => {
    if (!active) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const handleToggle = (e: any) => {
    if (data?.onClick) {
      data.onClick();
    }

    if (!isFold && hasChild) {
      setOpen(!open);
    }

    if (isFold && hasChild) {
      setOpen(!open);
      setOpenPopover(e.currentTarget);
    }

    if (depth !== 1 && handlePopver) {
      if (openPopoverB) {
        handlePopver(null);
      } else {
        handlePopver(e.currentTarget);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <NavItem
        item={data}
        depth={depth}
        open={open}
        active={active}
        isExternalLink={isExternalLink}
        onClick={handleToggle}
        isFold={isFold}
      />

      {!isFold && hasChild && (
        <Collapse in={open} unmountOnExit>
          <NavSubList data={data.children} depth={depth} />
        </Collapse>
      )}

      {isFold && hasChild && (
        <NavSubPopver
          openPopover={openPopover}
          handleClosePopover={() => {
            setOpenPopover(null);
          }}
          children={
            <NavSubList
              data={data.children}
              depth={depth}
              openPopoverB={openPopover}
              handlePopver={(e) => {
                setOpenPopover(e);
              }}
            />
          }
        />
      )}
    </>
  );
}

type NavListSubProps = {
  data: NavListProps[];
  depth: number;
  openPopoverB?: HTMLElement | null;
  handlePopver?: (e: any) => void;
};

function NavSubList({ data, depth, openPopoverB, handlePopver }: NavListSubProps) {
  return (
    <>
      {data.map((list) => (
        <NavList
          key={list.title + list.path}
          data={list}
          depth={depth + 1}
          hasChild={!!list.children}
          openPopoverB={openPopoverB}
          handlePopver={handlePopver}
        />
      ))}
    </>
  );
}

type NavSubPopverProps = {
  openPopover: HTMLElement | null;
  handleClosePopover: () => void;
  children: ReactNode;
};

function NavSubPopver({ openPopover, handleClosePopover, children }: NavSubPopverProps) {
  return (
    <MenuPopover
      arrow="left-center"
      open={openPopover}
      onClose={handleClosePopover}
      sx={{ width: 200, p: 0, ml: 1 }}
    >
      <Box
        sx={{
          width: '100%',
          overflow: 'hidden',
          position: 'relative',
          zIndex: 2,
          borderRadius: '12px',
        }}
      >
        {children}
      </Box>
    </MenuPopover>
  );
}
