/* eslint-disable */
import { effects } from '@cctip/saga-store';
import { makeAutoObservable, runInAction } from 'mobx';
import { t } from 'i18next';
import { securityApi_ } from '@/api';

export const UIState = makeAutoObservable({
  enableGoogleAuth: false,
  enableEmailAuth: false,
  enablePhoneAuth: false,
  showEmailAuthConfirm: false,
  showEmailAuthEnabled: false,
  showEmailAuthForm: false,
  showEmailChangeConfirm: false,
  showEmailChangeForm: false,
  showGoogleAuthConfirm: false,
  showGoogleAuthForm: false,
  showGoogleAuthQRForm: false,
  showPhoneAuthConfirm: false,
  showPhoneAuthEnabled: false,
  showPhoneAuthForm: false,
  showPhoneChangeConfirm: false,
  showPhoneChangeForm: false,
  showNameChangeConfirm: false,
  showNameChangeForm: false,
  showPasswordChangeConfirm: false,
  showPasswordChangeForm: false,
  showEnableSmallWithdrawForm: false,
  showPayPasswordChangeConfirm: false,
  showPayPasswordChangeForm: false,
  loading: false,
});

export const ApiHandle = makeAutoObservable({
  settings: 0,
  whitelist: 0,
  paymentSettings: 0,
});

export default function* MerchantPageFlow(ch) {
  const { enqueueSnackbar } = yield effects.take(ch);

  yield effects.takeLatest('google-2fa/close', function* () {
    runInAction(() => {
      UIState.showGoogleAuthConfirm = true;
    });
    const [confirm] = yield effects.race([
      effects.take('dialog/confirm'),
      effects.take('dialog/cancel'),
    ]);
    runInAction(() => {
      UIState.showGoogleAuthConfirm = false;
      if (confirm) {
        UIState.showGoogleAuthForm = true;
      }
    });

    while (true) {
      const { payload } = yield effects.take('gg-disable/result');

      try {
        if (payload) {
          UIState.loading = true;
          // const { mail_code, totp_code } = payload;
          yield effects.call(securityApi_.closeGoogle2FA, {
            verify_code: {
              ...payload,
            },
          });
          enqueueSnackbar(t('common.Successful'), { variant: 'success' });
          runInAction(() => {
            UIState.loading = false;
            UIState.showGoogleAuthForm = false;
            ApiHandle.settings++;
          });
        } else {
          runInAction(() => {
            UIState.showGoogleAuthForm = false;
          });
        }

        return;
      } catch (e) {
        if (e?.code) {
          enqueueSnackbar(t(`new_interface_err.${e?.code}`), { variant: 'error' });
        } else {
          enqueueSnackbar(e.message, { variant: 'error' });
        }
      } finally {
        UIState.loading = false;
      }
    }
  });

  yield effects.takeLatest('google-2fa/open', function* () {
    runInAction(() => {
      UIState.showGoogleAuthQRForm = true;
    });

    while (true) {
      const { payload } = yield effects.take('gg-enable/result');
      try {
        if (payload) {
          const { authCode, emailCode, secretKey } = payload;
          UIState.loading = true;
          yield effects.call(securityApi_.setupGoogle2FA, {
            secret_key: secretKey,
            code: authCode,
            verify_code: {
              totp_code: '',
              sms_code: '',
              mail_code: emailCode,
            },
          });
          enqueueSnackbar(t('common.Successful'), { variant: 'success' });
          runInAction(() => {
            UIState.loading = false;
            UIState.showGoogleAuthQRForm = false;
            ApiHandle.settings++;
          });
        } else {
          runInAction(() => {
            UIState.showGoogleAuthQRForm = false;
          });
        }

        return;
      } catch (e) {
        if (e?.code) {
          enqueueSnackbar(t(`new_interface_err.${e?.code}`), { variant: 'error' });
        } else {
          enqueueSnackbar(e.message, { variant: 'error' });
        }
      } finally {
        UIState.loading = false;
      }
    }
  });

  yield effects.takeLatest('email-2fa/open', function* () {
    runInAction(() => {
      UIState.showEmailAuthEnabled = true;
    });

    while (true) {
      const { payload } = yield effects.take('gg-enable/result');
      try {
        if (payload) {
          // 设置email
          UIState.loading = true;
          yield effects.call(securityApi_.setupEmail);

          enqueueSnackbar(t('common.Successful'), { variant: 'success' });
          runInAction(() => {
            UIState.loading = false;
            UIState.showEmailAuthEnabled = false;
            ApiHandle.settings++;
          });
        } else {
          runInAction(() => {
            UIState.showEmailAuthEnabled = false;
          });
        }

        return;
      } catch (e) {
        if (e?.code) {
          enqueueSnackbar(t(`new_interface_err.${e?.code}`), { variant: 'error' });
        } else {
          enqueueSnackbar(e.message, { variant: 'error' });
        }
      } finally {
        UIState.loading = false;
      }
    }
  });

  yield effects.takeLatest('email-2fa/close', function* () {
    runInAction(() => {
      UIState.showEmailAuthConfirm = true;
    });
    const [confirm] = yield effects.race([
      effects.take('dialog/confirm'),
      effects.take('dialog/cancel'),
    ]);
    runInAction(() => {
      UIState.showEmailAuthConfirm = false;
      if (confirm) {
        UIState.showEmailAuthForm = true;
      }
    });

    while (true) {
      const { payload } = yield effects.take('gg-disable/result');
      try {
        if (payload) {
          UIState.loading = true;
          yield effects.call(securityApi_.closeEmail2FA, payload);
          enqueueSnackbar(t('common.Successful'), { variant: 'success' });
          runInAction(() => {
            UIState.loading = false;
            UIState.showEmailAuthForm = false;
            ApiHandle.settings++;
          });
        } else {
          runInAction(() => {
            UIState.showEmailAuthForm = false;
          });
        }

        return;
      } catch (e) {
        if (e?.code) {
          enqueueSnackbar(t(`new_interface_err.${e?.code}`), { variant: 'error' });
        } else {
          enqueueSnackbar(e.message, { variant: 'error' });
        }
      } finally {
        UIState.loading = false;
      }
    }
  });

  yield effects.takeLatest('phone-2fa/open', function* () {
    runInAction(() => {
      UIState.showPhoneAuthEnabled = true;
    });

    const [confirm] = yield effects.race([
      effects.take('dialog/confirm'),
      effects.take('dialog/cancel'),
    ]);
    runInAction(() => {
      UIState.showPhoneAuthEnabled = false;
      if (confirm) {
        UIState.showPhoneAuthForm = true;
      }
    });

    while (true) {
      const { payload } = yield effects.take('gg-enable/result');
      try {
        if (payload) {
          UIState.loading = true;
          const verify_code = {
            mail_code: payload?.mail_code || '',
            totp_code: payload?.totp_code || '',
            sms_code: payload?.code || '',
          };
          yield effects.call(securityApi_.setPhone2FA, {
            status: 1,
            verify_code,
          });
          enqueueSnackbar(t('common.Successful'), { variant: 'success' });
          runInAction(() => {
            UIState.loading = false;
            UIState.showPhoneAuthForm = false;
            ApiHandle.settings++;
          });
        } else {
          runInAction(() => {
            UIState.showPhoneAuthForm = false;
          });
        }

        return;
      } catch (e) {
        if (e?.code) {
          enqueueSnackbar(t(`new_interface_err.${e?.code}`), { variant: 'error' });
        } else {
          enqueueSnackbar(e.message, { variant: 'error' });
        }
      } finally {
        UIState.loading = false;
      }
    }
  });

  yield effects.takeLatest('phone-2fa/close', function* () {
    runInAction(() => {
      UIState.showPhoneAuthConfirm = true;
    });
    const [confirm] = yield effects.race([
      effects.take('dialog/confirm'),
      effects.take('dialog/cancel'),
    ]);
    runInAction(() => {
      UIState.showPhoneAuthConfirm = false;
      if (confirm) {
        UIState.showPhoneAuthForm = true;
      }
    });

    while (true) {
      const { payload } = yield effects.take('gg-disable/result');
      try {
        if (payload) {
          UIState.loading = true;
          const verify_code = {
            mail_code: payload?.mail_code || '',
            totp_code: payload?.totp_code || '',
            sms_code: payload?.code || '',
          };
          yield effects.call(securityApi_.setPhone2FA, {
            status: -1,
            verify_code,
          });
          enqueueSnackbar(t('common.Successful'), { variant: 'success' });
          runInAction(() => {
            UIState.loading = false;
            UIState.showPhoneAuthForm = false;
            ApiHandle.settings++;
          });
        } else {
          runInAction(() => {
            UIState.showPhoneAuthForm = false;
          });
        }

        return;
      } catch (e) {
        if (e?.code) {
          enqueueSnackbar(t(`new_interface_err.${e?.code}`), { variant: 'error' });
        } else {
          enqueueSnackbar(e.message, { variant: 'error' });
        }
      } finally {
        UIState.loading = false;
      }
    }
  });

  yield effects.takeLatest('email-change/confirm', function* () {
    runInAction(() => {
      UIState.showEmailChangeConfirm = true;
    });
    const { confirm } = yield effects.take('email-confirm/result');
    runInAction(() => {
      UIState.showEmailChangeConfirm = false;
    });
    if (!confirm) return;
    runInAction(() => {
      UIState.showEmailChangeForm = true;
    });
    while (true) {
      const { payload } = yield effects.take('email-change/result');
      if (!payload) {
        runInAction(() => {
          UIState.showEmailChangeForm = false;
        });
        return;
      }
      try {
        const { totp_code, sms_code, mail_code, ...rest } = payload;
        UIState.loading = true;
        yield effects.call(securityApi_.changeEmail, {
          ...rest,
          verify_code: { totp_code, sms_code, mail_code },
        });
        runInAction(() => {
          UIState.loading = false;
          UIState.showEmailChangeForm = false;
          ApiHandle.settings++;
        });
        // 'Email address has been changed.'
        enqueueSnackbar(t('merchantSettings.email_change_desc'));
        return;
      } catch (e) {
        if (e?.code) {
          enqueueSnackbar(t(`new_interface_err.${e?.code}`), { variant: 'error' });
        } else {
          enqueueSnackbar(e.message, { variant: 'error' });
        }
      } finally {
        UIState.loading = false;
      }
    }
  });

  yield effects.takeLatest('phone-change/confirm', function* ({ phone }) {
    if (phone) {
      runInAction(() => {
        UIState.showPhoneChangeConfirm = true;
      });
      const { confirm } = yield effects.take('phone-confirm/result');
      runInAction(() => {
        UIState.showPhoneChangeConfirm = false;
      });
      if (!confirm) return;
    }
    runInAction(() => {
      UIState.showPhoneChangeForm = true;
    });
    while (true) {
      const { payload } = yield effects.take('phone-change/result');
      if (!payload) {
        runInAction(() => {
          UIState.showPhoneChangeForm = false;
        });
        return;
      }
      try {
        UIState.loading = true;
        const { totp_code, sms_code, mail_code, phone: composedPhone, ...rest } = payload;
        const [phone_area, phoneInForm] = composedPhone.split('-');
        yield effects.call(phone ? securityApi_.changePhone : securityApi_.setupPhone, {
          ...rest,
          phone: phoneInForm,
          area: `+${phone_area}`,
          verify_code: { totp_code, sms_code, mail_code },
        });
        runInAction(() => {
          UIState.loading = false;
          UIState.showPhoneChangeForm = false;
          ApiHandle.settings++;
        });
        // 'Phone number has been changed.'
        // 'Phone number has been verified.'
        enqueueSnackbar(
          phone ? t('merchantSettings.phone_change_desc') : t('merchantSettings.phone_verify_desc')
        );
        return;
      } catch (e) {
        if (e?.code) {
          enqueueSnackbar(t(`new_interface_err.${e?.code}`), { variant: 'error' });
        } else {
          enqueueSnackbar(e.message, { variant: 'error' });
        }
      } finally {
        UIState.loading = false;
      }
    }
  });

  // nickname
  yield effects.takeLatest('name-change/confirm', function* ({ name }) {
    if (name) {
      runInAction(() => {
        UIState.showNameChangeConfirm = true;
      });
      const { confirm } = yield effects.take('name-change/result');
      runInAction(() => {
        UIState.showNameChangeConfirm = false;
      });
      if (!confirm) return;
    }
    runInAction(() => {
      UIState.showNameChangeForm = true;
    });
    while (true) {
      const { payload } = yield effects.take('name-change/result');
      if (!payload) {
        runInAction(() => {
          UIState.showNameChangeForm = false;
        });
        return;
      }
      try {
        UIState.loading = true;
        const { name } = payload;
        yield effects.call(securityApi_.accountSet, {
          field: 'username',
          value: name,
        });
        runInAction(() => {
          UIState.loading = false;
          UIState.showNameChangeForm = false;
          ApiHandle.settings++;
        });

        // enqueueSnackbar(
        //   phone ? t('merchantSettings.phone_change_desc') : t('merchantSettings.phone_verify_desc')
        // );
        return;
      } catch (e) {
        if (e?.code) {
          enqueueSnackbar(t(`new_interface_err.${e?.code}`), { variant: 'error' });
        } else {
          enqueueSnackbar(e.message, { variant: 'error' });
        }
      } finally {
        UIState.loading = false;
      }
    }
  });

  yield effects.takeLatest('password-change/confirm', function* () {
    runInAction(() => {
      UIState.showPasswordChangeConfirm = true;
    });
    const { confirm } = yield effects.take('password-confirm/result');
    runInAction(() => {
      UIState.showPasswordChangeConfirm = false;
    });
    if (!confirm) return;
    runInAction(() => {
      UIState.showPasswordChangeForm = true;
    });
    while (true) {
      const { payload } = yield effects.take('password-change/result');
      if (!payload) {
        runInAction(() => {
          UIState.showPasswordChangeForm = false;
        });
        return;
      }
      try {
        UIState.loading = true;
        const { totp_code, sms_code, mail_code, password2, login_pass, ...rest } = payload;
        yield effects.call(securityApi_.changePassword, {
          passwd: login_pass,
          repeat_passwd: password2,
          verify_code: { totp_code, sms_code, mail_code },
        });
        runInAction(() => {
          UIState.loading = false;
          UIState.showPasswordChangeForm = false;
        });
        // 'Password has been changed.'
        enqueueSnackbar(t('merchantSettings.pw_change_desc'));
        return;
      } catch (e) {
        if (e?.code) {
          enqueueSnackbar(t(`new_interface_err.${e?.code}`), { variant: 'error' });
        } else {
          enqueueSnackbar(e.message, { variant: 'error' });
        }
      } finally {
        UIState.loading = false;
      }
    }
  });

  // set payment pw
  yield effects.takeLatest('pay-password-set/confirm', function* () {
    runInAction(() => {
      UIState.showPayPasswordChangeForm = true;
    });
    while (true) {
      const { payload } = yield effects.take('pay-password-change/result');
      if (!payload) {
        runInAction(() => {
          UIState.showPayPasswordChangeForm = false;
        });
        return;
      }
      try {
        UIState.loading = true;
        const { totp_code, sms_code, mail_code, pay_pass, password2, ...rest } = payload;
        const param = {
          pay_passwd: pay_pass,
          repeat_pay_passwd: password2,
        };
        yield effects.call(securityApi_.setPayPw, {
          ...param,
          verify_code: {
            totp_code,
            sms_code,
            mail_code,
          },
        });
        runInAction(() => {
          UIState.loading = false;
          UIState.showPayPasswordChangeForm = false;
          ApiHandle.settings++;
        });
        enqueueSnackbar('Payment password has been bind.');
        return;
      } catch (e) {
        if (e?.code) {
          enqueueSnackbar(t(`new_interface_err.${e?.code}`), { variant: 'error' });
        } else {
          enqueueSnackbar(e.message, { variant: 'error' });
        }
      } finally {
        UIState.loading = false;
      }
    }
  });

  // payment pw
  yield effects.takeLatest('pay-password-change/confirm', function* () {
    runInAction(() => {
      UIState.showPayPasswordChangeConfirm = true;
    });
    const { confirm } = yield effects.take('pay-password-confirm/result');
    runInAction(() => {
      UIState.showPayPasswordChangeConfirm = false;
    });
    if (!confirm) return;
    runInAction(() => {
      UIState.showPayPasswordChangeForm = true;
    });
    while (true) {
      const { payload } = yield effects.take('pay-password-change/result');
      if (!payload) {
        runInAction(() => {
          UIState.showPayPasswordChangeForm = false;
        });
        return;
      }
      try {
        UIState.loading = true;
        const { totp_code, sms_code, mail_code, pay_pass, password2, ...rest } = payload;
        const param = {
          pay_passwd: pay_pass,
          repeat_pay_passwd: password2,
        };
        yield effects.call(securityApi_.changePayPw, {
          ...param,
          verify_code: {
            totp_code,
            sms_code,
            mail_code,
          },
        });
        runInAction(() => {
          UIState.loading = false;
          UIState.showPayPasswordChangeForm = false;
          ApiHandle.settings++;
        });
        // 'Payment password has been changed.'
        enqueueSnackbar(t('wallet.account.setting.pay.password.change.desc'));
        return;
      } catch (e) {
        if (e?.code) {
          enqueueSnackbar(t(`new_interface_err.${e?.code}`), { variant: 'error' });
        } else {
          enqueueSnackbar(e.message, { variant: 'error' });
        }
      } finally {
        UIState.loading = false;
      }
    }
  });

  yield effects.takeLatest('small-withdraw/open', function* () {
    runInAction(() => {
      UIState.showEnableSmallWithdrawForm = true;
    });

    while (true) {
      const { payload } = yield effects.take('small-withdraw-enable/result');
      try {
        if (!payload) {
          runInAction(() => {
            UIState.showEnableSmallWithdrawForm = false;
          });
          return;
        }
        if (payload) {
          UIState.loading = true;
          const { pay_pass, verify_code, ...rest } = payload;

          yield effects.call(securityApi_.setWithdrawSmall, {
            status: 1,
            passwd: pay_pass,
            verify_code,
          });
          enqueueSnackbar('2FA for withdrawals under $100 is now disabled.', {
            variant: 'success',
          });
        }
        runInAction(() => {
          UIState.loading = false;
          UIState.showEnableSmallWithdrawForm = false;
          ApiHandle.settings++;
        });
        return;
      } catch (e) {
        if (e?.code) {
          enqueueSnackbar(t(`new_interface_err.${e?.code}`), { variant: 'error' });
        } else {
          enqueueSnackbar(e.message, { variant: 'error' });
        }
      } finally {
        runInAction(() => {
          UIState.loading = false;
        });
      }
    }
  });

  yield effects.takeLatest('small-withdraw/close', function* () {
    // runInAction(() => {
    //   UIState.showEnableSmallWithdrawForm = true;
    // });

    while (true) {
      // const { payload } = yield effects.take('small-withdraw-enable/result');
      try {
        // if (!payload) {
        //   runInAction(() => {
        //     UIState.showEnableSmallWithdrawForm = false;
        //   });
        //   return;
        // }
        // if (payload) {
        //   UIState.loading = true;
        //   const { pay_pass, verify_code, ...rest } = payload;

        //   yield effects.call(securityApi_.setWithdrawSmall, {
        //     status: -1,
        //     pay_passwd: pay_pass,
        //     verify_code,
        //   });
        // }
        UIState.loading = true;
        // const { pay_pass, verify_code, ...rest } = payload;

        yield effects.call(securityApi_.setWithdrawSmall, {
          status: -1,
          pay_passwd: '',
          verify_code: {
            totp_code: '',
            sms_code: '',
            mail_code: '',
          },
          passwd: '',
        });
        runInAction(() => {
          UIState.loading = false;
          // UIState.showEnableSmallWithdrawForm = false;
          ApiHandle.settings++;
        });
        return;
      } catch (e) {
        if (e?.code) {
          enqueueSnackbar(t(`new_interface_err.${e?.code}`), { variant: 'error' });
        } else {
          enqueueSnackbar(e.message, { variant: 'error' });
        }
      } finally {
        runInAction(() => {
          UIState.loading = false;
        });
      }
    }
  });

  //   choose 安全验证时间
  yield effects.takeLatest('update-period', function* ({ payload }) {
    try {
      yield effects.call(securityApi_.accountSet, { field: 'lock_unit', value: `${payload}` });
      runInAction(() => {
        ApiHandle.settings++;
      });
    } catch (e) {
      if (e?.code) {
        enqueueSnackbar(t(`new_interface_err.${e?.code}`), { variant: 'error' });
      } else {
        enqueueSnackbar(e.message, { variant: 'error' });
      }
    }
  });
}
