import { useState, ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
// components
import LoadingScreen from '../components/loading-screen';
import { useAuthContext } from './useAuthContext';
import useLocalStorage from '@/hooks/useLocalStorage';
import { EmptyMerchantId, PATH_AFTER, getPathAfter } from '@/config';

type AuthGuardProps = {
  children: ReactNode;
};

export default function AuthGuard({ children }: AuthGuardProps) {
  const { isAuthenticated, isInitialized, isVerify, user, isNewUser } = useAuthContext();
  const { pathname, state } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState<string | null>(null);
  const [verifyType] = useLocalStorage('verifyType', '1');
  let invitedEmail = localStorage.getItem('invitedEmail') || '';
  const isEmptyMerchant = EmptyMerchantId === user?.merchantId;

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  // No login and no authentication both go login
  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    if (isNewUser && invitedEmail) {
      return <Navigate to={'/register'} />;
    }
    return <Navigate to={'/login'} />;
  }

  // Can only be directed to the verification screen after the login step
  // if (isAuthenticated && !isVerify && pathname !== '/verification') {
  //   if (pathname !== requestedLocation) {
  //     setRequestedLocation(pathname);
  //   }
  //   return <Navigate to={'/verification'} replace={true} state={state} />;
  // }

  // If you go to the verification page without a security verification method and you are not logged in, jump back to login, except for full verification
  if (
    isAuthenticated &&
    !isVerify &&
    pathname === '/verification' &&
    // eslint-disable-next-line
    !Boolean(user?.security_email) &&
    // eslint-disable-next-line
    !Boolean(user?.security_phone) &&
    !user?.exists_google_2fa &&
    !['2', 2].includes(verifyType)
  ) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Navigate to={'/login'} replace={true} state={state} />;
  }

  if (isAuthenticated && isVerify && ['/verification', '/login', '/register'].includes(pathname)) {
    if (isEmptyMerchant) {
      return <Navigate to={PATH_AFTER[100000]} />;
    }
    return (
      <Navigate to={getPathAfter(PATH_AFTER[(user?.role_id as keyof typeof PATH_AFTER) || -1])} />
    );
  }

  if (isEmptyMerchant && ['/dashboard/index'].includes(pathname)) {
    if (isEmptyMerchant) {
      return <Navigate to={getPathAfter(PATH_AFTER[100000])} />;
    }
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return <>{children}</>;
}
