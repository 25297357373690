/* eslint-disable */
//jsencrypt就是一个基于rsa加解密的js库
//  npm install jsencrypt

import JSEncrypt from 'jsencrypt';
import { b64tohex } from 'jsencrypt/lib/lib/jsbn/base64';

import CryptoJS from 'crypto-js';

const _charStr = 'abacdefghjklmnopqrstuvwxyzABCDEFGHJKLMNOPQRSTUVWXYZ0123456789';
const appid = '202211181420251593489282956267520';
const mechid = '10001';

let time = new Date();
var times = time.toString(); //log：Wed Aug 31 2022 10:47:48 GMT+0800 (中国标准时间)

// var randStr = getRandomString(5)
// TODO rsa加密
var encryptor = new JSEncrypt(); // 创建加密对象实例
//之前ssl生成的公钥，复制的时候要小心不要有空格

const pubKey =
  '-----BEGIN PUBLIC KEY-----\nMIICIjANBgkqhkiG9w0BAQEFAAOCAg8AMIICCgKCAgEAvpbwh1QidzeChzTcp/3P\nrupdq80cSuVi093/jg+t1kvBE8lQLwfWVfTmjfHQXKPSZ7jjmgu5Z1AJErePoksp\nnJs/zdtnfly4Kelp/VynhXgolVjgbrdJ8IzNIShmvCT+xrgQ4mC6w3eibnkWMCzk\nfulGRFCnUPOhhVevvnVpaEOp105TeBxXTiS+TNzcgOjq8m7nBHYraEYor8COs85K\n/gbEQSc+qdWVNpR9ZzPvwl5CE8k1dNnZdHY3/2y215KeQLEuIe1Yhd2/OI6sMg40\n8++f1ogiEOdIoRfSfiCCETUlHsNxZDRCt3sho4VNuuqUXJoroJUhavpqkfQXlgJB\n4x+NFo76/PZJKFJ5iY2Ob7SlY3R5w1pYAzxk5WrqmTjwyUhEbVX25yEeioVkwqNz\ngl7cUbR9JvJQ/Is0+eA9apVG5AysIfNBnkBXct47hOVI+FR+XUwvNwaRbn43ydsu\n7oqYGHA8OLhIk52aUS6aT+0CBHBB35NrRRDaq252EXGINg7utkjUuLGN8hCLmZv2\nSMhvb5G3g7btBKs1hNtZpnQXj+T/bviP6Wgsy2b1ZSJLr3iv/1JNcH6780NSygSo\ngVkBlKt0hkenLWdGRMbCfwQ7LSRUmQqzjkgjePkJMTXVdwESZ02jI5NSkLOi8+kW\nmcWh0rSpoX41HC8ZLwmFJq0CAwEAAQ==\n-----END PUBLIC KEY-----';
export const priKey = `
-----BEGIN PRIVATE KEY-----
MIIEvQIBADANBgkqhkiG9w0BAQEFAASCBKcwggSjAgEAAoIBAQDzNo6+qTQKoyuj
YUpWY9RORAB5MXTDjbwmCQS0UoXJGpNw+dAd1X7tEQWWGmZzNo/b9x1aEqtm0UMr
1VjDdr+ygG5rlSH+C0ZftnjhZMPsu/f6dFm0BKbO6WbmoC4SCGbrW/xp0OaYukgN
098uZ6K/Pyp3ndVOCxNo9aSXc7Gxldo8uvG6yfLLBwGJwmmJ0IAOF7p3ZzCEKwhp
/eQSeiUSkX3PZ23xE7NCyxVuVTtm5x/fT6Q/uisUnlmF9ADFlJqfGfx4/UrsutdT
aAac8T8pgjhKf+frZ7X/xliUiBuFMZlF5D4EKJdifXd1z1ZVst4sSpyuGOY8f1J4
FtBgeAI1AgMBAAECggEAJcdfvPYDg3N9H8/JAEpZfB7MBAdOn+GwTCgyduAqr+ZU
6MOlND0pjiGKwJoxN8xN5FkqUgd8YGg5CuIE+7fCZzPT8xojQZb2w39Qn5JLbMZ1
6o2QMYpXbuSKe2B+R4bOldigCL/OUgL9lkhG7qpieVqSeiomLA1EYpVlj7EE9RSI
X6Kgt6A3ei9JPkFrsNBG39tjnVrQ5TpQMzYIJR8ALtl1mUrOcyKWREes6geUGth7
2zc/iPJA0+UyIdV7lO7XGyGRWDfJjqFr2lyyrgSXIgYbcWq7o2IhH+YfmqW9uG9v
iU1WbcTz3OBHC4c/+rEtjyFYItX1KTXHfpn2w1GYsQKBgQD8PcB4CO4YOwVE2f5b
qDNPvKyjTZfRf0uUUasMM3jnTt2BvMvHujuNTKmV3OqtkD/Uwz0f5T7QOPrUghdJ
FnO5KurF+HyXfKgI3Hrf81f64Ffkk4KVeuB5PJULPFChURPr1At58VsJXXBPS3Yl
fKhfJ8CsRQV0t28gx6ee/P89nwKBgQD21l2MC8MhnmwTDdm7iwHi9iB3aJ4GmZBa
tUma72wijw2y+cCScywBLJn5cILXz7KxUOd/BG7WU+SMyO0ttx1b7BHt7p6hxhpj
iyGIKnjZF6TomVyNu+0FYyYMce8LMuE5WJOq8tTBk3uBZKHt6DNuAqe7ZTNuUmSS
JRTUT6WHqwKBgQD52t1MyWO3IrdsZRbcibcR8816EUEiJpqh92ecNVw8m1U0zuN4
GtQkedy98s+PTwS7Zw2kC3t13Asx9w7Dufa1Y3qc0S/xCmyUInmgXjY27oSBiVBf
axDxZn4oW/1uXZaDEk6v4tCPbTvzf8cvCNTrNMBYpCHsZEsyyDIUfX0C9wKBgC/W
OYjzmpyAVFeY/pJvDS2MH5ykpMOIrUY3Dg29ysojyTbfWifHtEaB+1ExMdpbQTlG
AadHF6fn2EpEglqyuzjeVlczQID/oIucIiJ+gK8p+uatGhfJzNSXc39D8VzNPoLb
nLqrlvnw+CWKeCtgLd9rGlCh+0atJxp/nzrKOL1DAoGAXu1h49cAQTpkJ+QuE9t4
GvjsVhGPBvBCyJYIXQkfYxdYZ/tKotaBQI9PTywIywd5RwaWy5aB96s/w+qgRPa2
HwDJpW7JdiAFgINhexZaqIuD9RK0vEVBuelfwXjZM+pQt0IyNI2UsSssMY7IOtxh
SDj03Y/K1tX1RTLA5rsuDoc=
-----END PRIVATE KEY-----
`.trim();

export function encrypt(
  {
    content = {},
    noncestr = '',
    time = '',
    key = '',
    merchant_id = '',
    app_id = '',
    api_key = '',
    signType = 1,
    token_id = '',
    out_order_no = '',
    amount = '',
    chain = '',
    contract = '',
    bodystr = '',
    merchant_order_id = '',
  },
  type
) {
  // var random = Math.floor(Math.random() * 100) + 1

  // var content = {
  //     "token_id": "e8f64d3d-df5b-411d-897f-c6d8d30206b7",
  //     "chain": "ETH",
  //     "amount": random.toString(),
  //     "out_order_no": orderCode(),
  //     "fiat_name": "USD",
  //     "contract": "0x2170ed0880ac9a755fd29b2688956bd959f933f8"
  // }
  // var str = 'ccpayment_id=' + merchant_id + '&app_id=' + app_id + `&app_secret=${api_key}` + '&json_content=' + JSON.stringify(content) + '&timestamp=' + time + '&noncestr=' + noncestr
  // console.log('string', str)
  // if (signType === 2) {
  //     encryptor.setPrivateKey(key)//设置公钥
  //     const buff = encryptor.sign(str, CryptoJS.SHA256, 'sha256')  // 对内容进行加密
  //     return b64tohex(buff);
  // }
  var str = '';
  if (type === 'token') {
    str = app_id + api_key + time;
  }
  if (type === 'chain') {
    str = app_id + api_key + time + token_id;
  }
  if (type === 'create') {
    str = app_id + api_key + time + bodystr;
  }

  return CryptoJS.SHA256(str).toString();
}

// TODO rsa解密
// function decrypt() {
//     var decrypt = new JSEncrypt()//创建解密对象实例
// //之前ssl生成的秘钥
//     decrypt.setPrivateKey(priKey)//设置秘钥
//     var uncrypted = decrypt.decrypt(encrypted)//解密之前拿公钥加密的内容

// }

/**
 * 随机生成字符串
 * @param len 指定生成字符串长度
 */
export function getRandomString(len) {
  let min = 0,
    max = _charStr.length - 1,
    _str = '';
  //判断是否指定长度，否则默认长度为15
  len = len || 15;
  //循环生成字符串
  for (var i = 0, index; i < len; i++) {
    index = RandomIndex(min, max, i);
    _str += _charStr[index];
  }
  return _str;
}

// function orderCode() {
//     var orderCode = '';
//     for (var i = 0; i < 6; i++) //6位随机数，用以加在timestamp后面。
//     {
//         orderCode += Math.floor(Math.random() * 10);
//     }
//     orderCode = new Date().getTime() + orderCode;  //timestamp，用来生成订单号。
//     console.log(orderCode)
//     return orderCode;
// }

/**
 * 随机生成索引
 * @param min 最小值
 * @param max 最大值
 * @param i 当前get位置
 */
function RandomIndex(min, max, i) {
  let index = Math.floor(Math.random() * (max - min + 1) + min),
    numStart = _charStr.length - 10;
  //如果字符串第一位是数字，则递归重新get
  if (i === 0 && index >= numStart) {
    index = RandomIndex(min, max, i);
  }
  //返回最终索引值
  return index;
}

// encrypt()
