// @mui
import { Typography, Stack, StackProps, Button } from '@mui/material';
//
import Image from '../image';
import { MyIcon } from '@/assets/icons/MyIcon';
import { useLocales } from '@/locales';

interface EmptyContentProps extends StackProps {
  title: string;
  img?: string;
  description?: string;
  type?: number;
  btnNode?: any;
  style?: {};
  onOK?: () => void;
  BtnClick?: () => void;
  closeImg?: boolean;
}

export default function EmptyContent({
  title,
  description,
  img,
  type,
  sx,
  style,
  btnNode,
  onOK,
  BtnClick,
  closeImg = false,
  ...other
}: EmptyContentProps) {
  const { translate: t } = useLocales();
  return (
    <Stack
      alignItems="center"
      justifyContent="center"
      sx={{
        height: 1,
        textAlign: 'center',
        padding: type === 1 ? '64px 16px 20px 16px' : (theme) => theme.spacing(8, 2),
        ...sx,
      }}
      {...other}
    >
      {closeImg ? null : (
        <Image
          disabledEffect
          alt="empty content"
          src={img || '/assets/illustrations/illustration_empty_content.svg'}
          sx={{ height: 160, mb: type === 1 ? 2 : 3 }}
        />
      )}

      <Typography
        variant="h5"
        gutterBottom
        sx={{ fontSize: '14px !important', fontWeight: 600, color: '#B3B9C5 !important', ...style }}
      >
        {(type === 1 && (
          <span style={{ color: '#27291E', fontSize: '16px', fontWeight: 500 }}>{title}</span>
        )) ||
          title}
      </Typography>
      {btnNode}

      {description && (
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {description}
        </Typography>
      )}
    </Stack>
  );
}
