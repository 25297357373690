// @mui
import { styled } from '@mui/material/styles';
import { Box, BoxProps, MenuItem, Stack, Tooltip, Typography } from '@mui/material';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// components
import { CustomAvatar } from '../../../components/custom-avatar';
import { MyIcon } from '@/assets/icons/MyIcon';
import MenuPopover from '@/components/menu-popover/MenuPopover';
import { useEffect, useState } from 'react';
import { useLocales } from '@/locales';
import DialogAddMerchanttForm from './components/add-merchant-dialog';
import { accountApi_ } from '@/api';
import { enqueueSnackbar } from 'notistack';
import MerchantLoading from './components/merchant-loading';
import { useLocation, useNavigate } from 'react-router';
import { accountStore, merchantStroe, walletStroe } from '@/stores';
import default_merchant_head from '@/assets/img/default_merchant_head.svg';
import { observer } from 'mobx-react';

import { MerchantStatusREG, PATH_AFTER, getPathAfter } from '@/config';
import { RoleEnum } from '@/routes';
import { handleCopy } from '@/components/copy-button';

const StyledRoot = styled(Box)<BoxProps>(({ theme, sx }) => ({
  display: 'flex',
  alignItems: 'center',
  // justifyContent: 'space-around',
  padding: theme.spacing(2, 2),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: '#F2F6F9',
  sx,
}));

export default observer(function NavAccount({ isFold }: { isFold?: boolean }) {
  const { merchantList: _merchantList, getMerchantList: _getMerchantList } = merchantStroe;
  const { user, updUserInfoToStore } = useAuthContext();
  const { translate: t } = useLocales();
  const navigate = useNavigate();
  const location = useLocation();
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [addloading, setAddLoading] = useState(false);
  const [oldMerchantId, setOldMerchantId] = useState('');

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const setInit = () => {
    localStorage.removeItem('timeForInvite');
    localStorage.setItem('isFirst', '1');
  };

  const handleClickItem = async (merchant_id: string) => {
    handleClosePopover();
    setLoading(true);
    try {
      await accountApi_.switchMerchantAccount(merchant_id);
      setInit();
      const resp = await updUserInfoToStore({ isNeedExtra: true, refreshRouter: true });
      await accountStore.updateSwitchStatus(true);

      if (resp?.merchantId) {
        setOldMerchantId(resp?.merchantId);
      }

      if (
        !location.pathname.includes('dashboard') ||
        !getPathAfter(PATH_AFTER[resp?.role_id || -1]).includes('dashboard')
      ) {
        navigate(getPathAfter(PATH_AFTER[resp?.role_id || -1]), {
          replace: true,
        });
      }
    } catch (error) {
      enqueueSnackbar(t(`nav.switch_merchant_error`), { variant: 'error' });
      handleClickItem(oldMerchantId);
      console.log('nav_account_error', { error });
    } finally {
      setLoading(false);
      walletStroe.asyncWalletInfo();
    }
  };

  const getMerchantList = async () => {
    try {
      await _getMerchantList();
      // const res = await accountApi_.getMerchantAccountList();
      // if (res) {
      //   setMerchantList(res.list);
      // }
    } catch (error) {
      enqueueSnackbar(error?.message, { variant: 'error' });
    }
  };

  useEffect(() => {
    getMerchantList();
    if (user?.merchantId) {
      setOldMerchantId(user?.merchantId);
    }

    // eslint-disable-next-line
  }, []);

  if (loading) {
    return <MerchantLoading showText={t('nav.Add_New_Merchant_dialog_switching')} />;
  }

  return (
    <>
      {addloading ? (
        <MerchantLoading showText={t('nav.Add_New_Merchant_dialog_creating')} />
      ) : (
        <>
          <StyledRoot sx={{ cursor: 'pointer' }} onClick={handleOpenPopover}>
            <Box style={{ position: 'relative' }}>
              <CustomAvatar
                src={user?.head_url || default_merchant_head}
                alt={user?.nickname}
                name={user?.email}
                sx={{ backgroundColor: 'transparent' }}
              />
              <MyIcon
                name={(user?.merchantStatus ?? 0) === MerchantStatusREG ? 'v_succ' : 'v_notsucc'}
                size={16}
                style={{ position: 'absolute', right: '-4px', bottom: '0px' }}
              />
            </Box>
            <Box
              sx={{
                ml: isFold ? 0 : 2,
                minWidth: 0,
                transition: 'margin 0.3s cubic-bezier(.21,.39,.63,.84)',
                display: 'flex',
                alignItems: 'center',
                width: '100%',
                justifyContent: 'space-between',
              }}
            >
              <Tooltip title={user?.nickname}>
                <Typography
                  variant="subtitle2"
                  className="text-one-overflow-hidden"
                  noWrap
                  sx={{
                    fontWeight: 600,
                    fontSize: '16px',
                    lineHeight: '22px',
                    color: '#1E1E1E',
                    mr: 2,
                  }}
                >
                  {user?.nickname}
                  {user?.nickname.toLocaleUpperCase() !==
                    `CP${user?.dispalyId}`.toLocaleUpperCase() && (
                    <>
                      <br />
                      <Typography
                        component={'div'}
                        sx={{
                          fontWeight: 400,
                          fontSize: '14px',
                          lineHeight: 'normal',
                          color: '#71757E',
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleCopy(user?.dispalyId || '', t);
                        }}
                      >
                        ID: {user?.dispalyId}
                      </Typography>
                    </>
                  )}
                </Typography>
              </Tooltip>

              <MyIcon
                name="black_arrow"
                size={14}
                style={{
                  height: '14px',
                  transform: `rotate(${openPopover ? '-270deg' : '-90deg'})`,
                  cursor: 'pointer',
                  transition: 'transform 0.2s cubic-bezier(.21,.39,.63,.84)',
                }}
              />

              {/* <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
        {user?.nickname}
      </Typography> */}
            </Box>
          </StyledRoot>
          <MenuPopover
            open={openPopover}
            onClose={handleClosePopover}
            disabledArrow={true}
            // height: 300, overflow: 'overlay'
            sx={{ width: 250, p: 1, mt: 1, ml: 0 }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            className="notifition-scrollbar"
          >
            <Stack
              // , maxHeight: 300, overflow: 'overlay'
              sx={{ px: 0, py: 0, borderRadius: '12px', maxHeight: 300, overflow: 'overlay' }}
            >
              {_merchantList &&
                _merchantList
                  .filter(
                    (item) => item.merchant_id !== user?.merchantId && [1, 2].includes(item.status),
                  )
                  .map((list) => (
                    <MenuItem
                      key={list.merchant_id}
                      onClick={() => {
                        handleClickItem(list.merchant_id);
                      }}
                      sx={{
                        // borderBottom: '1px solid rgba(145, 158, 171, 0.24)',
                        borderRadius: '8px !important',
                        padding: '12px 8px',
                        height: '64px',
                        ':hover': {
                          backgroundColor: 'rgba(145, 158, 171, 0.08)',
                        },
                        // backgroundColor:
                        //   list.id === user?.merchant_id ? 'rgba(145, 158, 171, 0.08)' : '#fff',
                        // ':last-child': {
                        //   borderBottom: 0,
                        // },
                      }}
                    >
                      <CustomAvatar
                        src={list.logo || default_merchant_head}
                        alt={list.title}
                        name={list.title}
                        sx={{ mr: 2, ml: 1, backgroundColor: 'transparent' }}
                      />

                      <Typography
                        className="text-one-overflow-hidden"
                        sx={{
                          fontSize: '14px !important',
                          fontWeight: '500 !important',
                          letterSpacing: '0.1px',
                          color: '#1E1E1E',
                          lineHeight: '20px !important',
                        }}
                      >
                        {list.title}
                      </Typography>
                    </MenuItem>
                  ))}
              {_merchantList && _merchantList.filter((item) => item?.role_id === 1).length < 10 && (
                <MenuItem
                  onClick={() => {
                    setOpen(true);
                    handleClosePopover();
                  }}
                  sx={{
                    fontSize: '14px !important',
                    fontWeight: '500 !important',
                    letterSpacing: '0.1px',
                    color: '#1E1E1E',
                    lineHeight: '20px !important',
                    padding: '12px 8px 12px 0px',
                  }}
                >
                  <MyIcon
                    name="ic_add2"
                    size={24}
                    style={{ marginRight: '20px', marginLeft: '8px' }}
                  />
                  {t('nav.Add_New_Merchant_btn')}
                </MenuItem>
              )}
            </Stack>
          </MenuPopover>
          {open && (
            <DialogAddMerchanttForm
              open={open}
              onClose={async (isConfirm, id) => {
                setOpen(false);
                if (isConfirm && id) {
                  setAddLoading(true);
                  await getMerchantList();
                  accountApi_
                    .switchMerchantAccount(id)
                    .then(async () => {
                      const resp = await updUserInfoToStore({
                        isNeedExtra: true,
                        refreshRouter: true,
                      });
                      await accountStore.updateSwitchStatus(true);
                      if (resp?.merchantId) {
                        setOldMerchantId(resp?.merchantId);
                      }
                      if ([RoleEnum.Owner].includes(resp?.role_id || 1)) {
                        if (!location.pathname.includes('/merchatsetting/menu/branding')) {
                          navigate('/merchatsetting/menu/branding');
                        }
                      } else {
                        if (
                          !location.pathname.includes('/account/settingPageSection/merchantlist')
                        ) {
                          await getMerchantList();
                          navigate('/account/settingPageSection/merchantlist');
                        }
                      }
                    })
                    .catch((error) => {
                      enqueueSnackbar(t(`nav.add_merchant_error`), { variant: 'error' });
                      handleClickItem(oldMerchantId);
                    })
                    .finally(() => {
                      setAddLoading(false);
                      walletStroe.asyncWalletInfo();
                    });
                }
              }}
            />
          )}
        </>
      )}
    </>
  );
});
