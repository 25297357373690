import merge from 'lodash/merge';
import { useEffect, useMemo } from 'react';
// @mui
import { alpha, ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
//
import { useSettingsContext } from './SettingsContext';
import { useAuthContext } from '@/auth/useAuthContext';
import { useLocation } from 'react-router';

type Props = {
  children: React.ReactNode;
};

export default function ThemeColorPresets({ children }: Props) {
  const outerTheme = useTheme();
  const location = useLocation();

  const { presetsColor, onChangeColorPresets } = useSettingsContext();

  const { chooseApi } = useAuthContext();

  useEffect(() => {
    if (['/verification', '/register', '/forgetPassword'].includes(location.pathname)) {
      onChangeColorPresets('default');
    } else {
      if ([1, '1'].includes(chooseApi)) {
        onChangeColorPresets('default');
      } else {
        onChangeColorPresets('wallet');
      }
    }

    // eslint-disable-next-line
  }, [chooseApi]);

  // eslint-disable-next-line
  const themeOptions = useMemo(() => {
    return {
      palette: {
        primary: presetsColor,
      },
      customShadows: {
        primary: `0 8px 16px 0 ${alpha(presetsColor.main, 0.24)}`,
      },
    };
  }, [presetsColor]);

  const theme = createTheme(merge(outerTheme, themeOptions));

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
