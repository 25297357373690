import { getNewDate } from '@/utils/until';
import { useMemo, useState } from 'react';
import CalendarView, { AllowSelect } from './CalendarView';
import { yearMonthDayToNumber } from './util';
import { YearMonthVirtualView } from './YearMonthVirtualView';

export type SingleSelect = {
  year: number;
  month: number;
  day: number;
};
export type MultiSelect = [SingleSelect, SingleSelect];

export function fromTime(v: number): SingleSelect {
  const d = new Date(v);
  return {
    year: d.getFullYear(),
    month: d.getMonth() + 1,
    day: d.getDate(),
  };
}

export default function MultiSelectView({
  allowRange = true,
  allowSelect,
  config,
  setConfig,
  minDate,
}: {
  allowRange?: boolean;
  allowSelect?: AllowSelect;
  config?: Partial<MultiSelect>;
  setConfig(v: Partial<MultiSelect>): void;
  minDate?: number;
}) {
  const startDay = config?.[0];
  const endDay = config?.[1];
  const [calendar, setCalendar] = useState(() => {
    const theDay = startDay || fromTime(Date.now());
    return new YearMonthVirtualView(theDay.year, theDay.month, 7);
  });
  const startNum = useMemo(
    () => startDay && yearMonthDayToNumber(startDay.year, startDay.month, startDay.day),
    [startDay]
  );
  const endNum = useMemo(
    () => endDay && yearMonthDayToNumber(endDay.year, endDay.month, endDay.day),
    [endDay]
  );
  return (
    <CalendarView
      calendar={calendar}
      setCalendar={setCalendar}
      allowSelect={allowSelect}
      minDate={minDate}
      yearChange={(year) => {
        if (startDay && endDay) {
          startDay.year = year;
          endDay.year = year;
          // 如果 chooseEnd Time大于今天 就设置为今天
          // 如果 choose时间小于最小限制时间，就设置为最小时间
          const min = new Date(minDate || getNewDate('before', 12));
          const minNum = yearMonthDayToNumber(min.getFullYear(), min.getMonth() + 1, min.getDate());
          const max = new Date();
          const maxNum = yearMonthDayToNumber(max.getFullYear(), max.getMonth() + 1, max.getDate());
          const minN = yearMonthDayToNumber(startDay.year, startDay.month, startDay.day);
          const endN = yearMonthDayToNumber(endDay.year, endDay.month, endDay.day);

          if (minN && minN <= minNum) {
            startDay.year = min.getFullYear();
            startDay.month = min.getMonth() + 1;
            startDay.day = min.getDate();
          }
          if (minN && minN > maxNum) {
            startDay.year = max.getFullYear();
            startDay.month = max.getMonth() + 1;
            startDay.day = max.getDate();
          }
          if (endN && endN < minNum) {
            endDay.year = min.getFullYear();
            endDay.month = min.getMonth() + 1;
            endDay.day = min.getDate();
          }
          if (endN && endN >= maxNum) {
            endDay.year = max.getFullYear();
            endDay.month = max.getMonth() + 1;
            endDay.day = max.getDate();
          }

          setConfig([{ ...startDay }, { ...endDay }]);
        }
      }}
      onDayClick={(calendar, day) => {
        const endDate = {
          year: calendar.year,
          month: calendar.month,
          day: day,
        };
        const currentNum = yearMonthDayToNumber(endDate.year, endDate.month, endDate.day);
        if (allowRange) {
          if (startNum && !endDay) {
            //有第一个
            if (currentNum < startNum) {
              //当前 choose仍然更小
              setConfig([endDate]);
            } else {
              // choose第二个
              setConfig([startDay, endDate]);
            }
          } else {
            // choose第一个
            setConfig([endDate]);
          }
        } else {
          // choose第一个
          setConfig([endDate]);
        }
      }}
      isDaySelected={(calendar, day) =>
        (startDay &&
          calendar.year === startDay.year &&
          calendar.month === startDay.month &&
          day === startDay.day) ||
        (endDay &&
          calendar.year === endDay.year &&
          calendar.month === endDay.month &&
          day === endDay.day)
      }
      isDayInRange={(calendar, day) => {
        const n = yearMonthDayToNumber(calendar.year, calendar.month, day);
        if (startNum && endNum) {
          const isIn = startNum <= n && n <= endNum;
          if (isIn) {
            return {
              in: isIn,
              begin: startNum === n,
              end: endNum === n,
            };
          }
        }
        return false;
      }}
    />
  );
}
