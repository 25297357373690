// import http from './http';
import { Http } from './http';

const http = new Http('/xxxxxx/v1');

export default class suggestApi {
  /**
       * Feature Suggestion_Create
         message: text content string
         is_communicate: whether to accept communication, 0 not accept, 1 accept number
         to_email:string Useless parameters, mainly used for accepting emails during testing
         files: array
       *
       */
  uploadFile(data: { file: File; maxLength: number }) {
    let formData = new FormData();
    for (var i = 0; i < data.maxLength; i++) {
      formData.append('file', data.file[i]);
    }
    return http.postForm('/suggest/file/upload', formData);
  }

  creatSuggest(data: creatSuggestParamsType) {
    return http.postJson('/suggest/create', { ...data });
  }
}
export type creatSuggestParamsType = {
  message?: string;
  is_communicate?: number;
  to_email?: string;
  files?: {
    path?: string;
    size?: number;
    name?: string;
    [key: string]: any;
  }[];
  [key: string]: any;
};
export type upType = {
  [key: string]: any;
};
