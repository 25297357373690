import { accountApi_ } from '@/api';
import { AccountMerchantListItem } from '@/api/accountApi_';
import { observable, makeObservable } from 'mobx';

class MerchantStore {
  // Whether the user has successfully logged in
  merchantList: AccountMerchantListItem[] = [];

  constructor() {
    makeObservable(this, {
      merchantList: observable,
    });
    this.getMerchantList = this.getMerchantList.bind(this);
  }

  // 获取商户列表
  async getMerchantList() {
    const res = await accountApi_.accountMerchantList();
    this.merchantList = res?.list || [];
  }
}

export default MerchantStore;
