import { makeObservable, observable } from 'mobx';

export const replaceStore = makeObservable(
  {
    next: {
      url: '',
      state: {
        type: 1,
        email: '',
        phone: '',
        phoneArea: '',
        exists_google_2fa: false,
      },
    },
  },
  { next: observable }
);
