import { useLocales } from '@/locales';
import { useTheme } from '@mui/material';
import styled from 'styled-components';

type Props = {
  width?: string;
  showText?: string;
};

export default function MerchantLoading({ width, showText }: Props) {
  const { translate: t } = useLocales();
  const theme = useTheme();

  return (
    <MerchantLoadingWrap style={{ width: width || '100vw' }} theme={theme}>
      <div className="container">
        <div className="loader">
          <div className="loader-box">
            <div className="loader--dot" />
            <div className="loader--dot" />
            <div className="loader--dot" />
            <div className="loader--dot" />
            <div className="loader--dot" />
          </div>
          <div className="loader--text">{showText || t('nav.loading')}</div>
        </div>
      </div>
    </MerchantLoadingWrap>
  );
}

const MerchantLoadingWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100vh;
  /* width: 100vw; */
  z-index: 1000000;
  background-color: #fff;
  .container {
    height: 100vh;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .loader {
    text-align: center;
    /* height: 20px; */
    width: 250px;
    /* position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto; */
  }

  .loader-box {
    position: relative;
    height: 24px;
    margin-bottom: 16px;
  }

  .loader--dot {
    animation-name: loader;
    animation-timing-function: ease-in-out;
    animation-duration: 3s;
    animation-iteration-count: infinite;
    height: 20px;
    width: 20px;
    border-radius: 100%;
    position: absolute;
    border: 2px solid white;
  }

  .loader--dot:first-child {
    background-color: ${(props: any) => props.theme.palette.primary.main};
    animation-delay: 0.7s;
    opacity: 0.2;
  }

  .loader--dot:nth-child(2) {
    background-color: ${(props: any) => props.theme.palette.primary.main};
    animation-delay: 0.55s;
    opacity: 0.4;
  }

  .loader--dot:nth-child(3) {
    background-color: ${(props: any) => props.theme.palette.primary.main};
    animation-delay: 0.35s;
    opacity: 0.6;
  }

  .loader--dot:nth-child(4) {
    background-color: ${(props: any) => props.theme.palette.primary.main};
    animation-delay: 0.2s;
    opacity: 0.8;
  }

  .loader--dot:nth-child(5) {
    background-color: ${(props: any) => props.theme.palette.primary.main};
    animation-delay: 0.1s;
  }

  /* .loader--dot:nth-child(6) {
      background-color: #3C6FF5;
      animation-delay: 0s;
    } */

  .loader--text {
    /* position: absolute;
      top: 200%;
      left: 0;
      right: 0; */
    /* width: 12rem; */
    margin: auto;
    /* text-align: center; */
  }

  .loader--text:after {
    content: ' ';
    font-weight: bold;
    animation-name: loading-text;
    animation-duration: 3s;
    animation-iteration-count: infinite;
  }

  @keyframes loader {
    15% {
      transform: translateX(0);
    }

    45% {
      transform: translateX(200px);
    }

    65% {
      transform: translateX(200px);
    }

    95% {
      transform: translateX(0);
    }
  }

  @keyframes loading-text {
    0% {
      content: '';
    }

    25% {
      content: '.';
    }

    50% {
      content: '..';
    }

    75% {
      content: '...';
    }
  }
`;
