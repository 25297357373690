import { ChangeEvent, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
// @mui
import {
  Box,
  Table,
  Divider,
  TableRow,
  TableBody,
  TableCell,
  CardProps,
  TableContainer,
  TablePagination,
  IconButton,
  MenuItem,
} from '@mui/material';
// components
import Scrollbar from '@/components/scrollbar';
import { TableHeadCustom } from '@/components/table';
import EmptyContent from '@/components/empty-content';
import Iconify from '@/components/iconify';
import MenuPopover from '@/components/menu-popover';
import { LightTooltip, styles } from '../style';
import { formatChartsDate } from '@/utils/formatTime';
import { useLocales } from '@/locales';
import { SearchTypeEnum, optionsStatusType } from '../type';
import StopDialog from '../dialog/StopDialog';
import { getCurrencyShowText, getTermText } from '../untils';
import { invoiceApi } from '@/api';
import { enqueueSnackbar } from 'notistack';
import StatusLabel from '@/sections/tradingOrder/StatusLabel';

import { useAuthContext } from '@/auth/useAuthContext';
import PermissionGuard from '@/auth/PermissionGuard';
import { MENUMANAGEMENT } from '@/auth/AuthRoute';

interface Props extends CardProps {
  title?: string;
  subheader?: string;
  tableData: RowProps[];
  tableLabels: any[];
  total: number;
  pagination: {
    page_num: number;
    page_size: number;
  };
  getSearchParams: (pagination: { page_num: number; page_size: number }) => void;
}
export default function ManageTable({
  title,
  subheader,
  tableData,
  tableLabels,
  total,
  pagination,
  getSearchParams,
  ...other
}: Props) {
  const [isShadow, setIsShadow] = useState(false);
  useEffect(() => {
    var scrollable = document.getElementById('scrollable');

    function scroll(e: any) {
      if (e.target.scrollLeft + e.target.clientWidth < e.target.scrollWidth) {
        setIsShadow(true);
      }
      if (e.target.scrollLeft + e.target.clientWidth === e.target.scrollWidth) {
        setIsShadow(false);
      }
    }
    function handleMouseLeave(e: any) {
      var popover = document.getElementsByClassName('MuiPopover-paper');
      if (popover.length === 1) return;
      setIsShadow(false);
    }
    function handleMouseEnter(e: any) {
      setIsShadow(true);
      var popover = document.getElementsByClassName('MuiPopover-paper');
      if (popover.length === 1) return;
      if (e.target.scrollLeft + e.target.clientWidth < e.target.scrollWidth) {
        setIsShadow(true);
      }
      if (e.target.scrollLeft + e.target.clientWidth === e.target.scrollWidth) {
        setIsShadow(false);
      }
    }
    if (scrollable) {
      scrollable.addEventListener('scroll', scroll);
      scrollable.addEventListener('mouseleave', handleMouseLeave);
      scrollable.addEventListener('mouseenter', handleMouseEnter);
    }
    return () => {
      // Remove event listeners on component unmount
      scrollable?.removeEventListener('mouseleave', handleMouseLeave);
      scrollable?.removeEventListener('mouseOver', handleMouseEnter);
      scrollable?.removeEventListener('scroll', scroll);
    };
  }, []);
  // Handle mouseover events
  const handleMouseEnterTable = () => {
    var scrollable = document.getElementById('scrollable');
    if (
      (scrollable?.scrollLeft || 0) + (scrollable?.clientWidth || 0) ===
      (scrollable?.scrollWidth || 0)
    ) {
      setIsShadow(false);
    } else {
      setIsShadow(true);
    }
  };
  return (
    <Box {...other} sx={{ borderRadius: '0px 0px 16px 16px' }} className="trading-order-table">
      <TableContainer sx={{ overflow: 'unset' }} id={'scrol_table'}>
        <Scrollbar className="action" id={'scrollable'} sx={{ overflowY: 'hidden' }}>
          <Table
            sx={{
              minWidth: 720,
              '& .MuiTableRow-root': {
                borderBottom: '1px solid #DCE0E4',
              },
            }}
            onMouseEnter={handleMouseEnterTable}
          >
            <TableHeadCustom
              headLabel={tableLabels.map((tableLabel) => {
                const item = { ...tableLabel };
                if (tableLabel?.id === 'action') {
                  item.isShadow = isShadow;
                }
                return item;
              })}
              sx={{
                '& .MuiTableCell-root': {
                  backgroundColor: '#fff',
                  color: '#919EAB',
                  fontWeight: 500,
                  fontSize: '14px',
                  letterSpacing: '0.1px',
                },
              }}
            />

            <TableBody
              sx={{
                '& .MuiTableCell-root': {
                  color: '#1E1E1E',
                  fontWeight: 500,
                  fontSize: '14px',
                  letterSpacing: '0.1px',
                },
                '.MuiTableRow-root:last-child': {
                  borderBottom: 'none',
                },
              }}
            >
              {tableData.length > 0 ? (
                tableData.map((row) => (
                  <ManageTableRow
                    key={row.invoice_id}
                    row={row}
                    pagination={pagination}
                    getSearchParams={getSearchParams}
                    handlerOpenPopover={(openPopover: HTMLElement | null) => {
                      if (!openPopover) {
                        setIsShadow(false);
                      }
                    }}
                    isShadow={isShadow}
                  />
                ))
              ) : (
                <TableRow key={'manageTableNodata'}>
                  <TableCell colSpan={12}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                      }}
                    >
                      <EmptyContent title="No Data" />
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </Scrollbar>
      </TableContainer>

      <Divider />
      {/* paging component */}
      <Box sx={{ p: 0 }}>
        <TablePagination
          rowsPerPageOptions={[10, 20, 50, 80, 100]}
          component="div"
          count={total}
          rowsPerPage={pagination.page_size}
          page={pagination.page_num - 1}
          onPageChange={(e: unknown, newPage: number) => {
            getSearchParams({ page_num: newPage + 1, page_size: pagination.page_size });
          }}
          onRowsPerPageChange={(e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
            const page_size = e.target.value;
            getSearchParams({ page_num: 1, page_size: Number(page_size) });
          }}
          sx={{
            border: 0,
          }}
        />
      </Box>
    </Box>
  );
}

type RowProps = {
  id?: number;
  invoice_id: string;
  origin_id: string;
  payer_name: string;
  payer_email: string;
  business_name: string;
  business_email: string;
  title: string;
  currency: string;
  currency_type: number;
  status: number;
  price: string;
  invoice_type: number;
  term_num: number;
  had_term_num: number;
  created: number;
  next_time: number;
  [key: string]: any;
};

type RecurringRowProps = {
  row: RowProps;
  pagination: {
    page_num: number;
    page_size: number;
  };
  getSearchParams: (pagination: { page_num: number; page_size: number }) => void;
  isShadow: boolean;
  handlerOpenPopover: (openPopover: HTMLElement | null) => void;
};

function ManageTableRow({
  row,
  pagination,
  getSearchParams,
  isShadow,
  handlerOpenPopover,
}: RecurringRowProps) {
  const navigate = useNavigate();
  const location = useLocation();
  const { translate: t } = useLocales();
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const { user } = useAuthContext();
  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
    handlerOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
    handlerOpenPopover(null);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const optionsStatus: optionsStatusType = [
    { value: 0, label: t('invoices.status_all') },
    { value: 1, label: t('invoices.status_Onging') },
    { value: 2, label: t('invoices.status_Ended') },
    { value: 3, label: t('invoices.status_Stopped') },
  ];
  const optionsStatusType: optionsStatusType = [
    { value: 1, label: 'AwaitingPayment' },
    { value: 2, label: 'Overdue' },
    { value: 3, label: 'Revoked' },
  ];
  return (
    <>
      <TableRow
        sx={{
          '&:hover': {
            backgroundColor: 'rgba(145, 158, 171, 0.08)',
          },
        }}
      >
        <TableCell
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >{`${row.origin_id ?? '--'}`}</TableCell>
        <TableCell
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            minWidth: 220,
            maxWidth: 460,
          }}
        >
          {row.title.length > 49 ? (
            <LightTooltip
              title={<>{`${row.title}`}</>}
              placement="right"
              arrow
              sx={{
                '.MuiTooltip-tooltip': {
                  maxWidth: 450,
                  wordBreak: 'break-all',
                },
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  WebkitLineClamp: 2,
                  display: '-webkit-box',
                  WebkitBoxOrient: 'vertical',
                  wordBreak: 'break-all',
                }}
              >
                {`${row.title}`}
              </Box>
            </LightTooltip>
          ) : (
            row.title ?? '--'
          )}
        </TableCell>

        <TableCell
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            minWidth: 120,
            maxWidth: 120,
          }}
        >
          {row.payer_name.length > 18 ? (
            <LightTooltip
              title={<>{`${row.payer_name}`}</>}
              placement="right"
              arrow
              sx={{
                '.MuiTooltip-tooltip': {
                  maxWidth: 450,
                  wordBreak: 'break-all',
                },
              }}
            >
              <Box sx={{ ...styles.webkitLineClamp }}>{`${row.payer_name}`}</Box>
            </LightTooltip>
          ) : (
            <Box sx={{ ...styles.webkitLineClamp }}>{row.payer_name ?? '--'}</Box>
          )}
        </TableCell>
        <TableCell>{row.payer_email ?? '--'}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {row.price ? getCurrencyShowText(row.currency_type, row.currency, row.price) : '--'}
        </TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {row?.created ? formatChartsDate(row.created, 'dd MMM. yyyy kk:mm') : '--'}
        </TableCell>

        <TableCell>
          <StatusLabel
            label={optionsStatus.filter((item) => item.value === row.status)[0]?.label}
            type={
              optionsStatusType.filter((item) => item.value === row.status)[0]?.label || 'default'
            }
            sx={{
              padding: '4px 8px',
            }}
          />
        </TableCell>
        {/* term_num === 0 for indefinite renewal */}
        <TableCell>{getTermText(row?.had_term_num, row?.term_num)}</TableCell>

        <TableCell sx={{ whiteSpace: 'nowrap' }}>
          {row?.next_time ? formatChartsDate(row.next_time, 'dd MMM. yyyy kk:mm') : '--'}
        </TableCell>

        <TableCell align="right">
          <IconButton
            sx={{ color: openPopover ? '#3C6FF5' : 'default' }}
            onClick={handleOpenPopover}
          >
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>
      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        {/* Status 1 in progress 2 finished 3 stop */}
        <PermissionGuard permissionId={MENUMANAGEMENT.invoiceManageListDetail}>
          <MenuItem
            onClick={() => {
              navigate('/invoice/ManageDetails?key=' + row.invoice_id, {
                replace: true,
                state: {
                  backUrl: location.pathname,
                },
              });
            }}
          >
            {t('invoices.table_action_check')}
          </MenuItem>
        </PermissionGuard>

        <PermissionGuard permissionId={MENUMANAGEMENT.invoiceCreateEditDuplicate}>
          {[1].includes(row.status) && (
            <MenuItem
              onClick={() => {
                handleClosePopover();
                navigate('/invoice/createInvoice', {
                  replace: true,
                  state: {
                    bill_id: row.invoice_id, //bill_id-->invoice_id
                    SearchTypeEnum: SearchTypeEnum.ManageInvoice,
                    type: 'edit',
                    backUrl: location.pathname,
                  },
                });
              }}
            >
              {t('invoices.table_action_edit')}
            </MenuItem>
          )}
        </PermissionGuard>

        <PermissionGuard permissionId={MENUMANAGEMENT.invoiceCreateEditDuplicate}>
          <MenuItem
            disabled={((user?.merchantStatus ?? 0) & 2) !== 2}
            onClick={async () => {
              try {
                const res = await invoiceApi.getInvoiceDetails_(row?.invoice_id || '');

                navigate('/invoice/createInvoice', {
                  replace: true,
                  state: {
                    info: { ...res.invoice, invoice_type: SearchTypeEnum.ManageInvoice },
                    backUrl: location.pathname,
                  },
                });
                handleClosePopover();
              } catch (error) {
                if (error?.code) {
                  enqueueSnackbar(t(`new_interface_err.${error.code}`), { variant: 'error' });
                  return;
                } else {
                  enqueueSnackbar(error.message, { variant: 'error' });
                }
                enqueueSnackbar(error.message, { variant: 'error' });
                console.log('error', { error });
              }
            }}
          >
            {t('invoices.table_action_duplicate')}
          </MenuItem>
        </PermissionGuard>
        <PermissionGuard permissionId={MENUMANAGEMENT.invoiceManageListViewChildrenList}>
          {row?.had_term_num && (
            <MenuItem
              onClick={() => {
                handleClosePopover();
                navigate('/invoice/list/recurring', {
                  replace: true,
                  state: {
                    origin_id: row?.origin_id,
                  },
                });
              }}
            >
              {t('invoices.table_action_view_list')}
            </MenuItem>
          )}
        </PermissionGuard>

        {/* Unsent emails can be deactivated */}
        <PermissionGuard permissionId={MENUMANAGEMENT.invoiceManageListDone}>
          {[1].includes(row.status) && (
            <MenuItem
              onClick={() => {
                setOpenConfirm(true);
              }}
              sx={{ color: 'error.main' }}
            >
              {t('invoices.table_action_stop')}
            </MenuItem>
          )}
        </PermissionGuard>
      </MenuPopover>

      <StopDialog
        visible={openConfirm}
        onClose={(isSearch) => {
          if (isSearch) {
            getSearchParams(pagination);
          }
          handleCloseConfirm();
        }}
        handleStop={async () => {
          try {
            await invoiceApi.stopInvoice_(row.invoice_id);
          } catch (error) {
            if (error?.code) {
              enqueueSnackbar(t(`new_interface_err.${error.code}`), { variant: 'error' });
            } else {
              enqueueSnackbar(error.message, { variant: 'error' });
            }
          }
        }}
      />
    </>
  );
}
