import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, FreeMode } from 'swiper';
import 'swiper/css';
// import NotificitionDialog from './NotificitionDialog';
import { notificationListoResTypeItem } from '@/api/dashboardApi_';
import useResponsive from '@/hooks/useResponsive';
import { CCP_URL } from '@/config';

SwiperCore.use([FreeMode, Autoplay]);

const NotificationSwiper = ({ notificationList }: { notificationList: any[] }) => {
  type Swiper = any;
  const swiperRef = useRef<Swiper | null>(null);
  // const [notify, setNotify] = useState(false);
  // const [html_, setHtml] = useState({
  //   title: '',
  //   content: '',
  //   buttons: [],
  // });
  const isMobile = !useResponsive('up', 'sm');
  useEffect(() => {
    if (swiperRef.current) {
      // 手动更新 Swiper 内容的方法
      swiperRef.current.update();
      if (notificationList?.length > 1) {
        swiperRef.current.autoplay.start();
      }
    }
  }, [notificationList]);

  const handleItemClick = (item: notificationListoResTypeItem) => {
    const baseUrl = `${CCP_URL}support-center/announcement/`;
    const baseUrl2 = `${CCP_URL}support-center/announcement2/`;
    if (item?.tag_name) {
      window.open(`${baseUrl}${item.tag_name}/${item.notification_id}`);
    } else {
      window.open(`${baseUrl2}${item.notification_id}`);
    }

    // setHtml({
    //   title: item?.title || '',
    //   content: item?.content?.content || '',
    //   buttons: (item?.content?.buttons as any) || [],
    // });
    // setNotify(true);
  };

  return (
    <CommentWrap>
      <div className="screen" style={{ width: isMobile ? '262px' : '100%' }}>
        <Swiper
          id="swiper-container"
          modules={[Autoplay]}
          speed={2 * 1000}
          autoplay={
            notificationList.length > 1
              ? {
                  delay: 3 * 1000,
                  stopOnLastSlide: false,
                  disableOnInteraction: false,
                  pauseOnMouseEnter: true,
                }
              : false
          }
          spaceBetween={4}
          slidesPerView={1}
          loop={notificationList.length > 1 ? true : false}
          direction="vertical"
          onSwiper={(swiper) => {
            swiperRef.current = swiper;
          }}
        >
          {notificationList &&
            [...notificationList, ...notificationList]?.map((item, index) => (
              <div key={index.toString()}>
                <SwiperSlide key={index.toString()} className="text-one-overflow-hidden">
                  <div
                    className="slide-item text-one-overflow-hidden"
                    onClick={() => handleItemClick(item)}
                  >
                    <div className="content-box text-one-overflow-hidden">
                      <p className="text-one-overflow-hidden" style={{ color: '#0B1C75' }}>
                        {item.title}
                      </p>
                    </div>
                  </div>
                </SwiperSlide>
              </div>
            ))}
        </Swiper>
      </div>
      {/* <NotificitionDialog
        open={notify}
        onOk={() => {
          setNotify(false);
        }}
        html_={html_}
      /> */}
    </CommentWrap>
  );
};

export default NotificationSwiper;

const CommentWrap = styled.div`
  display: flex;
  align-items: center;
  background: '#D8E7FE';
  .screen {
    .swiper-wrapper {
      -webkit-transition-timing-function: linear;
      -moz-transition-timing-function: linear;
      -ms-transition-timing-function: linear;
      -o-transition-timing-function: linear;
      transition-timing-function: linear;
      z-index: 99;
    }

    .swiper {
      width: 100%;
      height: 36px;
      margin-top: 0px;
      &::after,
      &::before {
        content: '';
        width: 174px;
        height: 36px;
        position: absolute;
        top: 0;
        z-index: 3;
        background-repeat: no-repeat;
        background-size: cover;
      }

      &::before {
        left: 0;
      }

      &::after {
        right: 0;
      }

      .swiper-slide {
        /* width: 340px; */

        .slide-item {
          box-sizing: border-box;
          /* height: 374px; */
          /* width: 340px; */
          width: 100%;
          background-color: #d8e7fe;
          border-radius: 24px;
          padding: 0px 24px 0px 12px;
          align-items: flex-start;
          justify-content: space-between;
          cursor: pointer;

          > .content-box {
            max-height: 190px;
            overflow: hidden;
            box-sizing: border-box;
            width: 100%;
            p {
              width: 100%;
              margin-top: 8px;
              font-size: 14px;
              line-height: 22px;
              overflow: hidden;
              /* -webkit-line-clamp: 5; */
              /* -webkit-box-orient: vertical; */
              text-overflow: ellipsis;
            }
          }
        }
      }
    }
  }
`;
