import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { ManageSearchParams } from './type';
import { useLocales } from '@/locales';
import ManageSearchForm from './topSearch/ManageSearchForm';
import ManageTable from './table/ManageTable';
import { MyIcon } from '@/assets/icons/MyIcon';
import { Box, Breadcrumbs, Card, Container, Link, Stack, Typography } from '@mui/material';
import { InvoiceList } from '@/api/invoiceApi_';
import { invoiceApi } from '@/api/index';
import { useNavigate } from 'react-router';
import { Helmet } from 'react-helmet-async';
import MerchantLoadingNoPosition from '@/layouts/dashboard/nav/components/merchant-loading-no-position';
import { enqueueSnackbar } from 'notistack';
import useResponsive from '@/hooks/useResponsive';

function ManageRecurringInvoice() {
  const [searchParams, setSearchParams] = useState<ManageSearchParams>({
    keyword: '',
    dt_start: 0,
    dt_end: 0,
    status: 0,
    pagination: {
      page_num: 1,
      page_size: 20,
    },
  });
  const [tableData, setTableData] = useState<InvoiceList>();
  const [loading, setLoading] = useState(false);
  const [searchOrderValue, setSearchOrderValue] = useState<string>('');
  const { translate: t } = useLocales();
  const isMobile = !useResponsive('up', 'sm');
  const navigate = useNavigate();
  // get search parameters
  const getSearchParams = async (params: ManageSearchParams) => {
    setSearchParams({ ...searchParams, ...params });
    setSearchOrderValue(params?.keyword);
    await getTableData({ ...searchParams, ...params });
  };

  //Get table data
  const getTableData = async (params: ManageSearchParams) => {
    setLoading(true);
    try {
      const res = await invoiceApi.getInvoiceList_({
        ...params,
        dt_start: ~~((params?.dt_start || 0) / 1000),
        dt_end: ~~((params?.dt_end || 0) / 1000),
      });
      setTableData(res);
      setLoading(false);
    } catch (error) {
      if (error?.code) {
        enqueueSnackbar(t(`new_interface_err.${error.code}`), { variant: 'error' });
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getTableData({ ...searchParams });
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Helmet>
        <title>{t('seo_title.helmet_manage')}</title>
      </Helmet>
      <Container sx={{ maxWidth: 'inherit !important' }}>
        <Box sx={{ display: 'flex', marginTop: '24px' }}>
          {!isMobile && (
            <MyIcon
              name="arrow"
              size={32}
              style={{
                transform: 'rotate(90deg)',
                flexShrink: 1,
                flexGrow: 0,
                height: '32px',
                cursor: 'pointer',
                marginRight: '8px',
                display: 'inline-block',
                verticalAlign: 'bottom',
                marginBottom: '0px',
              }}
              onClick={() => {
                navigate(`/invoice/list/recurring`, {
                  replace: true,
                });
              }}
            />
          )}
          <h5
            className="fs-title-page mb2 mt0 color-text"
            style={{ marginBottom: '0px', fontSize: isMobile ? '20px' : '24px' }}
          >
            {t('invoices.Manage_Recurring_Invoice')}
          </h5>
        </Box>

        {/* Breadcrumbs */}
        <Stack
          spacing={2}
          sx={{
            my: 2,
            marginTop: '0',
            marginBottom: '24px',
            '& .MuiBreadcrumbs-separator': {
              fontSize: '30px',
              color: '#363A44',
              fontWeight: 500,
            },
          }}
        >
          <Breadcrumbs separator="·" aria-label="breadcrumb">
            <Link
              underline="hover"
              key="1"
              color="#1E1E1E"
              sx={{
                fontWeight: 500,
                fontSize: isMobile ? '12px' : '14px',
                cursor: 'pointer',
              }}
              onClick={() => {
                navigate('/invoice/list/recurring', { replace: true });
              }}
            >
              {t('invoices.title')}
            </Link>
            <Typography
              key="3"
              color="#919EAB"
              sx={{
                fontWeight: 500,
                fontSize: isMobile ? '12px' : '14px',
              }}
            >
              {t('invoices.Manage_Recurring_Invoice')}
            </Typography>
          </Breadcrumbs>
        </Stack>
        <Card>
          {/*  list  */}
          {/* titlesearch form */}
          <Box sx={{ padding: '16px' }}>
            <ManageSearchForm
              getSearchParams={getSearchParams}
              SearchParams={searchParams}
              searchOrderValue={searchOrderValue}
            />
          </Box>
          {/* <Divider /> */}
          {/* tableDatasheets */}
          {loading ? (
            <Card sx={{ width: '100%', height: '600px', boxShadow: 'none' }}>
              {/* <PageLoading /> */}
              <MerchantLoadingNoPosition width="100%" />
            </Card>
          ) : (
            <ManageTable
              tableData={tableData?.list || []}
              tableLabels={[
                { id: 'invoice_id', label: t('invoices.Manage_table_invoice_id') },
                { id: 'title', label: t('invoices.Manage_table_title') },
                { id: 'payer_name', label: t('invoices.Manage_table_payer') },
                { id: 'payer_email', label: t('invoices.Manage_table_to_email') },
                { id: 'price', label: t('invoices.Manage_table_price') },
                { id: 'created', label: t('invoices.Manage_table_create_time') },
                { id: 'status', label: t('invoices.Manage_table_Status') },
                { id: 'had_term_num', label: t('invoices.Manage_table_Sent') },
                { id: 'next_time', label: t('invoices.Manage_table_next_send_time') },
                { id: 'action', lable: '' },
              ]}
              total={tableData?.pagination?.total || 0}
              pagination={searchParams.pagination}
              getSearchParams={({ page_num, page_size }) =>
                getSearchParams({ ...searchParams, pagination: { page_num, page_size } })
              }
            />
          )}
        </Card>
      </Container>
    </>
  );
}

export default observer(ManageRecurringInvoice);
