import { MyIcon } from '@/assets/icons/MyIcon';
import useResponsive from '@/hooks/useResponsive';
import { Box, Dialog, DialogTitle, Grid, IconButton, styled } from '@mui/material';
import { SystemProps } from '@mui/system';
import { ReactNode } from 'react';

export default function MyModal({
  open,
  onClose,
  children,
  title,
  sx,
  showTitle = true,
  PageStyleSx,
  ModalWarpWidth = 480,
}: {
  open: boolean;
  onClose: (data: any) => void;
  children: ReactNode;
  title?: string | ReactNode;
  sx?: SystemProps;
  showTitle?: boolean;
  PageStyleSx?: SystemProps;
  ModalWarpWidth?: number | string;
}) {
  const isMobile = !useResponsive('up', 'sm');
  return (
    // (e)=>{onClose(e)}
    <ModalWarp
      open={open}
      onClose={(e: any, r: 'backdropClick' | 'escapeKeyDown') => {
        // 控制点击透明背景不关闭
        if (r === 'escapeKeyDown') {
          onClose(e);
        }
      }}
      // fullWidth
      sx={{
        width: isMobile ? '100vw' : ModalWarpWidth,
        mx: 'auto',
      }}
      PaperProps={{
        sx: { m: 0, width: '100%' },
      }}
    >
      <Box sx={sx} component="div">
        {showTitle && (
          <Grid
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              paddingRight: '20px',
            }}
          >
            <DialogTitle
              sx={{
                fontWeight: 700,
              }}
            >
              {title ?? 'Set backup account'}
            </DialogTitle>
            <IconButton
              onClick={onClose}
              aria-label="close"
              sx={{
                width: 30,
                height: 30,
              }}
            >
              <MyIcon name="close" size={14} />
            </IconButton>
          </Grid>
        )}

        <PageStyle sx={{ ...PageStyleSx }}>{children}</PageStyle>
      </Box>
    </ModalWarp>
  );
}

const ModalWarp = styled(Dialog, { skipSx: false })(({ theme }) => ({
  // '.MuiDialog-paper': {
  //   borderRadius: 12,
  //   margin: 0,
  // },
}));

const PageStyle = styled('div')(({ theme }) => ({
  // width: 480,
  // height: 670,
  // maxWidth: '95vw',
  // width: '100%',
  maxHeight: '100%',
  borderRadius: 30,
}));
