import AuthGuard from './AuthGuard';
import PaymentLayout from '@/sections/PaymentApi/PaymentLayout/index';
import InvoiceLayout from '@/pages/Invoice/InvoiceLayout/index';
import DashboardLayout from '@/layouts/dashboard/DashboardLayout';
import SettingAccountLayout from '@/sections/MerchantSetting/Index';
import WebsitVerificationLayout from '@/sections/MerchantSetting/WebSiteVerificationLayout/index';
import {
  ActionLogListPage,
  AddressListPage,
  BalancesPage,
  BrandingPage,
  // CollectionListPage,
  CreateInvoicePage,
  // CreateRefundPage,
  DashboardPage,
  InvoiceOneDetailsPage,
  InvoicePage,
  ManageDetailsPage,
  MemberPage,
  PaymentApiConfigPage,
  PaymentApiOrderDetailsPage,
  PaymentApiPage,
  RecurringDetailsPage,
  ReferralProgramPage,
  SecurityPage,
  SettingAccountPage,
  SettingPage,
  SupportPage,
  TradingOrderPage,
  WebHookPage,
  UserListPage,
  UserListDetailPage,
  WebsitVerificationPageSection,
  WithdrawRecordPage,
  WithdrawalApproval,
  WithdrawalApprovalRecords,
  WhiteAndBlacklist,
  WithdrawalApprovalLayout,
  CollectionListPage,
  UserTransactionPage,
  UserTradingOrderPage,
  UserAssetsLayout,
  UserDepositWithTablePage,
  UserSwapTablePage,
} from '@/routes/elements';
import ManageRecurringInvoice from '@/pages/Invoice/ManageRecurringInvoice';
import MerchantSettingLayout from '@/sections/MerchantSetting/MerchantSettingLayout';
import MemberLayout from '@/sections/MerchantSetting/member/MemberLayout';
import WithdrawlLayout from '@/sections/Withdrawal/WithdrawlLayout';
import DemoPage from '@/pages/DemoPage';

import { PATH_AFTER_LOGIN } from '@/config';
import { Navigate, RouteObject } from 'react-router';
import { MenuListItem } from '@/api/accountApi_';
import { cloneDeep } from 'lodash';
import TradingOrderLayout from '@/sections/tradingOrder/tradingOrderLayout';
import UserlistLayout from '@/pages/UserList/UserlistLayout';
import UserTransactionLayout from '@/pages/UserTransaction/UserTransactionLayout';

export enum RoleEnum {
  'Owner' = 1,
  'Developer' = 2,
  'Accountant' = 3,
  'CustomerService' = 4,
}

export type RoleListItemType = { roleId: number; name: string; pageIds: string[] };

export type IRouteObject = Omit<RouteObject, 'children'> & {
  children?: IRouteObject[];
  /** 路由权限标记 */
  meta?: {
    /** 是否需要登录, 暂未使用 */
    auth?: boolean;
    /** 角色list  */
    role?: number[];
    /** 路由编号  */
    mid?: string[];
  };
};

export const BaseRouteList: IRouteObject[] = [
  {
    path: '/dashboard',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    meta: {
      mid: ['1-1'],
      role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.CustomerService],
    },
    children: [
      { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
      {
        path: 'index',
        element: <DashboardPage />,
        meta: {
          mid: ['1-1'],
          role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.CustomerService],
        },
      },
      {
        path: 'address-list',
        element: <AddressListPage />,
        meta: {
          mid: ['1-1'],
          role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.Accountant, RoleEnum.CustomerService],
        },
      },
    ],
  },
  {
    path: '/transaction',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    meta: {
      mid: ['1-2'],
      role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.Accountant, RoleEnum.CustomerService],
    },
    children: [
      { element: <Navigate to={'/transaction/list'} replace />, index: true },
      {
        path: 'list',
        element: <TradingOrderLayout />,
        children: [
          {
            element: <Navigate to={'/transaction/list/deposit-withdrawal'} replace />,
            index: true,
          },

          {
            path: 'deposit-withdrawal',
            element: <TradingOrderPage />,
            meta: {
              mid: ['1-2-1'],
              role: [
                RoleEnum.Owner,
                RoleEnum.Developer,
                RoleEnum.Accountant,
                RoleEnum.CustomerService,
              ],
            },
          },
          {
            path: 'swap',
            element: <TradingOrderPage />,
            meta: {
              mid: ['1-2-2'],
              role: [
                RoleEnum.Owner,
                RoleEnum.Developer,
                RoleEnum.Accountant,
                RoleEnum.CustomerService,
              ],
            },
          },
          {
            path: 'collect-list',
            element: <CollectionListPage />,
            meta: {
              mid: ['1-2-3'],
              role: [
                RoleEnum.Owner,
                RoleEnum.Developer,
                RoleEnum.Accountant,
                RoleEnum.CustomerService,
              ],
            },
          },
        ],
      },
    ],
  },
  {
    path: '/balances',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    meta: {
      mid: ['1-5'],
      role: [RoleEnum.Owner, RoleEnum.Developer],
    },
    children: [
      { element: <Navigate to={'/balances/index'} replace />, index: true },
      {
        path: 'index',
        element: <BalancesPage />,
        meta: {
          mid: ['1-5-1'],
          role: [RoleEnum.Owner, RoleEnum.Developer],
        },
      },
    ],
  },
  {
    path: '/invoice',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    meta: {
      mid: ['1-4'],
      role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.Accountant, RoleEnum.CustomerService],
    },
    children: [
      { element: <Navigate to={'/invoice/list'} replace />, index: true },
      {
        path: 'list',
        element: <InvoiceLayout />,
        children: [
          {
            element: <Navigate to={'/invoice/list/recurring'} replace />,
            index: true,
          },

          {
            path: 'recurring',
            element: <InvoicePage />,
            meta: {
              mid: ['1-4-2'],
              role: [
                RoleEnum.Owner,
                RoleEnum.Developer,
                RoleEnum.Accountant,
                RoleEnum.CustomerService,
              ],
            },
          },
          {
            path: 'oneTime',
            element: <InvoicePage />,
            meta: {
              mid: ['1-4-1'],
              role: [
                RoleEnum.Owner,
                RoleEnum.Developer,
                RoleEnum.Accountant,
                RoleEnum.CustomerService,
              ],
            },
          },
        ],
      },
      {
        path: 'oneTimeDetails',
        element: <InvoiceOneDetailsPage />,
        meta: {
          mid: ['1-4-1', '1-4-1-1'],
          role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.Accountant, RoleEnum.CustomerService],
        },
      },
      {
        path: 'recurringDetails',
        element: <RecurringDetailsPage />,
        meta: {
          mid: ['1-4-2', '1-4-2-1'],
          role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.Accountant, RoleEnum.CustomerService],
        },
      },
      {
        path: 'ManageDetails',
        element: <ManageDetailsPage />,
        meta: {
          mid: ['1-4-3', '1-4-3-1'],
          role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.Accountant, RoleEnum.CustomerService],
        },
      },
      {
        path: 'createInvoice',
        element: <CreateInvoicePage />,
        meta: {
          mid: ['1-4-4'],
          role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.CustomerService],
        },
      },
      {
        path: 'ManageRecurring',
        element: <ManageRecurringInvoice />,
        meta: {
          mid: ['1-4-3'],
          role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.Accountant, RoleEnum.CustomerService],
        },
      },
    ],
  },

  {
    path: '/payment',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    meta: {
      mid: ['1-3'],
      role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.Accountant, RoleEnum.CustomerService],
    },
    children: [
      { element: <Navigate to={'/payment/paymentApiOrder'} replace />, index: true },
      {
        path: 'paymentApiOrder',
        element: <PaymentLayout />,
        children: [
          {
            element: <Navigate to={'/payment/paymentApiOrder/outgoing'} replace />,
            index: true,
          },
          // { path: ':tab', element: <PaymentApiPage /> },
          {
            path: 'outgoing',
            element: <PaymentApiPage />,
            meta: {
              mid: ['1-3-1'],
              role: [
                RoleEnum.Owner,
                RoleEnum.Developer,
                RoleEnum.Accountant,
                RoleEnum.CustomerService,
              ],
            },
          },
          {
            path: 'incoming',
            element: <PaymentApiPage />,
            meta: {
              mid: ['1-3-2'],
              role: [
                RoleEnum.Owner,
                RoleEnum.Developer,
                RoleEnum.Accountant,
                RoleEnum.CustomerService,
              ],
            },
          },
        ],
      },
      {
        path: 'paymentApiOrderDetails',
        element: <PaymentApiOrderDetailsPage />,
        meta: {
          mid: ['1-3-2-1', '1-3-1-1'],
          role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.Accountant],
        },
      },
    ],
  },
  {
    path: '/developer',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    meta: {
      mid: ['1-8'],
      role: [RoleEnum.Owner, RoleEnum.Developer],
    },
    children: [
      { element: <Navigate to={'/developer/config'} replace />, index: true },
      {
        path: 'config',
        element: <PaymentApiConfigPage />,
        meta: {
          mid: ['1-8'],
          role: [RoleEnum.Owner, RoleEnum.Developer],
        },
      },
    ],
  },

  {
    path: '/referral',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    meta: {
      auth: true,
    },
    children: [
      { element: <Navigate to={'/referral/program'} replace />, index: true },
      {
        path: 'program',
        element: <ReferralProgramPage />,
        meta: {
          // role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.Accountant, RoleEnum.CustomerService],
        },
      },
      {
        path: 'withdraw-record',
        element: <WithdrawRecordPage />,
        meta: {
          // role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.Accountant, RoleEnum.CustomerService],
        },
      },
    ],
  },

  {
    path: '/account',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    meta: {
      auth: true,
    },
    children: [
      { element: <Navigate to={'/account/settingPageSection'} replace />, index: true },
      {
        path: 'settingPageSection',
        element: <SettingAccountLayout />,
        children: [
          {
            element: <Navigate to={'/account/settingPageSection/settings'} replace />,
            index: true,
          },
          {
            path: 'settings',
            element: <SettingAccountPage />,
            meta: {
              // role: [
              //   RoleEnum.Owner,
              //   RoleEnum.Developer,
              //   RoleEnum.Accountant,
              //   RoleEnum.CustomerService,
              // ],
            },
          },
          {
            path: 'merchantlist',
            element: <SettingAccountPage />,
            meta: {
              // role: [
              //   RoleEnum.Owner,
              //   RoleEnum.Developer,
              //   RoleEnum.Accountant,
              //   RoleEnum.CustomerService,
              // ],
            },
          },
          {
            path: 'login-history',
            element: <SettingAccountPage />,
            meta: {
              // role: [
              //   RoleEnum.Owner,
              //   RoleEnum.Developer,
              //   RoleEnum.Accountant,
              //   RoleEnum.CustomerService,
              // ],
            },
          },
          {
            path: 'referral',
            element: <SettingAccountPage />,
            meta: {
              // role: [
              //   RoleEnum.Owner,
              //   RoleEnum.Developer,
              //   RoleEnum.Accountant,
              //   RoleEnum.CustomerService,
              // ],
            },
          },
        ],
      },
    ],
  },

  {
    path: '/merchatsetting',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    meta: {
      mid: ['1-7'],
      role: [RoleEnum.Owner, RoleEnum.Developer],
    },
    children: [
      {
        path: 'menu',
        element: <MerchantSettingLayout />,
        children: [
          { element: <Navigate to="/merchatsetting/menu" replace />, index: true },
          {
            path: 'branding',
            element: <BrandingPage />,
            meta: {
              mid: ['1-7-1'],
              role: [RoleEnum.Owner],
            },
          },
          {
            path: 'settings',
            element: <SettingPage />,
            meta: {
              mid: ['1-7-2'],
              role: [RoleEnum.Owner],
            },
          },
          {
            path: 'security',
            element: <SecurityPage />,
            meta: {
              mid: ['1-7-3'],
              role: [RoleEnum.Owner, RoleEnum.Developer],
            },
          },
          {
            path: 'verification',
            element: <WebsitVerificationLayout />,
            meta: {
              mid: ['1-7-4'],
              role: [RoleEnum.Owner, RoleEnum.Developer],
            },
            children: [
              {
                element: <Navigate to={'/merchatsetting/menu/verification/index'} replace />,
                index: true,
              },
              {
                path: 'index',
                element: <WebsitVerificationPageSection />,
                meta: {
                  mid: ['1-7-4'],
                  role: [RoleEnum.Owner, RoleEnum.Developer],
                },
              },

              // { path: ':tab', element: <WebsitVerificationPageSection /> },
              // {
              //   path: 'file',
              //   element: <WebsitVerificationPageSection />,
              //   meta: {
              //     role: [RoleEnum.Owner, RoleEnum.Developer],
              //   },
              // },
              // {
              //   path: 'tag',
              //   element: <WebsitVerificationPageSection />,
              //   meta: {
              //     role: [RoleEnum.Owner, RoleEnum.Developer],
              //   },
              // },
            ],
          },
          {
            path: 'member',
            element: (
              <AuthGuard>
                <MemberLayout />
              </AuthGuard>
            ),
            meta: {
              mid: ['1-7-5'],
              role: [RoleEnum.Owner],
            },
            children: [
              {
                element: <Navigate to={'/merchatsetting/menu/member/index'} replace />,
                index: true,
              },
              {
                path: 'index',
                element: <MemberPage />,
                meta: {
                  mid: ['1-7-5-1'],
                  role: [RoleEnum.Owner],
                },
              },
              {
                path: 'actionLog',
                element: <ActionLogListPage />,
                meta: {
                  mid: ['1-7-5-5'],
                  role: [RoleEnum.Owner],
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/webhook',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    meta: {
      mid: ['1-9'],
      role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.CustomerService],
    },
    children: [
      { element: <Navigate to={'/webhook/index'} replace />, index: true },
      {
        path: 'index',
        element: <WebHookPage />,
        meta: {
          mid: ['1-9-1'],
          role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.CustomerService],
        },
      },
    ],
  },

  // {
  //   path: '/user-list',
  //   element: (
  //     <AuthGuard>
  //       <DashboardLayout />
  //     </AuthGuard>
  //   ),
  //   meta: {
  //     mid: ['1-6'],
  //     role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.CustomerService],
  //   },
  //   children: [
  //     { element: <Navigate to={'/user-list/index'} replace />, index: true },
  //     {
  //       path: 'index',
  //       element: <UserListPage />,
  //       meta: {
  //         mid: ['1-6-1'],
  //         role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.CustomerService],
  //       },
  //     },
  //     {
  //       path: 'detail',
  //       element: <UserListDetailPage />,
  //       meta: {
  //         mid: ['1-6-1'],
  //         role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.Accountant, RoleEnum.CustomerService],
  //       },
  //     },
  //   ],
  // },
  {
    path: '/user-assets',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    meta: {
      mid: ['1-6'],
      role: [RoleEnum.Owner, RoleEnum.Developer],
    },
    children: [
      {
        path: 'menu',
        // element: <UserAssetsLayout />,
        children: [
          // { element: <Navigate to="/user-assets/menu" replace />, index: true },
          {
            path: 'user-transaction',
            element: <UserTransactionLayout />,
            meta: {
              mid: ['1-2'],
              role: [RoleEnum.Owner],
            },
            children: [
              {
                element: (
                  <Navigate to={'/user-assets/menu/user-transaction/deposit-withdrawal'} replace />
                ),
                index: true,
              },
              {
                path: 'deposit-withdrawal',
                meta: {
                  mid: ['1-2-1'],
                  role: [RoleEnum.Owner],
                },
                element: <UserTransactionPage />,
              },
              {
                path: 'swap',
                meta: {
                  mid: ['1-2-1'],
                  role: [RoleEnum.Owner],
                },
                element: <UserTransactionPage />,
              },
              {
                path: 'user-transfer',
                meta: {
                  mid: ['1-2-1'],
                  role: [RoleEnum.Owner],
                },
                element: <UserTransactionPage />,
              },
            ],
          },
          {
            path: 'user-list',
            element: <UserlistLayout />,
            meta: {
              mid: ['1-6-1'],
              role: [RoleEnum.Owner],
            },
            children: [
              {
                element: <Navigate to={'/user-assets/menu/user-list/index'} replace />,
                index: true,
              },
              {
                path: 'index',
                element: <UserListPage />,
                meta: {
                  mid: ['1-6-1'],
                  role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.CustomerService],
                },
              },
              {
                path: 'detail',
                element: <UserListDetailPage />,
                meta: {
                  mid: ['1-6-1'],
                  role: [
                    RoleEnum.Owner,
                    RoleEnum.Developer,
                    RoleEnum.Accountant,
                    RoleEnum.CustomerService,
                  ],
                },
              },
            ],
          },
        ],
      },
    ],
  },
  {
    path: '/support',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    meta: {
      // role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.CustomerService],
    },
    children: [
      { element: <Navigate to={'/support/index'} replace />, index: true },
      {
        path: 'index',
        element: <SupportPage />,
        meta: {
          // role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.CustomerService],
        },
      },
      {
        path: 'demo',
        element: <DemoPage />,
        meta: {
          // role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.CustomerService],
        },
      },
    ],
  },
  {
    path: '/withdrawal-approval',
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
    meta: {
      mid: ['1-10'],
      role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.CustomerService],
    },
    children: [
      {
        path: 'menu',
        element: <WithdrawalApprovalLayout />,
        children: [
          { element: <Navigate to={'/withdrawal-approval/menu'} replace />, index: true },
          {
            path: 'pendingList',
            element: (
              <AuthGuard>
                <WithdrawlLayout />
              </AuthGuard>
            ),
            meta: {
              mid: ['1-10-1'],
              role: [RoleEnum.Owner],
            },
            children: [
              {
                element: <Navigate to={'/withdrawal-approval/menu/pendingList/index'} replace />,
                index: true,
              },
              {
                path: 'index',
                element: <WithdrawalApproval />,
                meta: {
                  mid: ['1-10-1'],
                  role: [RoleEnum.Owner],
                },
              },
              {
                path: 'blackList',
                element: <WhiteAndBlacklist />,
                meta: {
                  mid: ['1-10-2'],
                  role: [RoleEnum.Owner],
                },
              },
            ],
          },

          {
            path: 'records',
            element: <WithdrawalApprovalRecords />,
            meta: {
              mid: ['1-10-3'],
              role: [
                RoleEnum.Owner,
                RoleEnum.Developer,
                RoleEnum.Accountant,
                RoleEnum.CustomerService,
              ],
            },
          },
        ],
      },
    ],
  },
];

export const routeFilter = (
  routes: IRouteObject[],
  role: number = RoleEnum.Developer,
): IRouteObject[] => {
  const newRoutes = [] as IRouteObject[];
  for (let route of routes) {
    if (route.meta?.auth) {
      newRoutes.push(route);
      continue;
    }

    if (route.meta?.role) {
      // role 是数组，要求用户等级在数组中
      if (route.meta.role instanceof Array) {
        if (!route.meta.role.includes(role)) continue;
      } // role 不是数组，要求用户等级大于等于role
      else {
        if (role < route.meta.role) continue;
      }
    }
    const item = { ...route };

    // 如果路由有子路由，则递归过滤子路由
    if (route.children) {
      item.children = routeFilter(route.children, role);
    }

    newRoutes.push(item);
  }

  return newRoutes;
};

export function flattenMenu(menu: MenuListItem[]) {
  let arr: MenuListItem[] = [];

  menu.forEach((item) => {
    const subs = cloneDeep(item?.sub_menu || []);
    if (subs.length > 0) {
      delete item.sub_menu;
    }
    arr.push(item);
    if (subs.length > 0) {
      arr.push(...flattenMenu(subs).sort((a, b) => a.id - b.id));
    }
  }, []);
  return arr;
}

export const handleRoute = (rlist: RoleListItemType[], BaseRouteList: IRouteObject[]) => {
  const newRoutes: IRouteObject[] = [];
  for (let route of BaseRouteList) {
    if (route.meta?.mid) {
      if (route.meta?.role) {
        route.meta.role = [];
        for (let rl of rlist) {
          if (rl.pageIds.filter((item) => route.meta!.mid!.includes(item)).length > 0) {
            if (route.meta?.role) {
              route.meta.role.push(rl.roleId);
            }
          }
        }
        route.meta.role = [...new Set(route.meta.role)];
      }
    }

    const item = { ...route };

    if (route.children) {
      item.children = handleRoute(rlist, route.children);
    }

    newRoutes.push(item);
  }
  return newRoutes;
};

export const baseMenus = [
  { id: 11, name: 'Dashboard', mid: '1-1' },
  { id: 111, name: '资产列表', mid: '1-1-1' },
  { id: 112, name: 'API充值/提现订单统计', mid: '1-1-2' },
  { id: 113, name: '分类型的交易趋势图', mid: '1-1-3' },
  { id: 114, name: '直接充值的代币趋势图', mid: '1-1-4' },
  { id: 115, name: '余额走势图', mid: '1-1-5' },
  { id: 116, name: 'webhook通知走势图(24h)', mid: '1-1-6' },
  { id: 116, name: '地址管理用量图', mid: '1-1-7' },
  { id: 12, name: 'Transaction记录', mid: '1-2' },
  { id: 121, name: '查看Transaction记录列表、详情', mid: '1-2-1' },
  { id: 122, name: 'address list', mid: '1-2-2' },
  { id: 1211, name: '导出transaction记录', mid: '1-2-1-1' },
  { id: 16512, name: '归集列表', mid: '1-2-3' },
  { id: 13, name: 'API Orders', mid: '1-3' },
  { id: 131, name: 'API充值', mid: '1-3-1' },
  { id: 132, name: 'API提现', mid: '1-3-2' },
  { id: 1311, name: '查看API充值订单列表、详情', mid: '1-3-1-1' },
  { id: 1321, name: 'API提现列表,详情', mid: '1-3-2-1' },
  { id: 13111, name: 'API充值订单导出', mid: '1-3-1-1-1' },
  { id: 13112, name: 'API退款功能', mid: '1-3-1-1-2' },
  { id: 13211, name: 'API提现订单导出', mid: '1-3-2-1-1' },
  { id: 14, name: 'Invoice功能', mid: '1-4' },
  { id: 141, name: '查看Invoice母账单、发送列表及详情', mid: '1-4-1' },
  { id: 142, name: 'Invoice创建、编辑、停止', mid: '1-4-2' },
  { id: 1411, name: 'Invoice退款功能', mid: '1-4-1-1' },
  { id: 15, name: '资产管理', mid: '1-5' },
  { id: 151, name: '查看资产列表', mid: '1-5-1' },
  { id: 152, name: 'API退款功能', mid: '1-5-2' },
  { id: 153, name: 'Invoice退款功能', mid: '1-5-3' },

  { id: 1511, name: '充值功能', mid: '1-5-1-1' },
  { id: 1512, name: '提现功能', mid: '1-5-1-2' },
  { id: 1513, name: '换币功能', mid: '1-5-1-3' },

  { id: 16, name: '商户设置', mid: '1-6' },
  { id: 161, name: '商户设置', mid: '1-6-1' },
  { id: 162, name: '商户Security配置', mid: '1-6-2' },
  { id: 163, name: '商户网站验证', mid: '1-6-3' },
  { id: 164, name: '商户品牌管理', mid: '1-6-4' },
  { id: 165, name: '商户成员管理', mid: '1-6-5' },
  { id: 1611, name: '商户收款代币设置', mid: '1-6-1-1' },
  { id: 1612, name: '充值自动换币管理', mid: '1-6-1-2' },
  { id: 1613, name: 'webhook通知时机配置', mid: '1-6-1-3' },
  { id: 1614, name: '自动提现管理', mid: '1-6-1-4' },

  { id: 1621, name: '白名单配置', mid: '1-6-2-1' },
  { id: 1622, name: '小额提现免第2步验证', mid: '1-6-2-2' },

  { id: 1631, name: '查看网站验证', mid: '1-6-3-1' },

  { id: 1641, name: '查看商户Logo、商户名、社交账号、banner', mid: '1-6-4-1' },

  { id: 1651, name: '查看成员列表', mid: '1-6-5-1' },
  { id: 1652, name: '查看操作日志', mid: '1-6-5-2' },

  { id: 16311, name: '编辑商户网站', mid: '1-6-3-1-1' },
  { id: 16411, name: '编辑商户Logo、商户名、社交账号、banner', mid: '1-6-4-1-1' },
  { id: 16511, name: '成员列表详情、编辑、移除、添加', mid: '1-6-5-1-1' },

  { id: 17, name: 'Developer管理', mid: '1-7' },
  { id: 171, name: '查看APP ID、APP Secret', mid: '1-7-1' },
  { id: 172, name: '编辑Webhook URL,编辑IP白名单', mid: '1-7-2' },
  { id: 1711, name: '重置APP Secret', mid: '1-7-1-1' },
  { id: 18, name: 'webhook通知记录', mid: '1-8' },
  { id: 181, name: '查看webhook通知列表、详情', mid: '1-8-1' },
  { id: 1811, name: 'webhook重发、批量重发功能', mid: '1-8-1-1' },
];

export const MENUMANAGEMENT = {
  // dashboard
  dashboardMain: '1-1',
  dashboardBalanceList: '1-1-1',
  dashboardDepositWithdrawCount: '1-1-2',
  dashboardBalanceChart: '1-1-3',
  dashboardTransactionChart: '1-1-4',
  dashboardWebhookChart: '1-1-5',
  dashboardUserChart: '1-1-6',
  dashboardAddressChart: '1-1-7',

  // transaction
  transactionMain: '1-2',
  transactionListMain: '1-2-1',
  transactionList: '1-2-1-1',
  tarnsactionCollectList: '1-2-3',
  tarnsactionExport: '1-2-1-2',

  // api
  api: '1-3',
  apiWithdraw: '1-3-1',
  apiWithdrawList: '1-3-1-1',
  apiWithdrawExport: '1-3-1-2',
  apiDeposit: '1-3-2',
  apiDepositList: '1-3-2-1',
  apiDepositExport: '1-3-2-2',
  //-----pending----
  apiRefund: '1-3-1-1-2',

  // invoice
  invoiceMain: '1-4',

  invoiceOneList: '1-4-1',
  invoiceOneListDetail: '1-4-1-1',
  invoiceOneListRemark: '1-4-1-2',
  invoiceOneListRevoke: '1-4-1-3',
  invoiceOneListPdf: '1-4-1-4',

  invoiceRecurryList: '1-4-2',
  invoiceRecurryListViewManageDetail: '1-4-2-1',
  invoiceRecurryListDetail: '1-4-2-2',
  invoiceRecurryListDone: '1-4-2-3',
  invoiceRecurryListPdf: '1-4-2-4',
  invoiceRecurryListRemark: '1-4-2-4',

  invoiceManageList: '1-4-3',
  invoiceManageListDetail: '1-4-3-1',
  invoiceManageListViewChildrenList: '1-4-3-2',
  invoiceManageListDone: '1-4-3-3',
  invoiceCreateEditDuplicate: '1-4-4',

  // balance
  balanceMain: '1-5',
  balanceList: '1-5-1',
  balanceDeposit: '1-5-2',
  balanceWithdraw: '1-5-3',
  balanceSwap: '1-5-4',

  //user-list
  userListMain: '1-6',
  userListDetailView: '1-6-1',

  // merchant
  merchantMain: '1-7',
  merchantBranding: '1-7-1',
  merchantBrandingView: '1-7-1-1',
  merchantBrandingEdit: '1-7-1-2',
  merchantSetting: '1-7-2',
  merchantSettingToken: '1-7-2-1',
  merchantSettingTestNetwork: '1-7-2-2',
  merchantSettingAutoSwapDeposit: '1-7-2-3',
  merchantSettingAutoWithdraw: '1-7-2-4',
  merchantSettingWebhook: '1-7-2-5',
  merchantSecurity: '1-7-3',
  merchantSecuritySmallWithdraw: '1-7-3-1',
  merchantSecurityWhiteList: '1-7-3-2',
  merchantSecurityWhiteListView: '1-7-3-2-1',
  merchantSecurityWhiteListAdd: '1-7-3-2-2',
  merchantSecurityWhiteListEdit: '1-7-3-2-3',
  merchantSecurityWhiteListDel: '1-7-3-2-4',
  merchantSecurityWithdrawalIPWhitelist: '1-7-3-3',
  merchantSecurityWithdrawalIPWhitelistAction: '1-7-3-3-1',
  merchantSecurityWithdrawalIPWhitelistView: '1-7-3-3-2',
  merchantSecurityOwnerAuthorizedIPWhitelist: '1-7-3-4',
  merchantSecurityOwnerAuthorizedIPWhitelistAction: '1-7-3-4-1',
  merchantSecurityOwnerAuthorizedIPWhitelistView: '1-7-3-4-2',
  merchantVerify: '1-7-4',
  merchantVerifyWebView: '1-7-4-1',
  merchantVerifyWebEdit: '1-7-4-2',
  merchantMember: '1-7-5',
  merchantMemberList: '1-7-5-1',
  merchantMemberListInvite: '1-7-5-2',
  merchantMemberListEdit: '1-7-5-3',
  merchantMemberListDel: '1-7-5-4',
  merchantMemberActionLog: '1-7-5-5',

  // developer
  developerMain: '1-8',
  developerApp: '1-8-1',
  developerAppView: '1-8-1-1',
  developerAppReset: '1-8-1-2',
  developerAppEdit: '1-8-1-3',
  developerWebHookURL: '1-8-2',
  developerWebWhiteView: '1-8-2-1',
  developerWebWhiteEdit: '1-8-2-2',
  developerApiDoc: '1-8-3',

  // webhook
  webhookMain: '1-9',
  webhookListDetail: '1-9-1',
  webhookListResend: '1-9-3',
  webhookListResendSetting: '1-9-2',

  // Withdrawal Approval
  withdrawalApprovalMain: '1-10',
  withdrawalApprovalList: '1-10-1',
  withdrawalApprovalListView: '1-10-1-1',
  withdrawalApprovalVetting: '1-10-1-2',
  withdrawalApprovalVettingPass: '1-10-1-2-1',
  withdrawalApprovalVettingReject: '1-10-1-2-2',
  withdrawalApprovalSetting: '1-10-1-3',
  withdrawalApprovalWhite: '1-10-2',
  withdrawalApprovalWhiteView: '1-10-2-1',
  withdrawalApprovalWhiteAdd: '1-10-2-2',
  withdrawalApprovalWhiteDelete: '1-10-2-3',
  withdrawalApprovalRecord: '1-10-3',
};
