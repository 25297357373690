import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { TextFieldElement, FormContainer, useForm } from 'react-hook-form-mui';

import { useContext, useEffect } from 'react';
import { SettingsContext } from '../context';
import { MyIcon } from '@/assets/icons/MyIcon';
import { LoadingButton } from '@mui/lab';
import { useLocales } from '@/locales';
import { observer } from 'mobx-react';
import { UIState } from '../workflow';
import { useAuthContext } from '@/auth/useAuthContext';
import CommonDialog from '../components/CommonDialog';

export default observer(DialogPhoneChangeForm);

function DialogPhoneChangeForm({
  visible,
  onClose,
}: {
  visible: boolean;
  onClose: (payload: any) => void;
}) {
  const { translate: t } = useLocales();

  const formContext = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const settings = useContext(SettingsContext);
  const { user } = useAuthContext();

  const onConfirm = () => {
    const dom: HTMLInputElement = document.querySelector('#DialogNameChangeForm-Submit')!;
    dom.click();
  };

  const onOK = async (data: any) => {
    onClose(data);
  };

  useEffect(
    () => {
      formContext.reset();
      formContext.setValue('name', user?.username);
    },
    // eslint-disable-next-line
    [visible]
  );

  return (
    <CommonDialog open={visible} onClose={() => onClose(null)}>
      <DialogTitle id="scroll-dialog-title">
        <Box className="flex justify-between items-center">
          <Typography variant="h5" component="span">
            {settings?.name
              ? t('merchantSettings.settings_account_nickname_change')
              : t('merchantSettings.settings_account_nickname_change')}
          </Typography>
          <IconButton onClick={() => onClose(false)}>
            <MyIcon name="close" size={14} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ pt: 1 }}>
        <FormContainer formContext={formContext} handleSubmit={formContext.handleSubmit(onOK)}>
          <TextFieldElement
            name="name"
            label={
              user?.username
                ? t('merchantSettings.settings_account_nickname_edit')
                : t('merchantSettings.settings_account_nickname_edit')
            }
            fullWidth
            autoComplete={'off'}
            sx={{ mb: 2, '.MuiInputLabel-asterisk': { display: 'none' } }}
            // helperText={t('merchantSettings.security_phone_dialog_phone_helpText')}
            inputProps={{
              maxLength: 50,
            }}
            validation={{
              required: true,
            }}
          />

          <input id="DialogNameChangeForm-Submit" type="submit" hidden />
        </FormContainer>
      </DialogContent>
      <DialogActions className="loading-btn">
        <LoadingButton
          color="primary"
          variant="contained"
          type="submit"
          fullWidth
          onClick={onConfirm}
          className="btn-height48"
          loading={UIState.loading}
          loadingIndicator={<MyIcon name="loading" className="loading-icon-rotate" />}
        >
          {t('common.save')}
        </LoadingButton>
      </DialogActions>
    </CommonDialog>
  );
}
