import { useAuthContext } from '@/auth/useAuthContext';
import { useLocales } from '@/locales';
import { useLocalDispatch } from '@cctip/saga-store';
import { Box, Button, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { styles } from '../style';
import useResponsive from '@/hooks/useResponsive';
import { SecuritySettingBtnArrow } from '../security';

export default observer(function Index() {
  const isMobile = !useResponsive('up', 'sm');
  const { translate } = useLocales();
  const { user } = useAuthContext();
  const dispatch = useLocalDispatch();
  return (
    <>
      <Box
        sx={{
          borderRadius: 1,
        }}
        className="flex items-center"
      >
        <Box sx={{ flex: 1, marginRight: '16px' }}>
          <Typography
            variant="subtitle2"
            className="color-text"
            sx={{ color: '#1F2025', fontWeight: 600 }}
          >
            {translate('merchantSettings.security_pay_password_title1')}
          </Typography>
          {/* {!isMobile && (
            <Typography variant="body2" className="color-text2">
              {user?.is_pay_passwd
                ? translate('merchantSettings.security_pay_password_desc2')
                : translate('merchantSettings.security_pay_password_desc1')}
            </Typography>
          )} */}
          <Typography variant="body2" fontSize={isMobile ? 12 : 14} className="color-text2">
            {user?.is_pay_passwd
              ? translate('merchantSettings.security_pay_password_desc2')
              : translate('merchantSettings.security_pay_password_desc1')}
          </Typography>
        </Box>
        <Button
          sx={{ ...styles.set_up }}
          onClick={() => {
            user?.is_pay_passwd
              ? dispatch({ type: 'pay-password-change/confirm' })
              : dispatch({ type: 'pay-password-set/confirm' });
          }}
        >
          {!isMobile && (
            <>
              {user?.is_pay_passwd
                ? translate('merchantSettings.security_btn_change')
                : translate('merchantSettings.security_btn_set_up')}
            </>
          )}
          <SecuritySettingBtnArrow />
        </Button>

        {/* <Button
          variant="outlined"
          onClick={() => {
            user?.is_pay_passwd
              ? dispatch({ type: 'pay-password-change/confirm' })
              : dispatch({ type: 'pay-password-set/confirm' });
          }}
          sx={{ fontWeight: 500 }}
        >
          {user?.is_pay_passwd
            ? translate('merchantSettings.security_btn_change')
            : translate('merchantSettings.security_btn_set_up')}
        </Button> */}
      </Box>
    </>
  );
});
