import { Button, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import {
  withLocalStore,
  effects,
  useLocalDispatch,
  eventChannel,
  END,
  Channel,
  useSharedChannel,
} from '@cctip/saga-store';
import { useSnackbar } from 'notistack';
import { MyIcon } from '@/assets/icons/MyIcon';
import { useLocales } from '@/locales';
import { useLocation } from 'react-router';
import { PRIMARY } from '@/theme/palette';
import NewCaptchaDialog from '@/sections/auth/common/NewCaptchaDialog';
import { useAuthContext } from '@/auth/useAuthContext';
import { securityApi_ } from '@/api';
import { CheckCaptchaMax, PHONERULE } from '@/config';

// Register|Login|UpdateLoginPass|UpdatePayPass|BindPhone|UnBindPhone|ChangePhone
export type phoneCodeTimeType = { type: 'new' | 'old'; value: number; phone: string };

function GetPhoneCode({
  phone,
  codeType = 1,
  verifyRef,
  type,
  send_type = 1,
}: {
  phone: string;
  codeType?: number;
  verifyRef?: any;
  type?: 'new' | 'old';
  send_type: 1 | 2;
}) {
  const { translate: t } = useLocales();
  const [text, setText] = useState(t('verfition.get_code'));
  const { enqueueSnackbar } = useSnackbar();
  const [canSend, setCanSend] = useState(false);
  const [cutDownTime, setCutDownTime] = useState(0);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const theme = useTheme();

  const dispatch = useLocalDispatch();
  const sharedChannel = useSharedChannel();
  const { captchaData, changeCaptchaData, captchaDialogOpen, setCaptchaDialogOpen } =
    useAuthContext();

  const btnColor = useMemo(() => {
    let color = PRIMARY.main;
    if (!['/verification', '/register', '/forgetPassword', '/login'].includes(location.pathname)) {
      color = theme.palette.primary.main;
    }
    return color;
  }, [location, theme]);

  const dialogOpenDisable = useMemo(
    () => captchaDialogOpen && ['/verification'].includes(location.pathname),
    [captchaDialogOpen, location.pathname],
  );

  useEffect(
    () => {
      sharedChannel.put({
        setText,
        enqueueSnackbar,
        setCanSend,
        type: type ?? '',
        setLoading,
        t,
        setCaptchaDialogOpen,
        dialogOpenDisable,
        send_type,
      });
    },
    [], // eslint-disable-line
  );

  const onClick = (e: any) => {
    e.stopPropagation();
    // dispatch({ type: 'count-down', phone, codeType });
    // '/forgetPassword'
    // if (['/verification'].includes(location.pathname)) {
    //   setCaptchaDialogOpen(true);
    // } else {
    //   dispatch({ type: 'count-down', phone, codeType });
    // }

    if (['/verification'].includes(location.pathname)) {
      dispatch({
        type: 'count-down',
        phone,
        codeType,
        captcha_id: captchaData.CaptchaKey,
        captcha_code: captchaData.CaptchaCode,
      });
    } else {
      dispatch({ type: 'count-down', phone, codeType });
    }
  };

  const tick = () => {
    if (type && sessionStorage.getItem('phoneCodeTime')) {
      let phoneCodeTimeArr: phoneCodeTimeType[] = JSON.parse(
        sessionStorage.getItem('phoneCodeTime') as string,
      );
      const temp = phoneCodeTimeArr.find((item) => item.type === type && item.value > 0);
      // 如果存在60s倒计时
      if (temp) {
        setCanSend(true);
        setCutDownTime(temp!.value);
        let timer = setTimeout(() => {
          if (timer) clearTimeout(timer);
          if (temp?.value === 1) {
            setCutDownTime(0);
            temp.value = 0;
            setText(t('verfition.Resend'));
            setCanSend(false);
            sessionStorage.setItem('phoneCodeTime', JSON.stringify(phoneCodeTimeArr));
            clearTimeout(timer);
          } else {
            tick();
          }
        }, 300);
      }
    }
  };

  const loadCutDown = () => {
    if (type && sessionStorage.getItem('phoneCodeTime')) {
      let phoneCodeTimeArr: phoneCodeTimeType[] = JSON.parse(
        sessionStorage.getItem('phoneCodeTime') as string,
      );
      const temp = phoneCodeTimeArr.find((item) => item.type === type && item.value > 0);
      // 如果存在60s倒计时
      if (temp) {
        setCutDownTime(0);
        temp.value = 0;
        setText(t('verfition.get_code'));
        setCanSend(false);
        sessionStorage.setItem('phoneCodeTime', JSON.stringify(phoneCodeTimeArr));
      }
    }
  };

  const phoneValid = useMemo(() => {
    let flag = false;
    if (!phone) {
      flag = true;
    } else {
      if (!PHONERULE.test(phone)) {
        flag = true;
      }
    }
    return flag;
  }, [phone]);

  useEffect(() => {
    tick();
    window.onunload = function (e) {
      loadCutDown();
    };
    // eslint-disable-next-line
  }, []);

  return (
    <>
      {verifyRef ? (
        <Button
          variant="text"
          disabled={Number.isInteger(parseInt(text)) || canSend || phoneValid}
          onClick={onClick}
          sx={{ minWidth: 84, maxWidth: 120, fontWeight: 500, color: btnColor }}
          ref={verifyRef}
        >
          {loading ? (
            <MyIcon name="loading" className="loading-icon-rotate" />
          ) : (
            <>{cutDownTime > 0 ? `${cutDownTime}s` : text}</>
          )}
        </Button>
      ) : (
        <Button
          variant="text"
          disabled={Number.isInteger(parseInt(text)) || canSend || phoneValid}
          onClick={onClick}
          sx={{ minWidth: 84, maxWidth: 120, fontWeight: 500, color: btnColor }}
        >
          {loading ? (
            <MyIcon name="loading" className="loading-icon-rotate" />
          ) : (
            <>{cutDownTime > 0 ? `${cutDownTime}s` : text}</>
          )}
        </Button>
      )}

      {captchaDialogOpen && (
        <NewCaptchaDialog
          onClose={(v) => {
            changeCaptchaData(v);
            if (v?.CaptchaCode && v?.CaptchaKey) {
              dispatch({
                type: 'count-down',
                phone,
                codeType,
                captcha_id: v.CaptchaKey,
                captcha_code: v.CaptchaCode,
              });
            }
            setCaptchaDialogOpen(false);
          }}
          open={captchaDialogOpen}
        />
      )}
    </>
  );
}

const countdown = (secs: number, type: string, _phone: string) => {
  const target = Date.now() + secs * 1000;
  return eventChannel<number | END>((emitter) => {
    const iv = setInterval(() => {
      const remain = (target - Date.now()) / 1000;
      const remainSeconds = Math.ceil(remain);
      if (remainSeconds >= 0) {
        emitter(remainSeconds);
        if (type) {
          const phoneCodeTimeArr: phoneCodeTimeType[] = sessionStorage.getItem('phoneCodeTime')
            ? JSON.parse(sessionStorage.getItem('phoneCodeTime') as string)
            : [
                {
                  type: 'new',
                  value: 0,
                  phone: _phone,
                },
                {
                  type: 'old',
                  value: 0,
                  phone: _phone,
                },
              ];
          phoneCodeTimeArr.forEach((item) => {
            if (item.type === type) {
              item.value = remainSeconds;
            }
          });
          sessionStorage.setItem('phoneCodeTime', JSON.stringify(phoneCodeTimeArr));
        }
      } else {
        emitter(END);
        clearInterval(iv);
      }
    }, 300);
    return () => {
      clearInterval(iv);
    };
  });
};

export default withLocalStore(GetPhoneCode, {
  task: function* (ch: Channel<any>) {
    const {
      setText,
      enqueueSnackbar,
      setCanSend,
      type,
      setLoading,
      t,
      setCaptchaDialogOpen,
      dialogOpenDisable,
      send_type,
    } = yield effects.take(ch);
    while (true) {
      const {
        phone: _phone,
        // codeType,
        // captcha_id,
        // captcha_code,
      } = yield effects.take('count-down');
      try {
        setLoading(true);
        const [area, phone] = `+${_phone}`.split('-');
        yield effects.call(securityApi_.sendPhoneCode, {
          area,
          phone,
          send_type,
          // code_type: codeType,
          // captcha_id,
          // captcha_code,
        });
        setLoading(false);
        const cdCh = countdown(59, type, _phone);
        do {
          const seconds: number | END = yield effects.takeMaybe(cdCh);
          if (seconds === END) break;
          setText(`${seconds}s`);
        } while (true);
        setText(t('verfition.Resend'));
      } catch (e) {
        if ([224034, 10044, 10045].includes(e?.code)) {
          enqueueSnackbar(t('verfition.code_error_desc1'), {
            variant: 'error',
          });
          setCanSend(true);
        } else if ([10009].includes(e?.code)) {
          enqueueSnackbar(t('verfition.code_error_desc2'), { variant: 'error' });
        } else {
          if (e?.code) {
            if (e?.code === CheckCaptchaMax) {
              if (!dialogOpenDisable) {
                setCaptchaDialogOpen(true);
              }
            } else {
              enqueueSnackbar(t(`new_interface_err.${e.code}`), { variant: 'error' });
            }
          } else {
            enqueueSnackbar(e?.message, { variant: 'error' });
          }
        }
        setLoading(false);
      }
    }
  } as any,
}) as typeof GetPhoneCode;
