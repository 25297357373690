import { Navigate, RouteObject, useLocation, useRoutes } from 'react-router-dom';
// auth
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
// import DashboardLayout from '../layouts/dashboard';
// config
import { PATH_AFTER, getPathAfter } from '../config';
import {
  Page500,
  Page403,
  Page404,
  // DashboardPage,
  LoginPage,
  ForgotPasswordPage,
  RegisterPage,
  // TradingOrderPage,
  // AddressListPage,
  // ActionLogListPage,
  // BalancesPage,
  // WebHookPage,
  // InvoicePage,
  // InvoiceOneDetailsPage,
  // RecurringDetailsPage,
  // ManageDetailsPage,
  // CreateInvoicePage,
  InvoicePDFpage,
  // PaymentApiPage,
  // WebsitVerificationPageSection,
  // SettingAccountPage,
  // PaymentApiConfigPage,
  // PaymentApiOrderDetailsPage,
  // SupportPage,
  // CreateRefundPage,
  VerifitionPage,
  // AccountSettingsPage,
  // ReferralProgramPage,
  // WithdrawRecordPage,
  SelectPlatformPage,
  // BrandingPage,
  // SettingPage,
  // SecurityPage,
  // CollectionListPage,
  // MemberPage,
  RegistrationReviewPage,
  RegisterApplyAgainPage,
} from './elements';
import ModalRoute from './modalRoute';
// import DemoPage from '@/pages/DemoPage';
import RouteForward from '@/components/route-forward';
// import ManageRecurringInvoice from '@/pages/Invoice/ManageRecurringInvoice';

// import MerchantSettingLayout from '@/sections/MerchantSetting/MerchantSettingLayout';
// import PaymentLayout from '@/sections/PaymentApi/PaymentLayout/index';
// import WebsitVerificationLayout from '@/sections/MerchantSetting/WebSiteVerificationLayout/index';
// import SettingAccountLayout from '@/sections/MerchantSetting/Index';
// import InvoiceLayout from '@/pages/Invoice/InvoiceLayout/index';
// import MemberLayout from '@/sections/MerchantSetting/member/MemberLayout';
// import { useEffect, useState } from 'react';
import { useAuthContext } from '@/auth/useAuthContext';
// import { accountApi_ } from '@/api';
// import { MenuListItem, RoleMenuListItem } from '@/api/accountApi_';
// import { cloneDeep } from 'lodash';
// import LoadingScreen from '@/components/loading-screen/LoadingScreen';

export enum RoleEnum {
  'Owner' = 1,
  'Developer' = 2,
  'Accountant' = 3,
  'CustomerService' = 4,
}

export type RoleListItemType = { roleId: number; name: string; pageIds: string[] };

export type IRouteObject = Omit<RouteObject, 'children'> & {
  children?: IRouteObject[];
  /** 路由权限标记 */
  meta?: {
    /** 是否需要登录, 暂未使用 */
    auth?: boolean;
    /** 角色list  */
    role?: number[];
    /** 路由编号  */
    mid?: string[];
  };
};

// const BaseRouteList: IRouteObject[] = [
//   {
//     path: '/dashboard',
//     element: (
//       <AuthGuard>
//         <DashboardLayout />
//       </AuthGuard>
//     ),
//     meta: {
//       mid: ['1-1'],
//       role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.CustomerService],
//     },
//     children: [
//       { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
//       {
//         path: 'index',
//         element: <DashboardPage />,
//         meta: {
//           mid: ['1-1'],
//           role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.CustomerService],
//         },
//       },
//     ],
//   },
//   {
//     path: '/transaction',
//     element: (
//       <AuthGuard>
//         <DashboardLayout />
//       </AuthGuard>
//     ),
//     meta: {
//       mid: ['1-2'],
//       role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.Accountant, RoleEnum.CustomerService],
//     },
//     children: [
//       { element: <Navigate to={'/transaction/index'} replace />, index: true },
//       {
//         path: 'index',
//         element: <TradingOrderPage />,
//         meta: {
//           mid: ['1-2-1'],
//           role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.Accountant, RoleEnum.CustomerService],
//         },
//       },
//       {
//         path: 'create-refund',
//         element: <CreateRefundPage />,
//         meta: {
//           mid: ['1-3-1-1-2', '1-4-1-1', '1-5-2', '1-5-3'],
//           role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.CustomerService],
//         },
//       },
//       {
//         path: 'address-list',
//         element: <AddressListPage />,
//         meta: {
//           mid: ['1-2-2'],
//           role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.Accountant, RoleEnum.CustomerService],
//         },
//       },
//       {
//         path: 'collect-list',
//         element: <CollectionListPage />,
//         meta: {
//           mid: ['1-2-3'],
//           role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.Accountant, RoleEnum.CustomerService],
//         },
//       },
//     ],
//   },
//   {
//     path: '/balances',
//     element: (
//       <AuthGuard>
//         <DashboardLayout />
//       </AuthGuard>
//     ),
//     meta: {
//       mid: ['1-5'],
//       role: [RoleEnum.Owner, RoleEnum.Developer],
//     },
//     children: [
//       { element: <Navigate to={'/balances/index'} replace />, index: true },
//       {
//         path: 'index',
//         element: <BalancesPage />,
//         meta: {
//           mid: ['1-5-1'],
//           role: [RoleEnum.Owner, RoleEnum.Developer],
//         },
//       },
//     ],
//   },
//   {
//     path: '/invoice',
//     element: (
//       <AuthGuard>
//         <DashboardLayout />
//       </AuthGuard>
//     ),
//     meta: {
//       mid: ['1-4'],
//       role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.Accountant, RoleEnum.CustomerService],
//     },
//     children: [
//       { element: <Navigate to={'/invoice/list'} replace />, index: true },
//       {
//         path: 'list',
//         element: <InvoiceLayout />,
//         children: [
//           {
//             element: <Navigate to={'/invoice/list/recurring'} replace />,
//             index: true,
//           },

//           {
//             path: 'recurring',
//             element: <InvoicePage />,
//             meta: {
//               mid: ['1-4-1'],
//               role: [
//                 RoleEnum.Owner,
//                 RoleEnum.Developer,
//                 RoleEnum.Accountant,
//                 RoleEnum.CustomerService,
//               ],
//             },
//           },
//           {
//             path: 'oneTime',
//             element: <InvoicePage />,
//             meta: {
//               mid: ['1-4-1'],
//               role: [
//                 RoleEnum.Owner,
//                 RoleEnum.Developer,
//                 RoleEnum.Accountant,
//                 RoleEnum.CustomerService,
//               ],
//             },
//           },
//         ],
//       },
//       {
//         path: 'oneTimeDetails',
//         element: <InvoiceOneDetailsPage />,
//         meta: {
//           mid: ['1-4-1'],
//           role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.Accountant, RoleEnum.CustomerService],
//         },
//       },
//       {
//         path: 'recurringDetails',
//         element: <RecurringDetailsPage />,
//         meta: {
//           mid: ['1-4-1'],
//           role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.Accountant, RoleEnum.CustomerService],
//         },
//       },
//       {
//         path: 'ManageDetails',
//         element: <ManageDetailsPage />,
//         meta: {
//           mid: ['1-4-1'],
//           role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.Accountant, RoleEnum.CustomerService],
//         },
//       },
//       {
//         path: 'createInvoice',
//         element: <CreateInvoicePage />,
//         meta: {
//           mid: ['1-4-2'],
//           role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.CustomerService],
//         },
//       },
//       {
//         path: 'ManageRecurring',
//         element: <ManageRecurringInvoice />,
//         meta: {
//           mid: ['1-4-1'],
//           role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.Accountant, RoleEnum.CustomerService],
//         },
//       },
//     ],
//   },

//   {
//     path: '/payment',
//     element: (
//       <AuthGuard>
//         <DashboardLayout />
//       </AuthGuard>
//     ),
//     meta: {
//       mid: ['1-3'],
//       role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.Accountant, RoleEnum.CustomerService],
//     },
//     children: [
//       { element: <Navigate to={'/payment/paymentApiOrder'} replace />, index: true },
//       {
//         path: 'paymentApiOrder',
//         element: <PaymentLayout />,
//         children: [
//           {
//             element: <Navigate to={'/payment/paymentApiOrder/outgoing'} replace />,
//             index: true,
//           },
//           // { path: ':tab', element: <PaymentApiPage /> },
//           {
//             path: 'outgoing',
//             element: <PaymentApiPage />,
//             meta: {
//               mid: ['1-3-2'],
//               role: [
//                 RoleEnum.Owner,
//                 RoleEnum.Developer,
//                 RoleEnum.Accountant,
//                 RoleEnum.CustomerService,
//               ],
//             },
//           },
//           {
//             path: 'incoming',
//             element: <PaymentApiPage />,
//             meta: {
//               mid: ['1-3-1'],
//               role: [
//                 RoleEnum.Owner,
//                 RoleEnum.Developer,
//                 RoleEnum.Accountant,
//                 RoleEnum.CustomerService,
//               ],
//             },
//           },
//         ],
//       },
//       {
//         path: 'paymentApiOrderDetails',
//         element: <PaymentApiOrderDetailsPage />,
//         meta: {
//           mid: ['1-3-2-1', '1-3-1-1'],
//           role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.Accountant],
//         },
//       },
//     ],
//   },
//   {
//     path: '/developer',
//     element: (
//       <AuthGuard>
//         <DashboardLayout />
//       </AuthGuard>
//     ),
//     meta: {
//       mid: ['1-7'],
//       role: [RoleEnum.Owner, RoleEnum.Developer],
//     },
//     children: [
//       { element: <Navigate to={'/developer/config'} replace />, index: true },
//       {
//         path: 'config',
//         element: <PaymentApiConfigPage />,
//         meta: {
//           mid: ['1-7'],
//           role: [RoleEnum.Owner, RoleEnum.Developer],
//         },
//       },
//     ],
//   },

//   {
//     path: '/referral',
//     element: (
//       <AuthGuard>
//         <DashboardLayout />
//       </AuthGuard>
//     ),
//     meta: {
//       auth: true,
//     },
//     children: [
//       { element: <Navigate to={'/referral/program'} replace />, index: true },
//       {
//         path: 'program',
//         element: <ReferralProgramPage />,
//         meta: {
//           role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.Accountant, RoleEnum.CustomerService],
//         },
//       },
//       {
//         path: 'withdraw-record',
//         element: <WithdrawRecordPage />,
//         meta: {
//           role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.Accountant, RoleEnum.CustomerService],
//         },
//       },
//     ],
//   },

//   {
//     path: '/account',
//     element: (
//       <AuthGuard>
//         <DashboardLayout />
//       </AuthGuard>
//     ),
//     meta: {
//       auth: true,
//     },
//     children: [
//       { element: <Navigate to={'/account/settingPageSection'} replace />, index: true },
//       {
//         path: 'settingPageSection',
//         element: <SettingAccountLayout />,
//         children: [
//           {
//             element: <Navigate to={'/account/settingPageSection/settings'} replace />,
//             index: true,
//           },
//           {
//             path: 'settings',
//             element: <SettingAccountPage />,
//             meta: {
//               role: [
//                 RoleEnum.Owner,
//                 RoleEnum.Developer,
//                 RoleEnum.Accountant,
//                 RoleEnum.CustomerService,
//               ],
//             },
//           },
//           {
//             path: 'merchantlist',
//             element: <SettingAccountPage />,
//             meta: {
//               role: [
//                 RoleEnum.Owner,
//                 RoleEnum.Developer,
//                 RoleEnum.Accountant,
//                 RoleEnum.CustomerService,
//               ],
//             },
//           },
//           {
//             path: 'login-history',
//             element: <SettingAccountPage />,
//             meta: {
//               role: [
//                 RoleEnum.Owner,
//                 RoleEnum.Developer,
//                 RoleEnum.Accountant,
//                 RoleEnum.CustomerService,
//               ],
//             },
//           },
//           {
//             path: 'referral',
//             element: <SettingAccountPage />,
//             meta: {
//               role: [
//                 RoleEnum.Owner,
//                 RoleEnum.Developer,
//                 RoleEnum.Accountant,
//                 RoleEnum.CustomerService,
//               ],
//             },
//           },
//         ],
//       },
//     ],
//   },

//   {
//     path: '/merchatsetting',
//     element: (
//       <AuthGuard>
//         <DashboardLayout />
//       </AuthGuard>
//     ),
//     meta: {
//       mid: ['1-6'],
//       role: [RoleEnum.Owner, RoleEnum.Developer],
//     },
//     children: [
//       {
//         path: 'menu',
//         element: <MerchantSettingLayout />,
//         children: [
//           { element: <Navigate to="/merchatsetting/menu" replace />, index: true },
//           {
//             path: 'branding',
//             element: <BrandingPage />,
//             meta: {
//               mid: ['1-6-4'],
//               role: [RoleEnum.Owner],
//             },
//           },
//           {
//             path: 'settings',
//             element: <SettingPage />,
//             meta: {
//               mid: ['1-6-1'],
//               role: [RoleEnum.Owner],
//             },
//           },
//           {
//             path: 'security',
//             element: <SecurityPage />,
//             meta: {
//               mid: ['1-6-2'],
//               role: [RoleEnum.Owner, RoleEnum.Developer],
//             },
//           },
//           {
//             path: 'verification',
//             element: <WebsitVerificationLayout />,
//             meta: {
//               mid: ['1-6-3'],
//               role: [RoleEnum.Owner, RoleEnum.Developer],
//             },
//             children: [
//               {
//                 element: <Navigate to={'/merchatsetting/menu/verification/index'} replace />,
//                 index: true,
//               },
//               {
//                 path: 'index',
//                 element: <WebsitVerificationPageSection />,
//                 meta: {
//                   mid: ['1-6-3'],
//                   role: [RoleEnum.Owner, RoleEnum.Developer],
//                 },
//               },

//               // { path: ':tab', element: <WebsitVerificationPageSection /> },
//               // {
//               //   path: 'file',
//               //   element: <WebsitVerificationPageSection />,
//               //   meta: {
//               //     role: [RoleEnum.Owner, RoleEnum.Developer],
//               //   },
//               // },
//               // {
//               //   path: 'tag',
//               //   element: <WebsitVerificationPageSection />,
//               //   meta: {
//               //     role: [RoleEnum.Owner, RoleEnum.Developer],
//               //   },
//               // },
//             ],
//           },
//           {
//             path: 'member',
//             element: (
//               <AuthGuard>
//                 <MemberLayout />
//               </AuthGuard>
//             ),
//             meta: {
//               mid: ['1-6-5'],
//               role: [RoleEnum.Owner],
//             },
//             children: [
//               {
//                 element: <Navigate to={'/merchatsetting/menu/member/index'} replace />,
//                 index: true,
//               },
//               {
//                 path: 'index',
//                 element: <MemberPage />,
//                 meta: {
//                   mid: ['1-6-5'],
//                   role: [RoleEnum.Owner],
//                 },
//               },
//               {
//                 path: 'actionLog',
//                 element: <ActionLogListPage />,
//                 meta: {
//                   mid: ['1-6-5'],
//                   role: [RoleEnum.Owner],
//                 },
//               },
//             ],
//           },
//         ],
//       },
//     ],
//   },
//   {
//     path: '/webhook',
//     element: (
//       <AuthGuard>
//         <DashboardLayout />
//       </AuthGuard>
//     ),
//     meta: {
//       mid: ['1-8'],
//       role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.CustomerService],
//     },
//     children: [
//       { element: <Navigate to={'/webhook/index'} replace />, index: true },
//       {
//         path: 'index',
//         element: <WebHookPage />,
//         meta: {
//           mid: ['1-8-1'],
//           role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.CustomerService],
//         },
//       },
//     ],
//   },
//   {
//     path: '/support',
//     element: (
//       <AuthGuard>
//         <DashboardLayout />
//       </AuthGuard>
//     ),
//     meta: {
//       role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.CustomerService],
//     },
//     children: [
//       { element: <Navigate to={'/support/index'} replace />, index: true },
//       {
//         path: 'index',
//         element: <SupportPage />,
//         meta: {
//           role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.CustomerService],
//         },
//       },
//       {
//         path: 'demo',
//         element: <DemoPage />,
//         meta: {
//           role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.CustomerService],
//         },
//       },
//     ],
//   },
// ];

export const routeFilter = (
  routes: IRouteObject[],
  role: number = RoleEnum.Developer,
): IRouteObject[] => {
  const newRoutes = [] as IRouteObject[];
  for (let route of routes) {
    if (route.meta?.auth) {
      newRoutes.push(route);
      continue;
    }

    if (route.meta?.role) {
      // role 是数组，要求用户等级在数组中
      if (route.meta.role instanceof Array) {
        if (!route.meta.role.includes(role)) continue;
      } // role 不是数组，要求用户等级大于等于role
      else {
        if (role < route.meta.role) continue;
      }
    }
    const item = { ...route };

    // 如果路由有子路由，则递归过滤子路由
    if (route.children) {
      item.children = routeFilter(route.children, role);
    }

    newRoutes.push(item);
  }

  return newRoutes;
};

function AppRouter() {
  const location = useLocation();
  const { user, routes } = useAuthContext();
  // const [routes, setRoutes] = useState<RouteObject[]>(BaseRouteList as RouteObject[]);

  // const [roleList, setRoleList] = useState<RoleListItemType[]>([]);

  // const [loading, setLoading] = useState(false);

  // function flattenMenu(menu: MenuListItem[]) {
  //   let arr: MenuListItem[] = [];

  //   menu.forEach((item) => {
  //     const subs = cloneDeep(item?.sub_menu || []);
  //     if (subs.length > 0) {
  //       delete item.sub_menu;
  //     }
  //     arr.push(item);
  //     if (subs.length > 0) {
  //       arr.push(...flattenMenu(subs).sort((a, b) => a.id - b.id));
  //     }
  //   }, []);
  //   return arr;
  // }

  // const handleRouter = () => {
  //   setLoading(true);
  //   accountApi_
  //     .roleMenuList()
  //     .then((res) => {
  //       const arr: RoleListItemType[] = [];
  //       Object.keys(res.menus).forEach((item) => {
  //         const obj: RoleListItemType = {
  //           roleId: 0,
  //           name: '',
  //           pageIds: [],
  //         };
  //         obj.roleId = res.menus[item].role.id;
  //         obj.name = res.menus[item].role.name;
  //         obj.pageIds = flattenMenu(res.menus[item].list).map((item) => item.mid);

  //         arr.push(obj);
  //       });
  //       setRoleList(arr);

  //       const newRoutes = routeFilter(
  //         handleRoute(arr, BaseRouteList) as RouteObject[],
  //         user?.role_id || 1
  //       );
  //       console.log('routes', user?.role_id);

  //       if (user?.role_id === RoleEnum.Accountant) {
  //         // newRoutes.push({
  //         //   path: '/dashboard',
  //         //   element: (
  //         //     <AuthGuard>
  //         //       <DashboardLayout />
  //         //     </AuthGuard>
  //         //   ),
  //         //   meta: {
  //         //     role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.CustomerService],
  //         //   },
  //         //   children: [
  //         //     { element: <Navigate to={PATH_AFTER[user?.role_id || 1]} replace />, index: true },
  //         //     {
  //         //       path: 'index',
  //         //       element: <Navigate to={PATH_AFTER[user?.role_id || 1]} replace />,
  //         //     },
  //         //   ],
  //         // });
  //       }
  //       console.log('newRoutes', newRoutes);
  //       setRoutes([...(newRoutes as RouteObject[])]);
  //       // setRoutes(handleRoute(arr, routes) as RouteObject[]);
  //     })
  //     .catch((err) => {
  //       console.log('err', err);
  //     })
  //     .finally(() => {
  //       setLoading(false);
  //     });
  // };

  // const handleRoute = (rlist: RoleListItemType[], BaseRouteList: IRouteObject[]) => {
  //   const newRoutes: IRouteObject[] = [];
  //   for (let route of BaseRouteList) {
  //     if (route.meta?.mid) {
  //       if (route.meta?.role) {
  //         route.meta.role = [];
  //         for (let rl of rlist) {
  //           if (rl.pageIds.filter((item) => route.meta!.mid!.includes(item)).length > 0) {
  //             if (route.meta?.role) {
  //               route.meta.role.push(rl.roleId);
  //             }
  //           }
  //         }
  //         route.meta.role = [...new Set(route.meta.role)];
  //       }
  //     }

  //     const item = { ...route };

  //     if (route.children) {
  //       item.children = handleRoute(rlist, route.children);
  //     }

  //     newRoutes.push(item);
  //   }
  //   return newRoutes;
  // };

  // useEffect(() => {
  //   if (isVerify && isAuthenticated) {
  //     handleRouter();
  //   }
  // }, [isVerify, isAuthenticated, user?.role_id]);

  return useRoutes(
    [
      {
        path: '/',
        children: [
          {
            element: <Navigate to={getPathAfter(PATH_AFTER[user?.role_id || -1])} replace />,
            index: true,
          },
          {
            path: 'login',
            element: (
              <GuestGuard>
                <LoginPage />
              </GuestGuard>
            ),
          },
          {
            path: 'forgetPassword',
            element: (
              <GuestGuard>
                <ForgotPasswordPage />
              </GuestGuard>
            ),
          },
          {
            path: 'register',
            element: (
              <GuestGuard>
                <RegisterPage />
              </GuestGuard>
            ),
          },
          {
            path: 'register-review',
            element: (
              <GuestGuard>
                <RegistrationReviewPage />
              </GuestGuard>
            ),
          },
          {
            path: 'register-again',
            element: (
              <GuestGuard>
                <RegisterApplyAgainPage />
              </GuestGuard>
            ),
          },
          {
            path: 'checkout',
            element: <>2222222</>,
          },
          {
            path: 'verification',
            element: (
              <AuthGuard>
                <VerifitionPage />
              </AuthGuard>
            ),
          },
          {
            path: 'select-platform',
            element: (
              <GuestGuard>
                <SelectPlatformPage />
              </GuestGuard>
            ),
          },
          { path: 'invoice-pdf', element: <InvoicePDFpage /> },
        ],
      },
      // {
      //   path: '/dashboard',
      //   element: (
      //     <AuthGuard>
      //       <DashboardLayout />
      //     </AuthGuard>
      //   ),

      //   children: [
      //     { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
      //     {
      //       path: 'index',
      //       element: <DashboardPage />,
      //     },
      //   ],
      // },
      ...routes,

      {
        element: <CompactLayout />,
        children: [
          { path: '500', element: <Page500 /> },
          { path: '404', element: <Page404 /> },
          { path: '403', element: <Page403 /> },
        ],
      },
      { path: '*', element: <Navigate to="/404" replace /> },
    ],
    location.state?.backgroundLocation ?? location,
  );
}

export default function Router() {
  const location = useLocation() as unknown as { state: { backgroundLocation: Location } };
  return (
    <>
      <AppRouter />
      {location.state?.backgroundLocation && <ModalRoute />}
      <RouteForward />
    </>
  );
}
