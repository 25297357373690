import { ReactNode } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import LoadingScreen from '../components/loading-screen';
import { useAuthContext } from './useAuthContext';
import { EmptyMerchantId, PATH_AFTER, getPathAfter } from '@/config';

type GuestGuardProps = {
  children: ReactNode;
};

export default function GuestGuard({ children }: GuestGuardProps) {
  const { isAuthenticated, isInitialized, isVerify, user, isNewUser } = useAuthContext();
  const { pathname } = useLocation();

  const isEmptyMerchant = EmptyMerchantId === user?.merchantId;

  if (isNewUser && pathname === '/register') {
    return <>{children}</>;
  }

  // This controls the jump after successful login
  if (isAuthenticated && isVerify) {
    if (isEmptyMerchant) {
      return <Navigate to={PATH_AFTER[100000]} />;
    }
    return (
      <Navigate to={getPathAfter(PATH_AFTER[(user?.role_id as keyof typeof PATH_AFTER) || -1])} />
    );
  }
  // Security verification must be done after login;
  // if (
  //   isAuthenticated &&
  //   !isVerify &&
  //   (Boolean(user?.security_email) || Boolean(user?.security_phone) || user?.exists_google_2fa) &&
  //   pathname === '/login'
  // ) {

  //   return <Navigate to="/verification" state={{ fromLogin: 1, type: 1 }} />;
  // }

  if (isAuthenticated && !isVerify && pathname === '/login') {
    return <>{children}</>;
  }

  if (!isInitialized) {
    return <LoadingScreen />;
  }

  return <>{children}</>;
}
