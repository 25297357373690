import { Http } from '@/api/http';
const http = new Http('/payment/v1');
export default class OrderApi {
  getTradeCollectList(data: {
    page: number;
    page_size: number;
  }): Promise<{ list: CollectListItem[]; page_size: number; total_size: number }> {
    return http.postJson('/trade/collect/list', data);
  }

  /**
    * getorder list data  ---new
    * page: page number int,
      page_size: page capacity int,
      begin_at: start time int,
      end_at: End Time int,
      types: transaction type string array,
      stats: transaction status string array,
      token: token string array,
      bil_type: order type int,
      bill_id: order string
    *
    */
  getTradeListInfo({
    page,
    page_size,
    begin_at,
    end_at,
    types,
    tokens,
    stats,
    swap_status,
    bill_type,
    bill_id,
    key,
    is_swap,
    coin_id,
  }: {
    page: number;
    page_size: number;
    begin_at?: number;
    end_at?: number;
    types?: string[];
    stats?: string[];
    swap_status?: string[];
    tokens?: string[];
    bill_type?: number;
    bill_id?: string;
    key?: number;
    is_swap?: boolean;
    coin_id?: number;
  }): Promise<OrderLsitType> {
    return http.postJson('/trade/list', {
      page,
      page_size,
      begin_at,
      end_at,
      types,
      tokens,
      stats,
      bill_type,
      // record_id: bill_id,
      value: bill_id,
      key,
      is_swap,
      swap_status,
      coin_id,
    });
  }

  getUserTradeListInfo({
    page,
    page_size,
    begin_at,
    end_at,
    types,
    tokens,
    status,
    swap_status,
    bill_type,
    bill_id,
    key,
    is_swap,
    coin_id,
  }: {
    page: number;
    page_size: number;
    begin_at?: number;
    end_at?: number;
    types?: string[];
    status?: string[];
    swap_status?: string[];
    tokens?: string[];
    bill_type?: number;
    bill_id?: string;
    key?: number;
    is_swap?: boolean;
    coin_id?: number;
  }): Promise<OrderLsitType> {
    return http.postJson('/user/trade/list', {
      page,
      page_size,
      begin_at,
      end_at,
      types,
      tokens,
      status,
      bill_type,
      // record_id: bill_id,
      value: bill_id,
      key,
      is_swap,
      swap_status,
      coin_id,
    });
  }

  /**
    * Transaction order export excel  ---new
    * page: page number int,
      page_size: page capacity int,
      begin_at: start time int,
      end_at: End Time int,
      types: transaction type string array,
      stats: transaction status string array,
      token: token string array,
      bil_type: order type int,
      bill_id: order string
    *
    */
  tradeExport(params: {
    /** yyyy/mm/dd */
    export_begin_at: string;
    /** yyyy/mm/dd */
    export_end_at: string;
    begin_at?: number;
    end_at?: number;
    types?: string[];
    stats?: string[];
    swap_status?: string[];
    tokens?: string[];
    bill_type?: number;
    bill_id?: string;
    record_id?: string;
    txid?: string;
    value?: string;
    platform?: string;
    em?: number;
    page?: number;
    pageSize?: number;
    key?: number;
    zone?: string;
    is_swap: boolean;
    coin_id?: number;
  }): Promise<{ path: string }> {
    return http.postJson('/trade/export', params);
  }

  userTradeExport(params: {
    /** yyyy/mm/dd */
    export_begin_at: string;
    /** yyyy/mm/dd */
    export_end_at: string;
    begin_at?: number;
    end_at?: number;
    types?: string[];
    stats?: string[];
    swap_status?: string[];
    tokens?: string[];
    bill_type?: number;
    bill_id?: string;
    record_id?: string;
    txid?: string;
    value?: string;
    platform?: string;
    em?: number;
    page?: number;
    pageSize?: number;
    key?: number;
    zone?: string;
    is_swap: boolean;
    coin_id?: number;
  }): Promise<{ path: string }> {
    return http.postJson('/merchant/user/trade/export', params);
  }

  /**
    * collect list  export excel  ---new
    * 
      begin_at: start time int,
      end_at: End Time int,
      zone:  string
    *
    */
  collectExport(params: {
    /** yyyy/mm/dd */
    export_begin_at: string;
    /** yyyy/mm/dd */
    export_end_at: string;
    begin_at?: number;
    end_at?: number;
    zone?: string;
  }): Promise<{ path: string }> {
    return http.postJson('/trade/collect/export', params);
  }

  /**
    * Transaction order details  ---new
      bill_id: order id string
    *
    */
  getTradeDetail(
    record_id: string,
    is_swap?: boolean,
  ): Promise<{ detail: orderDetailTypeOld; swap_detail: swapRes }> {
    return http.postJson('/trade/detail', { record_id, is_swap: is_swap || false });
  }

  /**
   * Recharge result query  ---new
   * @param {string} address
   * @param {number} generate_time
   * @returns checkDepositListType
   */
  checkDeposit(data: { address: string; generate_time: number }): Promise<checkDepositListType_> {
    return http.postJson('/trade/deposit/check', data);
  }

  /**
   * Recharge result block height query  ----new
   * @param {string} chain
   * @param {string} address
   * @returns queryBlockHeightRes
   */
  queryBlockHeight(chain: string, address: string, symbol: string): Promise<queryBlockHeightRes> {
    return http.postJson('/trade/block/height', { chain, address, symbol });
  }

  /**
   * get network list ---new
   */
  queryNetwork(): Promise<{ network: queryNetworkType[] }> {
    return http.postJson('/trade/network/list', {});
  }

  /**
   * get Permanent Address Management list ---new
   */
  queryAddressList(params: addressParamsType): Promise<adressListRes> {
    return http.postJson('/trade/address/direct/list', { ...params });
  }

  // -------   new
  getTradeSelectInfo(): Promise<selectAndFilterType> {
    return http.postJson('/trade/cons', {});
  }

  // -------getUserAsset
  getUserAsset(): Promise<{ usd_value: string }> {
    return http.postJson('/merchant/user/asset', {});
  }

  // -------getTodayDws
  getTodayDws(
    timestamp: number,
  ): Promise<{ deposit_amount: string; withdraw_amount: string; swap_amount: string }> {
    return http.postJson('/merchant/user/today-dws', { timestamp });
  }
}

export type Collect = {
  coin_id: number;
  symbol: string;
  amount: string;
  logo: string;
};

export type NetworkFee = {
  coin_id: number;
  symbol: string;
  amount: string;
  logo: string;
};

export type PaymentItem = {
  coin_id: number;
  symbol: string;
  amount: string;
  logo: string;
};

export type CollectListItem = {
  collect: Collect;
  network_name: string;
  address: string;
  network_fee: NetworkFee;
  payments: PaymentItem[];
  collect_time: string;
  status: number;
};

export type radeSelectInfoParamsType = {
  record_id: string;
  merchant_id: string;
  type: string;
  action: string;
  bill_id: string;
};

export type addressParamsType = {
  key?: string;
  network?: string;
  page: number;
  page_size: number;
};
export interface adressListRes {
  list: {
    user_id: string;
    network: string;
    chain_id: string;
    address: string;
    memo: string;
    created: number;
    received_times: number;
    [key: string]: any;
  }[];
  address_total: number;
  remain_address_total: number;
  received_total: number;
  pagination: {
    page_num: number;
    page_size: number;
    total: number;
  };
}

export type queryNetworkType = {
  chain_id: string;
  network: string;
};
export interface queryBlockHeightRes {
  chain: string;
  symbol: string;
  paid_amount: string;
  current_chain_height: number;
  tx_confirm_block_num: number;
  block_height: number;
}

export type checkDepositListType = {
  record_id: string;
  bill_id: string;
  type: string;
  action: string;
  amount: string;
  chain_id: string;
  contract: string;
  symbol: string;
  status: string;
  em: number;
  txid?: string;
  txid_url?: string;
  invoice: string;
};
export type checkDepositListType_ = {
  list: checkDepositListType[];
};

export type selectAndFilterType = {
  bill_type: {
    label: string;
    value?: number;
  }[];
  stats: string[];
  tokens: string[];
  types: string[];
};

export type swapItem = {
  from_amount: string;
  from_coin_id: number;
  record_id: string;
  status: string;
  to_amount: string;
  to_coin_id: number;
  type: string;
  updated_at: number;
};

export type OrderLsitType = {
  list?: {
    id: number;
    merchant_id: number;
    merchant_name: string;
    /** trading ID*/
    bill_id: string;
    symbol: string;
    /**Chain ID*/
    chain_id: string;
    contract: string;
    logo: string;
    amount: string;
    em: number;
    created: number;
    updated: number;
    record_id: string;
    type: string;
    /**
     * Status: Pending pending payment;
     * Processing has detected user payment and is waiting for transaction;
     * Expired expired;
     * Success The transaction is successful;
     * Failed Transaction failed (including payment amount < order amount). Expires are not included here
     * */
    status:
      | 'Pending'
      | 'Processing'
      | 'Expired'
      | 'Successful'
      | 'Failed'
      | 'Overpaid'
      | 'Underpaid'
      | 'Refund';
    pay_time: number;
    token_price: string;
    from_address: string;
    to_address: string;
    from_memo: string;
    refund_bill_id: string;
    deposit_bill_id: string;
    uuid: string;
    action: string;
    network: string;
    origin_amount: string;
    from_cwallet_id: string;
    to_cwallet_id: string;
    network_fee: string;
    remark: string;
    to_memo: string;
    service_fee: string;
    rate: string;
    is_inner_tx: boolean;
    bill_create_time: number;
    txid_url: string;
    txid_logo: string;
    chain_logo: string;
    token_id: string;
    merchant_order_id: string;
    network_name: string;
    origin_symbol: string;
    invoice: string;
    block_height: number;
    merchant_pays_fee: boolean;
    system_filter_token: boolean;
    address_number: number;
    collected_fees?: collectedFeesType[];
  }[];
  swap_records?: swapItem[];
  total: number;
  total_value: string;
  total_count?: number;
  /** 0 无成本 1 归集 2 授权 3 归集/授权 为了提高手续费来隐藏归集列表用的 大于0表示可以显示 */
  cost_control: number;
};

export type orderDetailType = {
  detail: {
    bill_id?: string;
    action?: string;
    amount?: string;
    chain_id?: string;
    contract?: string;
    created?: number;
    from_address?: string;
    from_cwallet_id?: string;
    logo?: string;
    origin_amount?: string;
    pay_time?: number;
    record_id?: string;
    refund_mark?: string;
    refund_records?: null;
    remark?: string;
    symbol?: string;
    to_address?: string;
    to_cwallet_id?: string;
    /**Operation type
     * swap currency exchange
     * API/Email Billing/Checkout Button/Checkout URL to place an order
     * Refund Refund
     * Deposit recharge
     * Withdraw Withdrawal
     */
    type?:
      | 'Withdrawal'
      | 'Swap'
      | 'Refund'
      | 'Deposit'
      | 'API'
      | 'Email Billing'
      | 'Checkout Button'
      | 'Checkout URL';
    rate?: string;
    swap_from_amount?: string;
    swap_from_chain_id?: string;
    swap_from_contract?: string;
    swap_from_symbol?: string;
    swap_to_amount?: string;
    swap_to_chain_id?: string;
    swap_to_contract?: string;
    swap_to_symbol?: string;
    detail?: {
      bill_id?: string;
      type?:
        | 'Withdrawl'
        | 'Swap'
        | 'Refund'
        | 'Deposit'
        | 'API'
        | 'Email Billing'
        | 'Checkout Button'
        | 'Checkout URL';
      action?: string;
      refund_amount?: string;
      to_address?: string;
      to_cwallet_id?: string;
      is_inner_tx?: string;
      txid?: string;
      chain_id?: string;
      contract?: string;
      symbol?: string;
      logo?: string;
      created?: number;
      pay_time?: number;
      paid_amount?: string;
      origin_amount?: string;
      from_address?: string;
      from_cwallet_id?: string;
      refund_status?: string;
      can_refund?: boolean;
      [key: string]: any;
    };
    from_record?: {
      bill_id?: string;
      amount?: string;
      origin_amount?: string;
      from_address?: string;
      from_cwallet_id?: string;
      em?: number;
      chain_id?: string;
      contract?: string;
      symbol?: string;
      logo?: string;
      created?: number;
      pay_time?: number;
      [key: string]: any;
    };
    refund_record?: {
      amount?: string;
      to_address?: string;
      created?: number;
      pay_time?: number;
      status?: string;
      em?: number;
      to_cwallet_id?: string;
      chain_id?: string;
      contract?: string;
      symbol?: string;
      bill_id?: string;
      logo?: string;
      [key: string]: any;
    }[];
    extra_trade_record?: {
      chain_id?: string;
      contract?: string;
      symbol?: string;
      amount?: string;
      from_address?: string;
      is_inner_tx?: number;
      txid?: string;
      service_fee?: string;
      logo?: string;
      created?: number;
      [key: string]: any;
    }[];
    [key: string]: any;
  };
};
export type collectedFeesType = {
  value: string;
  symbol: string;
};
export type swapRes = {
  bill_id: string;
  created: number;
  pay_time: number;
  rate: string;
  record_id: string;
  status?:
    | 'Pending'
    | 'Processing'
    | 'Expired'
    | 'Successful'
    | 'Failed'
    | 'Overpaid'
    | 'Underpaid'
    | 'Refund';
  swap_to_amount: string;
  swap_to_logo: string;
  swap_to_symbol: string;
  type: string;
};

export type orderDetailTypeOld = {
  id?: number;
  merchant_id?: number;
  merchant_name?: string;
  /**order id*/
  bill_id?: string;
  /**token*/
  symbol?: string;
  /**chain ID*/
  chain_id?: string;
  contract?: string;
  logo?: string;
  /**Actual transaction amount*/
  amount?: string;
  /**Direction of capital flow, 1 for incoming, 2 for outgoing*/
  em?: number;
  /**On-chain transaction id*/
  txid?: string;
  created?: number;
  updated?: number;
  from_address?: string;
  to_address?: string;
  from_memo?: string;
  /**When canceling an order, the original order id*/
  refund_bill_id?: string;
  deposit_bill_id?: string;
  uuid?: string;
  /**total transaction record id*/
  record_id?: string;
  /**Operation type
   * swap currency exchange
   * API/Email Billing/Checkout Button/Checkout URL to place an order
   * Refund Refund
   * Deposit recharge
   * Withdraw Withdrawal
   */
  type: string;
  /**Refund*/
  action?: string;
  network?: string;
  /**The amount of the order placed, the amount of the refund application*/
  origin_amount?: string;
  /**source wallet - internal, such as payment*/
  from_cwallet_id?: string;
  /**Into wallet - internal, such as refund*/
  to_cwallet_id?: string;
  /**Transaction time milliseconds ms*/
  pay_time?: number;
  /**Order expiration time in milliseconds ms*/
  expired_timestamp?: number;
  /**
   * Status: Pending pending payment;
   * Processing has detected user payment and is waiting for transaction;
   * Expired expired;
   * Success The transaction is successful;
   * Failed Transaction failed (including payment amount < order amount). Expires are not included here
   * */
  status?:
    | 'Pending'
    | 'Processing'
    | 'Expired'
    | 'Successful'
    | 'Failed'
    | 'Overpaid'
    | 'Underpaid'
    | 'Refund';
  /** Network Fee*/
  network_fee?: string;
  network_coin_id?: string;
  network_fee_crypto?: string;
  network_fee_value?: string;
  network_coin_logo?: string;
  /**Fiat currency at the time of payment*/
  fiat_name?: string;
  /**When paying, 1 US dollar =? French currency*/
  fiat_rate?: string;
  remark?: string;
  to_memo?: string;
  /**The current unit price of the token is usd*/
  token_price?: string;
  service_fee?: string;
  /**exchange rate*/
  rate?: string;
  /**Amount in fiat currency when placing an order*/
  fiat_amount?: string;
  /**Platform service fee record id*/
  service_fee_record_id?: string;
  invoice?: string;
  is_inner_tx?: boolean;
  bill_create_time?: number;
  txid_url?: string;
  txid_logo?: string;
  chain_logo?: string;
  token_id?: string;
  merchant_order_id?: string;
  network_name?: string;
  origin_symbol?: string;
  block_height?: number;
  /** merchant true   user false */
  merchant_pays_fee?: boolean;
  system_filter_token?: boolean;
  address_number?: number;
  collected_fees?: collectedFeesType[];
  /** 充值自动换币手续费 */
  deposit_auto_swap_fees?: string;
  /** 自动提现手续费 */
  auto_withdraw_fees?: string;
  auto_swap_to?: {
    to_quantity: string;
    symbol: string;
    exchange_rate: string;
    logo: string;
  };
  user_id?: string;
  up_business_id?: string;
  coin_id?: number;
  webhook_send_status?: number;
  network_cc_coin_id?: string;
  [key: string]: any;
};
