import { createContext, useEffect, useReducer, useCallback, useState } from 'react';
//
import {
  ActionMapType,
  AuthStateType,
  AuthUserType,
  InItUserInfoParam,
  JWTContextType,
  // tempRoleMenus,
} from './types';
import { accountApi_ } from '@/api/index';
import { accountStore, walletStroe } from '@/stores';
import useLocalStorage from '@/hooks/useLocalStorage';
import intercom, { closeIntercom } from '@/utils/support';
import { HttpResponseError } from '@/api/http';
import { getSafetyMode, sleep } from '@/utils/until';

import { RegisterParam, RoleMenuList, loginVerifyParam } from '@/api/accountApi_';
import { RouteObject, useNavigate } from 'react-router';

import { EmptyMerchantId, PATH_AFTER, currentEnv, getPathAfter } from '@/config';

import { cloneDeep } from 'lodash';
import {
  BaseRouteList,
  RoleListItemType,
  flattenMenu,
  handleRoute,
  routeFilter,
} from './AuthRoute';
import { decrypt, encrypt } from '@/utils/pcrypto';

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
  CHECKEMAIL = 'CHECKEMAIL',
  WALLETINITIAL = 'WALLETINITIAL',
  WALLETLOGIN = 'WALLETLOGIN',
  WALLETREGISTER = 'WALLETREGISTER',
  WALLETLOGOUT = 'WALLETLOGOUT',
}

export enum SafetyMode {
  'none' = 0,
  email = 1,
  google = 2,
  eg = 3,
  phone = 4,
  ep = 5,
  gp = 6,
  egp = 7,
}

type Payload = {
  [Types.INITIAL]: {
    isAuthenticated: boolean;
    isVerify: boolean;
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;

  [Types.CHECKEMAIL]: {
    user: any;
    isNewUser: boolean;
  };

  // WALLET API
  [Types.WALLETINITIAL]: {
    isAuthenticated: boolean;
    isVerify: boolean;
    user: any;
  };
  [Types.WALLETLOGIN]: {
    user: any;
  };
  [Types.WALLETREGISTER]: {
    user: any;
  };
  [Types.WALLETLOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

const initialState: AuthStateType = {
  isInitialized: false,
  isAuthenticated: false,
  isVerify: false,
  isNewUser: false,
  user: {
    role_id: -1,
    create_time: 0,
    /** 用户邮箱 **/
    email: '',
    /** 是否开启google 2fa **/
    exists_google_2fa: false,
    /** 是否锁定登录 **/
    exists_lock_pass: false,
    /** 是否开启密码登录 **/
    exists_login_pass: true,
    /** 是否设置支付密码 **/
    exists_pay_pass: false,
    /** google 2fa的密钥 **/
    gg_verify_secret_key: '',
    /** google 2fa的二维码 **/
    gg_verify_secret_qr: '',
    /** 用户头像 **/
    head_url: '',
    id: 0,
    /** 14天无理由， fasle 需要 true 不需要 **/
    is_check: true,
    /** 密码锁定的次数 **/
    lock_try: 0,
    /** 无操作锁定时间 2-10min 3-30min 4-1hour 5-24hour **/
    lock_unit: 2,
    nickname: '',
    /** 用户电话 **/
    phone: '',
    phone_area: '',
    user_id: '',
    wwl_status: 0,
    security_email: 0,
    security_phone: 0,
    security_withdraw: 0,
    mode_type: 1,
    getAddressNum: 0,
    packageLimit: 0,
    emailNotify: 0,
    /** 商户是否开启了2fa验证 */
    safety2faStatus: 1,
    webhookNotifyMode: 1,
    ipWhitelist: '',
    webhookUrl: '',
    /** 账号的状态 */
    status: 1,
    isMaster: 0,
    /** 商户的的状态 */
    merchantStatus: 0,
    safetyMode: 0,
    merchantId: '',
    dispalyId: '',
    title: '',
    website_hash: '',
    company_website: '',
    company_address: '',
    twitter_url: '',
    telegram_url: '',
    company_industry: '',
    company_industry_text: '',
    main_functions: '',
    main_functions_text: '',
    is_pay_passwd: false,
    is_security: false,
    test_coin: -1,
    robot_txt: '',
    member_num: 0,
    username: '',
    version: 1,
    auto_add_coin: -1,
    withdraw_approve: -1,
    withdraw_approve_setting: {
      limit_types: [],
      limit_qty: '0',
      ttl: 1,
      safety_mode: 0,
    },
    is_newbie: '-1',
    lpw_status: -1,
    login_ip_whitelist: '',
    wiw_status: -1,
    withdraw_ip_whitelist: '',
    oiw_status: -1,
    owner_ip_whitelist: '',
  },
};

const setMerchantIdObj = (user: AuthUserType) => {
  if (user?.mode_type === 1) {
    if (user?.main_merchant_id && user?.merchant_id) {
      const obj = {
        walletMerchantId: user?.main_merchant_id,
        ccpMerchantId: user?.merchant_id,
      };
      localStorage.setItem('MerchantIdObj', JSON.stringify(obj));
    }
  }
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    setMerchantIdObj(action.payload.user);
    return {
      isInitialized: true,
      isNewUser: false,
      isVerify: action.payload.isVerify,
      isAuthenticated: action.payload.isAuthenticated,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    setMerchantIdObj(action.payload.user);
    return {
      ...state,
      isAuthenticated: true,
      isVerify: false,
      isNewUser: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    setMerchantIdObj(action.payload.user);
    return {
      ...state,
      isAuthenticated: true,
      isVerify: true,
      isNewUser: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      isInitialized: true,
      isAuthenticated: false,
      isVerify: false,
      isNewUser: false,
      user: null,
    };
  }
  if (action.type === Types.CHECKEMAIL) {
    return {
      ...state,
      isNewUser: action.payload.isNewUser,
      isInitialized: true,
      user: action.payload.user,
    };
  }

  return state;
};

export const AuthContext = createContext<JWTContextType | null>(null);

type AuthProviderProps = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: AuthProviderProps) {
  const [showBanlance, setShowBanlance] = useLocalStorage('hideBlance', false);
  const [IsLogin, setIsLogin] = useLocalStorage('isLogin', false);
  const [IsVerify, setIsVerify] = useLocalStorage('isVerify', false);
  // 模式 1 ccp 2 wallet
  const [chooseApi, setChooseApi] = useLocalStorage('chooseApi', 1);
  const [captchaData, setCaptchaData] = useState<{ CaptchaKey?: string; CaptchaCode?: string }>({
    CaptchaKey: '',
    CaptchaCode: '',
  });
  const [captchaDialogOpen, setCaptchaDialogOpen] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
  const navigate = useNavigate();

  const [showAnnouncement, setShowAnnouncement] = useState(false);

  //------------- route start
  const [routes, setRoutes] = useState<RouteObject[]>(BaseRouteList as RouteObject[]);
  const [roleList, setRoleList] = useState<RoleListItemType[]>([]);
  const [menuIds, setMenuIds] = useState<string[]>([]);

  const handleRouter = async (role_id: number, version: number) => {
    try {
      let res = await accountApi_.roleMenuList();

      if (res?.menus) {
        const currentTime = Date.now();
        const result =
          currentEnv === 'prd'
            ? encrypt(JSON.stringify(cloneDeep(res.menus)), currentTime)
            : cloneDeep(res.menus);
        localStorage.setItem(
          'menuResult',
          JSON.stringify({
            result,
            currentTime,
          }),
        );
      } else {
        const temp = localStorage.getItem('menuResult')
          ? JSON.parse(localStorage.getItem('menuResult') as string)
          : null;
        if (temp) {
          const result =
            currentEnv === 'prd' ? JSON.parse(decrypt(temp.result, temp.currentTime)) : temp.result;
          res = {
            menus: result,
          };
        }
      }
      // 临时固定数据
      // const res = cloneDeep(tempRoleMenus);
      handleRoutes(res, version, role_id);
      // setRoutes(handleRoute(arr, routes) as RouteObject[]);
    } catch (err) {
      console.log('err', err);

      const temp = localStorage.getItem('menuResult')
        ? JSON.parse(localStorage.getItem('menuResult') as string)
        : null;
      if (temp) {
        const result =
          currentEnv === 'prd' ? JSON.parse(decrypt(temp.result, temp.currentTime)) : temp.result;
        handleRoutes(
          {
            menus: result,
          },
          version,
          role_id,
        );
      }
    }
  };

  const handleRoutes = (
    res: {
      menus: RoleMenuList;
    },
    version: number,
    role_id: number,
  ) => {
    const arr: RoleListItemType[] = [];
    Object.keys(res.menus).forEach((item) => {
      const obj: RoleListItemType = {
        roleId: 0,
        name: '',
        pageIds: [],
      };
      obj.roleId = res.menus[item].role.id;
      obj.name = res.menus[item].role.name;
      obj.pageIds = flattenMenu(res.menus[item]?.list || []).map((item) => item.mid);
      if (role_id === res.menus[item].role.id) {
        setMenuIds(cloneDeep(obj.pageIds));
      }
      arr.push(obj);
    });

    setRoleList(arr);

    const newRoutes = routeFilter(handleRoute(arr, BaseRouteList) as RouteObject[], role_id || 1);
    setRoutes(
      version !== 1
        ? [...(newRoutes as RouteObject[])]
        : [...(newRoutes as RouteObject[])].filter((item) => item.path !== '/user-list'),
    );
  };

  const hasPermission = (mid: string) => menuIds.includes(mid);

  //------------- route end

  const logout_ = async () => {
    setIsLogin(false);
    setIsVerify(false);
    localStorage.removeItem('Authorization');
    localStorage.setItem('isout', JSON.stringify(true));
    localStorage.removeItem('isFirstInv');
    sessionStorage.setItem('directList', JSON.stringify([]));
    dispatch({
      type: Types.LOGOUT,
    });
  };

  const initialize = useCallback(async () => {
    setChooseApi(1);
    let manageEmail = localStorage.getItem('manageEmail')
      ? JSON.parse(localStorage.getItem('manageEmail') as string)
      : '';
    let invitedEmail = localStorage.getItem('invitedEmail')
      ? JSON.parse(localStorage.getItem('invitedEmail') as string)
      : '';
    let infoEmail = localStorage.getItem('info')
      ? JSON.parse(localStorage.getItem('info') as string)
      : '';
    try {
      //邀请链接跳入 判断是否是新账号需要注册 并且不是当前登录的邮箱 走登录或者注册逻辑  未注册的账号会走catch
      if (invitedEmail && invitedEmail !== infoEmail) {
        await accountApi_.checkEmail(invitedEmail);
        localStorage.setItem('newEmail', JSON.stringify(false));
        dispatch({
          type: Types.CHECKEMAIL,
          payload: {
            ...state,
            isNewUser: false,
            user: { ...initialState.user, mode_type: 1 } as AuthUserType,
          },
        });
        return;
      }

      if (IsLogin && IsVerify) {
        //管理链接跳入 已登录 判断是否需要跳转管理页面
        if (manageEmail && manageEmail === infoEmail) {
          navigate('/merchatsetting/menu/member/index');
          localStorage.removeItem('manageEmail');
        }
        if (manageEmail && manageEmail !== infoEmail) {
          dispatch({
            type: Types.LOGOUT,
          });
          return;
        }
        await updUserInfoToStore({ isNeedExtra: true, refreshRouter: true });
        await walletStroe.asyncWalletInfo();
      } else {
        logout_();
      }
    } catch (error) {
      if (error.message.includes('Account not found')) {
        dispatch({
          type: Types.CHECKEMAIL,
          payload: {
            ...state,
            isNewUser: true,
            user: { ...initialState.user, mode_type: 1 } as AuthUserType,
          },
        });
        localStorage.setItem('newEmail', JSON.stringify(true));
      } else {
        logout_();
      }
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    initialize();
    // eslint-disable-next-line
  }, [initialize]);

  // ccp

  const initUserInfo = async ({ isNeedExtra = true, refreshRouter = false }: InItUserInfoParam) => {
    try {
      const { detail: res } = await accountApi_.getUserInfo();
      let extraData = {
        website_hash: '',
        company_website: '',
        company_address: '',
        twitter_url: '',
        telegram_url: '',
        company_industry: '',
        company_industry_text: '',
        main_functions: '',
        main_functions_text: '',
        robot_txt: '',
        is_newbie: '-1',
      };
      if (isNeedExtra) {
        extraData = await accountApi_.getMerchantExtra();
      } else {
        extraData = {
          website_hash: state.user?.website_hash || '',
          company_website: state.user?.company_website || '',
          company_address: state.user?.company_address || '',
          twitter_url: state.user?.twitter_url || '',
          telegram_url: state.user?.telegram_url || '',
          company_industry: state.user?.company_industry || '',
          company_industry_text: state.user?.company_industry_text || '',
          main_functions: state.user?.main_functions || '',
          main_functions_text: state.user?.main_functions_text || '',
          robot_txt: '',
          is_newbie: state.user?.is_newbie || '-1',
        };
      }
      if (refreshRouter) {
        await handleRouter(res.role_id, res.version);
      }

      const safetyObj = getSafetyMode(res.safety_2fa);
      localStorage.setItem('info', JSON.stringify(res?.email || ''));

      // customer service
      intercom({
        email: res?.email,
        name: res?.title,
        user_id: res?.email,
        user_hash: '',
        showSupport: true,
        background_color: '#c6d7ff',
        action_color: '#3c6ff5',
      });
      // 使用商户id初始化
      // await walletStroe.initSnowflake(Number.isNaN(res.merchant_id) ? Number(res.merchant_id) : 1);

      const pu: any = {
        ...initialState.user,
        email: res?.email,
        /** 是否开启google 2fa **/
        exists_google_2fa: safetyObj?.google || false,
        /** google 2fa的密钥 **/
        gg_verify_secret_key: res?.google_key || '',
        /** 用户头像 **/
        head_url: res?.logo || '',
        /** 无操作锁定时间 2-10min 3-30min 4-1hour 5-24hour **/
        lock_unit: res?.lock_unit || 0,
        nickname: res?.title || '',
        /** 用户电话 **/
        phone: res?.phone || '',
        phone_area: res?.phone_area || '',
        wwl_status: res?.wwl_status || -1,
        security_email: safetyObj.email ? 1 : 0,
        security_phone: safetyObj.pbone ? 1 : 0,
        security_withdraw: res?.micro_withdraw === 1 ? 1 : 0,
        safetyMode: res?.safety_2fa | 0,
        mode_type: 1,
        getAddressNum: res?.get_address_num || 0,
        packageLimit: res?.package_limit || 0,
        emailNotify: res?.email_notify || -1,
        /** 商户是否开启了2fa验证 */
        safety2faStatus: res?.safety_2fa_status || 1,
        webhookNotifyMode: res?.webhook_notify_mode || -1,
        ipWhitelist: res?.ip_whitelist || '',
        webhookUrl: res?.webhook_url || '',
        /** 账号的状态 */
        status: res?.status || 1,
        isMaster: res?.is_master || 0,
        /** 商户的的状态 */
        merchantStatus: res?.merchant_status || 1,
        merchantId: res?.merchant_id || '',
        dispalyId: res?.display_id || '',
        title: res.title,
        website_hash: extraData?.website_hash || '',
        company_website: extraData?.company_website || '',
        company_address: extraData?.company_address || '',
        twitter_url: extraData?.twitter_url || '',
        telegram_url: extraData?.telegram_url || '',
        company_industry: extraData?.company_industry || '',
        company_industry_text: extraData?.company_industry_text || '',
        main_functions: extraData?.main_functions || '',
        main_functions_text: extraData?.main_functions_text || '',
        is_pay_passwd: res.is_pay_passwd || false,
        is_security: res.is_security || false,
        auto_swap: res?.auto_swap || -1,
        test_coin: res?.test_coin || -1,
        robot_txt: extraData?.robot_txt || '',
        member_num: res?.member_num || 0,
        username: res?.username || '',
        role_id: res?.role_id || -1,
        version: res?.version || 1,
        app_id: res?.app_id || '',
        auto_add_coin: res?.auto_add_coin || -1,
        withdraw_approve: res?.withdraw_approve || -1,
        withdraw_approve_setting: res?.withdraw_approve_setting || {
          limit_types: [],
          limit_qty: '0',
          ttl: 1,
          safety_mode: 0,
        },
        is_newbie: extraData?.is_newbie || -1,
        lpw_status: res?.lpw_status || -1,
        login_ip_whitelist: res.login_ip_whitelist || '',
        wiw_status: res?.wiw_status || -1,
        withdraw_ip_whitelist: res?.withdraw_ip_whitelist || '',
        oiw_status: res?.oiw_status || -1,
        owner_ip_whitelist: res?.owner_ip_whitelist || '',
      };
      accountStore.updateUser(pu as AuthUserType, true, true);
      setIsLogin(true);
      setIsVerify(true);
      localStorage.setItem('isFirstInv', '1');
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: true,
          isVerify: true,
          user: pu as AuthUserType,
        },
      });

      return pu;
    } catch (error) {
      if (error.message.includes(`Unexpected token 'e'`)) {
        localStorage.removeItem('Authorization');
        localStorage.removeItem('isInvite');
        localStorage.removeItem('isAd');
        localStorage.removeItem('invitedEmail');
        localStorage.removeItem('isFirstInv');
        sessionStorage.setItem('directList', JSON.stringify([]));
        setIsLogin(false);
        setIsVerify(false);
        dispatch({
          type: Types.LOGOUT,
        });
        if ((window as any)?.Intercom) {
          closeIntercom();
        }
      } else {
        await sleep(3000);
        initUserInfo({ isNeedExtra, refreshRouter });
      }

      console.log({ error });
    }
  };

  //login
  const login = async (email: string, passwd: string, setAuthLoading: (v: boolean) => void) =>
    accountApi_
      .login({ email, passwd })
      .then(async (res) => {
        localStorage.setItem('info', JSON.stringify(res?.email || ''));

        const safetyObj = getSafetyMode(res.safety_2fa);

        const obj = {
          exists_google_2fa: safetyObj?.google,
          security_email: safetyObj?.email ? 1 : 0,
          security_phone: safetyObj?.pbone ? 1 : 0,
          email: res.email,
          phone: res?.phone || '',
          phone_area: res?.phone_area || '',
          safetyMode: res.safety_2fa,
        };
        // 如果不需要验证 查询info
        if (!safetyObj?.email && !safetyObj?.pbone && !safetyObj.google) {
          setAuthLoading(true);
          const tempRes = await updUserInfoToStore({ isNeedExtra: true, refreshRouter: true });
          setAuthLoading(false);
          localStorage.setItem('verifyType', '1');

          setIsVerify(true);
          setIsLogin(true);
          localStorage.setItem('isFirstInv', '1');
          const isEmptyMerchant = EmptyMerchantId === tempRes?.merchant_id;

          navigate(
            `${getPathAfter(PATH_AFTER[!isEmptyMerchant ? tempRes?.role_id || -1 : 100000])}`,
            {
              state: {},
              replace: true,
            },
          );
        } else {
          dispatch({
            type: Types.LOGIN,
            payload: {
              user: { ...initialState.user, ...obj, mode_type: 1 } as AuthUserType,
            },
          });
          setIsLogin(true);
          localStorage.setItem('isFirstInv', '1');
        }
        return { ...obj, mode_type: 1 } as AuthUserType;
      })
      .catch((error) => {
        throw new HttpResponseError(error?.message, error?.code, error?.response);
      });

  // REGISTER
  const register = async (params: RegisterParam, setAuthLoading: (v: boolean) => void) => {
    const res = await accountApi_.register({
      ...params,
      captcha_code: captchaData.CaptchaCode,
      captcha_key: captchaData.CaptchaKey,
    });
    // 设置google安全设置提示flag
    localStorage.setItem('showGoogleAuth', JSON.stringify(true));
    setAuthLoading(true);
    if (res) {
      await updUserInfoToStore({ isNeedExtra: true, refreshRouter: true });
      setAuthLoading(false);
      await walletStroe.asyncWalletInfo();
      return res;
    }
  };

  // 登录退出并清除cookie
  const logout = async () => {
    try {
      await accountApi_.loginOut();
      localStorage.removeItem('Authorization');
      localStorage.removeItem('isInvite');
      localStorage.removeItem('isAd');
      localStorage.removeItem('invitedEmail');
      localStorage.removeItem('isFirstInv');
      sessionStorage.setItem('directList', JSON.stringify([]));
      setIsLogin(false);
      setIsVerify(false);
      dispatch({
        type: Types.LOGOUT,
      });
      if ((window as any)?.Intercom) {
        closeIntercom();
      }
    } catch (error) {
      localStorage.removeItem('Authorization');
      localStorage.removeItem('isInvite');
      localStorage.removeItem('isAd');
      localStorage.removeItem('invitedEmail');
      localStorage.removeItem('isFirstInv');
      sessionStorage.setItem('directList', JSON.stringify([]));
      setIsLogin(false);
      setIsVerify(false);
      if ((window as any)?.Intercom) {
        closeIntercom();
      }

      dispatch({
        type: Types.LOGOUT,
      });
      throw new Error(error);
    }
  };

  // 登录安全验证
  const loginVerify = async (data: loginVerifyParam) =>
    accountApi_.loginVerify({
      ...data,
      captcha_code: captchaData.CaptchaCode,
      captcha_key: captchaData.CaptchaKey,
    });

  // 更新用户信息
  const updUserInfo = useCallback(
    async (isAuthenticated: boolean, isVerify: boolean, user: AuthUserType) => {
      accountStore.updateUser(user, isAuthenticated, isVerify);
      setIsLogin(isAuthenticated);
      setIsVerify(isVerify);
      if (!isAuthenticated && !isVerify) {
        localStorage.removeItem('Authorization');
        localStorage.removeItem('isInvite');
        localStorage.removeItem('isAd');
      }
      dispatch({
        type: Types.INITIAL,
        payload: {
          isAuthenticated: isAuthenticated,
          isVerify: isVerify,
          user: user ? { ...user, mode_type: 1 } : ({ ...state.user } as AuthUserType),
        },
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    // eslint-disable-next-line
    [],
  );

  const updUserInfoToStore = async ({
    isNeedExtra = true,
    refreshRouter = false,
  }: InItUserInfoParam) => initUserInfo({ isNeedExtra, refreshRouter });

  const changeCaptchaData = (data: { CaptchaKey?: string; CaptchaCode?: string }) => {
    setCaptchaData(data);
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        menuIds,
        routes,
        roleList,
        method: 'jwt',
        hasPermission,
        login,
        loginWithGoogle: () => {},
        loginWithGithub: () => {},
        loginWithTwitter: () => {},
        logout,
        loginVerify,
        register,
        updUserInfo,
        updUserInfoToStore,
        chooseApi,
        setChooseApi,
        captchaData,
        changeCaptchaData,
        captchaDialogOpen,
        setCaptchaDialogOpen,
        showBanlance,
        setShowBanlance,
        showAnnouncement,
        setShowAnnouncement,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
