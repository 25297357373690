import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { TextFieldElement, FormContainer, useForm } from 'react-hook-form-mui';
import GetPhoneCode, { phoneCodeTimeType } from '@/components/get-code/phone';
import SecurityPart, { VerifyPart } from '../security-form';
import { useContext, useEffect } from 'react';
import { SettingsContext } from '../context';
import { MyIcon } from '@/assets/icons/MyIcon';
import { LoadingButton } from '@mui/lab';
import { useLocales } from '@/locales';
import { observer } from 'mobx-react';
import { UIState } from '../workflow';
import { useAuthContext } from '@/auth/useAuthContext';
import { PHONERULE } from '@/config';
import CommonDialog from '../components/CommonDialog';
import { PhoneSendTypeEnum } from '@/api/security_';

export default observer(DialogPhoneChangeForm);

function DialogPhoneChangeForm({
  visible,
  onClose,
}: {
  visible: boolean;
  onClose: (payload: any) => void;
}) {
  const { translate: t } = useLocales();

  const formContext = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const settings = useContext(SettingsContext);
  const { user } = useAuthContext();

  const onConfirm = () => {
    const dom: HTMLInputElement = document.querySelector('#DialogPhoneChangeForm-Submit')!;
    dom.click();
  };

  const onOK = async (data: any) => {
    onClose(data);
  };

  const nextPhone = formContext.watch('phone');

  useEffect(
    () => {
      formContext.reset();
      if (sessionStorage.getItem('phoneCodeTime')) {
        let phoneCodeTimeArr: phoneCodeTimeType[] = JSON.parse(
          sessionStorage.getItem('phoneCodeTime') as string,
        );
        const temp = phoneCodeTimeArr.find((item) => item.type === 'new' && item.value > 0);
        if (temp) {
          const { phone } = temp;
          formContext.setValue('phone', phone);
        }
      }
    },
    // eslint-disable-next-line
    [visible],
  );

  return (
    <CommonDialog open={visible} onClose={() => onClose(null)}>
      <DialogTitle id="scroll-dialog-title">
        <Box className="flex justify-between items-center">
          <Typography variant="h5" component="span">
            {settings?.phone
              ? t('merchantSettings.security_phone_dialog_title2')
              : t('merchantSettings.security_phone_dialog_title7')}
          </Typography>
          <IconButton onClick={() => onClose(false)}>
            <MyIcon name="close" size={14} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ pt: 1 }}>
        <FormContainer formContext={formContext} handleSubmit={formContext.handleSubmit(onOK)}>
          <TextFieldElement
            name="phone"
            label={
              user?.phone
                ? t('merchantSettings.security_phone_dialog_phone_number_label2')
                : t('merchantSettings.security_phone_dialog_phone_number_label')
            }
            fullWidth
            autoComplete={'off'}
            sx={{ mb: 2, '.MuiInputLabel-asterisk': { display: 'none' } }}
            InputProps={{
              startAdornment: <Typography variant="body2">+</Typography>,
              onBlur: async () => {
                const pp: string = await formContext.watch('phone');
                const pp2 = pp ? pp?.replace(' ', '-').replace(/\u202C/g, '') : '';
                formContext.setValue('phone', pp2);

                if (!PHONERULE.test(pp2)) {
                  formContext.setError('phone', {
                    message: t('merchantSettings.security_phone_dialog_phone_helpText'),
                  });
                } else {
                  formContext.clearErrors('phone');
                }
              },
            }}
            helperText={t('merchantSettings.security_phone_dialog_phone_helpText')}
            validation={{
              pattern: {
                value: PHONERULE,
                message: t('merchantSettings.security_phone_dialog_phone_helpText'),
              },
              required: true,
            }}
          />
          <TextFieldElement
            label={t('merchantSettings.security_phone_dialog_SMS_label')}
            name="code"
            fullWidth
            autoComplete={'off'}
            inputProps={{
              maxLength: 6,
            }}
            InputProps={{
              endAdornment: (
                <GetPhoneCode phone={nextPhone} type={'new'} send_type={PhoneSendTypeEnum.New} />
              ),
            }}
            helperText={
              nextPhone
                ? t('merchantSettings.security_phone_dialog_phone_code_helpText', {
                    phone: nextPhone || '--',
                  })
                : null
            }
            sx={{ mb: 2, '.MuiInputLabel-asterisk': { display: 'none' } }}
            validation={{
              required: true,
            }}
          />
          <SecurityPart currentVerifyPart={VerifyPart.password} type={'old'} />
          <input id="DialogPhoneChangeForm-Submit" type="submit" hidden />
        </FormContainer>
      </DialogContent>
      <DialogActions className="loading-btn">
        <LoadingButton
          color="primary"
          variant="contained"
          type="submit"
          fullWidth
          onClick={onConfirm}
          className="btn-height48"
          loading={UIState.loading}
          loadingIndicator={<MyIcon name="loading" className="loading-icon-rotate" />}
        >
          {t('common.Confirm')}
        </LoadingButton>
      </DialogActions>
    </CommonDialog>
  );
}
