import { useSharedChannel, withLocalStore } from '@cctip/saga-store';
import { Outlet } from 'react-router';
import MerchantPageFlow from './workflow';
import { useEffect } from 'react';
import { enqueueSnackbar } from 'notistack';
import { Container } from '@mui/material';
import { useSettingsContext } from '@/components/settings';

function MerchantSettingLayout() {
  const { themeStretch } = useSettingsContext();
  const sharedChannel = useSharedChannel();
  useEffect(
    () => {
      sharedChannel.put({ enqueueSnackbar });
    },
    [], // eslint-disable-line
  );
  return (
    <Container maxWidth={themeStretch ? false : 'xl'} sx={{ height: '100%' }}>
      <Outlet />
    </Container>
  );
}

export default withLocalStore(MerchantSettingLayout, { task: MerchantPageFlow as any });
