// @mui
import {
  enUS,
  frFR,
  zhCN,
  viVN,
  arSD,
  zhTW,
  bnBD,
  nlNL,
  deDE,
  hiIN,
  idID,
  itIT,
  jaJP,
  koKR,
  plPL,
  ptPT,
  ruRU,
  esES,
  trTR,
  ukUA,
  urPK,
  // myMY,
  // msMS,
  // tlTL,
  // neNP,
} from '@mui/material/locale';

// pacth-packge会运行两次，导致代码补丁添加了两次，进而报错

// SyntaxError: /drone/src/node_modules/@mui/material/locale/index.js: Identifier 'myMY' has already been declared. (4022:13)
//   4020 |
//   4021 | // Myanmar - မြန်မာ
// > 4022 | export const myMY = {
//        |              ^
//   4023 |   components: {
//   4024 |     MuiBreadcrumbs: {
//   4025 |       defaultProps: {
//     at parser.next (<anonymous>)
//     at normalizeFile.next (<anonymous>)
//     at run.next (<anonymous>)
//     at transform.next (<anonymous>)

// PLEASE REMOVE `LOCAL STORAGE` WHEN YOU CHANGE SETTINGS.


export const allLangs = [
  {
    label: 'English',
    value: 'en',
    systemValue: enUS,
    icon: '/assets/icons/flags/ic_flag_en.svg',
  },
  {
    label: 'Français',
    value: 'fr',
    systemValue: frFR,
    icon: '/assets/icons/flags/ic_flag_fr.svg',
  },
  {
    label: 'Tiếng Việt',
    value: 'vn',
    systemValue: viVN,
    icon: '/assets/icons/flags/ic_flag_vn.svg',
  },
  {
    label: '简体中文',
    value: 'zh-cn',
    systemValue: zhCN,
    icon: '/assets/icons/flags/ic_flag_cn.svg',
  },
  {
    label: 'عربي',
    value: 'ar',
    systemValue: arSD,
    icon: '/assets/icons/flags/ic_flag_sa.svg',
  },

  {
    label: 'বাংলা',
    value: 'bn',
    systemValue: bnBD,
    icon: '',
  },
  // 缅甸
  {
    label: 'မြန်မာ',
    value: 'my',
    systemValue: '',
    icon: '',
  },

  {
    label: '繁體中文',
    value: 'zh-TW',
    systemValue: zhTW,
    icon: '',
  },
  {
    label: 'Nederlands',
    value: 'nl',
    systemValue: nlNL,
    icon: '',
  },

  {
    label: 'Deutsch',
    value: 'de',
    systemValue: deDE,
    icon: '',
  },
  {
    label: 'हिंदी',
    value: 'hi',
    systemValue: hiIN,
    icon: '',
  },
  {
    label: 'Bahasa Indonesia',
    value: 'id',
    systemValue: idID,
    icon: '',
  },
  {
    label: 'Italiana',
    value: 'it',
    systemValue: itIT,
    icon: '',
  },
  {
    label: '日本語',
    value: 'ja',
    systemValue: jaJP,
    icon: '',
  },
  {
    label: '한국인',
    value: 'ko',
    systemValue: koKR,
    icon: '',
  },
  // 马来西亚
  {
    label: 'Melayu',
    value: 'ms',
    systemValue: '',
    icon: '',
  },
  // 尼泊尔语
  {
    label: 'नेपाली',
    value: 'ne-NP',
    systemValue: '',
    icon: '',
  },
  {
    label: 'Polski',
    value: 'pl',
    systemValue: plPL,
    icon: '',
  },
  {
    label: 'Português',
    value: 'pt-PT',
    systemValue: ptPT,
    icon: '',
  },
  {
    label: 'Русский',
    value: 'ru',
    systemValue: ruRU,
    icon: '',
  },
  {
    label: 'Española',
    value: 'es-ES',
    systemValue: esES,
    icon: '',
  },
  // 菲律宾
  {
    label: 'Tagalog',
    value: 'tl',
    systemValue: '',
    icon: '',
  },
  {
    label: 'Türk',
    value: 'tr',
    systemValue: trTR,
    icon: '',
  },
  {
    label: 'Українська',
    value: 'uk',
    systemValue: ukUA,
    icon: '',
  },
  {
    label: 'پاکستان',
    value: 'ur-PK',
    systemValue: urPK,
    icon: '',
  },

  // {
  //   label: 'English translation',
  //   value: 'en-UD',
  //   systemValue: '',
  //   icon: '',
  // },
];

export const defaultLang = allLangs[0]; // English
