import useResponsive from '@/hooks/useResponsive';
import { Dialog, DialogProps } from '@mui/material';

const CommonDialog = (props: DialogProps) => {
  const isMobile = !useResponsive('up', 'sm');
  return (
    <Dialog
      scroll="paper"
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      sx={{
        width: isMobile ? '100vw' : 480,
        mx: 'auto',
        '.MuiDialogContent-root': {
          paddingBottom: 0,
          paddingTop: '8px !important',
        },
        ...props.sx,
      }}
      PaperProps={{
        sx: { m: 0, width: '100%' },
      }}
      {...props}
    />
  );
};

export default CommonDialog;
