import { useEffect, useState } from 'react';

// @mui
import { Box, Paper, Grid, Button, Typography, useTheme } from '@mui/material';

import { useLocales } from '@/locales';

import { CustomAvatar } from '@/components/custom-avatar';
import { useAuthContext } from '@/auth/useAuthContext';
import default_merchant_head from '@/assets/img/default_merchant_head.svg';
import { MyIcon } from '@/assets/icons/MyIcon';
import { StyleWrap } from './style';
import { LoadingButton } from '@mui/lab';
import LeaveConfirm from './dialog/LeaveConfirm';
// import Leave from './dialog/Leave';
import { accountApi_ } from '@/api';
import { AccountMerchantListItem, invitLeaveParams } from '@/api/accountApi_';
import { accountStore, merchantStroe, walletStroe } from '@/stores';
import MerchantLoadingNoPosition from '@/layouts/dashboard/nav/components/merchant-loading-no-position';
import { enqueueSnackbar } from 'notistack';
import { observer } from 'mobx-react';
import { useLocation, useNavigate } from 'react-router';
import MerchantLoading from '@/layouts/dashboard/nav/components/merchant-loading';
import DialogAddMerchanttForm from '@/layouts/dashboard/nav/components/add-merchant-dialog';
import { MerchantStatusREG, PATH_AFTER, getPathAfter } from '@/config';
import EmptyContent from '@/components/empty-content';
import useResponsive from '@/hooks/useResponsive';

export default observer(function SettingsMerchantList() {
  const theme = useTheme();
  const { merchantList, getMerchantList } = merchantStroe;
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = !useResponsive('up', 'sm');
  const { translate } = useLocales();
  const { user, updUserInfoToStore, roleList } = useAuthContext();
  const [stepVis, setStepVis] = useState<AccountMerchantListItem | null>(null);

  const [loading, setLoading] = useState(false);
  const [changeLoading, setChangeLoading] = useState(false);
  const [inviteActionType, setInviteActionType] = useState(-1);
  const [open, setOpen] = useState(false);
  const [, setAddLoading] = useState(false);

  const [oldMerchantId, setOldMerchantId] = useState('');

  //role
  // const roleName = {
  //   1: translate('merchantSettings.settings_member_role_owner'),
  //   2: translate('merchantSettings.settings_member_role_dev'),
  //   3: translate('merchantSettings.settings_member_role_acc'),
  //   4: translate('merchantSettings.settings_member_role_service'),
  // };

  const getAccountMerchantList = async () => {
    setLoading(true);
    try {
      // const res = await accountApi_.merchantList();
      await getMerchantList();
      // if (res?.list) {
      //   setAccountMerchantList(res.list);
      // }
    } catch (error) {
      if (error?.code) {
        enqueueSnackbar(translate(`new_interface_err.${error.code}`), { variant: 'error' });
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    } finally {
      setLoading(false);
    }
  };

  const inviteAction = async (data: { status: number; id: number }) => {
    setInviteActionType(data.status);
    try {
      await accountApi_.inviteAction(data);
      await getAccountMerchantList();
    } catch (error) {
      if (error?.code) {
        enqueueSnackbar(translate(`new_interface_err.${error.code}`), { variant: 'error' });
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    } finally {
      setInviteActionType(-1);
    }
  };

  const inviteLeave = async (data: invitLeaveParams) => {
    try {
      await accountApi_.inviteLeave(data);
      await getAccountMerchantList();
    } catch (error) {
      if (error?.code) {
        enqueueSnackbar(translate(`new_interface_err.${error.code}`), { variant: 'error' });
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
  };

  const switchMerchantAccount = async (merchant_id: string) => {
    setChangeLoading(true);
    accountApi_
      .switchMerchantAccount(merchant_id)
      .then(async () => {
        localStorage.setItem('isFirst', '1');
        await getAccountMerchantList();
        await accountStore.updateSwitchStatus(true);
      })
      .catch((error) => {
        enqueueSnackbar(translate(`nav.switch_merchant_error`), { variant: 'error' });
        switchMerchantAccount(oldMerchantId);
      })
      .finally(() => {
        // 在跳转之前更新一下本地用户信息
        updUserInfoToStore({ isNeedExtra: true, refreshRouter: true })
          .then((res) => {
            if (res?.merchantId) {
              setOldMerchantId(res?.merchantId);
            }

            // window.location.href = '/dashboard/index';
            if (
              !location.pathname.includes('dashboard') ||
              !getPathAfter(PATH_AFTER[res?.role_id || -1]).includes('dashboard')
            ) {
              navigate(getPathAfter(PATH_AFTER[res?.role_id || -1]), {
                replace: true,
              });
            }
          })
          .finally(() => {
            setChangeLoading(false);
          });
        walletStroe.asyncWalletInfo();
      });
  };

  useEffect(() => {
    getAccountMerchantList();
    if (user?.merchantId) {
      setOldMerchantId(user?.merchantId);
    }
    // eslint-disable-next-line
  }, []);

  const showCreateBtn =
    merchantList.length > 0 && merchantList.filter((item) => item?.role_id === 1).length < 10;

  if (changeLoading)
    return <MerchantLoading showText={translate('nav.Add_New_Merchant_dialog_switching')} />;

  return (
    <StyleWrap>
      {loading ? (
        <Box height={500} width={'100%'} overflow={'hidden'}>
          <MerchantLoadingNoPosition width="100%" />
        </Box>
      ) : (
        <Box sx={{ borderRadius: '0px 0px 16px 16px', position: 'relative' }}>
          {showCreateBtn && (
            <Button
              variant="outlined"
              className="creat-btn btn ab-btn"
              onClick={() => {
                setOpen(true);
              }}
              sx={{
                position: isMobile ? 'initial !important' : 'absolute',
                marginBottom: isMobile ? '24px' : '0px',
                width: isMobile ? '100%' : 'auto',
                padding: isMobile ? '16px !important' : '8px 12px !important',
              }}
            >
              <MyIcon name="add_icon" size={24} style={{ marginRight: '8px' }} />{' '}
              {translate('merchantSettings.settings_merchant_creat')}
            </Button>
          )}

          {open && (
            <DialogAddMerchanttForm
              open={open}
              onClose={async (isConfirm, id) => {
                setOpen(false);
                if (isConfirm && id) {
                  setAddLoading(true);
                  accountApi_
                    .switchMerchantAccount(id)
                    .then(async () => {
                      await getAccountMerchantList();
                      await updUserInfoToStore({ isNeedExtra: true, refreshRouter: true });
                      await accountStore.updateSwitchStatus(true);
                    })
                    .catch((error) => {
                      enqueueSnackbar(translate(`nav.add_merchant_error`), { variant: 'error' });
                      switchMerchantAccount(oldMerchantId);
                    })
                    .finally(() => {
                      setAddLoading(false);
                      walletStroe.asyncWalletInfo();
                      if (user?.merchantId) {
                        setOldMerchantId(user?.merchantId);
                      }
                    });
                }
              }}
            />
          )}

          {merchantList.length > 0 ? (
            <Box>
              {merchantList.map((item) => {
                const isInvite = [3].includes(item.status);
                return (
                  <Paper
                    elevation={5}
                    sx={{
                      p: 3,
                      mb: 3,
                      position: isMobile ? 'relative' : 'initial',
                      boxShadow: '0px 6px 12px 0px rgba(230, 233, 246, 0.50)',
                      borderRadius: '16px',
                    }}
                    key={item.id}
                  >
                    <Grid container mt={isMobile ? 1 : 0}>
                      {item?.is_current_login && isMobile && (
                        <Box
                          sx={{
                            position: 'absolute',
                            right: '16px',
                            top: '12px',
                          }}
                        >
                          <MyIcon name="ic_wallet_successful" size={24} />
                        </Box>
                      )}
                      {/* left */}
                      <Grid
                        item
                        xs={isInvite ? (isMobile ? 12 : 5) : isMobile ? 12 : 4}
                        className="flex alignItem"
                      >
                        <Box className="flex" mr={3}>
                          <Box className="left-avatar">
                            <CustomAvatar
                              src={item?.logo || default_merchant_head}
                              alt={user?.nickname}
                              name={user?.email}
                              sx={{
                                backgroundColor: 'transparent',
                              }}
                            />
                            <MyIcon
                              name={
                                (item?.merchant_status ?? 0) === MerchantStatusREG
                                  ? 'v_succ'
                                  : 'v_notsucc'
                              }
                              size={16}
                              className="left-avatar-icon"
                            />
                          </Box>
                        </Box>
                        <Box>
                          <Box
                            className="left-name"
                            sx={{ fontSize: isMobile ? '16px !important' : '18px' }}
                          >
                            {item?.title}
                          </Box>
                          <Box className="left-role">
                            {isInvite ? (
                              translate('merchantSettings.settings_member_invite_join_desc', {
                                merchantName: item.title,
                              })
                            ) : (
                              <>
                                {translate('merchantSettings.settings_member_role')}:{' '}
                                {roleList.filter((role) => role.roleId === item.role_id)[0]?.name}
                              </>
                            )}
                          </Box>
                        </Box>
                      </Grid>
                      {/* right */}
                      <Grid
                        item
                        xs={isInvite ? (isMobile ? 12 : 7) : isMobile ? 12 : 8}
                        className="flex alignItem"
                        sx={{
                          justifyContent: isInvite ? 'flex-end' : 'space-between',
                        }}
                      >
                        {/* daily_limit_amount */}
                        {!isInvite && !isMobile && (
                          <Box
                            className="flex"
                            sx={{
                              alignItems: 'flex-end',
                              height: '100%',
                            }}
                          >
                            <Box pl={3} className="left-role left-border" />
                          </Box>
                        )}
                        {/* action btns */}
                        {item?.is_current_login ? (
                          !isMobile && (
                            <Box className="right-now">
                              <Typography
                                color={theme.palette.success.main}
                                display={'flex'}
                                alignItems={'center'}
                                fontSize={12}
                                fontWeight={600}
                                component={'div'}
                              >
                                <MyIcon
                                  name="successful"
                                  size={18}
                                  style={{
                                    flexShrink: 0,
                                    flexGrow: 1,
                                    height: '18px',
                                    marginRight: '8px',
                                  }}
                                />{' '}
                                {translate('merchantSettings.settings_merchant_current')}
                              </Typography>

                              {[2].includes(item.status) && (
                                <Button
                                  variant="outlined"
                                  className="leave-btn btn"
                                  onClick={() => {
                                    setStepVis(item);
                                  }}
                                  sx={{
                                    ml: 3,
                                  }}
                                >
                                  {translate('merchantSettings.settings_merchant_leave')}
                                </Button>
                              )}
                            </Box>
                          )
                        ) : (
                          <Box
                            className="right-now"
                            sx={{
                              width: isMobile ? '100%' : 'auto',
                              marginTop: isMobile ? '20px' : '0',
                              display: isMobile ? 'flex' : 'block',
                            }}
                          >
                            {/* access */}
                            {[1, 2].includes(item.status) && (
                              <Button
                                variant="outlined"
                                className="acc-btn btn"
                                sx={{
                                  mr: [2].includes(item.status) ? 3 : 0,
                                  width: isMobile ? '100%' : 'auto',
                                }}
                                onClick={() => {
                                  switchMerchantAccount(item.merchant_id);
                                }}
                              >
                                {translate('merchantSettings.settings_merchant_access')}
                              </Button>
                            )}
                            {/* leave */}
                            {[2].includes(item.status) && (
                              <Button
                                variant="outlined"
                                className="leave-btn btn"
                                onClick={() => {
                                  setStepVis(item);
                                }}
                              >
                                {translate('merchantSettings.settings_merchant_leave')}
                              </Button>
                            )}
                            {/* Accept or Reject */}
                            {isInvite && (
                              <Box
                                sx={{
                                  display: 'flex',
                                }}
                              >
                                <Box component={'div'} className="loading-btn">
                                  <LoadingButton
                                    variant="contained"
                                    className="btn"
                                    onClick={() => {
                                      inviteAction({ id: item.id, status: 1 });
                                    }}
                                    sx={{
                                      mr: 3,
                                    }}
                                    loading={inviteActionType === 1}
                                    disabled={inviteActionType === 2}
                                    loadingIndicator={
                                      <MyIcon name="loading" className="loading-icon-rotate" />
                                    }
                                  >
                                    {translate('merchantSettings.settings_merchant_accept')}
                                  </LoadingButton>
                                </Box>

                                <Box component={'div'} className="loading-btn-red">
                                  <LoadingButton
                                    variant="contained"
                                    className="leave-btn btn"
                                    onClick={() => {
                                      inviteAction({ id: item.id, status: 2 });
                                    }}
                                    loading={inviteActionType === 2}
                                    disabled={inviteActionType === 1}
                                    loadingIndicator={
                                      <MyIcon name="loading_red" className="loading-icon-rotate" />
                                    }
                                  >
                                    {translate('merchantSettings.settings_merchant_reject')}
                                  </LoadingButton>
                                </Box>
                              </Box>
                            )}
                          </Box>
                        )}
                      </Grid>
                    </Grid>
                  </Paper>
                );
              })}
            </Box>
          ) : (
            <Box>
              <EmptyContent
                title={translate('merchantSettings.settings_merchant_no')}
                btnNode={
                  <Button
                    style={{ marginTop: '24px' }}
                    variant="outlined"
                    className="creat-btn btn"
                    onClick={() => setOpen(true)}
                  >
                    <MyIcon name="add_icon" size={24} style={{ marginRight: '8px' }} />
                    {translate('merchantSettings.settings_merchant_creat')}
                  </Button>
                }
              />
            </Box>
          )}
        </Box>
      )}

      {stepVis && (
        <LeaveConfirm
          visible={Boolean(stepVis)}
          onClose={() => {
            setStepVis(null);
          }}
          handleAction={() => inviteLeave({ id: stepVis.id, invite_merchant_id: user!.merchantId })}
          title={translate('merchantSettings.settings_merchant_dialog_leave')}
          content={translate('merchantSettings.settings_merchant_dialog_leave_desc', {
            merchantName: stepVis.title,
          })}
          okBtnText={translate('merchantSettings.settings_merchant_leave')}
        />
      )}
    </StyleWrap>
  );
});
