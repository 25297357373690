import { Http } from '@/api/http';
const http = new Http('/payment/v1');

export default class DashboardApi {
  //getorder chart data
  getDashboardOrderInfo(dt: number, zone: string): Promise<statParamType> {
    return http.postJson('/dashboard/bill/stat', { dt, zone });
  }

  //getbalance chart data
  getDashboardBalanceInfo(dt: number, zone: string): Promise<statBalanceParamType> {
    return http.postJson('/dashboard/balance/stat', { dt, zone });
  }

  //getdashboard top data
  getDashboardTopInfo(): Promise<DashboardTopInfoType> {
    return http.postJson('/dashboard/index/stat', {});
  }

  //getdashboard top data
  getDepositWithdrawData(zone: string): Promise<getDepositWithdrawDataRes> {
    return http.postJson('/dashboard/deposit-withdraw/stat', { zone });
  }

  //Confirm Token pre-delisting notification
  setTokenConfirm(): Promise<confirmRes> {
    return http.postJson('/dashboard/info/confirm');
  }

  //Error log modification
  setErrorfirm(data: errorParamsType) {
    return http.postJson('/dashboard/error/save', { ...data });
  }

  //getorder chart data
  getDashboardDirectDepositInfo(
    data: getDashboardDirectDepositInfoParamType,
  ): Promise<getDashboardDirectDepositInfoResType> {
    return http.postJson('/dashboard/direct_deposit/stat', data);
  }

  //notify/notification/get
  getnotificationInfo(): Promise<getDashboardDirectDepositInfoResType> {
    return http.postJson('/notify/notification/get', {});
  }

  //notify/notification/get
  getnotificationList(): Promise<notificationListoResType> {
    return http.postJson('/notify/notification/list', {});
  }

  getDepositWithdrawBarData(
    day: number,
    zone: string,
  ): Promise<{ list: getDepositWithdrawBarDataResItem[] }> {
    return http.postJson('/dashboard/deposit-withdraw/bar', { day, zone });
  }

  getUserTrendData(
    day: number,
    zone: string,
  ): Promise<{ list: getUserTrendDataResItem[]; total: number; exists: boolean }> {
    return http.postJson('/dashboard/user/trend', { day, zone });
  }
}

export interface getDepositWithdrawDataItem {
  today_num: string;
  today_amount: string;
  yesterday_num: string;
  yesterday_amount: string;
  month_num: string;
  month_amount: string;
}

export interface getDepositWithdrawDataRes {
  deposit: getDepositWithdrawDataItem;
  withdraw: getDepositWithdrawDataItem;
}

export interface getUserTrendDataResItem {
  date: number;
  register_no: number;
  active_no: number;
}

export interface getDepositWithdrawBarDataResItem {
  date: number;
  deposit_balance: string;
  withdraw_balance: string;
}

export interface buttonsItem {
  link: string;
  name: string;
}
export interface notificationListoResTypeItem {
  type: string;
  title: string;
  notification_id: string;
  content: {
    title: string;
    content: string;
    buttons: buttonsItem[];
  };
  tag_name?: string;
}
export interface notificationListoResType {
  list: notificationListoResTypeItem[];
}
export interface confirmRes {
  status: {
    code: number;
    reason: string;
    message: string;
  };
}

export interface List {
  [key: string]: number;
}

export interface statParamList {
  date: number;
  list: List;
}

export interface statParamType {
  list: statParamList[];
}

export interface statBalanceList {
  date: number;
  balance: string;
}

export interface statBalanceParamType {
  list: statBalanceList[];
}

export type getDashboardDirectDepositInfoParamType = {
  start_date: string;
  // start_date: number;
  coin_ids: number[];
};

export interface PropertyItem {
  count: string;
  amount: string;
}

export interface getDashboardDirectDepositInfoResType {
  List: {
    property1: PropertyItem;
    property2: PropertyItem;
  };
}

// export type getDashboardDirectDepositInfoResType = {
//   [key: string]: {
//     /** num */
//     Count: number;
//     /** price of usdt */
//     Amount: number;
//   };
// };

export interface preOffTokenInterface {
  symbol: string;
  pre_off_date: number;
  logo: string;
}

export type errorParamsType = {
  Url: string;
  Err: string;
};

export type dataArrType = {
  chain_id: string;
  contract: string;
  logo: string;
  name: string;
  symbol: string;
  quantity: string;
  amount: string;
  price: string;
  [key: string]: any;
};

export type DashboardTopInfoType = {
  api_deposit_stat: {
    num_24: number;
    amount_24: string;
    today_num: number;
    today_amount: string;
    month_num: number;
    month_amount: string;
  };
  api_withdraw_stat: {
    num_24: number;
    amount_24: string;
    today_num: number;
    today_amount: string;
    month_num: number;
    month_amount: string;
  };
  token_stat: {
    total_balance: string;
    token: dataArrType[];
  };
  pre_off_token: preOffTokenInterface[];
  order_stat: {
    total: string;
    today: string;
    month: string;
    earned_total: string;
    earned_today: string;
    earned_month: string;
  };
};
