// @mui
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
//
import useLocales from './useLocales';



type Props = {
  children: React.ReactNode;
};

export default function ThemeLocalization({ children }: Props) {
  const outerTheme = useTheme();

  const { currentLang } = useLocales();

  // Mui temporarily does not support Myanmar (my), Malaysia (ms), Nepali (neNp), Philippines (tl)
  // If it is not from these countries, don't use mui to provide
  const theme =
    typeof currentLang.systemValue === 'object'
      ? createTheme(outerTheme, currentLang.systemValue)
      : createTheme(outerTheme);

  return <ThemeProvider theme={theme}> {children} </ThemeProvider>;
}
