import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, MenuItem } from '@mui/material';
// routes
import { PATH_AUTH } from '../../../routes/paths';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// components
import { CustomAvatar } from '../../../components/custom-avatar';
import { useSnackbar } from '../../../components/snackbar';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
import { settingEmail } from '@/utils/until';
import { useLocales } from '@/locales';
import default_user_head from '@/assets/img/default_user_head.svg';
import default_wallet_user_head from '@/assets/img/default_wallet_user_head.svg';
import { CCP_URL } from '@/config';

export default function AccountPopover() {
  const navigate = useNavigate();

  const { translate: t } = useLocales();

  const { user, logout, setChooseApi } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleLogout = async () => {
    try {
      await logout();
      setChooseApi(1);
      navigate(PATH_AUTH.login, { replace: true });
      handleClosePopover();
    } catch (error) {
      console.error({ error });
      if (error?.code) {
        enqueueSnackbar(t(`new_interface_err.${error?.code}`), { variant: 'error' });
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    }
  };

  const handleClickItem = (path: string) => {
    handleClosePopover();
    if (path.includes('https')) {
      window.open(path);
    } else {
      navigate(path, { replace: true });
    }
  };

  const headUrl = useMemo(() => {
    let url = default_user_head;
    if (user?.mode_type === 2) {
      url = default_wallet_user_head;
    }
    return url;
  }, [user]);

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <CustomAvatar
          src={headUrl}
          alt={user?.email}
          name={user?.nickname}
          sx={{ backgroundColor: 'transparent' }}
        />
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 200, p: 0, mt: 1 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="h6" sx={{ color: 'text.primary' }} noWrap>
            {user?.username}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: '14px' }} noWrap>
            {settingEmail(user?.email || '')}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={() => handleClickItem('/account/settingPageSection/settings')}
          sx={{ m: 1 }}
        >
          {t('nav.header_account_Settings_desc')}
        </MenuItem>

        <MenuItem
          onClick={() => {
            // console.log('跳转到帮助文档链接');
            handleClosePopover();
            // window.open('https://ccpayment.com/api/doc/?en#introduction');
            window.open(`${CCP_URL}en/support-center`);
          }}
          sx={{ m: 1 }}
        >
          {t('nav.Help_Center')}
        </MenuItem>

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          {t('nav.header_account_Logout')}
        </MenuItem>
      </MenuPopover>
    </>
  );
}
