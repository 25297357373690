import { Http } from '@/api/http';
import CryptoJS from 'crypto-js';
const http = new Http('/payment/v1');
const http1 = new Http('/account/v1');
/**
 *  wallet
 */

export default class WalletApi {
  /**getWithdrawId  */
  getWithdrawId(): Promise<{ bill_id: string }> {
    return http.postJson('/assets/withdraw/uuid', {});
  }

  /**getToken list ,chain list  */
  getTokenList(): Promise<TokenResponse> {
    return http.postJson('/token/chain/list/all', {});
  }

  /**get链 list  */
  getNetworkList(): Promise<{
    list: NetworkType[];
  }> {
    return http.postJson('/token/all/chain', {});
  }

  /**get owned assets list ---- */
  getTokenBlance(): Promise<{
    total_value: string;
    free_total: string;
    frozen_total: string;
    locked_total: string;
    list: getBalanceType[];
  }> {
    return http.postJson('/assets/list', {});
  }

  /**-----  */
  getBlance(chain_id: string): Promise<{
    coinId: number;
    balance: string;
  }> {
    return http.postJson('/balance/list', { chain_id });
  }

  /**get withdrawal whitelist list ------- */
  getWhitelistSendList(coin_id: number): Promise<{ list: WhitelistRes[] }> {
    return http.postJson('/assets/withdraw/white_list', { coin_id });
  }

  // ------
  getIncomeAddress(data: {
    platform: 'wallet';
    chain_id: string;
    contract: string;
    value: string;
    refresh: boolean;
    type: number;
    remarks: string;
  }): Promise<{ address: string; generate_time: number; memo?: string }> {
    return http.postJson('/assets/deposit/address/get', data);
  }

  // sats interface
  // lightning network/creat bill
  getInPaySats(data: {
    chain_id: string;
    contract: string;
    amount: string;
    remarks?: string;
  }): Promise<{ lnurl: string }> {
    return http.postJson('/ln/pay/get', data);
  }

  // lightning network/analyze bill
  getInPayParse(data: { invoice: string }): Promise<getInPayParseResType> {
    return http.postJson('/ln/pay/parse', data);
  }

  //  lightning network/pay bill
  getInPaySubmit(props: PayItem) {
    props.pay_pass = CryptoJS.MD5(props.pay_pass).toString();
    return http.postJson('/ln/pay/submit', props);
  }

  /**According to token_id get support chain ------*/
  getSuportNetworkList(coin_id: number): Promise<{ list: NetworkCoin[] }> {
    return http.postJson('/token/chains', { coin_id });
  }

  /**get Withdrawal fee*/
  getWithdrawFee(props: {
    chain_id: string;
    contract: string;
    address: string;
    memo: string;
    coin_id: number;
    refund_value?: string;
  }) {
    return http.postJson('/assets/withdraw/fee', props);
  }

  /**Create withdrawal order*/
  createWithdraw(): Promise<string> {
    return http.postJson('/assets/withdraw/apply');
  }

  /**Initiate withdrawal -------*/
  submitWithdraw(props: Withdraw) {
    props.pay_pass = CryptoJS.MD5(props.pay_pass?.trim()).toString();

    return http.postJson('/assets/withdraw/commit', props);
  }

  /**get currency exchange from list  -----*/
  getSwapFromList(): Promise<{
    list: swapFromListType[];
  }> {
    return http.postJson('/assets/swap/from/get', {});
  }

  /**get currency exchange to list  -----*/
  getSwapToList(): Promise<{
    list: swapToListType[];
  }> {
    return http.postJson('/assets/swap/to/get', {});
  }

  /**get currency exchange token rate ----- */
  getSwapTokenRate(data: getSwapTokenRateParamType): Promise<getSwapTokenRateResType> {
    return http.postJson('/assets/swap/token/rate', data);
  }

  /**get currency exchange token rate ----- */
  getSwapUsable(): Promise<{ value: string }> {
    return http1.postJson('/swap/usable_amount', {});
  }

  /** submit  swap -----*/
  swapCommit(data: swapCommitParamType): Promise<swapCommitResType> {
    data.pay_pass = CryptoJS.MD5(data.pay_pass).toString();
    return http.postJson('/assets/swap/commit', data);
  }

  /** small Withdraw Verify */
  smallWithdrawVerify(
    data: smallWithdrawVerifyParamType,
  ): Promise<{ is_verify: boolean; code: string }> {
    return http.postJson('/assets/withdraw/small/verify', data);
  }
}

export interface getBalanceType {
  coin_id: number;
  price: string;
  symbol: string;
  free: string;
  frozen: string;
  locked: string;
}

export interface netWorksRes {
  coin_id: number;
  chain_id: string;
  contract: string;
  precision: string;
  can_deposit: boolean;
  can_withdraw: boolean;
  min_deposit_amount: string;
  min_withdraw_amount: string;
  max_withdraw_amount: string;
  min_withdraw_fee: string;
  is_native: boolean;
  token_id: string;
}

export interface allListType {
  coin_id: number;
  symbol: string;
  logo_url: string;
  price: string;
  price_at_00h: string;
  coin_name: string;
  market_cap: string;
  total_supply: string;
  cc_merge_id: string;
  networks: {
    [key: string]: NetworkCoin;
  };
  free?: string;
  frozen?: string;
  locked?: string;
  status?: number;
  can_deposit: boolean;
  can_withdraw: boolean;
  can_inner_tx: boolean;
  can_swap: boolean;
}

interface TokenResponse {
  chains: Record<string, NetworkType>;
  tokens: allListType[];
}

export interface Token {
  name?: string;
  coin_id: number;
  /**代币简称 */
  symbol: string;
  /**图标*/
  logo: string;
  price: string;
  price_at_00h: string;
  balance: string;
  value: string;
  change24: string;
  market_cap: number;
  coin_name: string;
  networks?: {
    [key: string]: NetworkCoin;
  };
  status?: number;
  can_deposit: boolean;
  can_withdraw: boolean;
  can_inner_tx: boolean;
  can_swap: boolean;
  min?: string;
}

//----------------------------

export type WhitelistRes = {
  id: number;
  type: number;
  chain_id: string;
  address: string;
  memo: string;
  logo: string;
  remark: string;
  network: string;
  precision: number;
  is_support_memo?: boolean;
  can_withdraw?: boolean;
};

export enum smallWithdrawVerifyRefundEnum {
  'withdraw' = 0,
  'refund' = 1,
}

export type smallWithdrawVerifyParamType = {
  chain_id: string;
  contract: string;
  value: string;
  /** 0-withdraw 1-refund */
  refund: 0 | 1;
};

export type swapFromListType = {
  logo: string;
  price: string;
  balance: string;
  precision: number;
  chain_id: string;
  name: string;
  coin_id: number;
  min: string;
  max: string;
  symbol: string;
  visual_balance: string;
  type_symbol: string;
  market_cap: string;
  status?: number;
};

export type swapToListType = {
  token_id: string;
  chain_name: string;
  logo: string;
  price: string;
  balance: string;
  precision: number;
  chain_id: string;
  name: string;
  coin_id: number;
  min: string;
  max: string;
  symbol: string;
  visual_balance: string;
  type_symbol: string;
  market_cap: string;
  is_stop_withdraw: boolean;
  symbol_chain_name: string;
  status?: number;
};

export type swapCommitParamType = {
  from: string;
  to: string;
  from_quantity: string;
  to_quantity: string;
  /** 币价波动差距 */
  torrance: string;
  pre_id: string;
  pay_pass: string;
  f2ACode: string;
};

export type swapCommitResType = {
  status: number;
  rate: string;
  from: string;
  to: string;
  from_ico: string;
  to_ico: string;
  from_quantity: string;
  to_quantity: string;
  fee: string;
  order_no: string;
  create_time: number;
  user_id: string;
  from_token_id: string;
  to_coin_id: string;
  out_record_id: string;
  in_record_id: string;
  type: number;
};

export type getSwapTokenRateParamType = {
  from_quantity: string;
  to_quantity: string;
  from: string;
  to: string;
};

export type getSwapTokenRateResType = {
  from: string;
  to: string;
  from_rate: string;
  to_rate: string;
  rate: string;
  min_amount: string;
  max_amount: string;
  swap_fee: string;
  pre_id: string;
  from_balance: string;
  to_balance: string;
  from_quantity: string;
  to_quantity: string;
  usable_swap_amount?: string; //当日可换取的金额
};

export type getInPayParseResType = {
  lnd_type: string;
  invoice: {
    amount: string;
    callback: string;
    chain_id: string;
    commentAllowed: number;
    contract: string;
    maxSendable: number;
    metadata: {};
    minSendable: number;
    symbol: string;
    tag: string;
    token_id: string;
  };
};

export interface NetworkCoin {
  coin_id: number;
  chain_id: string;
  contract: string;
  precision: number;
  can_deposit: boolean;
  can_withdraw: boolean;
  min_deposit_amount: string;
  min_withdraw_amount: string;
  max_withdraw_amount: string;
  min_withdraw_fee: string;
  is_native: boolean;
  is_support_memo?: boolean;
  token_id: string;
  protocol: string; //network
  name: string;
  status?: number;
}

export interface NetworkType {
  protocol: string;
  coin_id: number;
  chain_id: string;
  name: string;
  can_deposit: boolean;
  can_withdraw: boolean;
  /**以下属性缺失 */
  contract: string;
  is_support_memo?: boolean;
  precision: number;
  status?: number;
}

export interface Withdraw {
  coin_id: number;
  /**订单ID */
  bill_id: string;
  /**提现到账地址 */
  address: string;
  memo?: string;
  /**手续费 */
  fee: string;
  /**提现金额 */
  value: string;
  /**支付密码*/
  pay_pass: string;
  totp_code: string;
  sms_code: string;
  mail_code: string;
  timestamp: number;
  submit_type: number;
  /**闪电 bill  */
  invoice?: string;
  remarks?: string;
  comment: string;
  chain_id: string;
  cwallet_id?: string;
  fee_symbol?: string;
  fee_logo?: string;
  fee_coin_id?: number;
}

export interface PayItem {
  token_id: string;
  chain_id: string;
  contract: string;
  invoice: string;
  amount: string;
  fee: string;
  pay_pass: string;
  code: string;
  remarks: string;
  code_str?: string;
}
