import { useLocales } from '@/locales';
import { BoxProps } from '@mui/material';
import { StatusStyleLabel } from './style';

type Props = {
  label: string;
  type:
    | 'Pending'
    | 'Processing'
    | 'Expired'
    | 'Successful'
    | 'Failed'
    | 'Overpaid'
    | 'Underpaid'
    | 'Refund'
    | 'OverduePayment'
    | 'MultiplePayment'
    | 'Others'
    | 'defalut'
    | 'AwaitingPayment'
    | 'Success'
    | 'Fail'
    | string;
} & BoxProps;

const labelStyle = {
  Pending: {
    color: '#B76E00',
    backgroundColor: 'rgba(255, 171, 0, 0.16)',
  },
  Processing: {
    color: '#919EAB',
    backgroundColor: 'transparent',
    border: '1px solid rgba(145, 158, 171, 0.32)',
  },
  Expired: {
    color: '#919EAB',
    backgroundColor: 'rgba(145, 158, 171, 0.16)',
  },
  Successful: {
    color: '#1B806A',
    backgroundColor: 'rgba(54, 179, 126, 0.16)',
  },
  Success: {
    color: '#1B806A',
    backgroundColor: 'rgba(54, 179, 126, 0.16)',
  },
  success: {
    color: '#1B806A',
    backgroundColor: 'rgba(54, 179, 126, 0.16)',
  },
  Failed: {
    color: '#B71D18',
    backgroundColor: 'rgba(255, 86, 48, 0.16)',
  },
  Fail: {
    color: '#B71D18',
    backgroundColor: 'rgba(255, 86, 48, 0.16)',
  },
  Overpaid: {
    color: '#006C9C',
    backgroundColor: 'rgba(0, 184, 217, 0.16)',
  },
  Underpaid: {
    color: '#B71D18',
    backgroundColor: 'rgba(255, 86, 48, 0.16)',
  },
  Refund: {
    color: '#701CDE',
    backgroundColor: 'rgba(112, 28, 222, 0.16)',
  },
  OverduePayment: {
    color: '#1939B7',
    backgroundColor: 'rgba(51, 102, 255, 0.16)',
  },
  MultiplePayment: {
    color: '#90009C',
    backgroundColor: 'rgba(144, 0, 156, 0.16)',
  },
  Others: {
    color: '#709200',
    backgroundColor: 'rgba(112, 146, 0, 0.16)',
  },
  defalut: {
    color: '#1E1E1E',
    backgroundColor: 'rgba(255, 255, 255, 0)',
  },
  AwaitingPayment: {
    color: '#B76E00',
    backgroundColor: 'rgba(255, 171, 0, 0.16)',
  },
  LatePayment: {
    color: '#1939B7',
    backgroundColor: 'rgba(51, 102, 255, 0.16)',
  },
  MarkAsDone: {
    color: '#1939B7',
    backgroundColor: 'rgba(0, 184, 217, 0.16)',
  },
  Revoked: {
    color: '#FF5630',
    backgroundColor: 'rgba(255, 86, 48, 0.16)',
  },
  Unsent: {
    color: '#B76E00',
    backgroundColor: 'rgba(255, 171, 0, 0.16)',
  },
  Overdue: {
    color: '#919EAB',
    backgroundColor: 'rgba(145, 158, 171, 0.16)',
  },
  Paid: {
    color: '#1B806A',
    backgroundColor: 'rgba(54, 179, 126, 0.16)',
  },
  EmailSending: {
    color: '#701CDE',
    backgroundColor: 'rgba(112, 28, 222, 0.16)',
  },
  Enable: {
    color: '#1B806A',
    backgroundColor: 'rgba(54, 179, 126, 0.16)',
  },
  Disabled: {
    color: '#27291E',
    backgroundColor: 'rgba(145, 158, 171, 0.16)',
  },
  Reviewing: {
    color: '#B76E00',
    backgroundColor: 'rgba(255, 171, 0, 0.16)',
  },
  Rejected: {
    color: '#B71D18',
    backgroundColor: 'rgba(255, 86, 48, 0.16)',
  },
};

export default function StatusLabel({ label, type, ...other }: Props) {
  const { translate } = useLocales();
  return (
    <StatusStyleLabel
      {...other}
      component="span"
      sx={{
        ...labelStyle[type],
        ...other.sx,
      }}
    >
      {label ? translate(`common.${label}`) : '--'}
    </StatusStyleLabel>
  );
}
