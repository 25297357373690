import { Http } from '@/api/http';
const http = new Http('/account/v1');

export default class UserApi {
  setGeneralSettings(data: {
    logo: string;
    title: string;
    type: 1 | 2; // 1 all ｜ 2 other
    token_list: Array<{
      symbol: string;
      token_id: string;
      logo: string;
      name: string;
    }>;
    email_notify?: number;
    company_address?: string;
    company_website?: string;
    twitter_url?: string;
    telegram_url?: string;
    webhook_notify_mode?: number;
  }) {
    return http.postJson('/user/general/set', data);
  }

  uploadFile(data: { file: File }) {
    const formData = new FormData();
    formData.append('file', data.file);
    return http.postForm('/user/file/upload', formData);
  }

  extraAdd(data: extraAddParam) {
    return http.postJson('/merchant/extra/add', data);
  }
}

export interface extraAddParam {
  merchant_id: string;
  company_website: string;
  company_address: string;
  twitter_url: string;
  telegram_url: string;
  company_industry: string;
  company_industry_text: string;
  main_functions: string;
  main_functions_text: string;
}
