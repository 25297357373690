import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { TextFieldElement, FormContainer, useForm } from 'react-hook-form-mui';
import { useEffect, useMemo } from 'react';
import SecurityPart, { VerifyPart } from '../security-form';
import { MyIcon } from '@/assets/icons/MyIcon';
import { LoadingButton } from '@mui/lab';
import { useLocales } from '@/locales';
import { observer } from 'mobx-react';
import { UIState } from '../workflow';
import { isSequentialLetter, isSequentialNumber } from '@/utils/until';
import CommonDialog from '../components/CommonDialog';

export default observer(DialogPasswordChangeForm);

function DialogPasswordChangeForm({
  visible,
  onClose,
}: {
  visible: boolean;
  onClose: (payload: any) => void;
}) {
  const { translate: t } = useLocales();

  const formContext = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const { handleSubmit } = formContext;

  const onConfirm = () => {
    const dom: HTMLInputElement = document.querySelector('#DialogPasswordChangeForm-Submit')!;
    dom.click();
  };

  const onOK = async (data: any) => {
    await onClose(data);
  };

  const login_pass = formContext.watch('login_pass');

  const passwordValidation = useMemo(
    () => ({
      required: t('register.password_rule_desc'),
      minLength: { value: 6, message: t('merchantSettings.security_password_dialog_helpText3') },
      maxLength: { value: 18, message: t('merchantSettings.security_password_dialog_helpText3_2') },
      deps: 'login_pass',
    }),
    // eslint-disable-next-line
    [login_pass]
  );

  const pwStrength = useMemo(() => {
    let flag = true;

    if (!login_pass) return true;
    // Determine whether the password length is less than 8 characters
    if (login_pass.length < 6 || login_pass.length > 18) {
      return true;
    }

    // Determine whether the password is pure numbers or letters
    if (/^\d+$/.test(login_pass) || /^[a-zA-Z]+$/.test(login_pass)) {
      return false;
    }

    // Determine whether the password contains consecutive 5-digit numbers or letters
    let hasSequentialChars = false;
    for (let i = 0; i <= login_pass.length - 5; i++) {
      const subString = login_pass.substr(i, 5);
      if (isSequentialNumber(subString) || isSequentialLetter(subString)) {
        hasSequentialChars = true;
        break;
      }
    }
    if (hasSequentialChars) {
      return false;
    }

    return flag;
  }, [login_pass]);

  useEffect(
    () => formContext.reset(),
    // eslint-disable-next-line
    [visible]
  );

  return (
    <CommonDialog open={visible} onClose={() => onClose(null)}>
      <DialogTitle id="scroll-dialog-title">
        <Box className="flex justify-between items-center">
          <Typography variant="h5" component="span">
            {t('merchantSettings.security_password_dialog_title2')}
          </Typography>
          <IconButton onClick={() => onClose(false)}>
            <MyIcon name="close" size={14} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ pt: 1 }}>
        <FormContainer
          formContext={formContext}
          handleSubmit={handleSubmit(onOK)}
          reValidateMode="onBlur"
        >
          <TextFieldElement
            name="login_pass"
            label={t('merchantSettings.security_password_dialog_label1')}
            type="password"
            fullWidth
            helperText={
              pwStrength ? t('register.password_rule_desc') : t('common.password_intensity_tip')
            }
            sx={{
              mb: 2,
              '.MuiFormHelperText-root': {
                color: pwStrength ? '#919EAB' : '#FFAB00',
              },
            }}
            validation={passwordValidation}
            autoComplete="new-password"
            onBlur={() => {
              formContext.clearErrors('login_pass');
              formContext.trigger('login_pass');
              console.log('formContext', formContext);
            }}
          />
          <SecurityPart currentVerifyPart={VerifyPart.password} type={'old'} />
          <input id="DialogPasswordChangeForm-Submit" type="submit" hidden />
        </FormContainer>
      </DialogContent>
      <DialogActions className="loading-btn">
        <LoadingButton
          color="primary"
          variant="contained"
          type="submit"
          fullWidth
          onClick={onConfirm}
          className="btn-height48"
          loading={UIState.loading}
          loadingIndicator={<MyIcon name="loading" className="loading-icon-rotate" />}
        >
          {t('common.Confirm')}
        </LoadingButton>
      </DialogActions>
    </CommonDialog>
  );
}
