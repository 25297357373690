import { Box, Paper, styled } from '@mui/material';

export const styles = {
  color_500: {
    fontWeight: 500,
    color: '#00B8D9',
  },
  flex_items: {
    display: 'flex',
    alignItems: 'center',
  },
  fw500: {
    fontWeight: 500,
  },
  loading_btn: {
    '.MuiLoadingButton-loading': {
      backgroundColor: 'rgba(51, 102, 255, 0.16) !important',
      border: '0 !important',
      color: 'transparent !important',
    },
  },
  check_hover: {
    ':hover': {
      background: '#1E387B',
      boxShadow: '0px 8px 16px 0px rgba(60, 111, 245, 0.24)',
    },
  },
  shadow: {
    boxShadow:
      '0px 12px 24px -4px rgba(145, 158, 171, 0.12), 0px 0px 2px 0px rgba(145, 158, 171, 0.20)',
  },
  flex_box: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  ver_desc: {
    fontSize: '14px',
    color: '#71757E',
    marginBottom: '18px',
  },
  file_desc: {
    fontSize: '16px',
    color: '#71757E',
    marginRight: '4px',
  },
  file_txt: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    width: '100%',
  },
  down_box: {
    background: '#F8F9FB',
    padding: '8px 20px',
    color: '#1F2025',
    fontWeight: '400',
    textTransform: 'none',
  },
  domain_name: {
    display: 'flex',
    color: '#3C6FF5',
    fontSize: '14px',
    fontWeight: 500,
  },
  domain_name_desc: {
    display: 'flex',
    color: '#FFAB00',
    fontSize: '14px',
    fontWeight: 400,
  },
  support_bot: {
    marginRight: '6px',
    color: '#00B8D9 !important',
    height: '26px',
    ':hover': {
      borderBottom: '1px solid #00B8D9',
    },
  },
  step_1_box: {
    padding: '0 0 0 16px',
    borderLeft: '1px solid rgba(145, 158, 171, 0.24)',
    marginLeft: '8px',
  },
  dis_btn: {
    '.Mui-disabled': {
      color: 'rgba(145, 158, 171, 0.8) !important',
      background: '#fff !important',
      border: '1px solid rgba(145, 158, 171, 0.24) !important',
    },
  },
  reva_btn: {
    padding: '6px 16px',
    fontSize: '12px',
    color: '#B76E00',
    background: 'rgba(255, 171, 0, 0.16)',
    border: 0,
    ':hover': {
      backgroundColor: 'rgba(255, 171, 0, 0.32)',
      border: 0,
    },
  },
  faq_box: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    justifyContent: 'flex-end',
  },

  text_code: {
    background: '#E8ECF2',
    padding: '8px 32px',
    borderRadius: '8px',
    width: 'max-content',
  },
  faq: {
    display: 'flex',
    padding: '6px 16px',
    width: 'max-content',
    borderRadius: '8px',
    ':hover': {
      background: '#E8ECF2',
    },
  },
  verSuc_box: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  verSuc: {
    color: '#36B37E',
    fontFamily: 'Poppins',
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '24px',
    marginTop: '24px',
  },
  verSuc_dec: {
    color: '#71757E',
    fontFamily: 'Poppins',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    marginTop: '24px',
  },
  verSuc_url: {
    display: 'flex',
    background: '#E8ECF2',
    padding: '10px 12px',
    borderRadius: '8px',
    margin: '24px 0 40px 0',
    width: 'max-content',
    alignItems: 'center',
  },
  card: {
    width: '100%',
    height: 'calc(100vh - 120px)',
    boxShadow: 'none',
  },
  learn_more: {
    disolay: 'inline-block',
    marginLeft: '12px',
    color: '#3C6FF5',
    cursor: 'pointer',
    fontWeight: 600,
  },
  paper: {
    p: 3,
    boxShadow: '0px 6px 12px 0px rgba(230, 233, 246, 0.50)',
  },
  box: {
    '.MuiTypography-button': { fontFamily: 'none', fontSize: '14px', fontWeight: 400 },
  },
  image: {
    width: 172,
    height: 172,
  },
  mobile_image: {
    width: 105,
    height: 105,
  },
  typo: {
    textAlign: 'center',
    mt: 3,
    fontSize: '14px',
  },
  mb_1: {
    mb: 1,
  },
  mt_5: {
    mt: 5,
  },
  add_fee: {
    color: '#1F2025',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    linHeight: '22px',
  },
  auto_box: {
    '.MuiAutocomplete-popupIndicator': {
      color: 'rgb(255 255 255/ 0%)',
    },
    '.MuiAutocomplete-clearIndicator': {
      marginRight: '16px',
    },
    position: 'relative',
    // '.MuiFormHelperText-contained': {
    //   position: 'absolute',
    //   top: '54px',
    // },
  },
  center: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    right: '10px',
  },
  set_up: {
    cursor: 'pointer',
    color: '#3C6FF5',
    fontFamily: 'Poppins',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '22px',
    display: 'flex',
  },
  address_item: {
    color: '#1F2025',
    textAlign: 'center',
    fonFamily: 'Poppins',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px',
    display: 'flex',
    alingeItems: 'center',
    padding: '4px 8px',
    background: '#F8F9FB',
    borderRadius: '50px',
    marginRight: '8px',
  },
  avater: {
    width: 12,
    height: 12,
    ml: 2.75,
    mr: 1.75,
    bgcolor: '#3C6FF5',
  },
  mt12_12_1: {
    width: 12,
    height: 12,
    mr: 1,
    bgcolor: '#3C6FF5',
  },
  avater_1: {
    width: 24,
    height: 24,
    mr: 1,
    ml: 2,
  },
  chain_status: {
    padding: '4px 10px',
    borderRadius: '8px',
    marginLeft: '8px',
    fontSize: '12px',
    fontWeight: 500,
  },
  address_tip: {
    color: '#000',
    textAlign: 'center',
    fontFamily: 'Poppins',
    fontSize: '12px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '18px' /* 150% */,
  },
  acc: {
    '&.Mui-expanded': {
      margin: 0,
      padding: 0,
      boxShadow: 'none',
    },
    '.MuiAccordionDetails-root': {
      padding: 0,
    },
    '.MuiAccordionSummary-root': {
      padding: 0,
    },
    '.MuiAccordionSummary-content': {
      margin: '0 !important',
    },
  },
  accs: {
    '&.Mui-expanded': {
      margin: '0 !important',
      padding: 0,
      minHeight: '38px',
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
      marginRight: '8px',
    },
  },
  busin: {
    fontWeight: 600,
    fontSize: '16px',
  },
  alignItems: {
    display: 'flex',
    alignItems: 'center',
  },
  btn: {
    ':hover': {
      backgroundColor: '#1E387B',
    },
  },
  mr4: {
    marginRight: '4px',
  },
  mt_4: {
    marginTop: '-4px',
  },
  mt_16: {
    marginTop: '16px',
  },
  mt36: {
    marginTop: '36px',
  },
  mt43: {
    marginTop: '43px',
  },
  myIcon: {
    position: 'absolute',
    left: '14px',
    bottom: '-2px',
  },
  logo: {
    width: 24,
    height: 24,
    mr: 1,
  },
  verficStatus: {
    borderRadius: '8px',
    padding: '8px 16px',
    fontWeight: 600,
    fontSize: '14px',
  },
  successful: {
    background: '#D8FBDE',
    color: '#0A5554',
  },
  warning: {
    background: '#FFF5CC',
    color: '#7A4100',
    display: 'flex',
    alignItems: 'center',
  },
  contart: {
    color: '#00B8D9',
    display: 'inline-block',
    marginLeft: '6px',
    cursor: 'pointer',
  },
  step: {
    display: 'inline-block',
    width: '20px',
    height: '20px',
    borderRadius: '20px',
    background: '#B3B9C5',
    color: '#fff',
    textAlign: 'center',
    lineHeight: '20px',
    fontSize: '12px',
  },
  step_text: {
    fontSize: '16px',
    fontWeight: 600,
  },
  creat_loading: {
    flexShrink: 0,
    padding: '12px 24px',
    height: '48px',
    marginLeft: '24px',
    ':hover': {
      background: '#1E387B',
    },
  },
};

export const SettingPaper = styled(Paper)(({ theme }) => ({
  padding: '24px',
}));

export const AutoBox = styled(Box)(({ theme }) => ({
  '.MuiAutocomplete-popupIndicator': {
    color: 'rgb(255 255 255/ 0%)',
  },
  '.MuiAutocomplete-clearIndicator': {
    marginRight: '16px',
  },
  position: 'relative',
  '.MuiFormHelperText-contained': {
    position: 'absolute',
    top: '54px',
  },
}));
