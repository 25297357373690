import React, { FC } from 'react'
import styled from 'styled-components';

interface ButtonProps {
  children?: React.ReactNode | React.ReactNode[];
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  size?: "large" | "medium" | "small",
  second?: boolean,
  third?: boolean,
  forth?: boolean,
  type?: "default" | "primary" | "success" | "danger"
}

const ColorButton: FC<ButtonProps> = ({
  children,
  className,
  disabled,
  onClick,
  size = "large",
  second,
  third,
  forth,
  type = "primary"
}) => (
  <ColorButtonWrap
    onClick={onClick}
    className={`pay-button ${className} a-btn-${type} a-btn-size-${size} ${second && 'a-btn-second'} ${third && 'a-btn-third'} ${forth && 'a-btn-forth'}`}
    disabled={disabled}
  >
    {children}
  </ColorButtonWrap>
)

export default ColorButton;

const ColorButtonWrap = styled.button`
    border-radius: 8px;
    border: none;
    outline: none;
    cursor: pointer;
    font-family: Poppins;
    font-weight: 600;
    color: #1E1E1E;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease-out;
    &:active {
        transform: scale(0.95);
    }
    &.a-btn-size-large {
        padding: 11px 24px;
        height: 48px;
        line-height: 26px;
        font-size: 16px;
    }
    &.a-btn-size-medium {
        padding: 6px 16px;
        height: 40px;
        line-height: 24px;
        font-size: 14px;
    }
    &.a-btn-size-small {
        padding: 4px 12px;
        height: 32px;
        line-height: 22px;
        font-size: 12px;
    }
    &.a-btn-default {
        background: var(--Grey-200, #E8ECF2);
        color: var(--Text-light-main, var(--Text-main, #1F2025));
        &:active {
            background: var(--Grey-300, #CACFD8);
        }
        &:hover {
            background: var(--Grey-300, #CACFD8);
        }
        &.a-btn-second {
            background: var(--Grey-100, #F8F9FB);
            color: var(--Text-light-main, var(--Text-main, #1F2025));
            &:active {
                background: var(--Grey-200, #E8ECF2);
            }
            &:hover {
                background: var(--Grey-200, #E8ECF2);
            }
        }
        &.a-btn-third {
            border: 1px solid var(--Grey-200, #E8ECF2);
            background: #fff;
            color: var(--Text-light-main, var(--Text-main, #1F2025));
            &:active {
                background: var(--Grey-100, #F8F9FB);
            }
            &:hover {
                background: var(--Grey-100, #F8F9FB);
            }
        }
        &.a-btn-forth {
            background: unset;
            color: var(--Primary-main, var(--Primary-main, #3C6FF5));
            &:active {
                background: rgba(60, 111, 245, 0.08);
                box-shadow: unset;
            }
            &:hover {
                background: rgba(60, 111, 245, 0.08);
                box-shadow: unset;
            }
        }
    }
    &.a-btn-primary {
        background: var(--Primary-main, #3C6FF5);
        color: var(--Common-white, #FFF);
        &:active {
            background: var(--Primary-dark, #1E3EB0);
            box-shadow: 0px 8px 16px 0px rgba(60, 111, 245, 0.24);
        }
        &:hover {
            background: var(--Primary-dark, #1E3EB0);
            box-shadow: 0px 8px 16px 0px rgba(60, 111, 245, 0.24);
        }
        &.a-btn-second {
            background: rgba(60, 111, 245, 0.16);
            color: var(--Primary-dark, var(--Primary-dark, #1E3EB0));
            &:active {
                background: rgba(60, 111, 245, 0.32);
            }
            &:hover {
                background: rgba(60, 111, 245, 0.32);
            }
        }
        &.a-btn-third {
            border: 1px solid var(--Primary-main, #3C6FF5);
            background: #fff;
            color: var(--Primary-main, var(--Primary-main, #3C6FF5));
            &:active {
                background: rgba(60, 111, 245, 0.08);
            }
            &:hover {
                background: rgba(60, 111, 245, 0.08);
            }
        }
        &.a-btn-forth {
            background: unset;
            color: var(--Primary-main, var(--Primary-main, #3C6FF5));
            &:active {
                background: rgba(60, 111, 245, 0.08);
                box-shadow: unset;
            }
            &:hover {
                background: rgba(60, 111, 245, 0.08);
                box-shadow: unset;
            }
        }
    }
`;