// @mui
import { List, Stack } from '@mui/material';
// locales
// import { useLocales } from '../../../locales';
//
import { NavSectionProps } from '../types';
// import { StyledSubheader } from './styles';
import NavList from './NavList';
import { NAV } from '@/config';

export default function NavSectionVertical({ data, sx, isFold, ...other }: NavSectionProps) {
  // const { translate } = useLocales();
  return (
    <Stack sx={sx} {...other}>
      {data.map((group) => {
        const key = group.subheader || group.children[0].title;

        return (
          <List
            key={key}
            disablePadding
            sx={{
              px: isFold ? 1 : 2,
              width: isFold ? NAV.W_DASHBOARD_MINI : NAV.W_DASHBOARD,
            }}
          >
            {/* {group.subheader && (
              <StyledSubheader disableSticky>{translate(group.subheader)}</StyledSubheader>
            )} */}

            {group.children.map((list) => (
              <NavList
                key={list.title + list.path}
                data={list}
                depth={1}
                hasChild={!!list.children}
                isFold={isFold}
              />
            ))}
          </List>
        );
      })}
    </Stack>
  );
}
