import React from 'react';
import IconList from './icon';
export type IconNames = keyof typeof IconList;
export interface IconProps extends React.ImgHTMLAttributes<HTMLImageElement> {
  name: IconNames;
  fill?: string;
  className?: string;
  style?: React.CSSProperties;
  onClick?(e: React.MouseEvent): void;
  size?: number;
}

export const MyIcon = React.memo(
  React.forwardRef(function Icon(
    { name, className = '', style, onClick, size = 14, ...rest }: IconProps,
    ref
  ) {
    const width = size + 'px';
    return (
      <img
        ref={ref as any}
        alt={`icon-${name}`}
        src={IconList[name]}
        className={className}
        style={{
          width,
          ...style,

        }}
        onClick={onClick}
        {...rest}
      />
    );
  })
);
