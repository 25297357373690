import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  styled,
  Typography,
} from '@mui/material';
import { TextFieldElement, FormContainer, useForm } from 'react-hook-form-mui';
import { useSnackbar } from 'notistack';
import { MyIcon } from '@/assets/icons/MyIcon';
import { LoadingButton } from '@mui/lab';
import { useLocales } from '@/locales';
import { accountApi_ } from '@/api';
import { useState } from 'react';
import useResponsive from '@/hooks/useResponsive';

type Props = {
  open: boolean;
  onClose: (isConfirm?: boolean, id?: string) => void;
};

export default function DialogAddMerchanttForm({ open, onClose }: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const { translate: t } = useLocales();
  const [loading, setLoading] = useState(false);
  const isMobile = !useResponsive('up', 'sm');

  const formContext = useForm({
    defaultValues: { merchant_name: '' },
  });
  const { handleSubmit, watch } = formContext;
  const merchantName = watch('merchant_name');

  const onConfirm = () => {
    const dom: HTMLInputElement = document.querySelector('#DialogAddMerchanttForm-Submit')!;
    dom.click();
  };

  const onOK = async (data: any) => {
    setLoading(true);
    try {
      const res = await accountApi_.addMerchant(data.merchant_name);

      formContext.reset();
      enqueueSnackbar(t('nav.Add_New_Merchant_dialog_success_desc'), { variant: 'success' });
      await onClose(true, res?.merchant_id);
      setLoading(false);
    } catch (e) {
      if (e?.code) {
        enqueueSnackbar(t(`new_interface_err.${e.code}`), { variant: 'error' });
      } else {
        enqueueSnackbar(e?.message, { variant: 'error' });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <AddMerchanttFormWrap>
      <Dialog
        open={open}
        onClose={() => {
          onClose();
        }}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        sx={{ width: isMobile ? '100vw' : 480, mx: 'auto' }}
        PaperProps={{
          sx: { m: 0, width: '100%' },
        }}
      >
        <DialogTitle id="scroll-dialog-title">
          <Box className="flex justify-between items-center">
            <Typography variant="h5" component="span" className="Add_New_Merchant_dialog_title">
              {t('nav.Add_New_Merchant_dialog_title')}
            </Typography>
            <IconButton onClick={() => onClose()}>
              <MyIcon name="close" size={14} />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent sx={{ pt: 1 }}>
          <FormContainer formContext={formContext} handleSubmit={handleSubmit(onOK)}>
            <Typography className="Add_New_Merchant_dialog_desc">
              {t('nav.Add_New_Merchant_dialog_desc')}
            </Typography>
            <TextFieldElement
              name="merchant_name"
              fullWidth
              placeholder={t('nav.Add_New_Merchant_dialog_placeholder')}
              autoComplete={'off'}
              className="Add_New_Merchant_dialog_placeholder"
              sx={{
                mt: 2,
                fontWeight: 500,
                fontSize: '16px',
                lineHeight: '24px',
                letterSpacing: '0.15px',
              }}
            />
            <input id="DialogAddMerchanttForm-Submit" type="submit" hidden />
          </FormContainer>
        </DialogContent>
        <DialogActions className="loading-btn">
          <LoadingButton
            disabled={Boolean(!merchantName)}
            color="primary"
            variant="contained"
            type="submit"
            fullWidth
            loading={loading}
            loadingIndicator={<MyIcon name="loading" className="loading-icon-rotate" />}
            onClick={onConfirm}
            className="btn-height48"
          >
            {t('nav.Add_New_Merchant_dialog_btn')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </AddMerchanttFormWrap>
  );
}

const AddMerchanttFormWrap = styled('div')(() => ({
  '.Add_New_Merchant_dialog_title': {
    fontSize: '18px',
    fontWeight: 700,
    lineHeight: '16px',
    letterSpacing: '0.5px',
    color: '#212b36',
  },
  '.Add_New_Merchant_dialog_desc': {
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '0.15px',
    color: '#1e1e1e',
  },
}));
