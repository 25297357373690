import { MyIcon } from '@/assets/icons/MyIcon';
import { Box, IconButton, Link } from '@mui/material';
import NotificationSwiper from './NotificationSwiper';
import useSWR from 'swr';
import { useEffect, useState } from 'react';
import { dashboardApi_ } from '@/api';
import 'swiper/css';
import { useAuthContext } from '@/auth/useAuthContext';
import { CCP_URL, LAYOUTTOP } from '@/config';
import { useLocales } from '@/locales';

export default function Announcement() {
  const { setShowAnnouncement } = useAuthContext();
  const [notify, setNotify] = useState(false);
  const { translate } = useLocales();

  const refreshIntervalNum = 1 * 60 * 1000;

  const { data } = useSWR(
    'getnotificationList-Announcement',
    () => dashboardApi_.getnotificationList(),
    {
      refreshInterval: refreshIntervalNum,
      revalidateOnFocus: false,
    },
  );

  useEffect(() => {
    if (data?.list && data?.list?.length > 0) {
      setNotify(true);
      setShowAnnouncement(true);
    } else {
      setNotify(false);
      setShowAnnouncement(false);
    }
    // eslint-disable-next-line
  }, [data?.list]);

  return (
    <>
      {notify && (
        <Box
          display={'flex'}
          justifyContent={'space-between'}
          alignItems={'center'}
          sx={{
            height: `${LAYOUTTOP}px`,
            background: '#D8E7FE',
            // borderRadius: '8px',
            padding: '0 16px',

            position: 'fixed',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 999,
          }}
          mb={3}
          // mt={3}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <MyIcon name="ic_announcement" size={24} style={{ marginRight: '8px' }} />
            <Box py={1} sx={{ width: '100%' }}>
              <NotificationSwiper notificationList={data?.list || []} />
            </Box>
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <Link
              component={'a'}
              mr={3}
              fontSize={12}
              fontWeight={600}
              whiteSpace={'nowrap'}
              href={`${CCP_URL}support-center/announcement/new-features`}
              target="_blank"
            >
              {translate('common.view_more')}
            </Link>
            <IconButton
              onClick={() => {
                setNotify(false);
                setShowAnnouncement(false);
              }}
            >
              <MyIcon name="ic_notifi_close" size={20} />
            </IconButton>
          </Box>
        </Box>
      )}
    </>
  );
}
