import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { TextFieldElement, FormContainer, useForm } from 'react-hook-form-mui';
import GetEmailCode from '@/components/get-code/email';
import SecurityPart, { VerifyPart } from '../security-form';
import { MyIcon } from '@/assets/icons/MyIcon';
import { LoadingButton } from '@mui/lab';
import { useEffect, useState } from 'react';
import { useLocales } from '@/locales';
import { observer } from 'mobx-react';
import { UIState } from '../workflow';
import { useAuthContext } from '@/auth/useAuthContext';
import { EMAILRULE } from '@/config';
import { accountApi_ } from '@/api';
import CommonDialog from '../components/CommonDialog';

export default observer(DialogEmailChangeForm);

function DialogEmailChangeForm({
  visible,
  onClose,
}: {
  visible: boolean;
  onClose: (payload: any) => void;
}) {
  const { translate: t } = useLocales();
  const { user } = useAuthContext();
  const [isRegister, setIsRegister] = useState(false);

  const formContext = useForm({
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });

  const onConfirm = () => {
    const dom: HTMLInputElement = document.querySelector('#DialogEmailChangeForm-Submit')!;
    dom.click();
  };

  const onOK = async (data: any) => {
    await onClose(data);
  };

  const nextEmail = formContext.watch('email');

  const GetSafetyInfoByEmail = async () => {
    if (!nextEmail) return;
    try {
      await accountApi_.getSafetyInfoByEmail(nextEmail);
      setIsRegister(true);
    } catch (error) {
      setIsRegister(false);
      console.log('error', error);
    }
  };

  useEffect(
    () => formContext.reset(),
    // eslint-disable-next-line
    [visible]
  );

  useEffect(() => {
    formContext.trigger('email');
    // eslint-disable-next-line
  }, [isRegister]);

  return (
    <CommonDialog open={visible} onClose={() => onClose(null)}>
      <DialogTitle id="scroll-dialog-title">
        <Box className="flex justify-between items-center">
          <Typography variant="h5" component="span">
            {t('merchantSettings.security_email_dialog_title2')}
          </Typography>
          <IconButton onClick={() => onClose(false)}>
            <MyIcon name="close" size={14} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ pt: 1 }}>
        <FormContainer formContext={formContext} handleSubmit={formContext.handleSubmit(onOK)}>
          <TextFieldElement
            name="email"
            label="New Email Address"
            fullWidth
            sx={{ mb: 2 }}
            validation={{
              required: true,
              validate: (val) => {
                if (val === user?.email || isRegister) {
                  return 'Account already exists';
                } else {
                  if (!EMAILRULE.test(val)) {
                    return 'Please enter a valid email address';
                  }
                }
                return true;
              },
            }}
            InputProps={{
              onBlur: () => {
                GetSafetyInfoByEmail();
              },
            }}
          />
          <TextFieldElement
            label={t('merchantSettings.security_Recipient_dialog_emailCode_desc')}
            name="code"
            fullWidth
            autoComplete={'off'}
            inputProps={{
              maxLength: 6,
            }}
            InputProps={{
              endAdornment: <GetEmailCode email={nextEmail} type={'new'} />,
            }}
            helperText={t('merchantSettings.security_email_dialog_help_text1', {
              email: nextEmail ?? t('merchantSettings.security_email_dialog_help_text2'),
            })}
            sx={{ mb: 2 }}
            validation={{ required: true }}
          />
          <SecurityPart currentVerifyPart={VerifyPart.eMail} type={'old'} />
          <input id="DialogEmailChangeForm-Submit" type="submit" hidden />
        </FormContainer>
      </DialogContent>
      <DialogActions className="loading-btn">
        <LoadingButton
          color="primary"
          variant="contained"
          type="submit"
          fullWidth
          onClick={onConfirm}
          className="btn-height48"
          loading={UIState.loading}
          loadingIndicator={<MyIcon name="loading" className="loading-icon-rotate" />}
        >
          {t('common.Confirm')}
        </LoadingButton>
      </DialogActions>
    </CommonDialog>
  );
}
