import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  InputAdornment,
  Typography,
} from '@mui/material';
import { TextFieldElement, FormContainer, useForm } from 'react-hook-form-mui';
import { useEffect, useMemo, useState } from 'react';
import SecurityPart, { VerifyPart } from '../security-form';
import { MyIcon } from '@/assets/icons/MyIcon';
import { LoadingButton } from '@mui/lab';
import { useLocales } from '@/locales';
import { observer } from 'mobx-react';
import { UIState } from '../workflow';
import { useAuthContext } from '@/auth/useAuthContext';
import CommonDialog from '../components/CommonDialog';

export default observer(DialogPayPasswordChangeForm);

function DialogPayPasswordChangeForm({
  visible,
  onClose,
}: {
  visible: boolean;
  onClose: (payload: any) => void;
}) {
  const { translate: t } = useLocales();
  const formContext = useForm({
    defaultValues: {
      pay_pass: '',
      password2: '',
    },
    mode: 'onBlur',
    reValidateMode: 'onBlur',
  });
  const { user } = useAuthContext();
  const { handleSubmit, getValues } = formContext;
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const onConfirm = () => {
    const dom: HTMLInputElement = document.querySelector('#DialogPasswordChangeForm-Submit')!;
    dom.click();
  };

  const onOK = async (data: any) => {
    await onClose(data);
  };

  const pay_pass = formContext.watch('pay_pass');

  const passwordValidation = useMemo(
    () => ({
      required: true,
      minLength: {
        value: 6,
        message: t('merchantSettings.security_pay_password_dialog_helpText3'),
      },
      maxLength: {
        value: 18,
        message: t('merchantSettings.security_pay_password_dialog_helpText3'),
      },
      // validate: (val: string) => {
      //   if (!/[a-z]/.test(val)) return t('merchantSettings.security_password_dialog_helpText4');
      //   if (!/[A-Z]/.test(val)) return t('merchantSettings.security_password_dialog_helpText4_2');
      //   if (!/[0-9]/.test(val)) return t('merchantSettings.security_password_dialog_helpText5');
      //   if (!/[^a-zA-Z0-9\s]/.test(val))
      //     return t('merchantSettings.security_password_dialog_helpText6');
      //   return true;
      // },
      deps: 'pay_pass',
    }),
    // eslint-disable-next-line
    [pay_pass]
  );

  useEffect(
    () => formContext.reset(),
    // eslint-disable-next-line
    [visible]
  );

  return (
    <CommonDialog open={visible} onClose={() => onClose(null)}>
      <DialogTitle id="scroll-dialog-title">
        <Box className="flex justify-between items-center">
          <Typography variant="h5" component="span">
            {user?.exists_pay_pass
              ? t('merchantSettings.security_pay_password_dialog_title2')
              : t('merchantSettings.security_pay_password_dialog_title3')}
          </Typography>
          <IconButton onClick={() => onClose(null)}>
            <MyIcon name="close" size={14} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        sx={{
          paddingTop: '24px !important',
          // '.MuiOutlinedInput-notchedOutline': {
          //   border: '1px solid #E6E8E0',
          //   span: {
          //     color: '#73756D',
          //   },
          // },
          // '.MuiFormLabel-root': {
          //   color: '#73756D',
          // },
          // '.MuiFormHelperText-root': {
          //   color: '#73756D',
          // },
        }}
      >
        <FormContainer formContext={formContext} handleSubmit={handleSubmit(onOK)}>
          <TextFieldElement
            name="pay_pass"
            label={t('merchantSettings.security_password_dialog_label1')}
            type={`${showPassword ? 'text' : 'password'}`}
            fullWidth
            helperText={t('merchantSettings.security_pay_password_dialog_helpText3')}
            sx={{
              mb: 3,
            }}
            validation={passwordValidation}
            autoComplete="new-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{ marginRight: '-6px' }}
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <MyIcon
                      name={`${showPassword ? 'wallet_open_eye' : 'wallet_close_eye'}`}
                      size={20}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputProps={{
              maxLength: 18,
              minLength: 6,
            }}
          />
          <TextFieldElement
            name="password2"
            autoComplete="new-password"
            label={t('merchantSettings.security_pay_password_dialog_label1')}
            type={`${showConfirmPassword ? 'text' : 'password'}`}
            fullWidth
            sx={{ mb: 3 }}
            validation={{
              validate: (val) =>
                val === getValues('pay_pass') ||
                t('merchantSettings.security_password_dialog_helpText2'),
              deps: 'password',
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    sx={{ marginRight: '-6px' }}
                    onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                    edge="end"
                  >
                    <MyIcon
                      name={`${showConfirmPassword ? 'wallet_open_eye' : 'wallet_close_eye'}`}
                      size={20}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            inputProps={{
              maxLength: 18,
              minLength: 6,
            }}
          />
          <SecurityPart currentVerifyPart={VerifyPart.password} type={'old'} />
          <input id="DialogPasswordChangeForm-Submit" type="submit" hidden />
        </FormContainer>
      </DialogContent>
      <DialogActions className="loading-btn">
        <LoadingButton
          color="primary"
          variant="contained"
          type="submit"
          fullWidth
          onClick={onConfirm}
          className="btn-height48"
          loading={UIState.loading}
          loadingIndicator={<MyIcon name="loading" className="loading-icon-rotate" />}
        >
          {t('common.Confirm')}
        </LoadingButton>
      </DialogActions>
    </CommonDialog>
  );
}
