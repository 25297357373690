import CryptoJS from 'crypto-js';

const keysArr = [
  'U&4)LbDKG*WZnJsF7mgM7^gCtw4TEb9^',
  'iE_s~x@3w5n8MFDXg7iv(xSi1DSdzM4S',
  'MIXf+G+RT2dnI~6REeS(ruB%EgCgA4%G',
  '4S&zB#V3BUabCwxQc#N^b4+QxXd1F4Z(',
  '0a^)XhafgKL*&Ec(lO9*~0c%V6G!VsQj',
  'DEgBV1A6OuGdsUT0qVA3y14~yj)Vypwx',
  '4Sq~G^#@q!!~DSp4@0ah^_4S+N^aisJp',
  't*#LiCksKH1EiBXlYI~88G)T9@r0jVsL',
  'BO6R9(j^b2YOzVwviyvc#!cWPM9bEg7g',
  '9XwDB@rnDKIW%OB$adu4*YI#T4U@yxRE',
  'a)V0b5tkp2!!+MEp2#M6SiG!^dx@j#Ju',
  'FzEn6U(hh_2)P!VzOyqQjT8MN)XeM5mp',
  'w7bzXmpCg7fnMEa*&9bE^dxWZ&0ahd2b',
  'nIWU&6Jthac%O3yizU~KS)QlDLh+SbFA',
  'F4^c@tk1BZ+5vbwE&55i6VyuPL)YA6Hp',
  '6U^a(j~9!V0hj*_0%AxVB!~10fmnO7i7',
  'EtsRJkq)PPTyq%N(hf)Sf)$j!)ROAvZb',
  '8DYKIS)WPQsQk0buEePZq@5p4QfjRRMI',
  'H8A34$j%Cfh@A5vlJksDTG%RJmrjU_vh',
  '@rpE)Xo^gB#ZeX+6QmsR8A4ZiAva&8DJ',
  'ElIT9_0_yogVr0aabyvW@3sRB@2**S+I',
  'OwE_zRDLjV4~Ekp6DE(fok7i6Ap6P~FI',
  'wCg6DKSdrkoabCq~7eYTCtjQmdoafiBZ',
  'KG^b4@x~F9$eROyocV2+B!@w7od4T4T7',
  'HzOnKRFHzDKEk1FCjQuO1KDClUanE&7f',
  'NeLk7%Vo#Xo*@16PZfkzE_tdACsUZagD',
  'eXnLePV2^afgCx~9fh(kuHzPT85kv+H1',
  'zH0mw0jURM1DYNgQhaZ(q$wzOq!YBZ^b',
  ')HuE+Shi0a)I@2&Jkx^)PXmbAw2hbAuB',
  'pytix%CxWO0(x$sS&Crvdn7*^+RRKV0f',
  'OwtqYGeG#WJq&Crta_4YRNXth$17j)F8',
];

const ivs = [
  'JSJs*HMZDUy+TICm',
  'HMyt#8Ms$C93UZ8a',
  '+1t1ZkqBbp6+FMZH',
  'F~Dy*9ctawVEQ+R7',
  '#0AfpQtuN71k(7mQ',
  'Dozk$!i5D68Y4wKx',
  'QormtRjv0^3*h0&O',
  '#YWNIlcVksNc@C(3',
  'Kplzok1HU2N4DnC5',
  '*jDgMpWL~NSM&J#A',
  'pucM5+I_QVsHbi$Z',
  ')7c&OT19QzRZIu&S',
  'wcGbhIL_9gh+YN6Z',
  'Ac~CyQ5hZt0dbgZj',
  '^C!Rc1zD^6dMKQE7',
  '+OCdfkKDiGFmy+fK',
  'k~Kmx)Bphm@I#VO#',
  'StsuIymR0qd@h1Yf',
  '^9oX4%)r_$RdC)0x',
  'DNOrr_Lp*nx9bg@I',
  '4(aeYkzboh42Hdy!',
  'RQ$TE&9WgkXPsQ@l',
  'HOCzaaPFMw8W6RFg',
  'YY4YR4kLJ)!pC(9L',
  'hKif~*J824)dHc55',
  '523%EQUw5rb^m8B%',
  'r#92GXq(byc7a$SZ',
  '!gmC4O&Sjc5BBXEX',
  'Hb8VzWkd9y52gmmC',
  'w~JM5!l)~WUR^3vN',
  '9bg0jASHG$O9xVGP',
];

// const ALGORITHM = 'aes-256-cbc';
// const CIPHER_KEY = 'WWZ7rJswgQNELhGLMWFzdGAUhmCVV3Fe'; // Same key used in Golang
// const CIPHER_KEY = 'TNdzlNwI%6Ea65KV^FJhNGA2wQ!hwXtL'; // Same key used in Golang

// const text = 'mM~oTtnrQuB(3_20';
// const text ='Nb2SCmTH5OPFg0eu';
// const plainText = "1234567890";
// const plainText = JSON.stringify({
//   kdd: 'xxxxx',
//   name: 'xxxxx',
//   dt: 1687828805,
// });

function getKey(arr, currentTimes) {
  // const now = new Date();
  // const startOfYear = new Date(now.getFullYear(), 0, 0);
  // const diff = now - startOfYear;
  // const oneDay = 1000 * 60 * 60 * 24;
  // const dayOfYear = Math.floor(diff / oneDay);
  // const currentTimes = new Date().getTime();

  // console.log('当前日期是一年中的第 ' + dayOfYear + ' 天');
  return currentTimes % arr.length;
}

/** 解密 */
export function decrypt(cipherText, currentTimes) {
  // const iv = CryptoJS.enc.Utf8.parse(text);
  // const key = CryptoJS.enc.Utf8.parse(CIPHER_KEY);

  const iv = CryptoJS.enc.Utf8.parse(ivs[getKey(ivs, currentTimes)]);

  const key = CryptoJS.enc.Utf8.parse(keysArr[getKey(keysArr, currentTimes)]);

  const ciphertext = CryptoJS.enc.Hex.parse(cipherText);
  const decrypted = CryptoJS.AES.decrypt({ ciphertext: ciphertext }, key, {
    iv: iv,
    pad: CryptoJS.pad.Pkcs7,
  });
  const decryptedText = decrypted.toString(CryptoJS.enc.Utf8);

  return decryptedText;
}

/** 加密 */
export function encrypt(plainText, currentTimes) {
  // const iv = CryptoJS.enc.Utf8.parse(text);
  // console.log('currentTimes', currentTimes);
  // const key = CryptoJS.enc.Utf8.parse(CIPHER_KEY);
  const iv = CryptoJS.enc.Utf8.parse(ivs[getKey(ivs, currentTimes)]);

  const key = CryptoJS.enc.Utf8.parse(keysArr[getKey(keysArr, currentTimes)]);

  const cipher = CryptoJS.AES.encrypt(plainText, key, {
    iv: iv,
    pad: CryptoJS.pad.Pkcs7,
  });

  const cipherText = cipher.ciphertext.toString(CryptoJS.enc.Hex);
  return cipherText;
}
