// The svg image should be imported using import
import dollar from './img/dollar.svg';
import search_black from './img/search_black.svg';
import search_gray from './img/search_gray.svg';
import back_arrow from './img/back_arrow.svg';
import black_arrow from './img/black_arrow.svg';
import ic_arrow from './img/ic_arrow.svg';
import ccpayment from './img/ccpayment.svg';
import download from './img/download.svg';
import gray_open_eye from './img/gray_open_eye.svg';
import blue_open_eye from './img/blue_open_eye.svg';
import gray_close_eye from './img/gray_close_eye.svg';
import blue_close_eye from './img/blue_close_eye.svg';
import filters from './img/filters.svg';
import back_left_arrow from './img/back_left_arrow.svg';
import right_blue_arrow from './img/right_blue_arrow.svg';
import arrow_right from './img/arrow_right.svg';
import arrow_right_black from './img/arrow_right_black.svg';
import select_date from './img/select_date.svg';
import shape from './img/shape.svg';
import ic_doc from './img/ic_doc.svg';
import ic_doc_ from './img/ic_doc_.svg';
import copy from './img/ic_copy.svg';
import copy_blue from './img/ic_copy_blue.svg';
import ic_copy_gray from './img/ic_copy_gray.svg';
import ic_copy_ from './img/ic_copy_.svg';
import ic_success_fill from './img/ic_duigou.svg';
import ic_failed_fill from './img/ic_failed.svg';
import ic_failed2_fill from './img/ic_failed2.svg';
import success_opacity from './img/success_opcity.svg';
import pending_opacity from './img/pending_opacity.svg';
import failed_opacity from './img/failed_opacity.svg';
import ic_info from './img/ic_info.svg';
import ic_info_red from './img/ic_info_red.svg';
import ic_sign_inviter from './img/signIcon.svg';
import ic_lock from './img/ic_lock.png';
import ic_password from './img/ic_password.svg';
import ic_step_active from './img/ic_step_active.svg';
import arrow from './img/arrow.png';
import rise from './img/rise.png';
import decline from './img/decline.png';
import failed from './img/stepFaild.png';
import successful from './img/stepOk.png';
import pending from './img/stepPending.png';
import delete_out from './img/delete.png';
import notifition from './img/notifition.png';
import notifition2 from './img/notifition2.svg';
import notifition3 from './img/notifition3.svg';
import hollowClock from './img/hollowClock.png';
import banlanceOpenEye from './img/banlanceOpenEye.png';
import banlanceCloseEye from './img/banlanceCloseEye.png';
import close from './img/close.png';
import ic_pw_close from './img/ic_pw_close.svg';
import ic_pw_hook from './img/ic_pw_hook.svg';
import add_blue from './img/add_blue.svg';
import add_black from './img/add_black.svg';
import arrow_up from './img/arrow_up.svg';
import ic_time from './img/time.svg';
import edit from './img/edit_icon.svg';
import ccp_edit from './img/ccp_edit.svg';
import ccp_edit_gray from './img/ccp_edit_gray.svg';
import ccp_add from './img/ccp_add.svg';
import close_fill from './img/close-fill.png';
import close_gray from './img/close_gray.svg';
import ic_delet from './img/ic_delet.svg';
import loading from './img/loading-icon.svg';
import loading_green from './img/loading-icon-green.svg';
import loading_red from './img/loading-icon-red.svg';
import loading_yellow from './img/loading-icon-yellow.svg';
import loading_white from './img/loading-icon-white.svg';
import loading_black from './img/loading-icon-black.svg';
import ic_loading from './img/ic_loading.svg';
import ic_suggest from './img/ic_suggest.svg';
import ic_suggest_blue from './img/ic_suggest_blue.svg';
import ic_add from './img/ic_add.svg';
import ic_add2 from './img/ic_add2.svg';
import ic_add_ from './img/ic_add_.svg';
import ic_chevron_left from './img/ic_chevron_left.png';
import ic_down_triangle from './img/ic_down_triangle.png';
import developer from './img/developer.png';
import developer2 from './img/developer.svg';
import developer_blue from './img/developer_blue.svg';
import ic_attach from './img/attach.svg';
import ic_acc from './img/ic_acc.svg';
import ic_down_load from './img/ic_down_load.svg';
import ic_nav_flod_left from './img/ic_nav_flod_left.svg';
import ic_nav_flod_right from './img/ic_nav_flod_right.svg';
import ic_nav_wallet_flod_left from './img/ic_nav_wallet_flod_left.svg';
import ic_nav_wallet_flod_right from './img/ic_nav_wallet_flod_right.svg';
import ic_telegram from './img/telegram.svg';
import ic_twitter from './img/twitter.svg';
import ic_token_Pre_removal from './img/ic_token_Pre_removal.svg';
import ic_token_maintain from './img/ic_token_maintain.svg';
import ic_token_Withdrawal_warning from './img/ic_token_Withdrawal_warning.svg';
import ic_warning from './img/ic_warning.svg';
import ic_help_center2 from './img/ic_help_center.svg';
import ic_help_center from './img/ic_help_center2.svg';
import ic_help_center3 from './img/ic_help_center3.svg';
import ic_help_center_blue from './img/ic_help_center_blue.svg';
import ic_auth_google from './img/auth_google.svg';
import ic_wallet_doc from './img/ic_wallet_doc.svg';
import ic_wallet_add from './img/ic_wallet_add.svg';
import ic_wallet_end from './img/ic_wallet_end.svg';
import ic_wallet_back from './img/ic_wallet_back.svg';
import ic_wallet_edit from './img/ic_wallet_edit.svg';
import ic_wallet_addContract from './img/ic_wallet_addContract.svg';
import ic_wallet_more from './img/ic_wallet_more.svg';
import ic_wallet_balanceMore from './img/ic_wallet_balanceMore.svg';
import ic_wallet_delete from './img/ic_wallet_delete.svg';
import ic_mobile_delet from './img/ic_mobile_delet.svg';
import arrow_outline from './img/arrow-outline.svg';
import ic_wallet_info from './img/ic_wallet_info.svg';
import ic_wallet_add_coin from './img/ic_wallet_add_coin.svg';
import ic_wallet_right from './img/ic_wallet_right.svg';
import ic_link from './img/ic_link.svg';
import ic_swap from './img/ic_swap.svg';
import ic_swap_hover from './img/ic_swap_hover.svg';
import download_green from './img/download_green.svg';
import ic_wallet_ad from './img/ic-wallet-add.svg';
import ic_wallet_eye from './img/ic_wallet_eye.svg';
import ic_wallet_eyeClose from './img/ic_wallet_eyeClose.svg';
import ic_wallet_upline from './img/ic_wallet_upline.svg';
import ic_wallet_downLine from './img/ic_wallet_downLine.svg';
import ic_wallet_swap from './img/ic_wallet_swap.svg';
import arrow_red from './img/arrow_red.svg';
import ic_table_sort from './img/ic_table_sort.svg';
import ic_wallet_password from './img/ic_wallet_password.svg';
import header_setting from './img/header-setting2.svg';
import beginner from './img/beginner.svg';
import faqAdd from './img/faq-add.svg';
import faqFold from './img/faq-fold.svg';
import ic_wallet_arrow from './img/ic_wallet_arrow.svg';
import ic_bk_info from './img/ic_bk_info.svg';
import ic_wallet_link from './img/ic_wallet_link.svg';
import ic_wallet_book from './img/ic_wallet_book.svg';
import ic_wallet_card from './img/ic_wallet_card.svg';
import ic_wallet_user from './img/ic_wallet_user.svg';
import green_arrow from './img/green_arrow.svg';
import ic_wallet_merchant from './img/ic_wallet_merchant.svg';
import ic_wallet_r_arrow from './img/ic_wallet_r_arrow.svg';
import ic_wallet_t_arrow from './img/ic_wallet_t_arrow.svg';
import ic_wallet_d_arrow from './img/ic_wallet_d_arrow.svg';
import ic_wallet_r_l_arrow from './img/ic_wallet_r_l_arrow.svg';
import ic_wallet_dash_r_arrow from './img/ic_wallet_dash_r_arrow.svg';
import ic_wallet_b_arrow from './img/ic_wallet_b_arrow.svg';
import ic_wallet_warning from './img/ic_wallet_warning.svg';
import ic_login_waring from './img/ic_login_waring.svg';
import ic_collect_record_time from './img/collect-record-time.svg';
import ic_edit_green from './img/edit-green.svg';
import ic_info_black from './img/info-black.svg';
import ic_info_blue from './img/ic_info_blue.svg';
import ic_info_blue_ from './img/ic_info_blue_.svg';
import ic_more_gray from './img/ic_more_gray.svg';
import wallet_transaction_search from './img/wallet_transaction_search.svg';
import wallet_transaction_search_black from './img/wallet_transaction_search_black.svg';
import ic_close_info from './img/ic_close_info.svg';
import ic_wallet_successful from './img/ic_wallet_successful.svg';
import wallet_automatic_collection from './img/wallet_automatic_collection.svg';
import wallet_l_arrow from './img/wallet_l_arrow.svg';
import wallet_r_dash_arrow from './img/wallet_r_dash_arrow.svg';
import ic_wallet_added from './img/ic_wallet_added.svg';
import ic_wallet_dashed from './img/ic_wallet_dashed.svg';
import ic_payment_arrow from './img/ic_payment_arrow.svg';
import ic_payment_close from './img/ic_payment_close.svg';
import ccp_dia_add from './img/ccp_dia_add.svg';
import ic_address from './img/ic_address.svg';
import ic_network from './img/ic_network.svg';
import ic_balck_website from './img/ic_balck_website.svg';
import ic_black_address from './img/ic_black_address.svg';
import ic_black_twitter from './img/ic_black_twitter.svg';
import ic_black_tg from './img/ic_black_tg.svg';
import ic_payment_add from './img/ic_payment_add.svg';
import wallet_open_eye from './img/wallet_open_eye.svg';
import wallet_close_eye from './img/wallet_close_eye.svg';
import total_addresses from './img/total_addresses.svg';
import available_adresses from './img/available_adresses.svg';
import transfer_times from './img/transfer_times.svg';
import refresh_gray from './img/refresh_gray.svg';
import refresh_green from './img/refresh_green.svg';
import referral_program from './img/referral_program.svg';
import referr_time from './img/referr_time.svg';
import check_box_ok from './img/check-box-ok.svg';
import walletLockTime from './img/walletLockTime.svg';
import fee_info from './img/fee_info.svg';
import cost_link from './img/cost_link.svg';
import referral_program_blue from './img/referral_program_blue.svg';
import cost_link_black from './img/cost_link_black.svg';
import v_succ from './img/v_succ.svg';
import v_notsucc from './img/v_notsucc.svg';
import close_fill_ from './img/close_fill_.svg';
import select_date_ from './img/select_date_.svg';
import edit_black from './img/edit-black.svg';
import delect_lis from './img/delect_lis.svg';
import config_bg from './img/config_bg.svg';
import invoice_bg from './img/invoice_bg.svg';
import close_fill_guide from './img/close_fill.svg';
import ic_notifi_close from './img/ic_notifi_close.svg';
import close_fill_hover from './img/close_fill_hover.svg';
import tag from './img/invoice_tag.svg';
import select_arrow from './img/select_arrow.svg';
import close_fill_363 from './img/close_fill_363.svg';
import ic_add3 from './img/ic_add_1e3eb0.svg';
import ic_nav_flod_left_ from './img/ic_nav_flod_left_.svg';
import ic_txt_file from './img/ic_txt_file.svg';
import vertification_suc from './img/vertification_suc.svg';
import vertification_war from './img/vertification_war.svg';
import add_icon from './img/add_icon.svg';
import pc_icon from './img/pc_icon.svg';
import mobile_icon from './img/mobile_icon.svg';
import balance_to_usd from './img/balance_to_usd.svg';
import balance_to_usd_pc from './img/balance_to_usd_pc.svg';
import ic_announcement from './img/ic_announcement.svg';
import arrow2 from './img/arrow2.png';
import ic_arrow_right from './img/ic_arrow_right.svg';
import ic_delet_ from './img/ic_delet_.svg';
import arrow_black2 from './img/arrow_black2.png';
import arrow_black2_hover from './img/arrow_black2_hover.png';
import clear from './img/clear.svg';
import arrow_ic from './img/arrow-ic.svg';
import swap_ic from './img/swap_ic.svg';
import document_ic from './img/document_ic.svg';
import ic_assets from './img/ic_assets.svg';

const IconList = {
  arrow,
  arrow2,
  rise,
  decline,
  faild: failed,
  failed,
  successful,
  pending,
  delete: delete_out,
  notifition,
  notifition2,
  notifition3,
  hollowClock,
  banlanceOpenEye,
  banlanceCloseEye,
  close,
  dollar,
  search_black,
  search_gray,
  back_arrow,
  black_arrow,
  arrow_right,
  ccpayment,
  download,
  gray_open_eye,
  gray_close_eye,
  filters,
  back_left_arrow,
  right_blue_arrow,
  select_date,
  shape,
  ic_doc,
  ic_doc_,
  ic_delet_,
  copy,
  ic_success_fill,
  ic_failed_fill,
  ic_failed2_fill,
  ic_info,
  ic_lock,
  ic_password,
  ic_step_active,
  copy_blue,
  add_blue,
  add_black,
  arrow_up,
  ic_time,
  edit,
  close_fill,
  close_gray,
  ic_info_red,
  ic_delet,
  loading,
  loading_green,
  loading_red,
  ic_suggest,
  ic_add,
  ic_add2,
  ic_add3,
  ic_add_,
  ic_copy_,
  ic_suggest_blue,
  ic_chevron_left,
  ic_down_triangle,
  developer,
  developer2,
  developer_blue,
  ic_attach,
  ic_acc,
  ic_down_load,
  ic_nav_flod_left,
  ic_nav_flod_right,
  ic_telegram,
  ic_twitter,
  ic_token_Pre_removal,
  ic_token_maintain,
  ic_token_Withdrawal_warning,
  ic_help_center,
  ic_help_center2,
  ic_help_center_blue,
  ic_pw_close,
  ic_pw_hook,
  ic_auth_google,
  ic_wallet_doc,
  ic_wallet_add,
  ic_wallet_end,
  ic_wallet_back,
  ic_wallet_edit,
  ic_wallet_addContract,
  ic_wallet_more,
  ic_wallet_balanceMore,
  ic_wallet_delete,
  arrow_outline,
  ic_wallet_info,
  ic_wallet_add_coin,
  ic_wallet_ad,
  ic_wallet_right,
  ic_link,
  ic_nav_wallet_flod_right,
  ic_nav_wallet_flod_left,
  ic_arrow,
  ic_swap,
  ic_swap_hover,
  download_green,
  ic_wallet_eye,
  ic_wallet_eyeClose,
  ic_wallet_upline,
  ic_wallet_downLine,
  ic_wallet_swap,
  arrow_red,
  ic_table_sort,
  ic_help_center3,
  ic_wallet_password,
  header_setting,
  beginner,
  faqAdd,
  faqFold,
  ic_wallet_arrow,
  ic_bk_info,
  ic_wallet_link,
  ic_wallet_book,
  ic_wallet_card,
  ic_wallet_user,
  green_arrow,
  ic_wallet_merchant,
  ic_wallet_r_arrow,
  ic_wallet_t_arrow,
  ic_wallet_d_arrow,
  ic_wallet_r_l_arrow,
  ic_wallet_dash_r_arrow,
  ic_wallet_b_arrow,
  ic_wallet_warning,
  ic_collect_record_time,
  ic_edit_green,
  ic_info_black,
  ic_info_blue,
  ic_info_blue_,
  ic_more_gray,
  wallet_transaction_search,
  wallet_transaction_search_black,
  ic_close_info,
  ic_wallet_successful,
  wallet_automatic_collection,
  wallet_l_arrow,
  wallet_r_dash_arrow,
  ic_wallet_added,
  loading_yellow,
  ic_wallet_dashed,
  ic_payment_arrow,
  ic_payment_close,
  loading_white,
  loading_black,
  ic_loading,
  ccp_edit,
  ccp_add,
  ccp_dia_add,
  ic_address,
  ic_network,
  ic_balck_website,
  ic_black_address,
  ic_black_twitter,
  ic_black_tg,
  ic_payment_add,
  wallet_open_eye,
  wallet_close_eye,
  total_addresses,
  available_adresses,
  transfer_times,
  refresh_gray,
  refresh_green,
  referral_program,
  referr_time,
  check_box_ok,
  walletLockTime,
  fee_info,
  cost_link,
  referral_program_blue,
  cost_link_black,
  ic_copy_gray,
  ccp_edit_gray,
  v_succ,
  v_notsucc,
  close_fill_,
  select_date_,
  edit_black,
  delect_lis,
  config_bg,
  invoice_bg,
  close_fill_guide,
  ic_announcement,
  ic_notifi_close,
  tag,
  success_opacity,
  pending_opacity,
  failed_opacity,
  close_fill_hover,
  select_arrow,
  close_fill_363,
  ic_nav_flod_left_,
  ic_txt_file,
  vertification_suc,
  vertification_war,
  ic_sign_inviter,
  ic_login_waring,
  add_icon,
  pc_icon,
  mobile_icon,
  ic_warning,
  arrow_right_black,
  balance_to_usd,
  ic_arrow_right,
  ic_mobile_delet,
  blue_close_eye,
  blue_open_eye,
  balance_to_usd_pc,
  arrow_black2,
  arrow_black2_hover,
  clear,
  arrow_ic,
  swap_ic,
  document_ic,
  ic_assets,
};
export default IconList;
