import { preset, rem } from './lib';
import { YearMonthVirtualView } from './YearMonthVirtualView';
import styled from 'styled-components';
import { MyIcon } from '@/assets/icons/MyIcon';
import { MenuItem, TextField, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

//Jan，Feb，Mar，Apr，May，Jun，Jul，Aug，Sept，Oct，Nov，Dec

export const WEEKS = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

export const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

export type AllowSelect = {
  /** Allow choose to be a continuous interval */
  isConsequent?: boolean;
  allowSelect(calendar: YearMonthVirtualView, day: number): boolean;
};
type DayInRange = {
  in: boolean;
  begin: boolean;
  end: boolean;
};
const currentYear = new Date().getFullYear();

export default function CalendarView({
  calendar,
  setCalendar,
  allowSelect,
  onDayClick,
  isDaySelected,
  isDayInRange,
  minDate,
  yearChange,
}: {
  calendar: YearMonthVirtualView;
  setCalendar(v: YearMonthVirtualView): void;
  allowSelect?: AllowSelect;
  onDayClick(calendar: YearMonthVirtualView, day: number): void;
  isDaySelected(calendar: YearMonthVirtualView, day: number): boolean | undefined;
  isDayInRange?(calendar: YearMonthVirtualView, day: number): false | DayInRange;
  minDate?: number;
  yearChange: (year: number) => void;
}) {
  function isDayAllowSelect(calendar: YearMonthVirtualView, day: number): boolean {
    if (allowSelect) {
      return allowSelect.allowSelect(calendar, day);
    }
    return true;
  }
  function allowMonth(calendar: YearMonthVirtualView) {
    if (allowSelect?.isConsequent) {
      return isDayAllowSelect(calendar, 1) || isDayAllowSelect(calendar, 31);
    } else {
      return true;
    }
  }

  const beforeMonthSelect = allowMonth(calendar.lastMonth());
  const afterMonthSelect = allowMonth(calendar.nextMonth());
  const [yearVal, setYearVal] = useState(calendar.year);

  useEffect(() => {
    if (calendar.year) {
      setYearVal(calendar.year);
    }
    // eslint-disable-next-line
  }, [JSON.stringify(calendar.year)]);

  const theme = useTheme();
  // console.log(
  //   'MONTHS[calendar.month - 1]',
  //   MONTHS[calendar.month - 1],
  //   'calendar.month',
  //   calendar.month
  // );

  return (
    <CalendarWraper theme={theme}>
      <div className="year-month-picker">
        <div
          style={{
            width: '30%',
          }}
        >
          <TextField
            select
            // label="Select Network"
            // disabled={
            //   chainOptionsResp.isLoading ||
            //   !Boolean(chainOptionsResp.data?.list) ||
            //   (chainOptionsResp.data?.list &&
            //     chainOptionsResp.data?.list?.length === 1 &&
            //     tokensOptionsResp.data?.list &&
            //     tokensOptionsResp.data?.list?.length === 1)
            // }
            value={yearVal}
            onChange={(e) => {
              setYearVal(Number(e.target.value));
              setCalendar(new YearMonthVirtualView(Number(e.target.value), calendar.month, 7));
              yearChange(Number(e.target.value));
            }}
            SelectProps={{
              MenuProps: {
                PaperProps: {
                  sx: {
                    padding: '4px',
                    ul: {
                      maxHeight: 260,
                      overflow: 'auto',
                      li: {
                        fontSize: '14px',
                        fontWeight: 500,
                        padding: '8px',
                      },
                      '::-webkit-scrollbar': {
                        width: '6px',
                        height: '6px',
                        backgroundColor: '#fff',
                      },
                      '::-webkit-scrollbar-thumb': {
                        borderRadius: '12px',
                        backgroundColor: ' #b6bcc1',
                      },
                      '.Mui-selected': {
                        backgroundColor: 'rgba(145, 158, 171, 0.16)',
                        ':hover': {
                          backgroundColor: 'rgba(145, 158, 171, 0.08)',
                        },
                      },
                    },
                  },
                },
                sx: {
                  mt: 0,
                  top: '-10px',
                },
              },
              IconComponent: (val) => (
                <MyIcon
                  {...val}
                  name={'ic_down_triangle'}
                  size={16}
                  className={`${val.className}`}
                  style={{
                    top: 'calc(50% - 0.35em)',
                    // opacity: val.className.includes('Mui-disabled') ? 0.4 : 1,
                  }}
                />
              ),
              sx: {
                height: '56px',
              },
            }}
            sx={{
              width: '100%',
              borderRadius: '8px',
              border: 'none',
              '.MuiSelect-outlined': {
                border: 'none',
                pl: 0,
              },
              '.MuiOutlinedInput-input': {
                fontWeight: '500 !important',
              },
              '& .MuiOutlinedInput-notchedOutline': {
                borderRadius: '8px',
                border: 'none',
              },
              '.Mui-focused': {
                color: '#1E1E1E !important',
                border: 'none',
              },
              '.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: '#1E1E1E !important',
                borderWidth: '1px !important',
                border: 'none',
              },
            }}
          >
            {[currentYear, currentYear - 1, currentYear - 2, currentYear - 3, currentYear - 4].map(
              (item) => (
                <MenuItem
                  key={item}
                  value={item}
                  disabled={minDate ? item < new Date(minDate).getFullYear() : false}
                  sx={{
                    mx: 1,
                    my: 0.5,
                    fontSize: '16px',
                    fontWeight: 500,
                    borderRadius: 0.75,
                    // typography: 'body2',
                    padding: '6px',
                    // textTransform: 'capitalize',
                    '&:first-of-type': { mt: 0 },
                    '&:last-of-type': { mb: 0 },
                    textAlign: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {item}
                </MenuItem>
              )
            )}
          </TextField>
        </div>
        <div style={{ width: '60%' }}>
          <MyIcon
            name="arrow"
            size={24}
            className={`anchor ${beforeMonthSelect ? '' : 'disabled'}`}
            style={{ transform: 'rotate(90deg)', flexGrow: 0, height: '24px', cursor: 'pointer' }}
            onClick={() => {
              setCalendar(calendar.lastMonth());
              // if (beforeMonthSelect) {
              //   setCalendar(calendar.lastMonth())
              // }
            }}
          />
          <div className="view">
            {MONTHS[calendar.month - 1]}
            {/* {calendar.year} */}
          </div>
          {/* {
            !(new Date().getMonth() + 1 === calendar.month) && <MyIcon
              name="arrow"
              size={24}
              className={`anchor ${afterMonthSelect ? '' : 'disabled'}`}
              style={{
                transform: 'rotate(-90deg)',
                flexGrow: 0,
                height: '24px',
                cursor: 'pointer',
              }}
              onClick={() => {
                setCalendar(calendar.nextMonth());
                // if (afterMonthSelect) {
                //   setCalendar(calendar.nextMonth())
                // }
              }}
            />
          } */}
          <MyIcon
            name="arrow"
            size={24}
            className={`anchor ${afterMonthSelect ? '' : 'disabled'}`}
            style={{
              transform: 'rotate(-90deg)',
              flexGrow: 0,
              height: '24px',
              cursor: 'pointer',
            }}
            onClick={() => {
              setCalendar(calendar.nextMonth());
              // if (afterMonthSelect) {
              //   setCalendar(calendar.nextMonth())
              // }
            }}
          />
        </div>
      </div>
      <div className="week">
        {Array(7)
          .fill('')
          .map((_, i) => (
            <div key={i}>{WEEKS[calendar.weekDay(i)].slice(0, 1)}</div>
          ))}
      </div>
      <div className="day">
        {Array(calendar.firstDay > 5 ? 6 : 5)
          .fill('')
          .map((_, y) => (
            <div className="row" key={y}>
              <div className="background">
                {Array(7)
                  .fill('')
                  .map((_, x) => {
                    const o = calendar.fullDayOf(x, y);
                    let isInRange: DayInRange | boolean = false;
                    if (isDayInRange) {
                      isInRange =
                        (o.type === 'this' && isDayInRange(calendar, o.day)) ||
                        (o.type === 'last' && isDayInRange(calendar.lastMonth(), o.day)) ||
                        (o.type === 'next' && isDayInRange(calendar.nextMonth(), o.day));
                    }
                    const cls: string[] = [];
                    if (isInRange) {
                      cls.push('in-range');
                      if (isInRange.begin) {
                        cls.push('begin');
                      }
                      if (isInRange.end) {
                        cls.push('end');
                      }
                    }
                    return <div key={x} className={`cell ${cls.join(' ')}`} />;
                  })}
              </div>
              <div className="front">
                {Array(7)
                  .fill('')
                  .map((_, x) => {
                    const o = calendar.fullDayOf(x, y);

                    let onClick: (() => void) | undefined = undefined;
                    let className = '';
                    if (o.type === 'last') {
                      className = 'last';
                      const lastMonth = calendar.lastMonth();
                      if (isDayAllowSelect(lastMonth, o.day)) {
                        onClick = () => {
                          onDayClick(lastMonth, o.day);
                        };
                      }
                    } else if (o.type === 'next') {
                      className = 'next';
                      const nextMonth = calendar.nextMonth();
                      if (isDayAllowSelect(nextMonth, o.day)) {
                        onClick = () => {
                          onDayClick(nextMonth, o.day);
                        };
                      }
                    } else {
                      if (isDayAllowSelect(calendar, o.day)) {
                        onClick = () => {
                          onDayClick(calendar, o.day);
                        };
                      }
                    }

                    const isSelected =
                      (o.type === 'this' && isDaySelected(calendar, o.day)) ||
                      (o.type === 'last' && isDaySelected(calendar.lastMonth(), o.day)) ||
                      (o.type === 'next' && isDaySelected(calendar.nextMonth(), o.day));

                    return (
                      <div
                        key={x}
                        className={`cell ${className} ${isSelected ? 'selected' : ''} ${
                          onClick ? '' : 'disabled'
                        }`}
                        onClick={onClick}
                      >
                        {o.day}
                      </div>
                    );
                  })}
              </div>
            </div>
          ))}
      </div>
    </CalendarWraper>
  );
}

const CalendarWraper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: stretch;
  text-align: center;
  width: ${rem(320)};
  padding-left: ${rem(20)};
  padding-right: ${rem(20)};
  margin: 0 auto;

  > .year-month-picker {
    display: flex;
    justify-content: space-between;
    ${preset.fonts.black}
    font-size: ${rem(14)};
    /* margin-top: ${rem(4)}; */
    > * {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .view {
      flex: 1;
      font-weight: 600;
    }
    .anchor {
      width: ${rem(40)};
      height: ${rem(40)};
      &.disabled {
        color: gray;
      }
    }
  }
  > .week {
    display: flex;
    justify-content: space-between;
    > * {
      margin-top: ${rem(8)};
      width: ${rem(40)};
      height: ${rem(40)};

      font-size: ${rem(14)};
      text-align: center;
      text-transform: uppercase;
      color: #919eab;
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;

      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  > .day {
    display: flex;
    flex-direction: column;
    > .row {
      flex: 1;
      position: relative;
      margin-top: ${rem(2)};
      > .background {
        position: absolute;
        width: calc(100% + ((100% - (${rem(40)} * 7)) / 6));
        height: 100%;
        margin-left: calc(((${rem(40)} * 7) - 100%) / 12);
        margin-right: calc(((${rem(40)} * 7) - 100%) / 12);

        display: flex;
        > .cell {
          flex: 1;
          width: ${rem(40)};
          height: ${rem(40)};
          &.in-range {
            /* background: rgba(60, 111, 245, 0.1); */
            background-color: ${({ theme }: any) => theme.palette.primary.lighter};
          }
          &.begin {
            border-top-left-radius: ${rem(50)};
            border-bottom-left-radius: ${rem(50)};
            /* flex-grow: 0; */
          }
          &.end {
            border-top-right-radius: ${rem(50)};
            border-bottom-right-radius: ${rem(50)};
            /* flex-grow: 0; */
          }
        }
      }
      > .front {
        position: relative;
        width: 100%;
        height: 100%;

        display: flex;
        justify-content: space-between;
        > .cell {
          cursor: pointer;
          width: ${rem(40)};
          height: ${rem(40)};

          display: flex;
          align-items: center;
          justify-content: center;

          font-size: ${rem(14)};
          text-align: center;
          text-transform: uppercase;
          color: #000000;
          font-weight: 500;

          border-radius: ${rem(50)};
          &.last,
          &.next {
            color: gray;
          }
          &.disabled {
            /* background: rgb(249 249 249); */
            color: #dbdbdb;
            cursor: default;
          }
          &.selected {
            color: #fff;
            background-color: ${({ theme }: any) => theme.palette.primary.main};
          }
        }
      }
    }
  }
`;
