// @mui
import { Theme } from '@mui/material/styles';
import {
  Box,
  SxProps,
  Checkbox,
  TableRow,
  TableCell,
  TableHead,
  TableSortLabel,
  Tooltip,
  styled,
  TooltipProps,
  tooltipClasses,
} from '@mui/material';
import { useLocales } from '@/locales';

const visuallyHidden = {
  border: 0,
  margin: -1,
  padding: 0,
  width: '1px',
  height: '1px',
  overflow: 'hidden',
  position: 'absolute',
  whiteSpace: 'nowrap',
  clip: 'rect(0 0 0 0)',
} as const;

type Props = {
  order?: 'asc' | 'desc';
  orderBy?: string;
  headLabel: any[];
  rowCount?: number;
  numSelected?: number;
  onSort?: (id: string) => void;
  onSelectAllRows?: (checked: boolean) => void;
  sx?: SxProps<Theme>;
  type?: any;
};

export default function TableHeadCustom({
  order,
  orderBy,
  rowCount = 0,
  headLabel,
  numSelected = 0,
  onSort,
  onSelectAllRows,
  sx,
  type,
}: Props) {
  // console.log('typetype', type);
  const { translate } = useLocales();
  const NoMaxWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 'none',
      background: '#fff',
      color: '#1E1E1E',
      padding: '12px',
      fontWeight: 400,
      borderRadius: '8px',
      filter: 'drop-shadow(0px 0px 2px rgba(145, 158, 171, 0.36))',
    },
    [`& .MuiTooltip-arrow::before`]: {
      color: '#fff',
    },
  });
  return (
    <TableHead sx={sx} className="table-x-header">
      <TableRow>
        {onSelectAllRows && (
          <TableCell padding="checkbox">
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                onSelectAllRows(event.target.checked)
              }
            />
          </TableCell>
        )}

        {headLabel.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.align || 'left'}
            sortDirection={orderBy === headCell.id ? order : false}
            className={`${headCell?.isShadow ? headCell?.classNames : ''}`}
            sx={{
              width: headCell.width,
              minWidth: headCell.minWidth,
              whiteSpace: 'nowrap',
              '.label': {
                color: '#919EAB',
                borderBottom: '1px dashed #919EAB',
                cursor: 'pointer',
              },
            }}
          >
            {onSort ? (
              <TableSortLabel
                hideSortIcon
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={() => onSort(headCell.id)}
                sx={{ textTransform: 'capitalize' }}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box sx={{ ...visuallyHidden }}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              (headCell.id === 'hover_id' && (
                <NoMaxWidthTooltip
                  placement="right"
                  title={translate('tradingOrer.Transaction_ID2_desc')}
                >
                  <span className="label"> {headCell.label}</span>
                </NoMaxWidthTooltip>
              )) ||
              headCell.label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}
