import { Button, useTheme } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import {
  withLocalStore,
  effects,
  useLocalDispatch,
  useSharedChannel,
  eventChannel,
  END,
  Channel,
} from '@cctip/saga-store';
import { useSnackbar } from 'notistack';
import { MyIcon } from '@/assets/icons/MyIcon';
import { useLocales } from '@/locales';
import { useLocation } from 'react-router';
import NewCaptchaDialog from '@/sections/auth/common/NewCaptchaDialog';
import { useAuthContext } from '@/auth/useAuthContext';
import { securityApi_ } from '@/api';
import { CheckCaptchaMax } from '@/config';

export enum CodeType {
  Register = 1,
  UnRegister = 2,
}

export type emailCodeTimeType = { type: 'new' | 'old'; value: number };

function GetEmailCode({
  email,
  codeType = CodeType.UnRegister,
  verifyRef,
  type,
  handleSend,
}: {
  email: string;
  codeType?: CodeType;
  verifyRef?: any;
  type?: 'new' | 'old';
  handleSend?: (send: boolean) => void;
}) {
  const { translate: t } = useLocales();
  const [text, setText] = useState(t('verfition.get_code'));
  const { enqueueSnackbar } = useSnackbar();
  const [canSend, setCanSend] = useState(false);
  const [cutDownTime, setCutDownTime] = useState(0);
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const theme = useTheme();

  const dispatch = useLocalDispatch();
  const sharedChannel = useSharedChannel();
  const { changeCaptchaData, captchaData, captchaDialogOpen, setCaptchaDialogOpen } =
    useAuthContext();

  const btnColor = theme.palette.primary.main;

  const dialogOpenDisable = useMemo(
    () => captchaDialogOpen && ['/verification'].includes(location.pathname),
    [captchaDialogOpen, location.pathname],
  );

  useEffect(
    () => {
      sharedChannel.put({
        setText,
        enqueueSnackbar,
        setCanSend,
        type: type ?? '',
        setLoading,
        t,
        setCaptchaDialogOpen,
        dialogOpenDisable,
        handleSend,
      });
    },
    [], // eslint-disable-line
  );

  const onClick = (e: any) => {
    e.stopPropagation();
    // '/forgetPassword'
    // if (['/verification', '/register'].includes(location.pathname)) {
    //   setCaptchaDialogOpen(true);
    // } else {
    // dispatch({ type: 'count-down', email, codeType, });
    // }

    if (['/verification', '/register'].includes(location.pathname)) {
      dispatch({
        type: 'count-down',
        email,
        codeType,
        captcha_id: captchaData.CaptchaKey,
        captcha_code: captchaData.CaptchaCode,
      });
    } else {
      dispatch({ type: 'count-down', email, codeType });
    }
  };

  const tick = () => {
    if (type && sessionStorage.getItem('emailCodeTime')) {
      let emailCodeTimeObj: emailCodeTimeType[] = JSON.parse(
        sessionStorage.getItem('emailCodeTime') as string,
      );
      const temp = emailCodeTimeObj.find((item) => item.type === type && item.value > 0);
      //特殊场景下 timeReset 被清除时，可以重新获取验证码
      const timeRest = sessionStorage.getItem('timeReset');
      if (temp) {
        setCanSend(true);
        if (handleSend) {
          handleSend(true);
        }
        setCutDownTime(temp!.value);
        let timer = setTimeout(() => {
          if (timer) clearTimeout(timer);
          if (temp?.value === 1 || !timeRest) {
            setCutDownTime(0);
            temp.value = 0;
            setText(t('verfition.Resend'));
            if (handleSend) {
              handleSend(false);
            }
            setCanSend(false);
            sessionStorage.setItem(
              'emailCodeTime',
              JSON.stringify([
                {
                  type: 'new',
                  value: 0,
                },
                {
                  type: 'old',
                  value: 0,
                },
              ]),
            );
            clearTimeout(timer);
          } else {
            tick();
          }
        }, 300);
      }
    }
  };

  const loadCutDown = () => {
    if (type && sessionStorage.getItem('emailCodeTime')) {
      let emailCodeTimeObj: emailCodeTimeType[] = JSON.parse(
        sessionStorage.getItem('emailCodeTime') as string,
      );
      const temp = emailCodeTimeObj.find((item) => item.type === type && item.value > 0);
      // 如果存在60s倒计时
      if (temp) {
        setCutDownTime(0);
        temp.value = 0;
        setText(t('verfition.get_code'));
        if (handleSend) {
          handleSend(false);
        }
        setCanSend(false);
        sessionStorage.setItem(
          'emailCodeTime',
          JSON.stringify([
            {
              type: 'new',
              value: 0,
            },
            {
              type: 'old',
              value: 0,
            },
          ]),
        );
      }
    }
  };

  useEffect(() => {
    tick();
    window.onunload = function (e) {
      loadCutDown();
    };

    // eslint-disable-next-line
  }, []);

  return (
    <>
      {verifyRef ? (
        <Button
          variant="text"
          disabled={Number.isInteger(parseInt(text)) || canSend || !email}
          onClick={onClick}
          sx={{ minWidth: 84, maxWidth: 120, fontWeight: 500, color: btnColor }}
          ref={verifyRef}
        >
          {loading ? (
            <MyIcon name="loading" className="loading-icon-rotate" />
          ) : (
            <>{cutDownTime > 0 ? `${cutDownTime}s` : text}</>
          )}
        </Button>
      ) : (
        <Button
          variant="text"
          disabled={Number.isInteger(parseInt(text)) || canSend || !email}
          onClick={onClick}
          sx={{ minWidth: 84, maxWidth: 120, fontWeight: 500, color: btnColor }}
        >
          {loading ? (
            <MyIcon name="loading" className="loading-icon-rotate" />
          ) : (
            <>{cutDownTime > 0 ? `${cutDownTime}s` : text}</>
          )}
        </Button>
      )}

      {captchaDialogOpen && (
        <NewCaptchaDialog
          onClose={(v) => {
            changeCaptchaData(v);
            if (v?.CaptchaCode && v?.CaptchaKey) {
              dispatch({
                type: 'count-down',
                email,
                codeType,
                captcha_id: v.CaptchaKey,
                captcha_code: v.CaptchaCode,
              });
            }
            setCaptchaDialogOpen(false);
          }}
          email={email}
          open={captchaDialogOpen}
        />
      )}
    </>
  );
}

const countdown = (secs: number, type: string) => {
  const target = Date.now() + secs * 1000;
  sessionStorage.setItem('timeReset', '0');
  return eventChannel<number | END>((emitter) => {
    const iv = setInterval(() => {
      const remain = (target - Date.now()) / 1000;
      const remainSeconds = Math.ceil(remain);

      if (remainSeconds >= 0) {
        emitter(remainSeconds);
        if (type) {
          const emailCodeTimeArr: emailCodeTimeType[] = sessionStorage.getItem('emailCodeTime')
            ? JSON.parse(sessionStorage.getItem('emailCodeTime') as string)
            : [
                {
                  type: 'new',
                  value: 0,
                },
                {
                  type: 'old',
                  value: 0,
                },
              ];
          emailCodeTimeArr.forEach((item) => {
            if (item.type === type) {
              item.value = remainSeconds;
            }
          });
          sessionStorage.setItem('emailCodeTime', JSON.stringify(emailCodeTimeArr));
        }
      } else {
        emitter(END);
        clearInterval(iv);
      }
    }, 300);
    return () => {
      clearInterval(iv);
    };
  });
};

export default withLocalStore(GetEmailCode, {
  task: function* (ch: Channel<any>) {
    const {
      setText,
      enqueueSnackbar,
      setCanSend,
      type,
      setLoading,
      t,
      setCaptchaDialogOpen,
      dialogOpenDisable,
      handleSend,
    } = yield effects.take(ch);
    while (true) {
      const { email, codeType, captcha_id, captcha_code } = yield effects.take('count-down');
      try {
        setLoading(true);
        yield effects.call(securityApi_.sendEmailCode, {
          email,
          send_type: codeType,
          captcha_key: captcha_id,
          captcha_code,
        });
        setLoading(false);
        const cdCh = countdown(59, type);

        do {
          const seconds: number | END = yield effects.takeMaybe(cdCh);
          if (seconds === END) break;
          setText(`${seconds}s`);
          if (handleSend) {
            handleSend(false);
          }
        } while (true);
        setText(t('verfition.Resend'));
        if (handleSend) {
          handleSend(true);
        }
      } catch (e) {
        // 这里的code是禁止发送的code时再去禁用
        if (
          [224034, 10044, 10045, 10046].includes(e?.code) &&
          ['Register', 'ForgetLoginPass', 'MerchantVerifyCode'].includes(codeType)
        ) {
          enqueueSnackbar(t('verfition.code_error_desc1'), {
            variant: 'error',
          });
          setCanSend(true);
          if (handleSend) {
            handleSend(true);
          }
        } else {
          if (e?.code) {
            if (e?.code === CheckCaptchaMax) {
              if (!dialogOpenDisable) {
                setCaptchaDialogOpen(true);
              }
            } else {
              enqueueSnackbar(t(`new_interface_err.${e.code}`), { variant: 'error' });
            }
          } else {
            enqueueSnackbar(e?.message, { variant: 'error' });
          }
        }
        setLoading(false);
      }
    }
  } as any,
}) as typeof GetEmailCode;
