// import http from './http';
// const CryptoJS = require('crypto-js');
import { Http } from '@/api/http';
import { RegisterData } from '@/sections/auth/Register/Register';
import CryptoJS from 'crypto-js';
const http = new Http('/account/v1');
const http2 = new Http('/payment/v1');
export default class AccountApi {
  /**
   * Login log
   */
  getLoginLogs(): Promise<{
    list: LoginLogListItem[];
    page: number;
    page_size: number;
    total_size: number;
  }> {
    return http.postJson('/login/log', { page: 1, page_size: 100 });
  }

  /**
   * @returns
   */
  accountMerchantList(): Promise<{ list: AccountMerchantListItem[] }> {
    return http.postJson('/account/merchant/list', {});
  }

  /**
   * /account/list
   * @param merchant_id string
   * @returns
   */
  accountList(): Promise<accountListType> {
    return http.postJson('/merchant/account/list', {});
  }

  /**
   * /account/set
   * @returns
   */
  accountSet(data: accountSetParams): Promise<accountListType> {
    return http.postJson('/account/set', { data });
  }

  /**
   * /invite/add passwd
   * @returns
   */
  inviteSet(data: invitSetParams) {
    return http.postJson('/invite/add', {
      ...data,
      passwd: String(CryptoJS.MD5(data.passwd.trim())),
    });
  }

  /**
   * /invite/reset
   * @returns
   */
  inviteReset(data: invitResetParams) {
    return http.postJson('/invite/reset', {
      ...data,
      passwd: String(CryptoJS.MD5(data.passwd.trim())),
    });
  }

  /**
   * /invite/leave
   * @returns
   */
  inviteLeave(data: invitLeaveParams) {
    return http.postJson('/invited/leave', { ...data });
  }

  /**
   * /account/del
   * @returns
   */
  inviteDel(data: invitDelParams) {
    return http.postJson('/merchant/account/del', { ...data });
  }

  /**
   * /role/list
   * @returns
   */
  roleList(): Promise<roleListType> {
    return http.postJson('/role/list', {});
  }

  /**
   * /role_menu/list
   * @returns
   */
  roleMenuList(): Promise<{
    menus: RoleMenuList;
  }> {
    return http.postJson('/role_menu/list', {});
  }

  /**
   * /action/list
   * @returns
   */
  actionList(): Promise<actionListType> {
    return http.postJson('/action/list', {});
  }

  /**
   * /action/log
   * @returns
   */
  actionLog(data: {
    action?: string;
    uuid?: string;
    page: number;
    page_size: number;
  }): Promise<actionLogRes> {
    return http.postJson('/action/log', { ...data });
  }

  /**
   * /invite/get
   * @returns
   */
  inviteGet(data: { invite_code: string }): Promise<inviteGetRes> {
    return http.postJson('/invite/get', { ...data });
  }

  /**
   * Invitee operation reject/accept
   * /invite/set action
   * status 1 acceptd 2 Reject
   * @returns
   */
  inviteAction(data: { status: number; id: number }): Promise<inviteActionRes> {
    return http.postJson('/invited/set', { ...data });
  }

  /** microWithdrawCheck */
  microWithdrawCheck(data: { coin_id: number; value: string }): Promise<{ is_check?: boolean }> {
    return http2.postJson('/micro_withdraw/check', data);
  }

  /** register */
  register(params: Omit<RegisterParam, 'code'>) {
    return http.postJson('/register', {
      ...params,
      passwd: String(CryptoJS.MD5(params.passwd.trim())),
    });
  }

  /** login */
  login(params: { email: string; passwd: string }): Promise<LoginRes> {
    return http.postJson('/login', {
      email: params.email,
      passwd: String(CryptoJS.MD5(params.passwd.trim())),
    });
  }

  /** get user info */
  getUserInfo(): Promise<{ detail: getUserInfoRes }> {
    return http.postJson('/info', {});
  }

  /** login out */
  loginOut() {
    return http.postJson('/logout', {});
  }

  /**  loginVerify type 1 normal Verify 2 full verification */
  loginVerify(data: loginVerifyParam) {
    return http.postJson('/login/safety', data);
  }

  // get google  auth2
  getAuthUrl({ callback_url }: { callback_url: string }): Promise<{ url: string }> {
    return http.postJson('/auth/url', { callback_url, platform: 'Google' });
  }

  // login by google code
  googleAuthLogin({ code }: { code: string }): Promise<AuthUrlRes> {
    return http.postJson('/auth/login', { code, platform: 'Google' });
  }

  // forgot password
  forgetPassword(params: forgetPasswordParam) {
    return http.postJson('/passwd/forget', {
      ...params,
      passwd: String(CryptoJS.MD5(params.passwd)),
      repeat_passwd: String(CryptoJS.MD5(params.repeat_passwd)),
    });
  }

  // get Safety Info By Email -- Login free
  getSafetyInfoByEmail(email: string): Promise<getSafetyInfoByEmailRes> {
    return http.postJson('/info/with/email', { email });
  }

  // get Safety Info By Email -- Login free
  checkEmail(email: string) {
    return http.postJson('/info/check/email', { email });
  }

  getMerchantExtra(): Promise<getMerchantExtraRes> {
    return http.postJson('/merchant/extra', {});
  }

  setMerchantExtra(data: UserExtraParams): Promise<any> {
    return http.postJson('/merchant/extra/set', data);
  }

  saveMerchantSet(data: saveMerchantSetParam) {
    return http.postJson('/merchant/set', data);
  }

  /**
   * Get merchant list
   * @returns
   */
  getMerchantAccountList(): Promise<{ list: getMerchantAccountListRes[] }> {
    return http.postJson('/merchant/list', {});
  }

  /**
   * add merchant
   * @returns
   */
  addMerchant(title: string): Promise<{ merchant_id: string }> {
    return http.postJson('/merchant/add', { title });
  }

  /**
   * switch merchant
   * @param merchant_id string
   * @returns
   */
  switchMerchantAccount(merchant_id: string) {
    return http.postJson('/merchant/switch', { merchant_id });
  }

  /**
   * websiteCheck
   * @param website string
   * @returns
   */
  websiteCheck(website: string, robot_txt?: string) {
    return http.postJson('/website/check', { website, robot_txt });
  }

  /**
   * websiteReset
   * @param website string
   * @returns
   */
  websiteReset(website: string, robot_txt?: string) {
    return http.postJson('/website/reset', { website, robot_txt });
  }

  // Verify Human-Computer Interaction
  getCaptchaInfo(email: string): Promise<getCaptchaInfoRes> {
    return http.postJson('/captcha/get', { email });
  }

  checkCaptcha(data: checkCaptchaInfoParam) {
    return http.postJson('/captcha/check', data);
  }

  // Notify
  notifyList(data: { page: number; page_size: number }): Promise<{
    total_num: number;
    unread_num: number;
    lists: Array<NotifyItemType>;
  }> {
    return http2.postJson('/notify/message/list', data);
  }

  markNotifyReaded(data: { message_id: string }) {
    return http2.postJson('/notify/message/mark_read', data);
  }

  getNotifyDetail(data: { message_id: string }): Promise<{ message: NotifyItemType }> {
    return http2.postJson('/notify/message/detail', data);
  }

  getIndustryList(): Promise<{ list: IndustryListItem[] }> {
    return http.postJson('/merchant/industry/list', {});
  }

  // Common_NotFound         Common = 1004 // 找不到就报失效
  // Account_RegisterApply         Account = 16044 // 需要注册申请，在注册那个地方
  // Account_RegisterApplyPassed   Account = 16045 // 申请已经通过
  // Account_RegisterApplyApprove  Account = 16046 // 申请审核中
  // Account_RegisterApplyRejected Account = 16047 // 申请被拒绝

  /** 注册申请 */
  registerApply(data: RegisterData): Promise<{ code: string }> {
    return http.postJson('/register/apply', data);
  }

  /** 注册申请详情 */
  registerApplyDetail(data: { code: string }): Promise<RegisterApplyDetailRes> {
    return http.postJson('/register_apply/detail', data);
  }

  /** 重新修改 */
  setRegisterApply(data: Omit<RegisterData, 'type'> & { code: string }): Promise<{ code: string }> {
    return http.postJson('/register_apply/set', data);
  }

  adServingClick(params: adClickType) {
    return http.postJson('/ad_serving/click', {
      ...params,
    });
  }

  /** 重置登陆白名单开关 */
  setLoginIpWhitelistStatus(data: setLoginIpWhitelistStatusParam): Promise<any> {
    return http.postJson('/login_ip_whitelist/lpw/reset', data);
  }

  /** 重置登陆白名单 */
  setLoginIpWhitelist(data: setLoginIpWhitelistParam): Promise<{ ip_whitelist: string }> {
    return http.postJson('/login_ip_whitelist/reset', data);
  }

  /** 重置Owner授权白名单 */
  setOwnerIpWhitelist(data: setOwnerIpWhitelistParam): Promise<{ ip_whitelist: string }> {
    return http.postJson('/ip_whitelist/owner/reset', data);
  }

  /** 重置Owner授权白名单开关 */
  setOwnerIpWhitelistStatus(data: setOwnerIpWhitelistStatusParam): Promise<any> {
    return http.postJson('/ip_whitelist/owner_status/reset', data);
  }

  /** 重置Withdraw ip白名单 */
  setWithdrawIpWhitelist(data: setWithdrawIpWhitelistParam): Promise<{ ip_whitelist: string }> {
    return http.postJson('/ip_whitelist/withdraw/reset', data);
  }

  /** 重置Withdraw ip白名单开关 */
  setWithdrawIpWhitelistStatus(data: setWithdrawIpWhitelistStatusParam): Promise<any> {
    return http.postJson('/ip_whitelist/withdraw_status/reset', data);
  }

  /**-----------------------------------------------------------end */
}

export interface setWithdrawIpWhitelistStatusParam {
  /** -1 off 1 on */
  status: number;
  verify_code?: VerifyCode;
}

export interface setWithdrawIpWhitelistParam {
  ip_whitelist: string;
  verify_code?: VerifyCode;
}

export interface setLoginIpWhitelistStatusParam {
  /** -1 off 1 on */
  status: number;
  verify_code?: VerifyCode;
}

export interface setLoginIpWhitelistParam {
  ip_whitelist: string;
  verify_code?: VerifyCode;
}

export interface setOwnerIpWhitelistParam {
  ip_whitelist: string;
  verify_code?: VerifyCode;
}

export interface setOwnerIpWhitelistStatusParam {
  /** -1 off 1 on */
  status: number;
  verify_code?: VerifyCode;
}

export interface RegisterApplyDetailRes {
  detail: RegisterApplyDetail;
}

export interface RegisterApplyDetail {
  id: number;
  business_name: string;
  email: string;
  area: string;
  phone: string;
  first_name: string;
  last_name: string;
  industry_id: number;
  business_scale: string;
  telegram_url: string;
  skype: string;
  website: string;
  desc: string;
  /** "Approve"0 "Passed"1 "Rejected"2 */
  status: -1 | 0 | 1 | 2;
  apply_num: number;
  remarks: {
    option_entity: {
      remark: string;
      reason: string;
      created_at: string;
      option_username: string;
      is_send_email: boolean;
    }[];
  };
  extend: {
    device: string;
    ip: string;
  };
  created_at: number;
  updated_at: number;
}

export interface IndustryListItem {
  id: number;
  name: string;
  created_at: number;
}
export type adClickType = {
  ad_code: string;
  queue_tp?: string;
  created_at?: string;
};

export interface LoginLogListItem {
  merchant_name: string;
  location: string;
  ip: string;
  device: string;
  login_time: number;
  status_str: string;
}

export interface AccountMerchantListItem {
  id: number;
  uuid: string;
  username: string;
  title: string;
  role_id: number;
  logo: string;
  is_current_login: boolean;
  /** right btn status 1 switch 2 switch/leave 3 拒绝/接收 */
  /** 1-Verified 0 Send invitation -1 Delete 2 Reject 3 Leave */
  status: number;
  daily_limit_amount?: string;
  merchant_status: number;
  merchant_id: string;
}
export interface Sub_menu {}

export interface inviteActionRes {
  invite_uuid: string;
  invited_uuid: string;
  invited_username: string;
  invited_role_id: number;
}

export interface inviteGetRes {
  status: number;
  title: string;
  username: string;
  id: number;
}

export interface actionLogRes {
  list: actionLogType[];
  page: number;
  page_size: number;
  total_size: number;
}

export interface invitDelParams {
  id: number;
  type: number;
}
export interface invitLeaveParams {
  id: number;
  invite_merchant_id: string;
}

export interface MenuListItem {
  id: number;
  mid: string;
  name: string;
  desc: string;
  i18n_id: string;
  sub_menu?: MenuListItem[];
}
export interface RoleMenuList {
  [key: string]: RoleMenuListItem;
}

export interface RoleMenuListItem {
  role: {
    id: number;
    name: string;
    desc: string;
  };

  list: MenuListItem[];
}

export interface actionLogType {
  member_name: string;
  action: string;
  desc: string;
  time: string;
  role_name: string;
  created_at: number;
}
export interface actionListType {
  list: string[];
}

export interface roleItem {
  id: number;
  name: string;
  operable: string[];
  inoperable: string[];
  status: number;
  desc: string;
  menu_count: number;
  use_count: number;
}

export interface roleListType {
  roles: roleItem[];
}

export interface Verify_code {
  totp_code: string;
  sms_code: string;
  mail_code: string;
}
export interface invitSetParams {
  email: string;
  role_id: number;
  daily_limit_amount: string;
  passwd: string;
  verify_code: Verify_code;
}

export interface invitResetParams {
  id: string;
  role_id: number;
  daily_limit_amount: string;
  passwd: string;
  verify_code: Verify_code;
}
export interface accountSetParams {
  field: string;
  value: string;
  uuid: string;
}
export interface accountItem {
  index: number;
  uuid: string;
  username: string;
  role_id: number;
  logo: string;
  email: string;
  id: string;
  daily_limit_amount: string;
  latest_login_time: number;
  status: number;
  type: number;
  remark: string;
  hide: boolean;
}
export interface accountListType {
  list: accountItem[];
}
export interface getSafetyInfoByEmailRes {
  safety_2fa: number;
  phone: string;
  phone_area: string;
  email: string;
}

export interface checkCaptchaInfoParam {
  captcha_key: string;
  captcha_code: string;
}

export interface getCaptchaInfoRes {
  captcha_key: string;
  thumb_base_64: string;
  image_base_64: string;
}

export interface getMerchantAccountListRes {
  uuid: string;
  merchant_id: string;
  title: string;
  logo: string;
  id: number;
  username: string;
  role_id: number;
  is_current_login: boolean;
  status: number;
  merchant_status: number;
}

export interface saveMerchantSetParam {
  title: string;
  logo: string;
  // company_website: string;
  company_address: string;
  twitter_url: string;
  telegram_url: string;
}

export interface getMerchantExtraRes {
  merchant_id: string;
  website_hash: string;
  company_website: string;
  company_address: string;
  twitter_url: string;
  telegram_url: string;
  company_industry: string;
  company_industry_text: string;
  main_functions: string;
  main_functions_text: string;
  robot_txt: string;
  is_newbie: '-1' | '1';
}

export interface forgetPasswordParam {
  email: string;
  passwd: string;
  repeat_passwd: string;
  verify_code: VerifyCode;
}

//   InviteRegister = 1 // 邀请注册的
//   EmailRegister = 2 // 邮箱注册的
//   GoogleRegister = 3 // google注册的
//   AdRegister = 4 // 根据广告注册
//   ReferralRegister = 5 // 引荐
export enum registerTypeEnum {
  invite = 1,
  normal = 2,
  google = 3,
  ad = 4,
  referral = 5,
}

export interface RegisterParam {
  email: string;
  platform: string;
  passwd: string;
  referral_merchant_id: string;
  /** 1.邀请注册 2.normal 3.google 4. ad*/
  type: 1 | 2 | 3 | 4;
  // code: string;
  captcha_key?: string;
  captcha_code?: string;
  invite_code?: string;
  email_code?: string;
  ad_code?: string;
  code?: string;
}

export interface LoginRes {
  safety_2fa: number;
  email: string;
  phone_area: string;
  phone: string;
}

export interface getUserInfoRes {
  version: number;
  merchant_id: string;
  display_id: string;
  email: string;
  title: string;
  phone: string;
  phone_area: string;
  google_key: string;
  get_address_num: number;
  package_limit: number;
  wwl_status: number;
  lock_unit: number;
  email_notify: number;
  safety_2fa: number;
  safety_2fa_status: number;
  webhook_notify_mode: number;
  ip_whitelist: string;
  webhook_url: string;
  logo: string;
  status: number;
  is_master: number;
  merchant_status: number;
  is_pay_passwd: boolean;
  micro_withdraw: number;
  auto_swap: number;
  test_coin: number;
  member_num: number;
  username: string;
  role_id: number;
  app_id?: string;
  auto_add_coin: -1 | 1;
  /** 是否开启提现审核 */
  withdraw_approve: number;
  withdraw_approve_setting: {
    limit_qty: string;
    /** 审核类型数组 */
    limit_types: number[];
    /** 安全验证模式 */
    safety_mode: number;
    ttl: number;
  };
  /** 登录ip白名单是否开启 */
  lpw_status: -1 | 1;
  /** 登录ip白名单 */
  login_ip_whitelist: string;
  /** 提现ip 白名单 开关  */
  wiw_status: -1 | 1;
  /** 提现ip 白名单 */
  withdraw_ip_whitelist: string;
  /**  owner白名单 开关  */
  oiw_status: -1 | 1;
  /** owner 操作白名单  */
  owner_ip_whitelist: string;
  is_security: boolean;
}

export interface loginVerifyParam {
  mail_code_type: string;
  sms_code_type: string;
  trust: boolean;
  verify_code: VerifyCode;
  safety_2fa: number;
  captcha_key?: string;
  captcha_code?: string;
  merchant_id: string;
}

export interface VerifyCode {
  totp_code: string;
  sms_code: string;
  mail_code: string;
}

export interface AuthUrlRes {
  email: string;
  is_exist: boolean;
  platform: string;
}

export type MerchantAccountListItemType = {
  id: number;
  merchant_name: string;
  logo: string;
};

export type GoogleAuthInfoType = {
  platform: number;
  thirdUserId: string;
  thirdUserName: string;
  thirdAvatar: string;
  email: string;
};

export type NotifyItemType = {
  args: {
    msg_id?: string;
    bill_id?: string;
    daily_limit?: string;
    join_date?: string;
    role?: string;
    username?: string;
  };
  body: string;
  content_params: {
    header?: string;
    content: string;
    footer: string;
    ip_address: string;
    system: string;
    time: string;
    title: string;
    email?: string;
    mobile?: string;
    tokens?: string;
  };
  created: number;
  merchant_id: number;
  message_id: string;
  readed: boolean;
  title: string;
  type: MessageTypeEnum;
};

export type UserExtraParams = {
  field: 'is_newbie';
  value: '-1' | '1';
};

enum MessageTypeEnum {
  MessageTypeIpChange = 'ip_change',
  MessageTypeDepositSuccess = 'deposit_success',
  MessageTypeWithdrawSuccess = 'withdraw_success',
  MessageTypeSwapSuccess = 'swap_success',
  MessageTypeRefundInner = 'refund_inner',
  MessageTypeRefundOnChain = 'refund_on_chain',
  MessageTypeSystemNotify = 'system_notify',
  MessageTypeGoogleCodeShut = 'google_code_shut',
  MessageTypeEmailChange = 'email_change',
  MessageTypeMobileChange = 'mobile_change',
  MessageTypeInviteSuccess = 'invite_success',
}
