import { Http } from '@/api/http';
import {
  AddWhiteBlackListParams,
  ApprovalInfoParams,
  ApprovalInfoRes,
  ApprovalListParamsType,
  ApprovalListResType,
  ApprovalParams,
  VerifyCodeType,
  WhiteAndBlackListParams,
  WhiteAndBlackListRes,
} from '@/sections/Withdrawal/type';
const http = new Http('/account/v1');
const http2 = new Http('/payment/v1');
export default class ApprovalApi {
  /**
   * 更改审批状态
   * params: { status: 1 | -1 }
   */

  changeApprovalStatus(params: { status: number; verify_code?: VerifyCodeType }): Promise<any> {
    return http.postJson('/set/withdraw_approve_status', params);
  }
  /**
   * 查询审批信息
   */

  getApprovalInfo(): Promise<ApprovalInfoRes> {
    return http.postJson('/get/withdraw_approve_setting', {});
  }
  /**
   * 保存审批信息
   */

  saveApprovalInfo(params: ApprovalInfoParams): Promise<{}> {
    return http.postJson('/edit/withdraw_approve_setting', params);
  }

  /**
   * 审批
   */

  approval(params: ApprovalParams): Promise<{}> {
    return http2.postJson('/withdraw_approve', params);
  }

  /**
   *
   * 查询审批列表
   */

  getApprovalList(params: ApprovalListParamsType): Promise<ApprovalListResType> {
    return http2.postJson('/bill/withdraw_approve_records', params);
  }

  /**
   *
   * 查询审批等待列表
   */
  getApprovalPendingList(): Promise<{ count: number }> {
    return http2.postJson('/bill/withdraw_pending_approve_count', {});
  }

  /**
   *
   * 黑白名单列表
   */

  whiteBlackList(params: WhiteAndBlackListParams): Promise<WhiteAndBlackListRes> {
    return http2.postJson('/withdraw_whitelist/list', params);
  }

  /**
   *
   * 黑白名单列表新增
   */

  addWhiteBlackList(params: AddWhiteBlackListParams): Promise<{}> {
    return http2.postJson('/withdraw_whitelist/add', params);
  }
  /**
   *
   * 黑白名单列表删除
   */

  delWhiteBlackList(params: { ids: string[]; verify_code: VerifyCodeType }): Promise<{}> {
    return http2.postJson('/withdraw_whitelist/batch_del', params);
  }
}
