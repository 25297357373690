import { throttle } from 'lodash-es';
import { makeObservable, observable } from 'mobx';
export default class System {
  /**screen width */
  innerWidth: number = 0;

  designWidth: number = 428;

  maxDesignWidth: number = this.designWidth * 1.5;

  isMobile: boolean = false;

  showSupport: boolean = false;

  rootEm: number = 16;

  /** The width at which the layout starts changing */
  smallChangeWidth: number = 1400;

  /** The width at which the layout starts changing */
  smallNFTChangeWidth: number = 1830;

  /** bots  list compatible width */
  botListChangeWidth: number = 850;

  bigChangeWidth: number = 2400;

  /**title */
  pageTitle: string = 'Overview';

  /** Small screen switch */
  isSmallScreen: boolean = window.innerWidth < this.smallChangeWidth;

  isBotListChangeScreen: boolean = window.innerWidth < this.botListChangeWidth;

  /**send receive Small screen switch */
  isSmallNFTScreen: boolean = window.innerWidth < this.smallNFTChangeWidth;

  /** small screen height */
  smallHeight: number = 800;

  constructor() {
    makeObservable(this, {
      innerWidth: observable,
      isMobile: observable,
      rootEm: observable,
      pageTitle: observable,
      isSmallScreen: observable,
      isBotListChangeScreen: observable,
      smallHeight: observable,
      isSmallNFTScreen: observable,
    });
    this.onWindowResize = throttle(this.onWindowResize.bind(this), 500, { leading: true });
  }

  /** enable rem */
  enableRem(designWidth = this.designWidth) {
    this.designWidth = designWidth;
    window.removeEventListener('resize', this.onWindowResize);
    this.onWindowResize();
    window.addEventListener('resize', this.onWindowResize);
  }

  private onWindowResize() {
    const width = window.innerWidth;
    this.innerWidth = width;
    this.isMobile = width <= this.maxDesignWidth;
    this.isSmallScreen = width < this.smallChangeWidth;
    this.isBotListChangeScreen = width < this.botListChangeWidth;
    this.isSmallNFTScreen = width < this.smallNFTChangeWidth;
    // Set rem information
    let scale: number;
    if (width > this.maxDesignWidth) {
      scale = 1;
    } else {
      scale = width / this.designWidth;
    }
    this.rootEm = 16 * scale;
    document.documentElement.style.fontSize = this.rootEm + 'px';
  }
}
