// routes
import {
  PATH_DASHBOARD,
  PATH_TRADING_ORDER,
  PATH_BALANCES,
  PATH_PAYMENT_API,
  PATH_MERCHANT_SETTIMGS,
  PATH_INVOICE,
  // PATH_SUGGESTAFEATURE,
  PATH_WEB_HOOK,
  PATH_DEVELOPER,
  PATH_WITHDRAWAL_APPROVAL,
  PATH_USER_ASSETS,
} from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

import { Box } from '@mui/material';

export enum RoleEnum {
  'Owner' = 1,
  'Developer' = 2,
  'Accountant' = 3,
  'CustomerService' = 4,
}

const Icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);
// const WalletIcon = (name: string) => `/assets/icons/navbar/${name}`;

const ICONS = {
  user: Icon('ic_dashboard_'),
  trading_order: Icon('ic_trading_order'),
  balances: Icon('ic_balances'),
  user_list: Icon('ic_user'),
  invoice: Icon('ic_invoice'),
  email_Billling: Icon('ic_email_Billling'),
  check: Icon('ic_check'),
  personal_store: Icon('ic_personal_store'),
  payment_api: Icon('ic_payment_api'),
  merchant_settings: Icon('ic_merchant_settings'),
  support: Icon('ic_support'),
  suggest: Icon('ic_suggest'),
  web_hook: Icon('ic_webhook'),
  developer: Icon('ic_developer'),
  with_app: Icon('ic_with_app'),
};

const navConfig = [
  {
    subheader: 'Dashboard',
    meta: {
      mid: ['1-1'],
      role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.CustomerService],
    },
    children: [
      {
        title: 'nav.Dashboard',
        path: PATH_DASHBOARD.one,
        icon: ICONS.user,
        meta: {
          mid: ['1-1'],
          role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.CustomerService],
        },
      },
    ],
  },

  {
    subheader: 'trancaction',
    meta: {
      mid: ['1-2'],
      role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.Accountant, RoleEnum.CustomerService],
    },
    children: [
      {
        title: 'nav.Trading_order',
        path: PATH_TRADING_ORDER.root,
        icon: ICONS.trading_order,
        meta: {
          mid: ['1-2-1'],
          role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.Accountant, RoleEnum.CustomerService],
        },
      },
    ],
  },

  {
    subheader: 'payment_api',
    meta: {
      mid: ['1-3'],
      role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.Accountant, RoleEnum.CustomerService],
    },
    children: [
      {
        title: 'nav.payment_api',
        path: PATH_PAYMENT_API.one,
        icon: ICONS.payment_api,
        meta: {
          mid: ['1-3'],
          role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.Accountant, RoleEnum.CustomerService],
        },
      },
    ],
  },

  {
    subheader: 'Invoice',
    meta: {
      mid: ['1-4'],
      role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.Accountant, RoleEnum.CustomerService],
    },
    children: [
      {
        title: 'nav.Invoice',
        path: PATH_INVOICE.one,
        icon: ICONS.invoice,
        meta: {
          mid: ['1-4'],
          role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.Accountant, RoleEnum.CustomerService],
        },
      },
    ],
  },

  {
    subheader: 'Balance',
    meta: {
      mid: ['1-5'],
      role: [RoleEnum.Owner, RoleEnum.Developer],
    },
    children: [
      {
        title: 'nav.Balances',
        path: PATH_BALANCES.one,
        icon: ICONS.balances,
        meta: {
          mid: ['1-5'],
          role: [RoleEnum.Owner, RoleEnum.Developer],
        },
      },
    ],
  },

  // {
  //   subheader: 'user_list',
  //   meta: {
  //     mid: ['1-6'],
  //     role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.Accountant, RoleEnum.CustomerService],
  //   },
  //   children: [
  //     {
  //       title: 'nav.user_list_',
  //       path: PATH_USERLIST.root,
  //       icon: ICONS.user_list,
  //       meta: {
  //         mid: ['1-6'],
  //         role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.Accountant, RoleEnum.CustomerService],
  //       },
  //     },
  //   ],
  // },
  {
    subheader: 'user_assets',
    meta: {
      mid: ['1-6'],
      role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.Accountant, RoleEnum.CustomerService],
    },
    children: [
      {
        title: 'nav.user_assets',
        path: PATH_USER_ASSETS.root,
        icon: ICONS.user_list,
        children: [
          {
            title: 'User List',
            path: PATH_USER_ASSETS.userList,
            meta: {
              mid: ['1-6-1'],
              role: [RoleEnum.Owner],
            },
          },
          {
            title: 'User Transaction',
            path: PATH_USER_ASSETS.userTransaction,
            meta: {
              mid: ['1-2-1'],
              role: [
                RoleEnum.Owner,
                RoleEnum.Developer,
                RoleEnum.Accountant,
                RoleEnum.CustomerService,
              ],
            },
          },
        ],
      },
    ],
  },

  {
    subheader: 'merchant_settings',
    meta: {
      mid: ['1-7'],
      role: [RoleEnum.Owner, RoleEnum.Developer],
    },
    children: [
      {
        title: 'nav.Merchant_settings',
        path: PATH_MERCHANT_SETTIMGS.root,
        icon: ICONS.merchant_settings,
        children: [
          {
            title: 'branding',
            path: PATH_MERCHANT_SETTIMGS.branding,
            meta: {
              mid: ['1-7-1'],
              role: [RoleEnum.Owner],
            },
          },
          {
            title: 'settings',
            path: PATH_MERCHANT_SETTIMGS.settings,
            meta: {
              mid: ['1-7-2'],
              role: [RoleEnum.Owner],
            },
          },
          {
            title: 'security',
            path: PATH_MERCHANT_SETTIMGS.security,
            meta: {
              mid: ['1-7-3'],
              role: [RoleEnum.Owner],
            },
          },
          {
            title: 'verification',
            path: PATH_MERCHANT_SETTIMGS.verification,
            meta: {
              mid: ['1-7-4'],
              role: [RoleEnum.Owner, RoleEnum.Developer],
            },
          },
          {
            title: 'member',
            path: PATH_MERCHANT_SETTIMGS.member,
            info: (
              <Box
                sx={{
                  padding: '2px 8px',
                  background: 'rgba(190, 226, 70, 0.16)',
                  color: '#7FA223',
                  lineHeight: '20px',
                  borderRadius: '6px',
                  fontSize: '12px',
                  fontWeight: 500,
                }}
              >
                {0}
              </Box>
            ),
            meta: {
              mid: ['1-7-5'],
              role: [RoleEnum.Owner],
            },
          },
        ],
      },
    ],
  },

  {
    subheader: 'Developer',
    meta: {
      mid: ['1-8'],
      role: [RoleEnum.Owner, RoleEnum.Developer],
    },
    children: [
      {
        title: 'nav.Developer',
        path: PATH_DEVELOPER.one,
        icon: ICONS.developer,
        meta: {
          mid: ['1-8'],
          role: [RoleEnum.Owner, RoleEnum.Developer],
        },
      },
    ],
  },

  {
    subheader: 'webhook',
    meta: {
      mid: ['1-9'],
      role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.CustomerService],
    },
    children: [
      {
        title: 'nav.web_hook',
        path: PATH_WEB_HOOK.one,
        icon: ICONS.web_hook,
        meta: {
          mid: ['1-9'],
          role: [RoleEnum.Owner, RoleEnum.Developer, RoleEnum.CustomerService],
        },
      },
    ],
  },
  {
    subheader: 'withdrawal_approval',
    meta: {
      mid: ['1-10'],
      role: [RoleEnum.Owner, RoleEnum.Developer],
    },
    children: [
      {
        title: 'nav.Withdrawal_Approval',
        path: PATH_WITHDRAWAL_APPROVAL.root,
        icon: ICONS.with_app,
        children: [
          {
            title: 'Pending List',
            path: PATH_WITHDRAWAL_APPROVAL.PendingList,
            meta: {
              mid: ['1-10-1'],
              role: [RoleEnum.Owner],
            },
          },
          {
            title: 'Records',
            path: PATH_WITHDRAWAL_APPROVAL.Records,
            meta: {
              mid: ['1-10-3'],
              role: [RoleEnum.Owner],
            },
          },
        ],
      },
    ],
  },
];

export default navConfig;
