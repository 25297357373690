import {
  Box,
  Button,
  DialogActions,
  // DialogContent,
  DialogTitle,
  Typography,
} from '@mui/material';
import IconLock from '@/assets/icons/img/ic_lock.png';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { MyIcon } from '@/assets/icons/MyIcon';
import { useLocales } from '@/locales';
import CommonDialog from '../components/CommonDialog';

export default function DialogEnabledPhoneAuth({
  visible,
  onClose,
}: {
  visible: boolean;
  onClose: (confirm: boolean) => void;
}) {
  const { translate: t } = useLocales();

  const [loading, setLoading] = useState(false);

  const onOK = async () => {
    setLoading(true);
    await onClose(true);
    setLoading(false);
  };

  return (
    <CommonDialog open={visible} onClose={() => onClose(false)}>
      <DialogTitle id="scroll-dialog-title">
        <Box className="flex flex-column items-center">
          <Box component="img" src={IconLock} sx={{ width: '96px', height: '96px' }} />
          <Typography variant="h5" component="span">
            {t('merchantSettings.security_phone_dialog_title5')}
          </Typography>
        </Box>
      </DialogTitle>
      {/* <DialogContent dividers>
      </DialogContent> */}
      <DialogActions className="loading-btn">
        <Button
          color="primary"
          className="btn-height48"
          variant="outlined"
          fullWidth
          onClick={() => onClose(false)}
          sx={{
            color: '#1E1E1E',
            border: '1px solid rgba(145, 158, 171, 0.32)',
            '&:hover': {
              backgroundColor: '#fff',
              border: '1px solid rgba(145, 158, 171, 0.32)',
              boxShadow: '0 8px 8px 0 rgb(158 158 158 / 16%)',
            },
          }}
        >
          {t('common.Cancel')}
        </Button>
        <LoadingButton
          color="primary"
          className="btn-height48"
          variant="contained"
          fullWidth
          loading={loading}
          loadingIndicator={<MyIcon name="loading" className="loading-icon-rotate" />}
          onClick={onOK}
        >
          {t('common.Confirm')}
        </LoadingButton>
      </DialogActions>
    </CommonDialog>
  );
}
