import { useDropzone } from 'react-dropzone';
// @mui
import { styled } from '@mui/material/styles';
//
// import Iconify from '../iconify';
//
import { UploadProps } from './types';
import { MyIcon } from '@/assets/icons/MyIcon';



const StyledDropZone = styled('div')(({ theme }) => ({
  width: 72,
  height: 72,
  fontSize: 24,
  display: 'flex',
  flexShrink: 0,
  cursor: 'pointer',
  alignItems: 'center',
  justifyContent: 'center',
  margin: theme.spacing(0.5),
  color: theme.palette.text.disabled,
  borderRadius: theme.shape.borderRadius,
  border: `dashed 1px ${theme.palette.divider}`,
  // backgroundColor: alpha(theme.palette.grey[500], 0.08),
  '&:hover': {
    opacity: 0.72,
  },
}));



export default function UploadBox({ placeholder, error, disabled, sx, ...other }: UploadProps) {
  const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
    disabled,
    ...other,
  });

  const isError = isDragReject || !!error;

  return (
    <StyledDropZone
      {...getRootProps()}
      sx={{
        ...(isDragActive && {
          opacity: 0.72,
          background: 'rgba(230, 232, 224, 1)',
        }),
        ...(isError && {
          color: 'error.main',
          bgcolor: 'error.lighter',
          borderColor: 'error.light',
        }),
        ...(disabled && {
          opacity: 0.48,
          pointerEvents: 'none',
        }),
        ...sx,
      }}
    >
      <input
        type="file"
        accept=".numbers,image/pic,
        image/jpeg,image/png,image/gif,application/pdf,text/csv,
        text/plain,
        application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        {...getInputProps()}
      />

      {placeholder || <MyIcon name="ic_add" size={18} />}
    </StyledDropZone>
  );
}
