// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// locales
import ThemeLocalization, { useLocales } from './locales';
// components
import SnackbarProvider, { closeSnackbar, enqueueSnackbar } from './components/snackbar';
import { ThemeSettings } from './components/settings';
import { MotionLazyContainer } from './components/animate';
import LoadingScreen from './components/loading-screen';
import { observer } from 'mobx-react';
import { accountStore, merchantStroe } from '@/stores/index';
import { useEffect, useState } from 'react';
import { AuthProvider } from './auth/JwtContext';
import { useLocation, useNavigate } from 'react-router';
import { phoneCodeTimeType } from './components/get-code/phone';
import { emailCodeTimeType } from './components/get-code/email';
import { formartSearchParam } from './utils/until';
import { StyledChart } from './components/chart';
import { LoadingButton } from '@mui/lab';
import { Box, IconButton } from '@mui/material';
import { MyIcon } from './assets/icons/MyIcon';
import TwoStepsDialog from '@/sections/MerchantSetting/dialog/two-steps-confirm';
import { accountApi_ } from './api';
import { inviteGetRes } from './api/accountApi_';
import EnqueueSnackbarInvite from './components/invite/enqueueSnackbar';

export default observer(function App() {
  const { getMerchantList } = merchantStroe;
  const location = useLocation();
  const { translate: t } = useLocales();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [celloading, setCelloading] = useState(false);
  const [openConfirm, setConfirm] = useState(true);
  const [info, setInfo] = useState<inviteGetRes>();

  if (location.search) {
    const searchParam = formartSearchParam(location.search);
    if (searchParam?.authtoken) {
      localStorage.setItem('Authorization', JSON.stringify(searchParam?.authtoken));
      localStorage.setItem('isVerify', JSON.stringify(true));
      localStorage.setItem('isLogin', JSON.stringify(true));
      localStorage.setItem('isFirstInv', '1');
    }
    if (searchParam?.ad_code) {
      localStorage.setItem('isAd', JSON.stringify(searchParam.ad_code));
    }
    if (searchParam?.invite) {
      localStorage.setItem('isInvite', JSON.stringify(searchParam.invite));
    }
    if (searchParam?.email) {
      localStorage.setItem('invitedEmail', JSON.stringify(searchParam.email));
    }
    if (searchParam?.manageEmail) {
      localStorage.setItem('manageEmail', JSON.stringify(searchParam.manageEmail));
      // checkEmail(searchParam.email);
    }
  }

  // const chooseApi = localStorage.getItem('chooseApi')
  //   ? JSON.parse(localStorage.getItem('chooseApi') as string)
  //   : 1;

  const isVerify = localStorage.getItem('isVerify')
    ? JSON.parse(localStorage.getItem('isVerify') as string)
    : false;

  function changeFaviconSrc(event: MediaQueryListEvent) {
    const faviconLink = document.querySelector('#favicon-link');

    if (event.matches) {
      if (faviconLink) {
        (faviconLink as any).href = '/favicon/ccpfavicon-white.png';
      }
      // turn white
    } else {
      // turn black
      if (faviconLink) {
        (faviconLink as any).href = '/favicon/ccpfavicon-black.png';
      }
    }
  }

  const handleClick = async (ad_code: string) => {
    try {
      await accountApi_.adServingClick({
        ad_code: ad_code,
      });
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    const searchParam = formartSearchParam(location.search);
    if (searchParam?.ad_code) {
      handleClick(searchParam.ad_code);
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.onload = init;
    // Control the favicon icon on the browser tab

    if (window?.matchMedia) {
      const mediaQueryList = window.matchMedia('(prefers-color-scheme: dark)');
      mediaQueryList.addEventListener('change', changeFaviconSrc);
      const faviconLink = document.querySelector('#favicon-link');
      if (mediaQueryList.matches) {
        if (faviconLink) {
          (faviconLink as any).href = '/favicon/ccpfavicon-white.png';
        }
      } else {
        if (faviconLink) {
          (faviconLink as any).href = '/favicon/ccpfavicon-black.png';
        }
      }
    }

    // eslint-disable-next-line
  }, []);

  //init send code time
  const init = () => {
    localStorage.setItem('chooseApi', JSON.stringify(1));
    if (sessionStorage.getItem('phoneCodeTime')) {
      let phoneCodeTimeArr: phoneCodeTimeType[] = JSON.parse(
        sessionStorage.getItem('phoneCodeTime') as string,
      );
      const arr = phoneCodeTimeArr.map((item) => ({ ...item, value: 0 }));
      sessionStorage.setItem('phoneCodeTime', JSON.stringify(arr));
    }

    if (sessionStorage.getItem('emailCodeTime')) {
      let emailCodeTimeObj: emailCodeTimeType[] = JSON.parse(
        sessionStorage.getItem('emailCodeTime') as string,
      );
      const arr = emailCodeTimeObj.map((item) => ({ ...item, value: 0 }));
      sessionStorage.setItem('emailCodeTime', JSON.stringify(arr));
    }
  };

  const handleInviteAction = async (type?: number) => {
    if (type === 1) {
      setLoading(true);
    }
    if (type === 2) {
      setCelloading(true);
    }

    try {
      let params = {
        status: type || 1, // 1 ok  2 cel
        id: info?.id || 0,
      };
      let res = await accountApi_.inviteAction(params);
      // 更新 merhcant list
      await getMerchantList();
      if (res) {
        if (type === 2) {
          enqueueSnackbar(t('merchantSettings.move_invitation_reject'));
        }
        if (type === 1) {
          enqueueSnackbar(
            <Box
              sx={{
                fontWeight: 600,
                lineHeight: '22px',
                fontSize: '14px',
                color: '#1F2025',
                // width: '420px',
              }}
            >
              {t('merchantSettings.settings_member_team_successfully', {
                merchantName: info?.title,
              })}
            </Box>,
            {
              variant: 'success',
              autoHideDuration: null,
              action: (
                <Box>
                  <LoadingButton
                    variant="outlined"
                    sx={{
                      border: 0,
                      background: 'rgba(54, 179, 126, 0.16)',
                      color: '#1B806A',
                      padding: '6px 12px',
                      marginRight: '8px',
                      ':hover': {
                        border: 0,
                        backgroundColor: 'rgba(54, 179, 126, 0.26)',
                      },
                    }}
                    onClick={() => {
                      navigate('/account/settingPageSection/merchantlist');
                      closeSnackbar();
                      localStorage.removeItem('isInvite');
                    }}
                    loadingIndicator={<MyIcon name="loading" className="loading-icon-rotate" />}
                  >
                    {t('merchantSettings.settings_member_team_succ_go')}
                  </LoadingButton>
                  <IconButton
                    // sx={{
                    //   color: '#1F2025',
                    //   border: '1px solid #E8ECF2',
                    //   ':hover': {
                    //     borderColor: '#1E1E1E;',
                    //     backgroundColor: 'rgba(145, 158, 171, 0.08)',
                    //   },
                    // }}
                    onClick={() => {
                      closeSnackbar();
                      localStorage.removeItem('isInvite');
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      viewBox="0 0 20 20"
                      fill="none"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.69688 4.69668C5.02231 4.37124 5.54995 4.37124 5.87539 4.69668L15.3035 14.1248C15.6289 14.4502 15.6289 14.9778 15.3035 15.3033C14.978 15.6287 14.4504 15.6287 14.125 15.3033L4.69688 5.87519C4.37144 5.54975 4.37144 5.02211 4.69688 4.69668Z"
                        fill="#B3B9C5"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M4.69668 15.3031C4.37124 14.9777 4.37124 14.45 4.69668 14.1246L14.1248 4.69652C14.4502 4.37108 14.9778 4.37108 15.3033 4.69652C15.6287 5.02196 15.6287 5.5496 15.3033 5.87503L5.87519 15.3031C5.54975 15.6286 5.02211 15.6286 4.69668 15.3031Z"
                        fill="#B3B9C5"
                      />
                    </svg>
                  </IconButton>
                </Box>
              ),
            },
          );
        }
      }
    } catch (e) {
      if (e?.code) {
        enqueueSnackbar(t(`new_interface_err.${e?.code}`), { variant: 'error' });
      } else {
        enqueueSnackbar(e.message, { variant: 'error' });
      }
    } finally {
      setLoading(false);
      setCelloading(false);
      setConfirm(false);
      localStorage.removeItem('isInvite');
    }
  };

  if (!accountStore.isLogin && !accountStore.isVerify && !location.pathname.includes('invoice-pdf'))
    return (
      <MotionLazyContainer>
        <ThemeProvider>
          <LoadingScreen />
        </ThemeProvider>
      </MotionLazyContainer>
    );

  return (
    <MotionLazyContainer>
      <ThemeProvider>
        <AuthProvider>
          <ThemeSettings>
            <ThemeLocalization>
              <SnackbarProvider>
                <StyledChart />
                <Router />

                <EnqueueSnackbarInvite
                  location={location}
                  handleInfo={(res: inviteGetRes) => {
                    setInfo(res);
                  }}
                />
                {info?.status === 0 && isVerify && (
                  <TwoStepsDialog
                    finshed={loading}
                    celloading={celloading}
                    type="primary"
                    sx={{ color: '#71757E' }}
                    visible={openConfirm}
                    closeBtn={true}
                    cancelBtn={true}
                    onClose={() => {
                      setConfirm(false);
                    }}
                    handleAction={(type?: number) => {
                      handleInviteAction(type);
                    }}
                    title={t('merchantSettings.settings_member_team_title')}
                    content={[
                      t('merchantSettings.settings_member_team_desc', {
                        inviter: info?.username,
                        merchantName: info?.title,
                      }),
                    ]}
                    okBtn={t('merchantSettings.settings_member_team_accept')}
                    celBtn={t('merchantSettings.settings_member_team_reject')}
                  />
                )}
              </SnackbarProvider>
            </ThemeLocalization>
          </ThemeSettings>
        </AuthProvider>
      </ThemeProvider>
    </MotionLazyContainer>
  );
});
