import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Paper,
  Typography,
} from '@mui/material';
import { useQRCode } from 'next-qrcode';
import CopyButton from '@/components/copy-button';
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui';
import useSWR from 'swr';
import { useContext, useEffect, useMemo } from 'react';
import { SettingsContext } from '../context';
import GetEmailCode from '@/components/get-code/email';
import { MyIcon } from '@/assets/icons/MyIcon';
import { LoadingButton } from '@mui/lab';
import { observer } from 'mobx-react';
import { UIState } from '../workflow';
import { useLocales } from '@/locales';
import { securityApi_ } from '@/api';
import CommonDialog from '../components/CommonDialog';

const ChromeExtensionLink =
  'https://chrome.google.com/webstore/detail/authenticator/bhghoamapcdpbohphigoooaddinpkbai';

export default observer(DialogGoogleAuthQRForm);

function DialogGoogleAuthQRForm({
  visible,
  onClose,
}: {
  visible: boolean;
  onClose: (payload: any) => void;
}) {
  const settings = useContext(SettingsContext);
  const { translate: t } = useLocales();
  const { Image } = useQRCode();
  const formContext = useForm();

  const onConfirm = () => {
    const dom: HTMLInputElement = document.querySelector('#DialogGoogleAuthQRForm-Submit')!;
    dom.click();
  };

  const onOK = async (data: any) => {
    await onClose(data);
  };

  const resp = useSWR('google2FA', () => securityApi_.google2FA(), {
    revalidateOnFocus: false,
  });
  const QRCode = resp.data?.secret_key_qr ?? '';
  const secretKey = resp.data?.secret_key ?? '';
  useEffect(() => {
    formContext.setValue('secretKey', secretKey);
  }, [secretKey, formContext]);

  const email = useMemo(() => {
    const [name, suffix] = (settings?.email ?? '').split('@');
    return `${name.slice(0, 2)}****@${suffix}`;
  }, [settings?.email]);

  useEffect(
    () => formContext.reset(),
    // eslint-disable-next-line
    [visible]
  );

  return (
    <CommonDialog open={visible} onClose={() => onClose(false)}>
      <DialogTitle id="scroll-dialog-title">
        <Box className="flex justify-between items-center">
          <Typography variant="h5" component="span">
            {t('merchantSettings.security_google')}
          </Typography>
          <IconButton onClick={() => onClose(false)}>
            <MyIcon name="close" size={14} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ pt: 1 }}>
        <Typography variant="body2" textAlign="center">
          {t('merchantSettings.security_google_QR_desc1')}{' '}
          <Button variant="text" onClick={() => window.open(ChromeExtensionLink, '_blank')}>
            {t('merchantSettings.security_google_QR_desc2')}
          </Button>{' '}
          {t('merchantSettings.security_google_QR_desc3')}
        </Typography>
        <Paper elevation={5} sx={{ width: 248, height: 248, mx: 'auto', my: 3 }}>
          {QRCode && <Box component={Image} text={QRCode} options={{ width: 248, margin: 3 }} />}
        </Paper>
        <Box border="1px solid rgba(145, 158, 171, 0.32)" borderRadius={2} px={3} py={2.5} mb={2}>
          <Typography variant="subtitle2">
            {t('merchantSettings.security_google_QR_desc4')}
          </Typography>
          <Typography variant="body2">
            {secretKey} <CopyButton source={secretKey} />
          </Typography>
          <Typography variant="body2" className="color-text2">
            {t('merchantSettings.security_google_QR_desc5')}
          </Typography>
        </Box>
        <FormContainer formContext={formContext} handleSubmit={formContext.handleSubmit(onOK)}>
          <input {...formContext.register('secretKey', { value: secretKey })} hidden />
          <TextFieldElement
            name="emailCode"
            label={t('merchantSettings.security_email_dialog_Verification_label1')}
            fullWidth
            autoComplete={'off'}
            inputProps={{
              maxLength: 6,
            }}
            InputProps={{
              endAdornment: <GetEmailCode email={settings?.email} type={'old'} />,
            }}
            helperText={t('merchantSettings.security_email_dialog_helpText1', { email: email })}
            sx={{ mb: 2 }}
            validation={{
              required: true,
            }}
          />
          <TextFieldElement
            name="authCode"
            fullWidth
            autoComplete={'off'}
            inputProps={{
              maxLength: 6,
            }}
            label={t('merchantSettings.security_google')}
            helperText={t('merchantSettings.security_email_dialog_helpText2')}
            validation={{
              required: true,
            }}
          />
          <input id="DialogGoogleAuthQRForm-Submit" type="submit" hidden />
        </FormContainer>
      </DialogContent>
      <DialogActions className="loading-btn">
        <LoadingButton
          color="primary"
          variant="contained"
          fullWidth
          onClick={onConfirm}
          type="submit"
          className="btn-height48"
          loading={UIState.loading}
          loadingIndicator={<MyIcon name="loading" className="loading-icon-rotate" />}
        >
          {t('common.Confirm')}
        </LoadingButton>
      </DialogActions>
    </CommonDialog>
  );
}
