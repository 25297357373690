export function gapTop(size: string) {
  return `
> * {
  margin-top:${size};
  :first-child{
    margin-top:0;
  }
}
  `;
}
export function gapBottom(size: string) {
  return `
> * {
  margin-bottom:${size};
  :last-child{
    margin-bottom:0;
  }
}
  `;
}
export function gapLeft(size: string) {
  return `
> * {
  margin-left:${size};
  :first-child{
    margin-left:0;
  }
}
  `;
}
export function gapRight(size: string) {
  return `
> * {
  margin-right:${size};
  :last-child{
    margin-right:0;
  }
}
  `;
}

/**
 * 把年月日转化成数字方便比较
 * @param year
 * @param month
 * @param day
 * @returns
 */
export function yearMonthDayToNumber(year: number, month: number, day: number) {
  return year * 10000 + month * 100 + day;
}

export const ButtonScaleStyle = `
transition: transform 0.2s;
&:active {
  transform: scale(0.95);
}
`;

export const HideScrollBarStyle = `
-ms-overflow-style: none;//IE
scrollbar-width:none;//firefox
&::-webkit-scrollbar {
  display:none;
}
`;

export function numberFixRemoveZero(n: number, fix: number) {
  if (fix > 0) {
    const nx = n.toFixed(fix);
    let i = nx.length - 1;
    while (nx[i] === '0') {
      i--;
    }
    if (nx[i] === '.') {
      i--;
    }
    return nx.slice(0, i + 1);
  }
  return n + '';
}
export function timeoutPromise(timeOut: number): Promise<undefined>;
export function timeoutPromise<T>(timeOut: number, n: T): Promise<T>;
export function timeoutPromise() {
  const timeOut = arguments[0];
  const n = arguments[1];
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(n);
    }, timeOut);
  });
}

export type ReduceRowState<T> = (() => void) & ((v: React.SetStateAction<T>) => void);

export function run<T>(fun: () => T) {
  return fun();
}

/**
 * 可以不用输入https:// http://
 */
// const URLREG =
//   /^((https?:\/\/)?(([a-zA-Z0-9]+-?)+[a-zA-Z0-9]+\.)+[a-zA-Z]+)(:\d+)?(\/.*)?(\?.*)?(#.*)?$/;

export function isUrl(url: string) {
  //return URLREG.test(url)
  // if (!url.startsWith('http')) {
  //   url = `http://${url}`
  // }
  try {
    new URL(url);
  } catch (e) {
    return false;
  }
  return true;
}

export function fillTwo(i: number) {
  const v = i + '';
  if (v.length < 2) {
    return '0' + v;
  }
  return v;
}
