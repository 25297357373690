import { Http } from '@/api/http';
import CryptoJS from 'crypto-js';
import { VerifyCode } from './accountApi_';
const http = new Http('/account/v1');

export default class SecurityApi {
  google2FA(): Promise<{ secret_key: string; secret_key_qr: string }> {
    return http.postJson('/google_2fa/get', {});
  }

  setupGoogle2FA(data: setupGoogle2FAParam) {
    return http.postJson('/google_2fa/set', data);
  }

  closeGoogle2FA(data: { verify_code: VerifyCode }) {
    return http.postJson('/google_2fa/close', data);
  }

  changePassword(data: changePasswordParam) {
    return http.postJson('/passwd/change', {
      ...data,
      passwd: CryptoJS.MD5(data.passwd?.trim()).toString(),
      repeat_passwd: CryptoJS.MD5(data.repeat_passwd?.trim()).toString(),
    });
  }

  /**
   * setPayPassword
   */
  setPayPw(data: setPayPwParam): Promise<{ check_hash?: string }> {
    return http.postJson('/pay_passwd/set', {
      ...data,
      pay_passwd: String(CryptoJS.MD5(data.pay_passwd?.trim())),
      repeat_pay_passwd: String(CryptoJS.MD5(data.repeat_pay_passwd?.trim())),
    });
  }

  /**
   * changePayPw
   */
  changePayPw(data: setPayPwParam) {
    return http.postJson('/pay_passwd/change', {
      ...data,
      pay_passwd: String(CryptoJS.MD5(data.pay_passwd?.trim())),
      repeat_pay_passwd: String(CryptoJS.MD5(data.repeat_pay_passwd?.trim())),
    });
  }

  setupPhone(data: changePhoneParam) {
    return http.postJson('/phone/set', data);
  }

  changePhone(data: changePhoneParam) {
    return http.postJson('/phone/change', data);
  }

  changeEmail(data: changeEmailParam) {
    return http.postJson('/email/change ', data);
  }

  /**
   * @param area string
   * @param phone string
   * @param send_type 1|2
   * @description send_type 2 绑定新号使用 1是默认使用
   */
  sendPhoneCode(data: { area: string; phone: string; send_type: 1 | 2 }) {
    return http.postJson('/sms/send/code', data);
  }

  sendEmailCode(data: sendEmailCodeParam) {
    return http.postJson('/email/send/code', data);
  }

  /** field: 1.lock_unit===>>> 1\2\3\4\5
   * 2.username string */
  accountSet(data: { field: string; value: string }) {
    return http.postJson('/account/set', data);
  }

  setPhone2FA(data: setPhone2FAParam) {
    return http.postJson('/phone/2fa/set', data);
  }

  setWithdrawSmall(data: setWithdrawSmallParam) {
    return http.postJson('/micro_withdraw/set', {
      ...data,
      passwd: CryptoJS.MD5(data.passwd?.trim()).toString(),
    });
  }
  //--------------------------

  prevOpDate(): Promise<{ option_time: number }> {
    return http.postJson('/safety/setting/no_ops/get', {});
  }

  toggleWithdrawWhiteList({ status }: { status: 0 | 1 }) {
    return http.postJson('/safety/setting/withdraw_whitelist/set', {
      status,
    });
  }

  toggleWhiteList(data: { status: 0 | 1 }) {
    return http.postJson('/safety/setting/withdraw_whitelist/set', data);
  }
}

export enum PhoneSendTypeEnum {
  Normal = 1,
  New = 2,
}

export interface setWithdrawSmallParam {
  status: number;
  passwd: string;
  verify_code: VerifyCode;
}

export interface setPhone2FAParam {
  status: -1 | 1;
  verify_code: VerifyCode;
}

export interface setPayPwParam {
  pay_passwd: string;
  repeat_pay_passwd: string;
  verify_code: VerifyCode;
}

export interface sendEmailCodeParam {
  email: string;
  captcha_key: string;
  captcha_code: string;
  /** 1 注册 2 非注册 */
  send_type: number;
}

export interface setupGoogle2FAParam {
  secret_key: string;
  code: string;
  verify_code: VerifyCode;
}

export interface changePasswordParam {
  passwd: string;
  repeat_passwd: string;
  old_passwd: string;
  verify_code: VerifyCode;
}

export interface changePhoneParam {
  phone: string;
  area: string;
  code: string;
  verifyCode: VerifyCode;
}

export interface changeEmailParam {
  email: string;
  code: string;
  verifyCode: VerifyCode;
}
