import { Http } from '@/api/http';
import CryptoJS from 'crypto-js';
import { VerifyCode } from './accountApi_';
const http = new Http('/payment/v1');
const http2 = new Http('/account/v1');

export enum SignType {
  sha256 = 1,
  sha256WithPrivateKey = 2,
}

export default class PaymentApi {
  async webhookCheck({ url }: { url: string }): Promise<any> {
    try {
      await http2.postJson('/webhook/check', { url: url });
      return true;
    } catch (error) {
      return false;
    }
  }

  /**
   * Verify view appid APP Secret
   *
   */
  getAppInfo(data: VerifyCode): Promise<getAppInfoRes> {
    return http2.postJson('/app/info', { verify_code: data });
  }

  /**
   * reset appid  app_secret
   *
   */
  resetSecrets(data: { verify_code: VerifyCode }) {
    return http2.postJson('/app/reset', {
      ...data,
    });
  }

  /**
   * field => 1.webhook_url
   * 2.ip_whitelist
   * 3.wwl_status -1 1
   * 4.webhook_notify_mode 1 pending 2 Processing 3 Success
   * 5.auto_swap -1关闭 1开启
   * 6. auto_add_coin -1关闭 1开启
   *  */
  setDevelopInfo(data: setDevelopInfoParam) {
    return http2.postJson('/develop/set', data);
  }

  setMerchantCoin(data: number[]) {
    return http2.postJson('/merchant/coin/set', { coin_ids: data });
  }

  autoSwapCoinInfo(): Promise<autoSwapCoinInfoRes> {
    return http.postJson('/auto_swap_coin/info', {});
  }

  autoSwapCoinList(): Promise<autoSwapCoinListRes> {
    return http.postJson('/auto_swap_coin/list', {});
  }

  setDevelopReset(data: setDevelopResetParam) {
    return http2.postJson('/ip_whitelist/reset', data);
  }

  /**
   * info
   * set
   * auto Withdraw
   *  */

  autoWithdrawCoinList(): Promise<autoWithdrawCoinListRes> {
    return http.postJson('/auto_withdraw_coin/info', {});
  }

  autoWithdrawCoinSet(data: autoWithdrawCoinSetType) {
    return http2.postJson('/auto_withdraw_coin/set', {
      ...data,
      pay_passwd: CryptoJS.MD5(data.pay_passwd.trim()).toString(),
    });
  }

  autoSwapCoinSet(data: autoSwapCoinSetParam) {
    return http2.postJson('/auto_swap_coin/set', {
      ...data,
      passwd: CryptoJS.MD5(data.passwd.trim()).toString(),
    });
  }

  whitelist(): Promise<{ list: Array<WhitelistRes> }> {
    return http.postJson('/withdraw_whitelist/list', {});
  }

  addWhiteListItem(data: addWhiteListItemParam) {
    return http.postJson('/withdraw_whitelist/add', data);
  }

  removeWhilteListItem(data: { id: number }) {
    return http.postJson('/withdraw_whitelist/del', data);
  }

  checkCCPaymentUser(data: { id: string }): Promise<{ UserName: string }> {
    return http.postJson('/checkout/user', data);
  }

  // 设置测试网络是否开启
  testCoinSet(status: -1 | 1): Promise<any> {
    return http.postJson('/test_coin/set', { status });
  }

  // 获取测试网络列表
  testCoinGet(): Promise<{ list: TestCoinListItem[] }> {
    return http.postJson('/test_coin/get', {});
  }

  /**------------------------------end */
  checkWebHook(data: { url: string }) {
    return http.postJson('/app/info', data);
  }

  setWebhook(value: string) {
    return http.postJson('/payment/info/set', {
      field: 'webhook_url',
      value,
    });
  }

  setIps(value: string) {
    return http.postJson('/payment/info/set', {
      field: 'ips',
      value,
    });
  }

  setPublicKey(value: string, field: string) {
    return http.postJson('/payment/info/set', {
      value,
      field,
    });
  }

  setModetype(value: string) {
    return http.postJson('/payment/info/set', {
      value,
      field: 'mode_type',
    });
  }

  /**
    * getPayment API Order list data payment list
    * page_num: page int,
      page_size: size int,
      begin_at: start time ms, int
      begin_at: End Timems, int
      pay_status: payment status (the number greater than 8 is all), 0 unpaid, 1 successful payment, 2 insufficient payment, 3 overpaid, 4 payment failed, 5 expired, 6 overdue payment, 7 multiple payments, number
      symbol: token string array array,
      bill_id: order id string
    *
    */
  getPaymentListInfo({
    page_num,
    page_size,
    pay_status,
    merchant_order_id,
    symbol,
    begin_at,
    end_at,
  }: {
    page_num: number;
    page_size: number;
    pay_status: number;
    merchant_order_id?: string;
    symbol?: string[];
    begin_at: number;
    end_at: number;
  }): Promise<PaymentApiLsitType> {
    return http.postJson('/bill/api/list', {
      page_num,
      page_size,
      pay_status,
      merchant_order_id,
      symbol,
      begin_at,
      end_at,
    });
  }

  /**
   * order details
     bill_id: order id string
   *  
   */
  getPaymentDetail(bill_id: string): Promise<PaymentDetailType> {
    return http.postJson('/bill/api/detail', { bill_id });
  }

  /**
   * order details Remarks saved
     bill_id: order id string
     remark: Remarks string
   *  
   */
  savePaymentMark(bill_id: string, remark: string): Promise<PaymentSaveType> {
    return http.postJson('/bill/api/remark/save', { bill_id, remark });
  }

  /**
    * order list export
      * page_num: page number int,
     page_size: page capacity int,
     begin_at: start time ms, int
     begin_at: End Timems, int
     pay_status: payment status (the number greater than 8 is all), 0 unpaid, 1 successful payment, 2 insufficient payment, 3 overpaid, 4 payment failed, 5 expired, 6 overdue payment, 7 multiple payments, number
     symbol: token string array array,
     bill_id: order id string
   *
    *
    */
  paymentListExport(params: {
    /** yyyy/mm/dd */
    export_begin_at: string;
    /** yyyy/mm/dd */
    export_end_at: string;
    page_num?: number;
    pay_status?: number;
    symbol?: string[];
    bill_id?: string;
    begin_at: number;
    end_at: number;
    zone?: string;
  }): Promise<{ path: string }> {
    return http.postJson('/bill/api/list/export', params);
  }

  /**
      * api out Going list
        merchant_order_id: order id string
        status: status number status, 0 all 1 processing, 2 success, 3 failed
        symbol: tokens string[]
        page_num: current page number
        page_size: page size number
      *
      */
  getOutGoingList({
    merchant_order_id = '',
    status = 0,
    symbol = [],
    page_num = 1,
    page_size = 20,
    begin_at = 0,
    end_at = 0,
  }: {
    merchant_order_id: string;
    status: number;
    symbol: any[];
    page_num: number;
    page_size: number;
    begin_at: number;
    end_at: number;
  }): Promise<OutGoingListRestype> {
    return http.postJson('/bill/withdraw/list', {
      merchant_order_id,
      status,
      symbol,
      page_num,
      page_size,
      begin_at,
      end_at,
    });
  }

  /**
    * out order list export
     merchant_order_id: Merchant order id,
     begin_at: start time ms, int
     end_at: End Timems, int
     status: 0,1,2,3
     symbol: token string array array,
   *
    *
    */
  OutListExport(params: {
    /** yyyy/mm/dd */
    export_begin_at: string;
    /** yyyy/mm/dd */
    export_end_at: string;
    merchant_order_id: string;
    page_num: number;
    status: number;
    symbol: string[];
    begin_at: number;
    end_at: number;
    zone?: string;
  }): Promise<{ path: string }> {
    return http.postJson('/bill/withdraw/export', params);
  }

  /**
   * outorder details
     bill_id: order id string
   *  
   */
  getOutDetail(bill_id: string): Promise<{ detail: outDetailType }> {
    return http.postJson('/bill/withdraw/detail', { bill_id });
  }
}

export interface setDevelopResetParam {
  verify_code: {
    totp_code: string;
    sms_code: string;
    mail_code: string;
  };
  ip_whitelist?: string;
}

export interface TestCoinListItem {
  id: number;
  symbol: string;
  logo_url: string;
  price: string;
  networks: Networks;
  cc_merge_id: string;
  name: string;
  total_supply: string;
  price_at_00h: string;
  market_cap: string;
}

interface Networks {
  [key: string]: {
    coin_id: number;
    chain: string;
    symbol: string;
    contract: string;
    precision: number;
    can_deposit: boolean;
    can_withdraw: boolean;
    minimum_deposit_amount: string;
    minimum_withdraw_amount: string;
    maximum_withdraw_amount: string;
    minimum_withdraw_fee: string;
    is_native: boolean;
    cc_token_id: string;
    protocol: string;
  };
}

export interface autoSwapCoinSetParam {
  passwd: string;
  from_coin_ids: number[];
  to_coin_id: number;
}

export interface autoSwapCoinInfoRes {
  from_coin_list: FromCoinList[];
  to_coin: ToCoin;
}

export interface FromCoinList {
  coin_id: number;
  symbol: string;
  logo: string;
  name: string;
  status?: number;
}

export interface ToCoin {
  coin_id: number;
  symbol: string;
  logo: string;
  name: string;
}
export interface AutoWithdrawSetting {
  trigger_type: number;
  trigger_dt?: number | null;
  trigger_week?: number | null;
  min_amount_threshold: string;
  proportion: number;
  trigger_zone?: string | null;
}

export interface addressListType {
  coin_id: number;
  chain_id: string;
  address: string;
  memo?: string;
  logo: string;
  symbol: string;
  protocol: string;
  status: number;
  coin_status: string;
}

export interface verifyCodeType {
  totp_code: string;
  sms_code: string;
  mail_code: string;
}

export interface autoWithdrawCoinListRes {
  auto_withdraw_setting: AutoWithdrawSetting;
  list: addressListType[];
}

export interface autoWithdrawCoinSetType {
  pay_passwd: string;
  check_hash: string;
  auto_withdraw_setting: AutoWithdrawSetting;
  list: addressListType[];
  verify_code: verifyCodeType;
}

export interface autoSwapCoinListRes {
  from_coin_list: FromCoinList[];
  swap_coin_list: FromCoinList[];
  to_coin_list: FromCoinList[];
}

export interface addWhiteListItemParam {
  coin_id: number;
  chain: string;
  address: string;
  memo: string;
  remark: string;
  code: VerifyCode;
  submit_type: number;
}

export interface getAppInfoRes {
  app_id: string;
  app_secret: string;
}

/**
 * field => 1.webhook_url
 * 2.ip_whitelist
 * 3.wwl_status -1 1
 * 4.webhook_notify_mode 1 pending 2 Processing 3 Success
 * 5.auto_swap -1关闭 1开启
 * 6. auto_add_coin -1关闭 1开启
 *  */
export interface setDevelopInfoParam {
  field:
    | 'webhook_url'
    | 'ip_whitelist'
    | 'wwl_status'
    | 'webhook_notify_mode'
    | 'auto_swap'
    | 'auto_add_coin';
  value: string;
}

//--------------------------------

export type configCheckParamType = {
  code: string;
  mode_type: number;
};

export type outDetailType = {
  bill_id: string;
  merchant_order_id: string;
  network: string;
  amount: string;
  chain_id: string;
  contract: string;
  symbol: string;
  logo: string;
  network_fee: string;
  to_address: string;
  cwallet_id: string;
  email: string;
  txid: string;
  txid_url: string;
  status: number; //status，1 processing，2 success，3 failed
  created: number;
  network_name: string;
  network_fee_logo?: string;
  network_fee_symbol?: string;
};

export type OutGoingListRestype = {
  list: OutGoingList[];
  pagination: {
    page_num: number;
    page_size: number;
    total: number;
  };
};

export interface OutGoingList {
  bill_id: string;
  merchant_order_id: string;
  amount: string;
  chain_id: string;
  contract: string;
  symbol: string;
  logo: string;
  status: number;
  created: number;
  updated: number;
}

export type PaymentSaveType = {
  [key: string]: any;
};
export type PaymentApiLsitType = {
  pagination: any;
  list: {
    id: number;
    bill_id: string;
    merchant_id: number;
    merchant_order_id: string;
    chain_id: string;
    contract: string;
    symbol: string;
    origin_amount: string;
    paid_amount: string;
    created: number;
    logo: string;
    pay_status?: number;
    [key: string]: any;
  }[];
  total: number;
  total_value: string;
};
// order details返参
export type PaymentDetailType = {
  detail: {
    bill_id?: string;
    merchant_id?: string;
    merchant_order_id?: string;
    chain_id?: string;
    contract?: string;
    symbol?: string;
    origin_amount?: string;
    paid_amount?: string;
    transfer_record_id?: string;
    logo?: string;
    current_rate?: string;
    service_fee?: string;
    type?: string;
    created?: number;
    pay_status?: number;
    total_refund_amount?: string;
    total_refund_fee?: string;
    remain_amount?: string;
    remark?: string;
    token_id?: string;
    fiat_name?: string;
    fiat_amount?: string;
    rate?: string;
    product_name?: string;
    expired?: number;
  };
  pays: {
    record_id?: string;
    chain_id?: string;
    contract?: string;
    symbol?: string;
    amount?: string;
    em?: number;
    pay_time: number;
    service_fee?: string;
    pay_status: 'Successful' | 'Pending' | 'Failed';
    logo?: string;
  }[];
  refunds: {
    record_id?: string;
    chain_id?: string;
    contract?: string;
    symbol?: string;
    refund_amount?: string;
    network_fee?: string;
    pay_time?: number;
    refund_status: number;
    fee_style: number;
  }[];
};
export interface WhitelistRes {
  id: number;
  merchant_id: string;
  coin_id: number;
  chain_id: string;
  address: string;
  memo?: string;
  mark: string;
  type: number;
  // contract: string;
  // logo: string;
  // symbol: string;
  // token_status: number;
  // precision?: number;
}
