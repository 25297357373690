import { useNavigate } from 'react-router';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { reaction } from 'mobx';
import { replaceStore } from './store';

export default observer(function RouteForward() {
  const nav = useNavigate();
  // eslint-disable-next-line
  useEffect(() => {
    return reaction(
      () => replaceStore.next,
      (next) => {
        nav(next.url, { replace: true, state: { ...next.state } });
      }
    );
    // eslint-disable-next-line
  }, []);
  return null;
});
