import {
  Box,
  Button,
  Card,
  FormControl,
  FormControlLabel,
  FormGroup,
  Paper,
  PaperProps,
  Switch,
  Typography,
} from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useLocalDispatch, useSharedChannel, withLocalStore } from '@cctip/saga-store';
import { observer } from 'mobx-react-lite';
import DialogConfirmGoogleAuth from './dialog/disable-google-auth';
import MerchantPageFlow, { ApiHandle, UIState } from './workflow';
import DialogGoogleAuthCloseForm from './dialog/google-auth-form';
import DialogGoogleAuthQRForm from './dialog/google-auth-qr-form';
import DialogConfirmEmailChange from './dialog/change-email-confirm';
import DialogEmailChangeForm from './dialog/change-email-form';
import DialogConfirmPhoneChange from './dialog/change-phone-confirm';
import DialogPhoneChangeForm from './dialog/change-phone-form';
import DialogNameChangeForm from './dialog/change-name-form';
import DialogConfirmPasswordChange from './dialog/change-password-confirm';
import DialogPasswordChangeForm from './dialog/change-password-form';
import { SettingsContext } from './context';
import { useSnackbar } from 'notistack';
import './shame.scss';
import { useAuthContext } from '@/auth/useAuthContext';
import { useLocales } from '@/locales';
import DialogEnabledPhoneAuth from './dialog/enable-phone-auth';
import DialogConfirmPhoneAuth from './dialog/disable-phone-auth';
import DialogPhoneAuthCloseForm from './dialog/phone-auth-form';
import MerchantLoadingNoPosition from '@/layouts/dashboard/nav/components/merchant-loading-no-position';
import PaymentPwd from './payment-pwd/index';
import DialogConfirmPayPasswordChange from './dialog/change-pay-password-confirm';
import DialogPayPasswordChangeForm from './dialog/change-pay-password-form';
import { styles } from './style';
import { IconProps, MyIcon } from '@/assets/icons/MyIcon';
import useResponsive from '@/hooks/useResponsive';
import NewCaptchaDialog from '../auth/common/NewCaptchaDialog';

function SecuritySettings() {
  const isMobile = !useResponsive('up', 'sm');
  const dispatch = useLocalDispatch();
  const { translate } = useLocales();
  const { user, updUserInfoToStore, changeCaptchaData, captchaData } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const [settingLoading, setSettingLoading] = useState(false);

  const [captchaDialogOpen, setCaptchaDialogOpen] = useState(false);
  const [bindPhone, setBindPhone] = useState(false);
  const [changeEmail, setChangeEmail] = useState(false);

  useEffect(
    () => {
      if (ApiHandle.settings > 0) {
        setSettingLoading(true);
        updUserInfoToStore({ isNeedExtra: true, refreshRouter: false }).finally(() => {
          setSettingLoading(false);
        });
      }
    },
    // eslint-disable-next-line
    [ApiHandle.settings],
  );

  const sharedChannel = useSharedChannel();
  useEffect(
    () => {
      sharedChannel.put({ enqueueSnackbar });
    },
    [], // eslint-disable-line
  );

  const email = useMemo(() => {
    const [name, suffix] = (user?.email ?? '').split('@');
    return `${name.slice(0, 3)}****@${suffix}`;
  }, [user?.email]);

  const phone = useMemo(() => {
    const text = user?.phone ?? '';
    if (!text) return text;
    const prefix = text.slice(0, -7);
    const suffix = text.slice(-4);
    return `${user?.phone_area}-${prefix}***${suffix}`;
  }, [user?.phone, user?.phone_area]);

  const userRef = useRef(user);
  userRef.current = user;

  const onCloseGoogleAuthConfirm = async (confirm: boolean) => {
    if (confirm) {
      dispatch({ type: 'dialog/confirm' });
    } else {
      dispatch({ type: 'dialog/cancel' });
    }
  };

  // Whether to enable two-step verification
  const enableGoogle2FA = user?.exists_google_2fa ?? false;
  // const enableEmail = Boolean(settingsResp.data?.security_email) ?? false;
  const enablePhone = Boolean(user?.security_phone) ?? false;

  useEffect(
    () => {
      if (ApiHandle.paymentSettings > 0) {
        setSettingLoading(true);
        updUserInfoToStore({ isNeedExtra: true, refreshRouter: false }).finally(() => {
          setSettingLoading(false);
        });
      }
    },

    // eslint-disable-next-line
    [ApiHandle.paymentSettings],
  );

  if (settingLoading) {
    return (
      <Card sx={{ width: '100%', height: '100vh', boxShadow: 'none' }}>
        <MerchantLoadingNoPosition width="100%" />
      </Card>
    );
  }

  return (
    <SettingsContext.Provider value={user}>
      {/* <header className="flex mb4">
        <section className="flex flex-column">
          <span className="w-100 fs-title-page  color-text">
            {translate('merchantSettings.Account_Settings_title')}
          </span>
        </section>
      </header> */}
      <div>
        <SecuritySettingPaperWrap>
          {/* nickName */}
          <Box
            sx={{
              borderRadius: 1,
            }}
            className="flex items-center"
          >
            <Box sx={{ flex: 1 }}>
              <Typography
                variant="subtitle2"
                className="color-text"
                sx={{ color: '#1F2025', fontWeight: 600 }}
              >
                {translate('merchantSettings.settings_account_nickname')}
              </Typography>
              <Typography variant="body2" fontSize={isMobile ? 12 : 14} className="color-text2">
                {user?.username
                  ? user?.username
                  : translate('merchantSettings.settings_account_nickname_desc')}
              </Typography>
            </Box>
            <Button
              onClick={() => dispatch({ type: 'name-change/confirm' })}
              sx={{ ...styles.set_up }}
            >
              {!isMobile && (
                <>
                  {user?.email
                    ? translate('merchantSettings.security_btn_change')
                    : translate('merchantSettings.security_btn_set_up')}
                </>
              )}
              <SecuritySettingBtnArrow />
            </Button>
          </Box>
        </SecuritySettingPaperWrap>
        {/* Binding information changes */}
        <SecuritySettingPaperWrap>
          {/* email */}
          <Box
            sx={{
              borderRadius: 1,
            }}
            className="flex items-center"
          >
            <Box sx={{ flex: 1 }}>
              <Typography
                variant="subtitle2"
                className="color-text"
                sx={{ color: '#1F2025', fontWeight: 600 }}
              >
                {translate('merchantSettings.security_email')}
              </Typography>
              <Typography variant="body2" fontSize={isMobile ? 12 : 14} className="color-text2">
                {email ? email : translate('merchantSettings.security_email_desc')}
              </Typography>
            </Box>
            <Button
              sx={{ ...styles.set_up }}
              onClick={() => {
                dispatch({ type: 'email-change/confirm' });
              }}
            >
              {!isMobile && <>{translate('merchantSettings.security_btn_change')}</>}
              <SecuritySettingBtnArrow />
            </Button>
          </Box>
        </SecuritySettingPaperWrap>

        {/* phone */}
        <SecuritySettingPaperWrap>
          <Box
            sx={{
              borderRadius: 1,
            }}
            className="flex items-center"
          >
            <Box
              sx={{
                flex: 1,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: isMobile ? 'flex-start' : 'center',
                flexDirection: isMobile ? 'column' : 'row',
              }}
            >
              <Box>
                <Typography
                  variant="subtitle2"
                  className="color-text"
                  sx={{ color: '#1F2025', fontWeight: 600 }}
                >
                  {translate('merchantSettings.security_phone')}
                </Typography>
                {(!isMobile || !phone) && (
                  <Typography variant="body2" fontSize={isMobile ? 12 : 14} className="color-text2">
                    {translate('merchantSettings.security_phone_desc')}
                  </Typography>
                )}
              </Box>
              <Typography
                variant="body2"
                fontSize={isMobile ? 12 : 14}
                className="color-text2"
                mr={isMobile ? 0 : 3}
              >
                {phone}
              </Typography>
            </Box>

            <Button
              sx={{ ...styles.set_up }}
              onClick={() => {
                if (!captchaData.CaptchaCode && !captchaData.CaptchaKey && !phone) {
                  setCaptchaDialogOpen(true);
                  setBindPhone(true);
                  return;
                }
                dispatch({ type: 'phone-change/confirm', phone });
              }}
            >
              {!isMobile && (
                <>
                  {phone
                    ? translate('merchantSettings.security_btn_change')
                    : translate('merchantSettings.security_btn_verify')}
                </>
              )}

              <SecuritySettingBtnArrow />
            </Button>
          </Box>
        </SecuritySettingPaperWrap>

        {/* password */}
        <SecuritySettingPaperWrap>
          <Box
            sx={{
              borderRadius: 1,
            }}
            className="flex items-center"
          >
            <Box sx={{ flex: 1 }}>
              <Typography
                variant="subtitle2"
                className="color-text"
                sx={{ color: '#1F2025', fontWeight: 600 }}
              >
                {translate('merchantSettings.security_login')}
              </Typography>
              {/* {!isMobile && (
                <Typography variant="body2" className="color-text2">
                  {translate('merchantSettings.security_login_desc')}
                </Typography>
              )} */}
              <Typography variant="body2" fontSize={isMobile ? 12 : 14} className="color-text2">
                {translate('merchantSettings.security_login_desc')}
              </Typography>
            </Box>
            <Button
              sx={{ ...styles.set_up }}
              onClick={() => dispatch({ type: 'password-change/confirm' })}
            >
              {!isMobile && <>{translate('merchantSettings.security_btn_change')}</>}

              <SecuritySettingBtnArrow />
            </Button>
          </Box>
        </SecuritySettingPaperWrap>

        <SecuritySettingPaperWrap>
          {/* payment password */}
          <PaymentPwd />
        </SecuritySettingPaperWrap>

        {/* Two-step verification binding */}
        <SecuritySettingPaperWrap>
          <Typography variant="subtitle2" className="color-text" sx={{ fontWeight: 600, mb: 1 }}>
            {translate('merchantSettings.security_two')}
          </Typography>
          <Typography variant="body2" fontSize={isMobile ? 12 : 14} className="color-text2" mb={3}>
            {translate('merchantSettings.security_two_desc')}
          </Typography>
          {/* google */}
          <Box
            sx={{
              px: 2.5,
              py: 1.5,
              border: '1px solid rgba(145, 158, 171, 0.24);',
              borderRadius: 1,
              mb: 3,
              alignItems: 'center',
            }}
            className="flex"
          >
            <Box sx={{ flex: 1 }}>
              <Typography variant="subtitle2" className="color-text">
                {translate('merchantSettings.security_google')}
              </Typography>
            </Box>
            <Box>
              <FormControl component="fieldset">
                <FormGroup row>
                  <FormControlLabel
                    checked={enableGoogle2FA}
                    // disabled={settingsResp.isLoading || settingsResp.isValidating}
                    onChange={(e, checked) => {
                      dispatch({
                        type: checked ? 'google-2fa/open' : 'google-2fa/close',
                        payload: user,
                      });
                    }}
                    control={<Switch color="primary" />}
                    label={
                      enableGoogle2FA
                        ? translate('merchantSettings.security_on')
                        : translate('merchantSettings.security_off')
                    }
                    labelPlacement="start"
                  />
                </FormGroup>
              </FormControl>
            </Box>
          </Box>
          <DialogConfirmGoogleAuth
            visible={UIState.showGoogleAuthConfirm}
            onClose={onCloseGoogleAuthConfirm}
          />

          {/* phone */}
          {user?.phone && (
            <>
              <Box
                sx={{
                  px: 2.5,
                  py: 1.5,
                  border: '1px solid rgba(145, 158, 171, 0.24);',
                  borderRadius: 1,
                  alignItems: 'center',
                }}
                className="flex items-center"
              >
                <Box sx={{ flex: 1 }}>
                  <Typography variant="subtitle2" className="color-text">
                    {translate('merchantSettings.security_phone')}
                  </Typography>
                </Box>
                <Box>
                  <FormControl component="fieldset">
                    <FormGroup row>
                      <FormControlLabel
                        checked={enablePhone}
                        // disabled={settingsResp.isLoading || settingsResp.isValidating}
                        onChange={(e, checked) => {
                          dispatch({
                            type: checked ? 'phone-2fa/open' : 'phone-2fa/close',
                            payload: user,
                          });
                        }}
                        control={<Switch color="primary" />}
                        label={
                          enablePhone
                            ? translate('merchantSettings.security_on')
                            : translate('merchantSettings.security_off')
                        }
                        labelPlacement="start"
                      />
                    </FormGroup>
                  </FormControl>
                </Box>
              </Box>
              <DialogEnabledPhoneAuth
                visible={UIState.showPhoneAuthEnabled}
                onClose={(payload) => {
                  if (payload) {
                    dispatch({ type: 'dialog/confirm' });
                  } else {
                    dispatch({ type: 'dialog/cancel' });
                  }
                }}
              />
              <DialogConfirmPhoneAuth
                visible={UIState.showPhoneAuthConfirm}
                onClose={onCloseGoogleAuthConfirm}
              />
              <DialogPhoneAuthCloseForm
                visible={UIState.showPhoneAuthForm}
                onClose={(payload) => {
                  if (user?.security_phone) {
                    dispatch({ type: 'gg-disable/result', payload });
                  } else {
                    dispatch({ type: 'gg-enable/result', payload });
                  }
                }}
              />
            </>
          )}
        </SecuritySettingPaperWrap>

        {/*  form  */}
        <DialogGoogleAuthCloseForm
          visible={UIState.showGoogleAuthForm}
          onClose={(payload) => dispatch({ type: 'gg-disable/result', payload })}
        />

        {UIState.showGoogleAuthQRForm && (
          <DialogGoogleAuthQRForm
            visible={UIState.showGoogleAuthQRForm}
            onClose={(payload) => dispatch({ type: 'gg-enable/result', payload })}
          />
        )}

        <DialogConfirmEmailChange
          visible={UIState.showEmailChangeConfirm}
          onClose={(confirm) => {
            if (!captchaData.CaptchaCode && !captchaData.CaptchaKey && !changeEmail && confirm) {
              setCaptchaDialogOpen(true);
              setChangeEmail(true);
              return;
            }
            dispatch({ type: 'email-confirm/result', confirm });
          }}
        />
        <DialogEmailChangeForm
          visible={UIState.showEmailChangeForm}
          onClose={(payload) => dispatch({ type: 'email-change/result', payload })}
        />
        <DialogConfirmPhoneChange
          visible={UIState.showPhoneChangeConfirm}
          onClose={(confirm) => {
            if (!captchaData.CaptchaCode && !captchaData.CaptchaKey && confirm) {
              setCaptchaDialogOpen(true);
              return;
            }
            dispatch({ type: 'phone-confirm/result', confirm });
          }}
        />
        <DialogPhoneChangeForm
          visible={UIState.showPhoneChangeForm}
          onClose={(payload) => dispatch({ type: 'phone-change/result', payload })}
        />
        <DialogNameChangeForm
          visible={UIState.showNameChangeForm}
          onClose={(payload) => dispatch({ type: 'name-change/result', payload })}
        />

        <DialogConfirmPasswordChange
          visible={UIState.showPasswordChangeConfirm}
          onClose={(confirm) => dispatch({ type: 'password-confirm/result', confirm })}
        />
        <DialogPasswordChangeForm
          visible={UIState.showPasswordChangeForm}
          onClose={(payload) => dispatch({ type: 'password-change/result', payload })}
        />

        {/* payment passwrod start */}
        <DialogConfirmPayPasswordChange
          visible={UIState.showPayPasswordChangeConfirm}
          onClose={(confirm) => dispatch({ type: 'pay-password-confirm/result', confirm })}
        />
        <DialogPayPasswordChangeForm
          visible={UIState.showPayPasswordChangeForm}
          onClose={(payload) => dispatch({ type: 'pay-password-change/result', payload })}
        />
        {/* payment passwrod end */}
        {captchaDialogOpen && (
          <NewCaptchaDialog
            onClose={(v) => {
              changeCaptchaData(v);
              if (v?.CaptchaCode && v?.CaptchaKey) {
                // setTimeout(() => {
                //   subRef.current?.click();
                // }, 200);

                // 绑定新电话号码的时候
                if (bindPhone) {
                  dispatch({ type: 'phone-change/confirm', phone: '' });
                  setBindPhone(false);
                  changeCaptchaData({
                    CaptchaKey: '',
                    CaptchaCode: '',
                  });
                }
                // 改变电话号码的时候
                if (UIState.showPhoneChangeConfirm) {
                  dispatch({ type: 'phone-confirm/result', confirm: true });
                  changeCaptchaData({
                    CaptchaKey: '',
                    CaptchaCode: '',
                  });
                }

                if (UIState.showEmailChangeConfirm) {
                  dispatch({ type: 'email-confirm/result', confirm: true });
                  setChangeEmail(false);
                  changeCaptchaData({
                    CaptchaKey: '',
                    CaptchaCode: '',
                  });
                }
              }
              setCaptchaDialogOpen(false);
              setBindPhone(false);
              setChangeEmail(false);
            }}
            email={user?.email}
            open={captchaDialogOpen}
          />
        )}
      </div>
    </SettingsContext.Provider>
  );
}

export default withLocalStore(observer(SecuritySettings), { task: MerchantPageFlow as any });

// export const SecuritySettingPaperWrap=styled((props)=><Paper {...props}/>)(({})=>({}));

export const SecuritySettingPaperWrap = (props: PaperProps) => {
  const isMobile = !useResponsive('up', 'sm');
  return (
    <Paper
      elevation={5}
      sx={{
        mb: isMobile ? 2 : 3,
        p: isMobile ? 2 : 3,
        boxShadow: '0px 6px 12px 0px rgba(230, 233, 246, 0.50)',
      }}
      {...props}
    />
  );
};

type SecuritySettingBtnArrowProps = Omit<IconProps, 'name'>;

export const SecuritySettingBtnArrow = (props: SecuritySettingBtnArrowProps) => {
  const isMobile = !useResponsive('up', 'sm');
  return (
    <MyIcon
      size={20}
      style={{ marginLeft: '8px' }}
      {...props}
      name={isMobile ? 'arrow_right_black' : 'arrow_right'}
    />
  );
};
