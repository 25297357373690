// @mui
import { useTheme } from '@mui/material/styles';
import { AppBar, Toolbar, IconButton, Box, Link } from '@mui/material';
// utils
import { bgBlur } from '../../../utils/cssStyles';
// hooks
import useOffSetTop from '../../../hooks/useOffSetTop';
import useResponsive from '../../../hooks/useResponsive';
// config
import { CCP_URL, EmptyMerchantId, HEADER, LAYOUTTOP, NAV } from '../../../config';
// components
import Logo from '../../../components/logo';
import { useSettingsContext } from '../../../components/settings';
//
// import Searchbar from './Searchbar';
import AccountPopover from './AccountPopover';
// import LanguagePopover from './LanguagePopover';
// import ContactsPopover from './ContactsPopover';
import NotificationsPopover from './NotificationsPopover';
// import CheckModeBtn from './CheckModeBtn';
// import SettingsPopover from './SettingsPopover';
import { useAuthContext } from '@/auth/useAuthContext';
import MerchantGuidePopover from './MerchantGuidePopover';
// import ReferralPopover from './ReferralPopover';
import { MyIcon } from '@/assets/icons/MyIcon';
// import { MyIcon } from '@/assets/icons/MyIcon';
// import { IconButtonAnimate } from '@/components/animate';
// import { useNavigate } from 'react-router';

import AnnouncementPopover from './AnnouncementPopover';

type Props = {
  isFold?: boolean;
  onOpenNav?: VoidFunction;
  open?: boolean;
};

export default function Header({ onOpenNav, isFold, open }: Props) {
  const theme = useTheme();

  // const nav = useNavigate();

  const { user, showAnnouncement } = useAuthContext();

  const { themeLayout } = useSettingsContext();

  const isNavHorizontal = themeLayout === 'horizontal';

  const isNavMini = themeLayout === 'mini';

  const isDesktop = useResponsive('up', 'lg');

  const isOffset = useOffSetTop(HEADER.H_DASHBOARD_DESKTOP) && !isNavHorizontal;

  const isEmptyMerchant = EmptyMerchantId === user?.merchantId;

  const renderContent = (
    <>
      {((isDesktop && isNavHorizontal) || isEmptyMerchant) && (
        <Logo sx={{ mr: isEmptyMerchant ? 0 : 2.5 }} />
      )}
      {isEmptyMerchant && (
        <Link href={CCP_URL} sx={{ display: 'contents' }}>
          {/* <Tooltip title={translate('nav.Official_website')} placement="right-start"> */}
          <MyIcon name="ccpayment" size={140} style={{ marginLeft: '16px' }} />
          {/* </Tooltip> */}
        </Link>
      )}

      <div>
        {!isDesktop && !open && (
          <IconButton onClick={onOpenNav} sx={{ mr: 1 }}>
            <MyIcon name={'ic_nav_flod_right'} size={24} />
          </IconButton>
        )}
      </div>

      {/* <Searchbar /> */}
      <Box sx={{ display: 'flex', justifyContent: 'flex-end', flexGrow: 1, alignItems: 'center' }}>
        {/* <Box sx={{ mr: 1 }}>
          <IconButtonAnimate sx={{ width: 40, height: 40 }}>
            <MyIcon
              name="developer2"
              size={24}
              onClick={() => {
                nav('/developer/config', { replace: true });
              }}
            />
          </IconButtonAnimate>
        </Box> */}

        {/* {['1', 1].includes(chooseApi) && (
          <Box sx={{ mr: 2 }}>
            <ReferralPopover />
          </Box>
        )} */}

        {/* {currentEnv === 'prd' && TESTEMAILACCOUNTS.includes(user?.email || '') && (
          <Box sx={{ mr: 2 }}>
            <CheckModeBtn />
          </Box>
        )} */}
        {/* <LanguagePopover /> */}
        <Box sx={{ mr: 2 }}>
          <NotificationsPopover />
        </Box>

        <Box sx={{ mr: 2 }}>
          <AnnouncementPopover />
        </Box>

        {/* <Box sx={{ mr: 2 }}>
          <SettingsPopover />
        </Box> */}

        {!isEmptyMerchant && (
          <Box sx={{ mr: 3 }}>
            <MerchantGuidePopover />
          </Box>
        )}

        {/* <ContactsPopover /> */}
        <Box>
          <AccountPopover />
        </Box>
      </Box>
      {/* <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 3 }}
      > */}
      {/* <IconButtonAnimate sx={{ width: 40, height: 40 }}>
          <MyIcon
            name="developer2"
            size={24}
            onClick={() => {
              nav('/developer/config', { replace: true });
            }}
          /> */}
      {/* </IconButtonAnimate> */}

      {/* <LanguagePopover /> */}

      {/* <NotificationsPopover /> */}

      {/* <ContactsPopover /> */}

      {/* <AccountPopover /> */}
      {/* </Stack> */}
    </>
  );

  return (
    <AppBar
      sx={{
        boxShadow: '0px 4px 12px #F9FAF6',
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(isDesktop && {
          width: isEmptyMerchant
            ? '100%'
            : `calc(100% - ${isFold ? NAV.W_DASHBOARD_MINI : NAV.W_DASHBOARD}px)`,
          // height: HEADER.H_DASHBOARD_DESKTOP,
          height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          ...(isOffset && {
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DASHBOARD_DESKTOP_OFFSET,
            borderBottom: (theme) => `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_DASHBOARD_MINI + 1}px)`,
          }),
        }),
        top: showAnnouncement ? LAYOUTTOP : 0,
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  );
}
