// @mui
import {
  Dialog,
  Button,
  DialogTitle,
  DialogActions,
  DialogContent,
  IconButton,
  Box,
} from '@mui/material';
//
import { ConfirmDialogProps } from './types';
import { useLocales } from '@/locales';
import { MyIcon } from '@/assets/icons/MyIcon';

export default function ConfirmDialog({
  title,
  content,
  action,
  open,
  close,
  onClose,
  closeBtn,
  ...other
}: ConfirmDialogProps) {
  const { translate: t } = useLocales();
  return (
    <Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} {...other}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          paddingRight: '20px',
        }}
      >
        <DialogTitle sx={{ pb: 2 }}>{title}</DialogTitle>
        {closeBtn && (
          <IconButton onClick={onClose} aria-label="close" sx={{ width: 28, height: 28 }}>
            <MyIcon name="close" size={14} />
          </IconButton>
        )}
      </Box>

      {content && <DialogContent sx={{ typography: 'body2' }}> {content} </DialogContent>}

      <DialogActions>
        {action}
        {close && (
          <Button
            variant="outlined"
            color="inherit"
            onClick={onClose}
            sx={{ fontWeight: 600, display: action ? 'flex' : 'inline-block', padding: '6px 16px' }}
          >
            {t('common.Cancel')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
}
