import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui';
import { useEffect } from 'react';
import { MyIcon } from '@/assets/icons/MyIcon';
import { LoadingButton } from '@mui/lab';
import { useAuthContext } from '@/auth/useAuthContext';
import GetPhoneCode from '@/components/get-code/phone';
import GetEmailCode from '@/components/get-code/email';
import { observer } from 'mobx-react';
import { UIState } from '../workflow';
import { useLocales } from '@/locales';
import CommonDialog from '../components/CommonDialog';
import { PhoneSendTypeEnum } from '@/api/security_';

export default observer(DialogPhoneAuthCloseForm);

function DialogPhoneAuthCloseForm({
  visible,
  onClose,
}: {
  visible: boolean;
  onClose: (payload: any) => void;
}) {
  const { user } = useAuthContext();
  const formContext = useForm();
  const { translate: t } = useLocales();

  const onConfirm = () => {
    const dom: HTMLInputElement = document.querySelector('#DialogGoogleAuthBaseForm-Submit')!;
    dom.click();
  };

  const onOK = async (data: any) => {
    await onClose(data);
  };

  useEffect(
    () => formContext.reset(),
    // eslint-disable-next-line
    [visible],
  );

  return (
    <CommonDialog open={visible} onClose={() => onClose(null)}>
      <DialogTitle id="scroll-dialog-title">
        <Box className="flex justify-between items-center">
          <Typography variant="h5" component="span">
            {t('merchantSettings.security_phone_dialog_title6')}
          </Typography>
          <IconButton onClick={() => onClose(null)}>
            <MyIcon name="close" size={14} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ pt: 1 }}>
        <FormContainer formContext={formContext} handleSubmit={formContext.handleSubmit(onOK)}>
          <TextFieldElement
            label={t('merchantSettings.security_phone_dialog_SMS_label')}
            name="code"
            fullWidth
            InputProps={{
              endAdornment: (
                <GetPhoneCode
                  phone={user?.phone_area?.slice(1) + '-' + user?.phone}
                  type={'old'}
                  send_type={PhoneSendTypeEnum.Normal}
                />
              ),
            }}
            inputProps={{
              maxLength: 6,
            }}
            autoComplete={'off'}
            helperText={t('merchantSettings.security_phone_dialog_SMS_helpText', {
              phone: user?.phone_area?.slice(1) + '-' + user?.phone || '--',
            })}
            sx={{ mb: 2, '.MuiInputLabel-asterisk': { display: 'none' } }}
            validation={{
              required: true,
            }}
          />

          {user?.exists_google_2fa ? (
            <TextFieldElement
              name="totp_code"
              fullWidth
              autoComplete={'off'}
              inputProps={{
                maxLength: 6,
              }}
              validation={{
                required: true,
              }}
              label={t('merchantSettings.security_google')}
              helperText={t('merchantSettings.security_email_dialog_helpText2')}
            />
          ) : (
            <TextFieldElement
              name="mail_code"
              fullWidth
              autoComplete={'off'}
              inputProps={{
                maxLength: 6,
              }}
              validation={{
                required: true,
              }}
              InputProps={{
                endAdornment: <GetEmailCode email={user?.email || ''} type={'old'} />,
              }}
              label={t('common.Verification_email_label')}
              helperText={`${t('merchantSettings.security_email_helperText')} ${user?.email || ''}`}
            />
          )}

          <input id="DialogGoogleAuthBaseForm-Submit" type="submit" hidden />
        </FormContainer>
      </DialogContent>
      <DialogActions className="loading-btn">
        <LoadingButton
          color="primary"
          variant="contained"
          fullWidth
          onClick={onConfirm}
          type="submit"
          className="btn-height48"
          loading={UIState.loading}
          loadingIndicator={<MyIcon name="loading" className="loading-icon-rotate" />}
        >
          {t('common.Confirm')}
        </LoadingButton>
      </DialogActions>
    </CommonDialog>
  );
}
