import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from '@mui/material';
import { FormContainer, TextFieldElement, useForm } from 'react-hook-form-mui';
import GetEmailCode from '@/components/get-code/email';
import { useContext, useEffect, useMemo } from 'react';
import { SettingsContext } from '../context';
import { MyIcon } from '@/assets/icons/MyIcon';
import { LoadingButton } from '@mui/lab';
import { observer } from 'mobx-react';
import { UIState } from '../workflow';
import { useLocales } from '@/locales';
import CommonDialog from '../components/CommonDialog';

export default observer(DialogGoogleAuthCloseForm);

function DialogGoogleAuthCloseForm({
  visible,
  onClose,
}: {
  visible: boolean;
  onClose: (payload: any) => void;
}) {
  const { translate: t } = useLocales();
  const formContext = useForm();

  const onConfirm = () => {
    const dom: HTMLInputElement = document.querySelector('#DialogGoogleAuthBaseForm-Submit')!;
    dom.click();
  };

  const onOK = async (data: any) => {
    await onClose(data);
  };
  const settings = useContext(SettingsContext);
  const email = useMemo(() => {
    const [name, suffix] = (settings?.email ?? '').split('@');
    return `${name.slice(0, 2)}****@${suffix}`;
  }, [settings?.email]);

  useEffect(
    () => formContext.reset(),
    // eslint-disable-next-line
    [visible]
  );

  return (
    <CommonDialog open={visible} onClose={() => onClose(null)}>
      <DialogTitle id="scroll-dialog-title">
        <Box className="flex justify-between items-center">
          <Typography variant="h5" component="span">
            {t('merchantSettings.security_google')}
          </Typography>
          <IconButton onClick={() => onClose(null)}>
            <MyIcon name="close" size={14} />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent sx={{ pt: 1 }}>
        <FormContainer formContext={formContext} handleSubmit={formContext.handleSubmit(onOK)}>
          <TextFieldElement
            name="mail_code"
            label={t('merchantSettings.security_email_dialog_Verification_label1')}
            fullWidth
            InputProps={{
              endAdornment: <GetEmailCode email={settings?.email} type={'old'} />,
            }}
            inputProps={{
              maxLength: 6,
            }}
            autoComplete={'off'}
            helperText={t('merchantSettings.security_email_dialog_helpText1', { email: email })}
            sx={{ mb: 2 }}
            validation={{
              required: true,
            }}
          />
          <TextFieldElement
            name="totp_code"
            fullWidth
            inputProps={{
              maxLength: 6,
            }}
            autoComplete={'off'}
            label={t('merchantSettings.security_email_dialog_Verification_label2')}
            helperText={t('merchantSettings.security_email_dialog_helpText2')}
            validation={{
              required: true,
            }}
          />
          <input id="DialogGoogleAuthBaseForm-Submit" type="submit" hidden />
        </FormContainer>
      </DialogContent>
      <DialogActions className="loading-btn">
        <LoadingButton
          color="primary"
          variant="contained"
          fullWidth
          onClick={onConfirm}
          type="submit"
          className="btn-height48"
          loading={UIState.loading}
          loadingIndicator={<MyIcon name="loading" className="loading-icon-rotate" />}
        >
          {t('common.Confirm')}
        </LoadingButton>
      </DialogActions>
    </CommonDialog>
  );
}
