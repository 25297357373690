import { Http } from '@/api/http';
const http = new Http('/payment/v1');
const CryptoJS = require('crypto-js');

const token = {
  coin_id: 0,
  logo: 'https://resource.cwallet.com/token/icon/btc.png',
  price: '16290.52',
  symbol: 'BTC',
  token_id: '',
  type: 1,
  name: 'Bitcoin',
  label: '',
  status: 0,
  can_swap: false,
};

export type SystemToken = typeof token;

const mToken = {
  merchant_id: 10107,
  coin_id: 0,
  symbol: 'BTC',
  name: 'Bitcoin',
  logo: 'https://resource.cwallet.com/token/icon/btc.png',
  id: 'f36ad1c',
};

export type MerchantToken = typeof mToken;

const chain = {
  chain_id: 'ETC',
  network: 'ETC',
  network_detail: 'Ethereum Classic',
  chain_logo: 'https://resource.cwallet.com/token/icon/etc.png',
  name: 'Ethereum Classic',
  explorer: '',
  explorer_tx_url: '',
  can_deposit: true,
  can_withdraw: true,
  fee: {
    low: '',
    normal: '',
    fast: '',
  },
  is_support_memo: false,
  status: 0,
  // contract: '61',
  // symbol: 'ETC',
  // status: 1,
  // token_id: '0bb7b3ef-5acb-4b0c-8fac-59d48260740c',
  // precision: 18,
  // coin_id: 0,
};

export type SystemChain = typeof chain;

export default class TokenApi {
  tokenList(): Promise<{ list: SystemToken[] }> {
    return http.postJson('/token/system/list', {});
  }

  merchantTokenList(): Promise<{ list: MerchantToken[] }> {
    return http.postJson('/token/support/list', {});
  }

  chainList(): Promise<{ list: SystemChain[] }> {
    return http.postJson('/token/all/chain', {});
  }

  //Get the merchant's token balance
  getChainBalance({
    chain_id,
    contract,
  }: {
    contract: string;
    chain_id: string;
  }): Promise<ChainBalanceInfo> {
    return http.postJson('/bill/chain/balance', { chain_id, contract });
  }

  //Get the merchant's token balance
  getNetwork(token_id: string): Promise<NetworkType[]> {
    return http.postJson('/token/chains', { token_id });
  }

  //get Network Fee ----
  getNetWorkFee({
    chain_id = '',
    contract = '',
    address = '',
    memo = '',
    coin_id = 0,
    refund_value = '',
  }: {
    chain_id: string;
    contract: string;
    address: string;
    memo: string;
    coin_id: number;
    refund_value: string;
  }): Promise<netWorkFeeType> {
    return http.postJson('/assets/withdraw/fee', {
      chain_id,
      contract,
      address,
      memo,
      coin_id,
      refund_value,
    });
  }

  //get order token balance
  getOrderRefundRemain(
    bill_id: string,
    type: string,
    symbol: string,
  ): Promise<OrderRefundRemainType> {
    return http.postJson('/bill/symbol/remain', { bill_id, type, symbol });
  }

  //get refund coins list
  getRefundBillSymbols(bill_id: string, type: string): Promise<RefundBillSymbolsType> {
    return http.postJson('/bill/symbols', { bill_id, type });
  }

  //create refund
  createRefund({
    pass = '',
    code = '',
    merchant_bill_id = '',
    refund_amount = '',
    chain_id = '',
    contract = '',
    symbol = '',
    refund_fee_amount = '',
    memo = '',
    to_address = '',
    invoice = '',
    fee_style = 1,
    network = '',
    type = 'API',
  }: {
    pass: string;
    code: string;
    merchant_bill_id: string;
    refund_amount: string;
    chain_id: string;
    contract: string;
    symbol: string;
    refund_fee_amount: string;
    memo: string;
    to_address?: string;
    invoice?: string;
    fee_style: number;
    network: string;
    type: string;
  }): Promise<createRefundType> {
    return http.postJson('/bill/refund/create', {
      merchant_bill_id,
      refund_amount,
      chain_id,
      contract,
      symbol,
      refund_fee_amount,
      memo,
      to_address,
      invoice,
      fee_style,
      network,
      pass: String(CryptoJS.MD5(pass.trim())),
      code,
      type,
    });
  }
}

export type SystemToken_ = {
  list: SystemToken[];
};

export type RefundBillSymbolsType = {
  [key: string]: {
    chain_id: string;
    contract: string;
    symbol: string;
    virtual_token_id: string;
    [key: string]: any;
  };
};

export type createRefundType = {
  refund_apply_id: string;
  transaction_record_id: string;
  [key: string]: any;
};

export type OrderRefundRemainType = {
  amount: string;
  chain_id: string;
  contract: string;
  symbol: string;
  networks: string[];
  [key: string]: any;
};

export type ChainBalanceInfo = {
  balance: string;
  chain_id: string;
  contract: string;
  symbol: string;
  [key: string]: any;
};

export type NetworkType = {
  chain_id: string;
  contract: string;
  symbol: string;
  network: string;
  status: number;
  token_id: string;
  network_detail: string;
  is_support_memo: boolean;
  precision: number;
  chain_logo: string;
  name: string;
  [key: string]: any;
};

export type netWorkFeeType = {
  chain_id: string;
  contract: string;
  symbol: string;
  value: string;
  virtual_value: string;
  token_id: string;
  [key: string]: any;
};
