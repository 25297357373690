import { useState, useCallback } from 'react';
// @mui
import { Box, Stack } from '@mui/material';
// components
import { MultiFilePreview, UploadBox } from '@/components/upload';
import { fileData } from '../file-thumbnail';
import { enqueueSnackbar } from 'notistack';
import { useLocales } from '@/locales';



type Props = {
  attachments: string[]; //Default Show image back
  onClose: (arr: any, file: any) => void; //
  length?: number
};

export default function ImageAdd({ attachments, onClose, length }: Props) {
  const [files, setFiles] = useState<(File | string)[]>(attachments);
  const { translate } = useLocales();
  const handleDrop = useCallback(
    (acceptedFiles: File[]) => {
      // eslint-disable-next-line
      const newFiles = acceptedFiles.map((file: File, index: number) => {
        return Object.assign(file, { preview: URL.createObjectURL(file), });
      });
      //Determine the file format
      const index = newFiles[0].name.lastIndexOf('.'); //get the last . position of the
      const ext = newFiles[0].name.slice(index + 1); //get suffix
      let pattern = /^(csv|docx|txt|xlsx|pic|jpg|png|jpeg|doc|pdf|numbers)$/;
      if (length) {
        pattern = /^(jpg|png|jpeg|pdf|gif)$/;
      }
      if (!pattern.test(ext)) {
        enqueueSnackbar(translate('suggest.up_sup'), {
          variant: 'error',
        });
        return;
      }
      //Individual limitations 3M 1024kb * 3 = 3072kb * 1024b = 3145728b
      let filesMax = 3145728;
      if (length) {
        //Individual limitations 3.1M 1024kb * 3.1 = 3174.4kb * 1024b = 3250585b
        filesMax = 3250585;
      }
      if (newFiles[0].size > filesMax) {
        enqueueSnackbar(translate('suggest.up_3m'), {
          variant: 'error',
        });
        return;
      }
      const arr = [...files, ...acceptedFiles];
      //limitations 15m 1572864b
      let fielSize = 0;
      const maxSize = 15728640;
      let max = false;
      // eslint-disable-next-line
      arr?.map((item, index) => {
        fielSize += fileData(item).size || 0;
        if (fielSize > maxSize) {
          max = true;
          enqueueSnackbar(translate('suggest.up_15m'), {
            variant: 'error',
          });
        }
      });

      if (max) return;
      //add coin Special Handling Delete then add
      // handleRemoveFile()
      if (length && arr.length > length) {
        // enqueueSnackbar(translate('suggest.up_5files', { number: 1 }), {
        //   variant: 'error',
        // });
        // handleRemoveFile()
        onClose(1, newFiles,);
        setFiles([...newFiles]);
        return;
      }
      //Limit uploads to 5 files
      if (arr.length > 5) {
        enqueueSnackbar(translate('suggest.up_5files', { number: 5 }), {
          variant: 'error',
        });
        return;
      }
      let newArr: (
        | {
          name: string | undefined;
          size?: undefined;
          path?: undefined;
        }
        | {
          name: string;
          size: number;
          path: string | undefined;
        }
      )[] = [];
      let newObj = {
        name: '',
        size: 0,
        path: '',
      };

      arr &&
        // eslint-disable-next-line
        arr.map((item) => {
          newObj.name = fileData(item).name || '';
          newObj.size = fileData(item).size || 0;
          newObj.path = fileData(item).path || '';
          newArr.push(newObj);
        });
      onClose(newArr.length, arr,);
      setFiles([...arr]);
    },
    // eslint-disable-next-line
    [files]
  );

  const handleRemoveFile = (inputFile: File | string) => {
    const filtered = files.filter((file) => file !== inputFile);
    onClose(files.length - 1, filtered);
    setFiles([...filtered]);
  };

  return (
    <Stack direction="row" flexWrap="wrap" sx={{ position: 'relative' }}>
      <MultiFilePreview
        thumbnail
        files={files}
        onRemove={(file) => handleRemoveFile(file)}
        sx={{
          width: 72,
          height: 72,
        }}
        length={length}
      />
      {
        length && <Box sx={{ position: 'absolute', }}>
          <UploadBox onDrop={handleDrop} sx={{ position: 'absolute', left: 0, top: 0, }} />
        </Box> || files.length < 5 && <UploadBox onDrop={handleDrop} />
      }
    </Stack>
  );
}
