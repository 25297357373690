import { Box, Grid, Tabs, Tab, Container, useTheme } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useLocation, useNavigate } from 'react-router';
import { useLocales } from '@/locales';
import Security from './security';

import MerchantList from './settings-merchant-list/Index';
import LoginList from './settings-login-history/Index';

import { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { useSettingsContext } from '@/components/settings';
import useResponsive from '@/hooks/useResponsive';

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function SettingPageSection() {
  const isMobile = !useResponsive('up', 'sm');
  const { themeStretch } = useSettingsContext();
  const navigate = useNavigate();
  const location = useLocation();
  const { translate } = useLocales();
  const theme = useTheme();

  const [tabValue, setTabValue] = useState(0);

  const jump = (t: number) => {
    setTabValue(t);

    if (t === 0) {
      navigate('/account/settingPageSection/settings');
      // navigate(location.pathname.replace(tab!, 'settings'), { replace: true });
    } else if (t === 1) {
      navigate('/account/settingPageSection/merchantlist');
      // navigate(location.pathname.replace(tab!, 'merchantlist'), { replace: true });
    } else if (t === 2) {
      navigate('/account/settingPageSection/login-history');
      // navigate(location.pathname.replace(tab!, 'login-history'), { replace: true });
    } else if (t === 3) {
      navigate('/account/settingPageSection/referral');
      // navigate(location.pathname.replace(tab!, 'referral'), { replace: true });
    }
  };

  useEffect(() => {
    switch (location.pathname.split('/')[3]) {
      case 'settings':
        setTabValue(0);
        break;
      case 'merchantlist':
        setTabValue(1);
        break;
      case 'login-history':
        setTabValue(2);
        break;
      case 'referral':
        setTabValue(3);
        break;
      default:
        setTabValue(0);
        break;
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Helmet>
        <title>{translate('seo_title.helmet_account')}</title>
      </Helmet>
      <Container maxWidth={themeStretch ? false : 'xl'} sx={{ height: '100%' }}>
        <Grid container spacing={2} sx={{ padding: !isMobile ? '0 40px 24px 24px' : '0px' }}>
          {/* title */}
          <Grid item container xs={12} md={12} mt={3}>
            <Grid item container spacing={3}>
              {/* 标题 */}
              <Grid item md={'auto'}>
                <span className="w-100 fs-title-page mb2 color-text">
                  {translate('merchantSettings.Account_Settings_title')}
                </span>
              </Grid>
            </Grid>
          </Grid>
          {/*  list  */}
          <Grid item xs={12} md={12} mt={1}>
            {/* tabs */}
            <Box>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  marginBottom: '24px',
                }}
              >
                <Tabs
                  value={tabValue}
                  onChange={(e, t) => jump(t)}
                  sx={{
                    '.Mui-selected': {
                      color: theme.palette.primary.main,
                    },
                    '.MuiTab-textColorInherit': {
                      ':hover': {
                        color: theme.palette.primary.main,
                      },
                    },
                    // '.MuiTabs-indicator': {
                    //   backgroundColor: '#1F2025',
                    // },
                    '.MuiTabScrollButton-root': {
                      display: 'none',
                    },
                  }}
                >
                  <Tab label={translate('merchantSettings.security_Security_Settings')} />
                  <Tab label={translate('merchantSettings.settings_account_tab_merchant')} />
                  <Tab label={translate('merchantSettings.settings_account_tab_login')} />
                  {/* <Tab label={translate('merchantSettings.settings_account_tab_referral')} /> */}
                </Tabs>
              </Box>
            </Box>

            {/* tabcontainer */}
            <TabPanel value={tabValue} index={0}>
              <Security />
            </TabPanel>
            <TabPanel value={tabValue} index={1}>
              <MerchantList />
            </TabPanel>
            <TabPanel value={tabValue} index={2}>
              <LoginList />
            </TabPanel>
            {/* <TabPanel value={tabValue} index={3}>
              <Referral />
            </TabPanel> */}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}

export default observer(SettingPageSection);
