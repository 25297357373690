import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Box, Tooltip, Link, ListItemText, Badge } from '@mui/material';
// locales
import { useLocales } from '../../../locales';
// auth
import RoleBasedGuard from '../../../auth/RoleBasedGuard';
//
import { NavItemProps } from '../types';
import { StyledItem, StyledIcon, StyledDotIcon } from './styles';
import { ICON, NAV } from '@/config';
import Iconify from '@/components/iconify';

export default function NavItem({
  item,
  depth,
  open,
  active,
  isExternalLink,
  isFold,
  ...other
}: NavItemProps) {
  const { translate } = useLocales();

  const { title, path, icon, info, children, disabled, caption, roles } = item;

  const subItem = depth !== 1;
  const renderContent = (
    <StyledItem
      depth={depth}
      active={active}
      disabled={disabled}
      caption={!!caption}
      sx={{
        flexDirection: isFold ? 'column' : 'row',
        paddingTop: isFold ? '16px' : '8px',
        paddingRight: children && isFold ? '0' : '16px',
        paddingLeft: children && isFold ? '24px' : '16px',
        height: isFold ? '56px' : '48px',
        width: isFold
          ? `calc(${NAV.W_DASHBOARD_MINI}px - 16px) !important`
          : `calc(${NAV.W_DASHBOARD}px - 32px) !important`,
        transition:
          'width 0.3s cubic-bezier(.21,.39,.63,.84),height 0.3s cubic-bezier(.21,.39,.63,.84)',
      }}
      {...other}
    >
      {icon && (
        <Box
          sx={{
            display: children && isFold && 'flex',
            alignItems: 'center',
          }}
        >
          <StyledIcon
            sx={{
              mr: isFold ? 0 : 1,
              width: ICON.NAV_ITEM,
              height: ICON.NAV_ITEM,
            }}
          >
            {icon}
          </StyledIcon>
          {children && isFold && (
            <Iconify width={16} icon={'eva:arrow-ios-forward-fill'} sx={{ ml: 1, flexShrink: 0 }} />
          )}
        </Box>
      )}

      {subItem && (
        <StyledIcon>
          <StyledDotIcon active={active && subItem} />
        </StyledIcon>
      )}
      {!isFold && (
        <ListItemText
          primary={translate(title)}
          secondary={
            caption && (
              <Tooltip title={translate(caption)} placement="top-start">
                <span>{translate(caption)}</span>
              </Tooltip>
            )
          }
          primaryTypographyProps={{
            noWrap: true,
            component: 'span',
            variant: active ? 'subtitle2' : 'body2',
            // variant: 'subtitle2',
          }}
          secondaryTypographyProps={{
            noWrap: true,
            variant: 'caption',
          }}
          sx={{
            fontWeight: '500 !important',
            width: isFold ? '165%' : 'auto',
            textAlign: isFold ? 'center' : 'left',
          }}
        />
      )}

      {info && (
        <Box component="span" sx={{ lineHeight: 0 }}>
          {info}
        </Box>
      )}

      {!isFold && !!children && (
        <Iconify
          width={16}
          icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
          sx={{ ml: 1, flexShrink: 0 }}
        />
      )}
    </StyledItem>
  );

  const renderItem = () => {
    // ExternalLink
    if (isExternalLink)
      return (
        <Link href={path} target="_blank" rel="noopener" underline="none">
          {renderContent}
        </Link>
      );

    // Has child
    if (children) {
      return renderContent;
    }

    // Default
    return (
      <Link component={RouterLink} to={path} underline="none">
        {renderContent}
      </Link>

      // <div onClick={() => setAct(!act)}>{renderContent}</div>
    );
  };

  return <RoleBasedGuard roles={roles}> {renderItem()} </RoleBasedGuard>;
}
