import WalletApi from './WalletApi';
import SuggestApi from './suggestApi';
import ReferralApi from './ReferralApi';

import AccountApi_ from './accountApi_';
import PaymentApi_ from './payment-api_';
import SecurityApi_ from './security_';
import UserApi_ from './user-api_';
import WalletApi_ from './WalletApi_';
import InvoiceApi from './invoiceApi_';
import WebhookApi from './webhook-api_';
import OrderApi_ from './orderApi_';
import DashboardApi_ from './dashboardApi_';
import TokenApi_ from './token_';
import userListApi_ from './userListApi';
import ApprovalApi from './approvalApi_';

export { default as http } from './http';

export const walletApi = new WalletApi();

export const suggestApi = new SuggestApi();
export const referralApi = new ReferralApi();

export const accountApi_ = new AccountApi_();
export const paymentApi_ = new PaymentApi_();
export const securityApi_ = new SecurityApi_();
export const userApi_ = new UserApi_();
export const walletApi_ = new WalletApi_();
export const invoiceApi = new InvoiceApi();
export const webhookApi = new WebhookApi();
export const orderApi_ = new OrderApi_();
export const tokenApi_ = new TokenApi_();
export const dashboardApi_ = new DashboardApi_();
export const userListApi = new userListApi_();
export const approvalApi = new ApprovalApi();
