import { walletApi_ } from '@/api';
import {
  coinSort,
  numDiv,
  numSubtr,
  numTake,
  removeInvalidZero,
  toLocalString,
  tryRun,
} from '@/utils/until';
import Decimal from 'decimal.js';
import { makeObservable, observable } from 'mobx';
import { accountStore } from '.';
import { integerTokenArr } from '@/config';
import SnowFlakeId from 'snowflake-id';
import { NetworkType, Token } from '@/api/WalletApi_';

export type AmountNum = string | number | Decimal;

type GoSendOrder = (orderId: string, is_swap?: boolean) => void;

export enum ChainStatus {
  Normal = 0,
  Maintain = 1,
  Offline = 2,
}

export enum CoinStatus {
  Normal = 0,
  Maintain = 1,
  PrepareOffline = 2,
  Offline = 3,
}

export enum autoCoinStatus {
  Normal = 'Normal',
  Maintain = 'Maintain',
  PrepareOffline = 'ComingOffline',
  Offline = 'Offline',
}

class WalletStroe {
  inited = false;

  tokens: Token[] = [];

  chains: NetworkType[] = [];

  tokenDict: Record<string, Token> = {};

  totalBalance = '0';

  isOpenTransaction = false;

  WithdrawBtnLoading = false;

  snowflake = new SnowFlakeId({
    mid: 1,
    offset: (2021 - 1970) * 31536000 * 1000,
  });

  /**Because send is also a modal, you need to keep the navigate of the blance route */
  goSendOrder: GoSendOrder = null as any;

  constructor() {
    makeObservable(this, {
      inited: observable,
      tokens: observable,
      chains: observable,
      totalBalance: observable,
      isOpenTransaction: observable,
      WithdrawBtnLoading: observable,
      snowflake: observable,
    });
  }

  /**Return token object according to token_id*/
  getToken(coin_id: number | string) {
    return this.tokenDict[coin_id];
  }

  async initSnowflake(merchant_id: number) {
    this.snowflake = new SnowFlakeId({
      mid: merchant_id,
      offset: (2021 - 1970) * 31536000 * 1000,
    });
  }

  async setWithdrawBtnLoading(par: boolean) {
    this.WithdrawBtnLoading = par;
  }

  async setIsOpenTransaction(par: boolean) {
    this.isOpenTransaction = par;
  }

  async asyncWalletInfo() {
    this.tokens = [];
    // token.tokens
    //   .map((item: any) => ({
    //     ...item,
    //     balance: 0,
    //     value: '0',
    //   }))
    //   .filter((item) => item.name !== 'Arbitrum');
    this.inited = true;
    const { tokens, chains } = await walletApi_.getTokenList();
    // const { lists } = await walletApi_.getNetworkList();
    const arr: NetworkType[] = [];
    if (chains) {
      for (let key in chains) {
        arr.push(chains[key]);
      }
    }

    this.chains = arr;
    // The filter here is to filter out the eth token with only one chain
    this.tokens = tokens
      // eslint-disable-next-line
      .map((item) => {
        const change24 = numTake(
          numDiv(numSubtr(item?.price || '0', item.price_at_00h || '0'), item.price_at_00h || '0'),
          100,
        );
        return { ...item, balance: '0', value: '0', change24, logo: item.logo_url, market_cap: 0 };
      })
      .filter((item) => item.symbol !== 'SATS');

    await this.syncBalance();
  }

  async syncBalance() {
    if (!accountStore.isLogin() || !accountStore.isVerify()) return Promise.resolve(false);
    const { list, free_total } = (await walletApi_.getTokenBlance()) || {
      list: [],
      free_total: '0',
    };
    this.totalBalance = free_total;
    list?.forEach((item) => {
      const index = this.tokens.findIndex((j) => j.coin_id === item.coin_id) || 0;
      if (index < 0) return;
      this.tokens[index].balance =
        Number(this.handleAmount(item?.free ?? '0')) === 0 ? '0' : item?.free ?? '0';
      this.tokens[index].price = item?.price || '0';
      this.tokens[index].value =
        Number(this.handleValue(numTake(item.price || '0', item.free))) === 0
          ? '0'
          : numTake(item.price || '0', item.free) ?? '0';
    });
    this.tokens.forEach((item) => {
      this.tokenDict[item.coin_id] = item;
    });

    this.tokens = this.tokens.map((item) => {
      const change24 = numTake(
        numDiv(numSubtr(item?.price || '0', item.price_at_00h || '0'), item.price_at_00h || '0'),
        100,
      );
      return { ...item, change24 };
    });
    this.tokens = coinSort(this.tokens);
  }

  //(<= 8)Output precision control, if it is greater than 8 digits, 8 digits will be reserved, if it is less than 8 digits, the corresponding digits will be displayed, and the default is 8 digits
  formatePrecision(n: AmountNum, precision?: number, tokenSymbol?: string) {
    if (!isNaN(Number(n))) {
      let prec = precision && precision <= 8 ? precision : 8;
      if (tokenSymbol && integerTokenArr.includes(tokenSymbol)) {
        prec = 0;
      }
      return new Decimal(Number(n)).toFixed(prec, Decimal.ROUND_DOWN);
    }
    const num = new Decimal(n).toNumber();
    return num;
  }

  /**
   * (>= 8) formatted amount, number of tokens
   * Keep 8 digits after the decimal point, a total of 10 digits
   */
  formateAmount({
    num = 0,
    precision = 8,
    tokenSymbol = '',
    type = '0',
  }: {
    num: AmountNum;
    precision?: number;
    tokenSymbol?: string;
    type?: string;
  }) {
    return (
      tryRun(() => {
        const dec = new Decimal(num);
        const { length } = dec.abs().floor().toString();
        const pres = precision;
        precision = precision > 8 ? 9 : precision + 1;
        if (length >= precision) {
          let value = '';
          // Here it is necessary to judge whether it is greater than 1, and if it is greater than 1, directly retain 8 decimal places
          if (dec.greaterThan(1)) {
            value = dec.toFixed(8, Decimal.ROUND_DOWN);
          } else {
            value = dec.toFixed(precision - length, Decimal.ROUND_DOWN);
          }
          value = this.formatePrecision(value, pres).toString();
          // If you round up here, the decimal place will be removed
          // If it is a sats token, round up
          if (integerTokenArr.includes(tokenSymbol)) {
            value = new Decimal(value).toFixed(0, Decimal.ROUND_CEIL);
          }
          // Do thousandths
          let result = '';
          if (typeof value === 'string') {
            if (value.includes('.')) {
              result = value.replace(/\d(?=(\d{3})+\.)/g, '$&,');
            } else {
              result = toLocalString(value);
            }
          } else {
            result = value;
          }

          if (Number(num) === 0) {
            return result;
          } else {
            return dec.greaterThan(new Decimal(0))
              ? removeInvalidZero(result)
              : type === '0'
              ? '-' + removeInvalidZero(result)
              : removeInvalidZero(result);
          }
        } else {
          let value = '';
          // Here it is necessary to judge whether it is greater than 1, and if it is greater than 1, directly retain 8 decimal places
          if (dec.greaterThan(1)) {
            value = dec.toFixed(8, Decimal.ROUND_DOWN);
          } else {
            value = dec.toFixed(precision - length, Decimal.ROUND_DOWN);
          }

          value = this.formatePrecision(value, pres).toString();
          // If it is a sats token, round up
          if (integerTokenArr.includes(tokenSymbol)) {
            value = new Decimal(value).toFixed(0, Decimal.ROUND_CEIL);
          }

          // Do thousandths
          let result = '';
          if (typeof value === 'string') {
            if (value.includes('.')) {
              result = value.replace(/\d(?=(\d{3})+\.)/g, '$&,');
            } else {
              result = toLocalString(value);
            }
          } else {
            result = value;
          }

          return removeInvalidZero(result);
        }
      }) ?? '0.00'
    );
  }

  /**
   * Format the local fiat currency, with two decimal places by default
   */
  formateFaitAmount(num: AmountNum = 0) {
    return (
      tryRun(() => {
        const dec = new Decimal(num);
        const fnum = dec.toFixed(2, Decimal.ROUND_DOWN);
        // If it is greater than 1000, perform thousandths processing
        if (Number(num) >= 1000) {
          let result = this.toLocalString(fnum);
          if (!result.includes('.')) {
            result = result + '.00';
          }
          return result;
        } else {
          return fnum;
        }
      }) ?? '0.00'
    );
  }

  /**
   *string type fiat currency quantity add thousands separator
   *number type fiat currency quantity add thousands separator use Number.prototype.toLocalString
   * @param {string} num
   * @param {string} delimiter
   * @param {number} size
   * @return {*}  {string}
   * @memberof WalletStore
   */
  toLocalString(num: string | number) {
    return Number(num).toLocaleString('en-US');
  }

  handleValue(val: AmountNum) {
    const num = this.formateFaitAmount(val).replace(/,/gi, '');
    return num;
  }

  handleAmount(val: AmountNum) {
    const num = this.formateAmount({ num: val }).replace(/,/gi, '');
    return num;
  }
}
export default WalletStroe;
