import { useEffect, useMemo, useState } from 'react';
import { TextFieldElement } from 'react-hook-form-mui';
import GetEmailCode from '@/components/get-code/email';
import GetPhoneCode from '@/components/get-code/phone';
import { Typography } from '@mui/material';
import { useLocales } from '@/locales';
import { useAuthContext } from '@/auth/useAuthContext';
import { PhoneSendTypeEnum } from '@/api/security_';

export enum VerifyPart {
  eMail = 'eMail',
  phone = 'phone',
  authenticator = 'authenticator',
  password = 'password',
}

// deincrease 10 seconds for Alvin's require.
const isValidForNow = (seconds: number) => seconds * 1000 + (10 * 60 - 10) * 1000 > Date.now();

export default function SecurityPart({
  currentVerifyPart,
  type,
  errorMessage,
}: {
  errorMessage?: {
    code: number;
    msg: string;
  };
  currentVerifyPart: VerifyPart;
  type?: 'new' | 'old';
}) {
  const { user } = useAuthContext();
  const { translate: t } = useLocales();
  const [message, setMessage] = useState({ code: 0, msg: '' });
  const [canSend, setCanSend] = useState(true);
  useEffect(() => {
    setMessage({
      code: errorMessage?.code || 0,
      msg: errorMessage?.msg || '',
    });
  }, [errorMessage]);
  const {
    exists_google_2fa = false,
    phone = '',
    phone_area = '',
    email = '',
    verify_email_time = 0,
    verify_phone_time = 0,
    security_email = 0,
    security_phone = 0,
  }: any = user || {};

  const processedEmail = useMemo(() => {
    const [name, suffix] = (email ?? '').split('@');
    return `${name.slice(0, 3)}****@${suffix}`;
  }, [email]);

  const processedPhone = useMemo(
    () => `${phone_area}-${phone.slice(0, 4)}****${phone.slice(7)}`,
    [phone, phone_area],
  );

  //priority 2fa > email > sms

  const showEmail = Boolean(security_email);
  // (currentVerifyPart === VerifyPart.eMail && !security_phone) ||
  // !exists_google_2fa ||
  // !security_phone;
  // give it the last chance when no other method to verify user
  const showGoogle = exists_google_2fa && currentVerifyPart !== VerifyPart.authenticator;

  const showPhone = Boolean(security_phone) && currentVerifyPart !== VerifyPart.phone;

  const colorMapping = {
    totp_code: {
      code: 1026,
    },
    sms_code: {
      code: 1025,
    },
    mail_code: {
      code: 1024,
    },
  };
  const codeMsg = message.msg;

  const codeMapping = {
    1024: codeMsg,
    1025: codeMsg,
    1026: codeMsg,
  };
  const options = [
    showEmail &&
      !isValidForNow(verify_email_time) && {
        key: 'mail_code',
        name: 'mail_code',
        autoComplete: 'off',
        label: t('common.Verification_email_label'),
        fullWidth: true,
        InputProps: {
          endAdornment: (
            <GetEmailCode
              email={email}
              type={type}
              handleSend={(v: boolean) => {
                setCanSend(v);
              }}
            />
          ),
        },
        // security_email_will_helperText
        helperText:
          message?.code === 1024
            ? codeMapping[message?.code]
            : `${t(
                canSend
                  ? 'merchantSettings.security_email_will_helperText'
                  : 'merchantSettings.security_email_helperText',
              )} ${processedEmail}`,
        sort: 2,
        inputProps: {
          maxLength: 6,
        },
        validation: {
          required: true,
        },
      },
    showPhone &&
      !isValidForNow(verify_phone_time) && {
        key: 'sms_code',
        name: 'sms_code',
        fullWidth: true,
        autoComplete: 'off',
        label: t('common.Verification_phone_label'),
        helperText:
          message?.code === 1025
            ? codeMapping[message?.code]
            : `${t('merchantSettings.security_phone_helperText')} ${processedPhone}`,
        InputProps: {
          endAdornment: (
            <GetPhoneCode
              phone={`${phone_area?.slice(1)}-${phone}`}
              type="old"
              send_type={PhoneSendTypeEnum.Normal}
            />
          ),
        },
        validation: {
          required: true,
          pattern: {
            value: /^[\d]+/,
            message: t('merchantSettings.security_phone_valid_desc1'),
          },
        },
        sort: 3,
        inputProps: {
          maxLength: 6,
        },
      },
    showGoogle && {
      key: 'totp_code',
      name: 'totp_code',
      fullWidth: true,
      autoComplete: 'off',
      label: t('common.Verification_google_label'),
      helperText:
        message?.code === 1026
          ? codeMapping[message?.code]
          : `${t('merchantSettings.security_google_helperText')}`,
      sort: 1,
      inputProps: {
        maxLength: 6,
      },
      validation: {
        required: true,
      },
    },
  ]
    .filter(Boolean)
    .sort((a, b) => a.sort - b.sort)
    .slice(0, 2)
    .sort((a, b) => b.sort - a.sort)
    .map((props, idx) => (
      <TextFieldElement
        {...props}
        key={props.key}
        onChange={() => {
          setMessage({
            code: 0,
            msg: '',
          });
        }}
        sx={{
          mt: idx === 0 ? 1 : 2,
          '.MuiFormLabel-root': {
            color:
              colorMapping[props.key] && message?.code === colorMapping[props.key].code
                ? '#FF5630'
                : '#73756D',
          },
          '.MuiFormHelperText-root': {
            color:
              colorMapping[props.key] && message?.code === colorMapping[props.key].code
                ? '#FF5630'
                : '#73756D',
          },
          '.MuiOutlinedInput-notchedOutline': {
            border: `1px solid ${
              colorMapping[props.key] && message?.code === colorMapping[props.key].code
                ? '#FF5630'
                : '#E6E8E0'
            }`,
          },
        }}
      />
    ));

  return options.length > 0 ? (
    <>
      <Typography variant="subtitle2" mb={0}>
        {t('common.Security_Verification_title')}
      </Typography>
      {options}
    </>
  ) : null;
}
