// eslint-disable-next-line
import { format, getTime, formatDistanceToNow } from 'date-fns';
// eslint-disable-next-line
import { enUS } from 'date-fns/locale';

type InputValue = Date | string | number | null;

/** 将时间转换为 UTC 格式 */
export function formatDateTime(dateTime: number | Date) {
  if (!dateTime) return '--';
  const formattedDate = format(
    new Date(
      new Date(dateTime)
        .toLocaleString('en-US', {
          hourCycle: 'h24',
          timeZone: 'UTC',
        })
        .split(', ')[0],
    ),
    'dd MMM. yyyy',
    { locale: enUS },
  );
  // const formattedTime = format(dateTime, 'HH:mm');
  const formattedTime = new Date(dateTime).toLocaleString('en-US', {
    hourCycle: 'h24',
    timeZone: 'UTC',
    timeStyle: 'short',
  });

  return `${formattedDate} ${formattedTime}(UTC)`;
}

export function fDate(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM. yyyy';

  return date ? format(new Date(date), fm) : '';
}

export function fDateTime(date: InputValue, newFormat?: string) {
  const fm = newFormat || 'dd MMM. yyyy p';

  return date ? format(new Date(date), fm) : '';
}

export function fTimestamp(date: InputValue) {
  return date ? getTime(new Date(date)) : '';
}

export function fToNow(date: InputValue) {
  return date
    ? formatDistanceToNow(new Date(date), {
        addSuffix: true,
      })
    : '';
}

export function formatDate(date: InputValue, type: string) {
  return date ? format(new Date(date), type) : '';
}

export function formatChartsDate(date: number, type: string) {
  return date ? format(new Date(date * 1000), type) : '';
}

export function handlerDateDurationCurrent(time: number) {
  let d1 = new Date(time);
  let d2 = new Date();

  let cha = Math.abs(d2.getTime() - d1.getTime());
  let days = parseInt((cha / (24 * 60 * 60 * 1000)).toString());
  let hours = parseInt(((cha % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000)).toString());
  let mins = parseInt(((cha % (60 * 60 * 1000)) / (60 * 1000)).toString());
  let Seconds = parseInt(((cha % (60 * 1000)) / 1000).toString());
  if (days) {
    return {
      days,
      hours,
      mins,
    };
  } else if (hours) {
    return {
      hours,
      mins,
    };
  } else if (mins) {
    return {
      mins,
      Seconds,
    };
  } else {
    return {
      Seconds,
    };
  }
}

export const UTCData = [
  { value: '0', label: 'Local Time Zone' },
  { value: 'Pacific/Midway', label: 'Pacific/Midway (UTC-11)' },
  { value: 'Pacific/Honolulu', label: 'Pacific/Honolulu (UTC-10)' },
  { value: 'America/Anchorage', label: 'America/Anchorage (UTC-9)' },
  { value: 'America/Los_Angeles', label: 'America/Los Angeles (UTC-8)' },
  { value: 'America/Denver', label: 'America/Denver (UTC-7)' },
  { value: 'America/Chicago', label: 'America/Chicago (UTC-6)' },
  { value: 'America/Mexico_City', label: 'America/Mexico City (UTC-6)' },
  { value: 'America/New_York', label: 'America/New York (UTC-5)' },

  { value: 'America/Bogota', label: 'America/Bogota (UTC-5)' },
  { value: 'America/Lima', label: 'America/Lima (UTC-5)' },

  { value: 'America/Caracas', label: 'America/Caracas (UTC-4:30)' },
  { value: 'America/Halifax', label: 'America/Halifax (UTC-4)' },
  { value: 'America/Santiago', label: 'America/Santiago (UTC-4)' },
  { value: 'America/Montevideo', label: 'America/Montevideo (UTC-3)' },
  { value: 'America/Sao_Paulo', label: 'America/Sao Paulo (UTC-3)' },
  {
    value: 'America/Argentina/Buenos_Aires',
    label: 'America/Argentina (UTC-3)',
  },
  { value: 'Atlantic/South_Georgia', label: 'Atlantic/South Georgia (UTC-2)' },
  { value: 'Atlantic/Azores', label: 'Atlantic/Azores (UTC-1)' },
  { value: 'Europe/London', label: 'Europe/London (UTC+0)' },
  { value: 'Europe/Berlin', label: 'Europe/Berlin (UTC+1)' },
  { value: 'Europe/Paris', label: 'Europe/Paris (UTC+1)' },
  { value: 'Europe/Rome', label: 'Europe/Rome (UTC+1)' },
  { value: 'Europe/Madrid', label: 'Europe/Madrid (UTC+1)' },
  { value: 'Europe/Amsterdam', label: 'Europe/Amsterdam (UTC+1)' },
  { value: 'Europe/Zurich', label: 'Europe/Zurich (UTC+1)' },
  { value: 'Europe/Prague', label: 'Europe/Prague (UTC+1)' },
  { value: 'Europe/Budapest', label: 'Europe/Budapest (UTC+1)' },
  { value: 'Africa/Johannesburg', label: 'Africa/Johannesburg (UTC+2)' },

  { value: 'Africa/Cairo', label: 'Africa/Cairo (UTC+2)' },
  { value: 'Africa/Nairobi', label: 'Africa/Nairobi (UTC+3)' },

  { value: 'Europe/Moscow', label: 'Europe/Moscow (UTC+3)' },
  { value: 'Europe/Istanbul', label: 'Europe/Istanbul (UTC+3)' },
  { value: 'Asia/Riyadh', label: 'Asia/Riyadh (UTC+3)' },
  { value: 'Asia/Baghdad', label: 'Asia/Baghdad (UTC+3)' },
  { value: 'Asia/Kuwait', label: 'Asia/Kuwait (UTC+3)' },
  { value: 'Asia/Qatar', label: 'Asia/Qatar (UTC+3)' },
  { value: 'Asia/Tehran', label: 'Asia/Tehran (UTC+3:30)' },
  { value: 'Asia/Dubai', label: 'Asia/Dubai (UTC+4)' },
  { value: 'Asia/Kabul', label: 'Asia/Kabul (UTC+4:30)' },
  { value: 'Asia/Karachi', label: 'Asia/Karachi (UTC+5)' },
  { value: 'Asia/Tashkent', label: 'Asia/Tashkent (UTC+5)' },
  { value: 'Asia/Kolkata', label: 'Asia/Kolkata (UTC+5:30)' },
  { value: 'Asia/Colombo', label: 'Asia/Colombo (UTC+5:30)' },
  { value: 'Asia/Kathmandu', label: 'Asia/Kathmandu (UTC+5:45)' },
  { value: 'Asia/Dhaka', label: 'Asia/Dhaka (UTC+6)' },
  { value: 'Asia/Almaty', label: 'Asia/Almaty (UTC+6)' },
  { value: 'Asia/Yangon', label: 'Asia/Yangon (UTC+6:30)' },
  { value: 'Asia/Jakarta', label: 'Asia/Jakarta (UTC+7)' },
  { value: 'Asia/Bangkok', label: 'Asia/Bangkok (UTC+7)' },
  { value: 'Asia/Novosibirsk', label: 'Asia/Novosibirsk (UTC+7)' },
  { value: 'Asia/Shanghai', label: 'Asia/Shanghai (UTC+8)' },
  { value: 'Asia/Hong_Kong', label: 'Asia/Hong Kong (UTC+8)' },
  { value: 'Asia/Singapore', label: 'Asia/Singapore (UTC+8)' },
  { value: 'Asia/Manila', label: 'Asia/Manila (UTC+8)' },
  { value: 'Asia/Kuala_Lumpur', label: 'Asia/Kuala Lumpur (UTC+8)' },
  { value: 'Asia/Taipei', label: 'Asia/Taipei (UTC+8)' },
  { value: 'Asia/Ulaanbaatar', label: 'Asia/Ulaanbaatar (UTC+8)' },
  { value: 'Asia/Tokyo', label: 'Asia/Tokyo (UTC+9)' },
  { value: 'Asia/Seoul', label: 'Asia/Seoul (UTC+9)' },
  { value: 'Australia/Adelaide', label: 'Australia/Adelaide (UTC+9:30)' },
  { value: 'Australia/Sydney', label: 'Australia/Sydney (UTC+10)' },
  { value: 'Asia/Vladivostok', label: 'Asia/Vladivostok (UTC+10)' },
  { value: 'Asia/Magadan', label: 'Asia/Magadan (UTC+11)' },
  { value: 'Asia/Kamchatka', label: 'Asia/Kamchatka (UTC+12)' },
  { value: 'Pacific/Auckland', label: 'Pacific/Auckland (UTC+12)' },
  { value: 'Pacific/Fiji', label: 'Pacific/Fiji (UTC+12)' },
  { value: 'Pacific/Chatham', label: 'Pacific/Chatham (UTC+12:45)' },
  { value: 'Pacific/Tongatapu', label: 'Pacific/Tongatapu (UTC+13)' },
].map((item) => {
  if (item.value === '0') {
    return item;
  }

  return {
    ...item,
    label: `${item.value.replace(/_/g, ' ')} (${getUTCTimezoneOffset(item.value)})`,
  };
});

function getUTCTimezoneOffset(timezone: string) {
  // 获取指定时区当前时间的 UTC 偏移量（以分钟为单位）
  const date = new Date();
  const utcDate = new Date(date.toLocaleString('en-US', { timeZone: 'UTC' }));
  const tzDate = new Date(date.toLocaleString('en-US', { timeZone: timezone }));

  // 计算偏移量（以小时为单位）
  const offsetInHours = (tzDate.getTime() - utcDate.getTime()) / (1000 * 60 * 60);

  // 格式化为UTC偏移量格式
  const sign = offsetInHours >= 0 ? '+' : '-';
  const hours = Math.abs(offsetInHours);
  const formattedOffset = `UTC${sign}${hours}`;

  return formattedOffset;
}

/** 获取UTC-时区标号 */
export function getUTCOffset() {
  // 获取当前时间的时区偏移量，以分钟为单位
  const offset = new Date().getTimezoneOffset();
  // 将偏移量转换为小时和分钟
  const hours = Math.floor(Math.abs(offset) / 60);
  const minutes = Math.abs(offset) % 60;
  // 生成UTC格式的时区标号
  const sign = offset > 0 ? '-' : '+';
  const utcOffset = `UTC${sign}${Number(String(hours).padStart(2, '0'))}:${String(minutes).padStart(
    2,
    '0',
  )}`;
  return utcOffset;
}

// 将时间戳转换为特定时区的时间
export function convertTimestampToTimeZone(timestamp: number, timeZone: string) {
  const date = new Date(timestamp);
  const options: Intl.DateTimeFormatOptions = {
    timeZone: timeZone,
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  };
  return new Intl.DateTimeFormat([], options).format(date);
}
