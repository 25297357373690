import { Box, Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import IconLock from '@/assets/icons/img/ic_lock.png';
import { useLocales } from '@/locales';
import CommonDialog from '../components/CommonDialog';

export default function DialogConfirmPayPasswordChange({
  visible,
  onClose,
}: {
  visible: boolean;
  onClose: (confirm: boolean) => void;
}) {
  const { translate: t } = useLocales();

  return (
    <CommonDialog open={visible} onClose={() => onClose(false)}>
      <DialogTitle id="scroll-dialog-title">
        <Box className="flex flex-column items-center">
          <Box component="img" src={IconLock} sx={{ width: '96px', height: '96px' }} />
          <Typography
            variant="h5"
            component="span"
            sx={{
              color: '#1E1E1E',
              textAlign: 'center',
            }}
          >
            {t('merchantSettings.security_pay_password_dialog_title')}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography
          variant="body2"
          className="color-text2"
          sx={{
            // color: '#73756D',
            textAlign: 'center',
          }}
        >
          {t('merchantSettings.security_pay_password_dialog_desc1')}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          className="btn-height48"
          fullWidth
          onClick={() => onClose(false)}
          sx={{
            color: '#1E1E1E',
            border: '1px solid rgba(145, 158, 171, 0.32)',
            '&:hover': {
              backgroundColor: '#fff',
              border: '1px solid rgba(145, 158, 171, 0.32)',
              boxShadow: '0 8px 8px 0 rgb(158 158 158 / 16%)',
            },
          }}
        >
          {t('common.Cancel')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          className="btn-height48"
          fullWidth
          onClick={() => onClose(true)}
        >
          {t('common.Confirm')}
        </Button>
      </DialogActions>
    </CommonDialog>
  );
}
