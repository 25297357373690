import { ReactNode } from 'react';
import { useAuthContext } from './useAuthContext';

/** 权限组件
 *  @author eric
 *  @param permissionId 权限id {string}
 *  @returns children or null
 */
const PermissionGuard = (props: { permissionId: string; children: ReactNode }) => {
  const { menuIds } = useAuthContext();
  return <>{menuIds.includes(props.permissionId) ? props.children : null}</>;
};

export default PermissionGuard;
