import { accountApi_ } from '@/api';
import { inviteGetRes } from '@/api/accountApi_';
import { MyIcon } from '@/assets/icons/MyIcon';

import { useLocales } from '@/locales';
import { LoadingButton } from '@mui/lab';
import { Box, IconButton } from '@mui/material';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';

type Props = {
  handleInfo: (res: inviteGetRes) => void;
  location: any;
};

export default function EnqueueSnackbar({ handleInfo, location }: Props) {
  const { translate: t } = useLocales();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  let login = localStorage.getItem('isLogin') || '';
  let isVerify = localStorage.getItem('isVerify') || '';
  const inviteGetInfo = async () => {
    try {
      let invite = localStorage.getItem('isInvite') || '';
      if (!invite || show) return;
      let invitedEmail = localStorage.getItem('invitedEmail') || '';
      let info = localStorage.getItem('info') || '';
      //Determine whether it is the current login account
      if (!(JSON.parse(invitedEmail) === JSON.parse(info))) return;
      let res = await accountApi_.inviteGet({ invite_code: JSON.parse(invite) });
      handleInfo(res);
      if (!JSON.parse(login) || !JSON.parse(isVerify)) return;
      if (res?.status === 1) {
        enqueueSnackbar(
          <Box
            sx={{
              fontWeight: 600,
              lineHeight: '22px',
              fontSize: '14px',
              color: '#1F2025',
              // width: '420px',
            }}
          >
            {t('merchantSettings.settings_member_team_successfully', {
              merchantName: res?.title || '',
            })}
          </Box>,
          {
            variant: 'success',
            autoHideDuration: null,
            action: (
              <Box>
                <LoadingButton
                  variant="outlined"
                  sx={{
                    border: 0,
                    background: 'rgba(54, 179, 126, 0.16)',
                    color: '#1B806A',
                    padding: '6px 12px',
                    marginRight: '8px',
                    ':hover': {
                      border: 0,
                      backgroundColor: 'rgba(54, 179, 126, 0.26)',
                    },
                  }}
                  onClick={() => {
                    navigate('/account/settingPageSection/merchantlist');
                    closeSnackbar();
                    localStorage.removeItem('isInvite');
                  }}
                  loadingIndicator={<MyIcon name="loading" className="loading-icon-rotate" />}
                >
                  {t('merchantSettings.settings_member_team_succ_go')}
                </LoadingButton>
                <IconButton
                  // sx={{
                  //   color: '#1F2025',
                  //   border: '1px solid #E8ECF2',
                  //   ':hover': {
                  //     borderColor: '#1E1E1E;',
                  //     backgroundColor: 'rgba(145, 158, 171, 0.08)',
                  //   },
                  // }}
                  onClick={() => {
                    closeSnackbar();
                    localStorage.removeItem('isInvite');
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.69688 4.69668C5.02231 4.37124 5.54995 4.37124 5.87539 4.69668L15.3035 14.1248C15.6289 14.4502 15.6289 14.9778 15.3035 15.3033C14.978 15.6287 14.4504 15.6287 14.125 15.3033L4.69688 5.87519C4.37144 5.54975 4.37144 5.02211 4.69688 4.69668Z"
                      fill="#B3B9C5"
                    />
                    <path
                      fillRule="evenodd"
                      clipRule="evenodd"
                      d="M4.69668 15.3031C4.37124 14.9777 4.37124 14.45 4.69668 14.1246L14.1248 4.69652C14.4502 4.37108 14.9778 4.37108 15.3033 4.69652C15.6287 5.02196 15.6287 5.5496 15.3033 5.87503L5.87519 15.3031C5.54975 15.6286 5.02211 15.6286 4.69668 15.3031Z"
                      fill="#B3B9C5"
                    />
                  </svg>
                </IconButton>
              </Box>
            ),
          },
        );
      }
      if (res?.status === -1) {
        enqueueSnackbar(
          <Box
            sx={{
              fontWeight: 600,
              lineHeight: '22px',
              fontSize: '14px',
              color: '#1F2025',
              width: '420px',
            }}
          >
            {t('merchantSettings.settings_member_team_revoked')}
          </Box>,
          {
            variant: 'warning',
            autoHideDuration: null,
            action: null,
          },
        );
      }
    } catch {
      setShow(false);
    }
  };
  useEffect(() => {
    // do not operate unless you are logged in
    if (!login || !isVerify) return;
    if (!JSON.parse(login) || !JSON.parse(isVerify)) return;
    inviteGetInfo();
    setShow(true);
    // eslint-disable-next-line
  }, [location]);

  return null;
}
