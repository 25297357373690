import { useEffect, useState } from 'react';
import { Box, Drawer, Tooltip } from '@mui/material';
import { IconButtonAnimate } from '../../../components/animate';
import { useLocation } from 'react-router';
import { MyIcon } from '@/assets/icons/MyIcon';
import { observer } from 'mobx-react';
import { useLocales } from '@/locales';

import MerchantGuidePayment from '../MerchantGuide/index';
import { useAuthContext } from '@/auth/useAuthContext';
import styled from 'styled-components';
import { accountApi_ } from '@/api';
import { UserExtraParams } from '@/api/accountApi_';
import { useSnackbar } from 'notistack';

export default observer(function MerchantGuidePopover() {
  const [openPopover] = useState<HTMLElement | null>(null);
  const { translate: t } = useLocales();
  const [anchorFlag, setAnchorFlag] = useState(false);
  const location = useLocation();
  const { user, updUserInfoToStore } = useAuthContext();
  const { enqueueSnackbar } = useSnackbar();
  const [showIcon, setShowIcon] = useState(true);

  useEffect(() => {
    setAnchorFlag(false);
  }, [location?.pathname]);

  useEffect(() => {
    if (Number(user?.is_newbie) === 1) {
      setShowIcon(false);
    } else {
      setShowIcon(true);
    }
  }, [user?.is_newbie]);

  const changeFlag = async () => {
    setAnchorFlag(true);
    try {
      if (Number(user?.is_newbie) !== 1) {
        const params: UserExtraParams = {
          field: 'is_newbie',
          value: '1',
        };
        await accountApi_.setMerchantExtra(params);
        await updUserInfoToStore({
          isNeedExtra: true,
          refreshRouter: false,
        });
      }
    } catch (error) {
      enqueueSnackbar(t(`new_interface_err.${error.code}`), { variant: 'error' });
    }
  };

  return (
    <>
      <IconButtonAnimate
        color={openPopover ? 'primary' : 'default'}
        onClick={changeFlag}
        sx={{ width: 40, height: 40 }}
        id="beginner_guide"
      >
        <Tooltip title={t('wallet.beginner.guide')}>
          <GuideWrap>
            <MyIcon name="beginner" size={24} />
            {showIcon && <div className="will" />}
          </GuideWrap>
        </Tooltip>
      </IconButtonAnimate>

      <Drawer
        anchor={'right'}
        open={anchorFlag}
        onClose={() => {
          setAnchorFlag(false);
        }}
        sx={{
          width: 320,
          '.MuiModal-backdrop': {
            backgroundColor: 'transparent',
          },
        }}
      >
        <Box
          sx={{
            position: 'relative',
            overflow: 'hidden',
            width: '320px',
            flexShrink: 0,
          }}
        >
          <MerchantGuidePayment
            setOpenBenginer={() => {
              setAnchorFlag(false);
            }}
          />
        </Box>
      </Drawer>
    </>
  );
});

const GuideWrap = styled.div`
  .will {
    width: 6px;
    height: 6px;
    border-radius: 6px;
    background: #ff5630;
    position: absolute;
    top: 3px;
    right: 3px;
  }
`;
