import { Box, Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import IconLock from '@/assets/icons/img/ic_lock.png';
import './style.scss';
import { useLocales } from '@/locales';
import CommonDialog from '../components/CommonDialog';

export default function DialogConfirmEmailChange({
  visible,
  onClose,
}: {
  visible: boolean;
  onClose: (confirm: boolean) => void;
}) {
  const { translate: t } = useLocales();

  return (
    <CommonDialog open={visible} onClose={() => onClose(false)}>
      <DialogTitle id="scroll-dialog-title">
        <Box className="flex flex-column items-center">
          <Box component="img" src={IconLock} sx={{ width: '96px', height: '96px' }} />
          <Typography
            variant="h5"
            component="span"
            sx={{ textAlign: 'center', fontSize: '18px !important' }}
          >
            {t('merchantSettings.security_email_dialog_title')}
          </Typography>
        </Box>
      </DialogTitle>
      <DialogContent>
        <ul className="custom-list">
          <li style={{ color: '#919EAB' }}>
            <Typography variant="body2" className="color-text2" component="span">
              {t('merchantSettings.security_email_dialog_desc1')}
            </Typography>
          </li>
          <li style={{ color: '#919EAB' }}>
            <Typography variant="body2" className="color-text2" component="span">
              {t('merchantSettings.security_email_dialog_desc2')}
            </Typography>
          </li>
        </ul>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          className="btn-height48"
          variant="outlined"
          fullWidth
          onClick={() => onClose(false)}
          sx={{
            color: '#1E1E1E',
            border: '1px solid rgba(145, 158, 171, 0.32)',
            '&:hover': {
              backgroundColor: '#fff',
              border: '1px solid rgba(145, 158, 171, 0.32)',
              boxShadow: '0 8px 8px 0 rgb(158 158 158 / 16%)',
            },
          }}
        >
          {t('common.Cancel')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          fullWidth
          onClick={() => onClose(true)}
          className="btn-height48"
        >
          {t('common.Confirm')}
        </Button>
      </DialogActions>
    </CommonDialog>
  );
}
