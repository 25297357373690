import { AuthUserType, paymentSettingType } from '@/auth/types';
import { observable, makeObservable } from 'mobx';

export class AccountStore {
  // Whether the user has successfully logged in
  isAuthenticated: boolean = false;

  // Whether the merchant chooses successfully
  isSwitchMerchant: boolean = false;

  // Is the user verified successfully?
  Verify: boolean = false;

  /**是否重定向到api订单详情 */
  isRedirectApiOrder: boolean = false;

  // Basic user information
  user: AuthUserType = {
    create_time: 0,
    /** 用户邮箱 **/
    email: '',
    /** 是否开启google 2fa **/
    exists_google_2fa: false,
    /** 是否锁定登录 **/
    exists_lock_pass: false,
    /** 是否开启密码登录 **/
    exists_login_pass: true,
    /** 是否设置支付密码 **/
    exists_pay_pass: false,
    /** google 2fa的密钥 **/
    gg_verify_secret_key: '',
    /** google 2fa的二维码 **/
    gg_verify_secret_qr: '',
    /** 用户头像 **/
    head_url: '',
    id: 0,
    /** 14天无理由， fasle 需要 true 不需要 **/
    is_check: true,
    /** 密码锁定的次数 **/
    lock_try: 0,
    /** 无操作锁定时间 2-10min 3-30min 4-1hour 5-24hour **/
    lock_unit: 2,
    nickname: '',
    /** 用户电话 **/
    phone: '',
    user_id: '',
    wwl_status: 0,
    security_email: 0,
    security_phone: 0,
    security_withdraw: 0,
    mode_type: 1,
    getAddressNum: 0,
    packageLimit: 0,
    emailNotify: 0,
    /** 商户是否开启了2fa验证 */
    safety2faStatus: 1,
    webhookNotifyMode: 1,
    ipWhitelist: '',
    webhookUrl: '',
    /** 账号的状态 */
    status: 1,
    isMaster: 0,
    /** 商户的的状态 */
    merchantStatus: 0,
    safetyMode: 0,
    merchantId: '',
    dispalyId: '',
    title: '',
    website_hash: '',
    company_website: '',
    company_address: '',
    twitter_url: '',
    telegram_url: '',
    company_industry: '',
    company_industry_text: '',
    main_functions: '',
    main_functions_text: '',
    is_pay_passwd: false,
    test_coin: -1,
    member_num: 0,
    username: '',
    role_id: 1,
    version: 1,
    auto_add_coin: -1,
    withdraw_approve: -1,
    withdraw_approve_setting: {
      limit_types: [],
      limit_qty: '0',
      ttl: 1,
      safety_mode: 0,
    },
  };

  paymentSetting: paymentSettingType = {
    check_hash: '',
    pay_passwd: '',
    totp_code: '',
    sms_code: '',
    mail_code: '',
  };

  constructor() {
    makeObservable(this, {
      isAuthenticated: observable,
      isSwitchMerchant: observable,
      Verify: observable,
      isRedirectApiOrder: observable,
      user: observable,
      paymentSetting: observable,
    });
  }

  // initialization User data
  initData() {
    this.user = {
      create_time: 0,
      /** 用户邮箱 **/
      email: '',
      /** 是否开启google 2fa **/
      exists_google_2fa: false,
      /** 是否锁定登录 **/
      exists_lock_pass: false,
      /** 是否开启密码登录 **/
      exists_login_pass: true,
      /** 是否设置支付密码 **/
      exists_pay_pass: false,
      /** google 2fa的密钥 **/
      gg_verify_secret_key: '',
      /** google 2fa的二维码 **/
      gg_verify_secret_qr: '',
      /** 用户头像 **/
      head_url: '',
      id: 0,
      /** 14天无理由， fasle 需要 true 不需要 **/
      is_check: true,
      /** 密码锁定的次数 **/
      lock_try: 0,
      /** 无操作锁定时间 2-10min 3-30min 4-1hour 5-24hour **/
      lock_unit: 2,
      nickname: '',
      /** 用户电话 **/
      phone: '',
      user_id: '',
      wwl_status: 0,
      security_email: 0,
      security_phone: 0,
      security_withdraw: 0,
      mode_type: 1,
      getAddressNum: 0,
      packageLimit: 0,
      emailNotify: 0,
      /** 商户是否开启了2fa验证 */
      safety2faStatus: 1,
      webhookNotifyMode: 1,
      ipWhitelist: '',
      webhookUrl: '',
      /** 账号的状态 */
      status: 1,
      isMaster: 0,
      /** 商户的的状态 */
      merchantStatus: 0,
      safetyMode: 0,
      merchantId: '',
      dispalyId: '',
      title: '',
      website_hash: '',
      company_website: '',
      company_address: '',
      twitter_url: '',
      telegram_url: '',
      company_industry: '',
      company_industry_text: '',
      main_functions: '',
      main_functions_text: '',
      is_pay_passwd: false,
      test_coin: -1,
      member_num: 0,
      username: '',
      role_id: 1,
      version: 1,
      auto_add_coin: -1,
      withdraw_approve: -1,
      withdraw_approve_setting: {
        limit_types: [],
        limit_qty: '0',
        ttl: 1,
        safety_mode: 0,
      },
    };
    this.isAuthenticated = false;
    this.isSwitchMerchant = false;
    this.isRedirectApiOrder = false;
  }

  // Are you logged in
  isLogin() {
    return this.isAuthenticated;
  }

  //Are you logged in
  isVerify() {
    return this.Verify;
  }

  async setPaymentSetting(paymentSetting: paymentSettingType) {
    this.paymentSetting = { ...paymentSetting };
  }

  async setPaymentInit() {
    this.paymentSetting = {
      check_hash: '',
      pay_passwd: '',
      totp_code: '',
      sms_code: '',
      mail_code: '',
    };
  }

  // Update login user information
  async updateUser(user: AuthUserType, isAuthenticated: boolean, IsVerify: boolean) {
    if (user) {
      this.user = { ...user };
    }

    this.isAuthenticated = isAuthenticated;
    this.Verify = IsVerify;
  }

  // Update choose merchant status
  async updateSwitchStatus(status: boolean) {
    this.isSwitchMerchant = status;
  }

  async updateIsRedirectApiOrder(status: boolean) {
    this.isRedirectApiOrder = status;
  }
}
