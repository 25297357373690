import { makeObservable, observable } from 'mobx';
import { enqueueSnackbar } from 'notistack';
import i18n from 'i18next';
import { WebhookLogListRes } from '@/api/webhook-api_';
import { webhookApi } from '@/api';

// WebhookLogListSearchParam
interface searchParamProps {
  /** API Deposit、API Withdrawal、Invoice、Direct Deposit */
  type?: 'API Deposit' | 'API Withdrawal' | 'Invoice' | 'Direct Deposit' | string;
  /** pending、processing、success */
  status?: 'pending' | 'processing' | 'success' | string;
  /** record_id、notify_url */
  key?: 'record_id' | 'notify_url';
  value?: string;
  /** success、failed */
  result?: 'success' | 'failed' | string;
  page_num?: number;
  page_size?: number;
  begin_at?: number;
  end_at?: number;
  is_last?: boolean;
}
class CCpWebhookStroe {
  searchParam = {
    type: 'all',
    status: 'all',
    key: 'record_id',
    value: '',
    result: 'all',
    page_num: 1,
    page_size: 20,
    begin_at: 0,
    end_at: 0,
    is_last: false,
  };

  searchLoading = false;

  isResend = false;

  tableData: WebhookLogListRes = {
    list: [],
    pagination: { page_num: 1, page_size: 20, total: 0 },
  };

  constructor() {
    makeObservable(this, {
      searchParam: observable,
      tableData: observable,
      searchLoading: observable,
      isResend: observable,
    });
    this.setSearchParam = this.setSearchParam.bind(this);
    this.queryList = this.queryList.bind(this);
    this.setIsResend = this.setIsResend.bind(this);
  }

  async setSearchParam(param: searchParamProps) {
    const oldsSearchParam = { ...this.searchParam };
    this.searchParam = {
      ...oldsSearchParam,
      ...param,
    };
  }

  async setIsResend(data: boolean) {
    this.isResend = data;
  }

  /**
   * @memberof CCpWebhookStroe
   */
  async queryList() {
    try {
      this.searchLoading = true;
      const data = {
        ...this.searchParam,
        type: this.searchParam.type === 'all' ? '' : this.searchParam.type,
        status: this.searchParam.status === 'all' ? '' : this.searchParam.status,
        result: this.searchParam.result === 'all' ? '' : this.searchParam.result,
      };
      const res = await webhookApi.getWebhookLogList(data as any);

      this.tableData = res;
    } catch (error) {
      if (error?.code) {
        enqueueSnackbar(i18n.t(`new_interface_err.${error.code}`) as string, { variant: 'error' });
      } else {
        enqueueSnackbar(error.message, { variant: 'error' });
      }
    } finally {
      this.searchLoading = false;
    }
  }
}
export default CCpWebhookStroe;
