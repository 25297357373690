import { SystemToken } from '@/api/token_';

import { ChainStatus } from '../balance/Utils';

// Invoice 1 Ordinary order (single/recurring) ManageInvoice 2 master bill
export enum SearchTypeEnum {
  Invoice = 1,
  ManageInvoice = 2,
}

//single list
export type searchOneParamsType = {
  page_num: number;
  page_size: number;
  keyword: string;
  type: number;
  status: number;
  begin_at: number;
};

export interface recurrySearchParams {
  type: number;
  keyword: string;
  status: number;
  begin_at: number;
  end_at: number;
  page_num: number;
  page_size: number;
  [key: string]: any;
}
export interface ManageSearchParams {
  keyword: string;
  dt_start?: number;
  dt_end?: number;
  status: number;
  pagination: {
    page_num: number;
    page_size: number;
  };
}

export type optionsStatusType = {
  value: number;
  label: string;
  labelType?: string;
}[];

export type MarkAsDoneType = {
  bill_id: string;
  remark: string;
};

export interface paysInterface {
  record_id: string;
  chain_id: string;
  contract: string;
  symbol: string;
  amount: string;
  em: number;
  pay_time: number;
  service_fee: string;
  pay_status: string;
  logo: string;
}

export interface refundsInterface {
  record_id: string;
  chain_id: string;
  contract: string;
  symbol: string;
  refund_amount: string;
  pay_time: number;
  network_fee: string;
  fee_style: number;
  refund_status: number;
  logo: string;
}
export type IInvoiceAddress = {
  id: string;
  name: string;
  address: string;
  company: string;
  email: string;
  phone: string;
};
export type IInvoiceItem = {
  currency?: any;
  title: string;
  price: string;
  quantity: number;
  total: string;
  discount_mode?: number;
  discount_number?: string;
};
export type attItem = {
  name: string;
  path: string;
  size: number;
};
export type IInvoice = {
  payment_url: string;
  attachment: attItem[];
  currency_type: number;
  invoice_id: string;
  send_time: number;
  payer_name: string;
  payer_email: string;
  business_name: string;
  business_email: string;
  title: string;
  desc: string;
  item_list: IInvoiceItem[];
  bill_id: string;
  currency: string;
  extra_fee: string;
};

export type currencyOptionType = {
  coin_id: string;
  status?: ChainStatus;
  current_rate: string;
  label: string;
  logo: string;
  mark: string;
  name: string;
  symbol: string;
  rate: string;
  type: number;
  id: number;
};

export type formType = {
  payer_name: string;
  payer_email: string;
  business_name: string;
  business_email: string;
  title: string;
  desc: string;
  token_list: SystemToken[];
  invoice_type: number;
  send_time: string;
  due_time: string;
  emailNotifyTrade: boolean;
  emailNotifyWebhook: boolean;
  period: number;
  is_standing: boolean;
  due_time_issued?: any;
  company_address: string;
  company_website: string;
  twitter_url: string;
  telegram_url: string;
  currency: any;
  coin_id: number;
  [key: string]: any;
};
export interface item_list_type {
  title: string;
  price: string;
  quantity: number;
  total: string;
  discount_number: string;
  discount_mode: number;
}

export interface infoType {
  id?: number;
  bill_id: string;
  invoice_id: string;
  created: number;
  status: number;
  cur_term_num: number;
  term_num: number;
  title: string;
  type: number;
  payer_name: string;
  payer_email: string;
  business_name: string;
  business_email: string;
  item_list: item_list_type[];
  currency_type: number;
  currency: string;
  price: string;
  period: number;
  email_notify: number;
  webhook_notify: number;
  invoice_status: number;
  send_time: number;
  due_time: number;
  overdue_expired: number;
  merchant_id: string;
  had_term_num: number;
  origin_id: string;
  bill_type: string;
  updated: number;
  more: string;
  total_refund_amount: string;
  extra_fee: string;
  currency_id?: number;
  invoice_type: number;
}
