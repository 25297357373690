import { Http } from '@/api/http';
const http = new Http('/payment/v1');
export default class userListApi {
  /**
   * Get the list of user
   */
  getUserList(data: userListParam): Promise<userListResType> {
    return http.postJson('/merchant/user/list', data);
  }

  /**
   * Get the user of all
   */
  getUserAll(): Promise<userAllResType> {
    return http.postJson('/merchant/user/all', {});
  }

  /**
   * Get the list of user banlance
   */
  getUserBanlanceList(data: { user_id: string }): Promise<userBanlanceListResType> {
    return http.postJson('/merchant/user/coin/list', data);
  }

  /**
   * Get the list of user address
   */
  getUserAddressList(data: { user_id: string }): Promise<userAddressListResType> {
    return http.postJson('/merchant/user/address/list', data);
  }

  /**
   * Get the detail of user
   */
  getUserListDetail(data: userListDetailParam): Promise<userListDetailResType> {
    return http.postJson('/user/trade/list', data);
  }
}

export interface userListDetailTypeItem {
  id: string;
  record_id: string;
  app_id: string;
  user_id: string;
  bill_id: string;
  type: string;
  coin_id: string;
  coin_symbol: string;
  before: string;
  after: string;
  amount: string;
  free: string;
  locked: string;
  frozen: string;
  desc: string;
  version: string;
  ref_record_id: string;
  created_at: string;
  symbol: string;
  logo: string;
  network: string;
  pay_time: number;
  chain_id: string;
  em?: number;
}

export interface userListItemSwap {
  from_amount: string;
  record_id: string;
  from_coin_id: string;
  from_coin_symbol: string;
  to_coin_id: string;
  to_amount: string;
  to_coin_symbol: string;
  status: number;
  updated_at: number;
  type: string;
}

export interface userListDetailResType {
  list: userListDetailTypeItem[];
  swap_records: userListItemSwap[];
  total_count: string;
}

export interface userAllResType {
  balance: string;
  user_count: string;
  address_count: string;
  address_use_count: string;
  address_count_limit: string;
}

export interface paginationItem {
  page_num: number;
  page_size: number;
  total?: string;
}

export interface userListDetailParam {
  user_id: string;
  record_id: string;
  coin_id: number;
  types: string[];
  pagination: paginationItem;
  is_swap: boolean;
}

export interface userBanlanceListItemParam {
  coin_id: string;
  coin_symbol: string;
  free: string;
  frozen: string;
  locked: string;
  coin_name: string;
  logo: string;
  balance: string;
  symbol: string;
}

export interface userBanlanceListResType {
  coin_list: userBanlanceListItemParam[];
  balance: string;
}

export interface userAddressListItemParam {
  user_id: string;
  chain: string;
  address: string;
  memo: string;
  usage: string;
  expired: string;
  bind_id: string;
  type: string;
  app_id: string;
  all_chain_name: string;
}

export interface userAddressListResType {
  user_addresses: userAddressListItemParam[];
}

export interface userListParam {
  user_id: string;
  pagination: paginationItem;
}

export interface userListItemParam {
  index: number;
  user_id: string;
  balance: string;
  address_count: string;
  address_use_count: string;
  create_date: string;
}

export interface userListResType {
  merchant_users: userListItemParam[];
  total: string;
}
